import {
  SET_USER,
  SET_USERS,
  SET_USER_LOADING,
  SET_USERS_LOADING,
  SET_TOTAL_USERS,
  DELETE_USER,
  UPDATE_USER,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  users: [],
  usersLoading: false,
  totalUsers: 0,
  user: null,
  userLoading: false,
};

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USERS: {
      return {
        ...state,
        users: action.payload,
        usersLoading: false,
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: action.payload,
        userLoading: false,
      };
    }
    case SET_USERS_LOADING: {
      return {
        ...state,
        usersLoading: action.payload,
      };
    }
    case SET_USER_LOADING: {
      return {
        ...state,
        userLoading: false,
      };
    }
    case SET_TOTAL_USERS: {
      return {
        ...state,
        totalParticipants: action.payload,
      };
    }
    case DELETE_USER: {
      return {
        ...state,
        totalUsers: state.totalUsers - 1,
        users: state.users.filter((p) => p.id !== action.payload),
      };
    }
    case UPDATE_USER: {
      return {
        ...state,
        users: state.users.map((t) =>
          t.id === action.payload.id ? action.payload : t
        ),
        user:
          state.user &&
          (state.user.id === action.payload.id ? action.payload : state.user),
      };
    }
    default:
      return state;
  }
};

export default UserReducer;
