// Import package
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Import file
import {
  getDiscount,
  getDiscountParticipants,
  getDiscountTrainings,
} from "../../appRedux/actions";
import DiscountDetail from "../../components/adminRole/discount/DiscountDetail";
import CircularProgress from "../../components/CircularProgress";
import PageContentWrapper from "../../components/PageContentWrapper";

// Component
export default function DetailDiscountPage() {
  const dispatch = useDispatch();
  const { discount, discountLoading } = useSelector(({ discount }) => discount);
  const { discountParticipants, discountParticipantsLoading } = useSelector(
    ({ discountParticipant }) => discountParticipant
  );
  const { discountTrainings, discountTrainingsLoading } = useSelector(
    ({ discountTraining }) => discountTraining
  );
  const { id } = useParams();
  useEffect(() => {
    dispatch(getDiscount(id));
    dispatch(getDiscountParticipants({ discount_id: id }));
    dispatch(getDiscountTrainings({ discount_id: id }));
  }, [dispatch]);

  return discount &&
    !discountLoading &&
    discountTrainings &&
    !discountTrainingsLoading &&
    discountParticipants &&
    !discountParticipantsLoading ? (
    <PageContentWrapper title="Detail Discount" backLink="/discount">
      <DiscountDetail
        discount={discount}
        discountParticipants={discountParticipants}
        discountTrainings={discountTrainings}
      />
    </PageContentWrapper>
  ) : discountLoading &&
    discountTrainingsLoading &&
    discountParticipantsLoading ? (
    <CircularProgress />
  ) : (
   ""
  );
}
