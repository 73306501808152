// Import package
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

// Import file
import {
  getTrainingCategories,
  createTrainingOption,
  getTrainingOptionCertificateTemplate,
  updateTrainingOption,
} from "../../../../appRedux/actions";
import TrainingOptionForm from "../../../../components/adminRole/trainingOption/TrainingOptionForm";

export default function TrainingOptionFormContainer({ trainingOption }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { trainingCategories, trainingCategoriesLoading } = useSelector(
    ({ trainingCategory }) => trainingCategory
  );
  useEffect(() => {
    dispatch(getTrainingCategories());
  }, [dispatch]);
  const handleSubmit = (data) => {
    if (!trainingOption) {
      dispatch(createTrainingOption(data)).then(() => {
        history.push("/training-option/");
      });
    } else {
      dispatch(
        updateTrainingOption({ ...data, id: trainingOption.id })
      ).then(() => history.push("/training-option/" + trainingOption.id));
    }
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const config = {
      headers: {
        "Content-Type": file.type,
        "x-ms-blob-type": "BlockBlob",
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    try {
      const { upload_url, blob_name, expired_at } = await dispatch(
        getTrainingOptionCertificateTemplate()
      );
      await axios.put(upload_url, file, config);
      onSuccess(blob_name);
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };
  return (
    <TrainingOptionForm
      trainingCategories={trainingCategories}
      trainingCategoriesLoading={trainingCategoriesLoading}
      onSubmit={handleSubmit}
      uploadImage={uploadImage}
      trainingOption={trainingOption}
    />
  );
}
