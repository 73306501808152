import {
  DELETE_AD,
  SET_ADS,
  SET_ADS_LOADING,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  ads: [],
  adsLoadings: false,
};

const AdSlotReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ADS: {
      return {
        ...state,
        ads: action.payload,
        adsLoadings: false,
      };
    }
    case SET_ADS_LOADING: {
      return {
        ...state,
        adsLoadings: action.payload,
      };
    }
    case DELETE_AD: {
      return {
        ...state,
        ads: state.ads.filter((p) => p.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

export default AdSlotReducer;
