// Import package
import React, { useEffect, useState, useCallback } from "react";
import { Space, Select, Row, Col, Typography, Card, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import Carousels from "../../components/participantRole/dashboard/Carousels";
import { createAd, getAdImage, getAds } from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";

export default function AdSlotsPage() {
  const dispatch = useDispatch();
  const { ads, adsLoadings } = useSelector(({ adSlot }) => adSlot);
  useEffect(() => {
    dispatch(getAds());
  }, [dispatch]);
  return adsLoadings ? (
    <CircularProgress />
  ) : ads ? (
    <PageContentWrapper
      title="Slot Iklan"
      extra={
        <Link to="/ads/update">
          <Button
            icon={<VerticalAlignBottomOutlined />}
            type="primary"
            style={{ margin: 0 }}
          >
            Ganti iklan
          </Button>
        </Link>
      }
    >
      <Carousels adsList={ads} />
    </PageContentWrapper>
  ) : (
    <CircularProgress />
  );
}
