// Import package
import React from "react";
import { Descriptions, List } from "antd";
import moment from "moment";

// Import file
import "react-phone-number-input/style.css";
import { toRupiah } from "../../../libs";

// Component
export default function DiscountDetail({
  discount,
  discountParticipants = [],
  discountTrainings = [],
}) {
  const { name, code, percent, expired_at, price, training_options } = discount;
  return (
    <Descriptions bordered size="small" column={1}>
      <Descriptions.Item label="Nama diskon">{name}</Descriptions.Item>
      <Descriptions.Item label="Kode Diskon">{code}</Descriptions.Item>
      <Descriptions.Item label="Potongan">{percent !== null ? percent + "%" : toRupiah(price)}</Descriptions.Item>
      <Descriptions.Item label="Berlaku hingga">
        {moment(expired_at).format("D MMMM YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="Peserta / pelanggan">
        {discountParticipants.length > 0 ? (<List>
          {discountParticipants.map((dt) =>
            <List.Item key={dt.participant}>
              {dt.participant.name} {dt.participant.email && " ( " + dt.participant.email + " )"}
            </List.Item>
          )}
        </List>) : "-"}

      </Descriptions.Item>
      <Descriptions.Item label="Jadwal Training">
        {discountTrainings.length > 0 ? (<List>
          {discountTrainings.map((dt) =>
            <List.Item key={dt}>
              {dt.training.training_option.name}{" "}
              {dt.training.dates.length > 0 &&
                " (" +
                moment(dt.training.dates[0].date).format("D MMMM YYYY") +
                " - " +
                moment(dt.training.dates[dt.training.dates.length - 1].date).format(
                  "D MMMM YYYY"
                ) +
                ")"}
            </List.Item>
          )}
        </List>) : "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Pilihan training">
        {training_options.length > 0 ? (<List>
          {training_options.map((training_option) =>
            <List.Item key={training_option}>
              {training_option}
            </List.Item>
          )}
        </List>) : "-"}
      </Descriptions.Item>
    </Descriptions>
  );
}
