import React, { useState } from "react";
import { Space, Select, Input, DatePicker, Button } from "antd";
import moment from "moment";
import { syncMutations } from "../../../../appRedux/actions/MesinOtomatis";

export default function MutationsListFilter({
  search = "",
  onSearch,
  isDebit = null,
  onSelectIsDebit,
  dateRange = null,
  onChangeDateRange,
  bankAccounts = [],
  bankAccountsLoading = false,
  bankAccount = null,
  onChangeBankAccount,
  enableReset = false,
  onReset,
}) {
  const [searchValue, setSearchValue] = useState(search);
  const [syncEnabled, setSyncEnabled] = useState(true)

  const syncTransactions = () => {
    setSyncEnabled(false)
    syncMutations().then((v) => {
      onSearch(searchValue)
      setSyncEnabled(true)
    }).catch((e) => {
      console.log(e);
    })
  }

  return (
    <Space wrap style={{ marginBottom: 10 }}>
      <Input.Search
        placeholder="Cari"
        style={{ margin: 0 }}
        defaultValue={search}
        onSearch={(v) => onSearch(v)}
        allowClear
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <Select
        placeholder="Tipe"
        value={isDebit}
        onChange={onSelectIsDebit}
        dropdownMatchSelectWidth={false}
      >
        <Select.Option value={null}>Semua tipe</Select.Option>
        <Select.Option value={true}>Uang keluar</Select.Option>
        <Select.Option value={false}>Uang masuk</Select.Option>
      </Select>
      <DatePicker.RangePicker
        format="DD MMMM YYYY"
        onChange={(v) =>
          v
            ? onChangeDateRange([
              v[0].startOf("day").toISOString(),
              v[1].endOf("day").toISOString(),
            ])
            : onChangeDateRange([null, null])
        }
        value={dateRange ? [moment(dateRange[0]), moment(dateRange[1])] : null}
      />
      <Select
        placeholder="Rekening"
        value={bankAccount}
        loading={bankAccountsLoading}
        onChange={onChangeBankAccount}
        dropdownMatchSelectWidth={false}
      >
        <Select.Option value={null}>Semua rekening</Select.Option>
        {bankAccounts.map((b) => (
          <Select.Option key={b.id} value={b.id}>
            {b.name}
          </Select.Option>
        ))}
      </Select>
      {enableReset && (
        <Button
          type="primary"
          style={{ margin: 0 }}
          onClick={() => {
            onReset();
            setSearchValue("");
          }}
        >
          Reset
        </Button>
      )}
      <Button
        type="primary"
        style={{ margin: 0 }}
        onClick={syncTransactions}
        disabled={!syncEnabled}
        loading={!syncEnabled}
      >
        Sync
      </Button>
    </Space>
  );
}
