import {
  DELETE_TRAINER,
  SET_TOTAL_TRAINERS,
  SET_TRAINER,
  SET_TRAINERS,
  SET_TRAINERS_LOADING,
  SET_TRAINER_LOADING,
  UPDATE_TRAINER,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  trainers: [],
  trainersLoading: false,
  trainer: null,
  trainerLoading: false,
  totalTrainers: 0,
};

const TrainerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TRAINERS: {
      return {
        ...state,
        trainers: action.payload,
        trainersLoading: false,
      };
    }
    case SET_TRAINER: {
      return {
        ...state,
        trainer: action.payload,
        trainerLoading: false,
      };
    }
    case SET_TRAINERS_LOADING: {
      return {
        ...state,
        trainersLoading: action.payload,
      };
    }
    case SET_TRAINER_LOADING: {
      return {
        ...state,
        trainerLoading: action.payload,
      };
    }
    case SET_TOTAL_TRAINERS: {
      return {
        ...state,
        totalTrainers: action.payload,
      };
    }
    case DELETE_TRAINER: {
      return {
        ...state,
        totalTrainers: state.totalTrainers - 1,
        trainers: state.trainers.filter((p) => p.id !== action.payload),
      };
    }
    case UPDATE_TRAINER: {
      return {
        ...state,
        trainers: state.trainers.map((t) =>
          t.id === action.payload.id ? action.payload : t
        ),
        trainer:
          state.trainer &&
          (state.trainer.id === action.payload.id
            ? action.payload
            : state.trainer),
      };
    }
    default:
      return state;
  }
};

export default TrainerReducer;
