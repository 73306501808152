// Import package
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardTrainingParticipantsMonthlyGraph } from "../../../appRedux/actions";
import FilterBarChartMonthlyParticipants from "../../../components/adminRole/dashboard/FilterBarChartMonthlyParticipants";
import DashboardBarChart from "../../../components/adminRole/dashboard/DashboardBarChart";
// Component
export default function BarChartMonthlyParticipant({ trainingOptions, locations }) {
  const dispatch = useDispatch();
  const {
    trainingParticipantsMonthlyGraph,
    // trainingParticipantstMonthlyGraphFilter,
  } = useSelector(({ dashboard }) => dashboard);
  const [filter, setFilter] = useState({
    training_option_id: null,
    is_retraining: null,
    start_date: moment().startOf('year').toISOString(),
    end_date: moment().endOf('year').toISOString(),
    location: null,
    category_id: null,
  })
  
  useEffect(() => {
    dispatch(
      getDashboardTrainingParticipantsMonthlyGraph(
        filter
      )
    );
  }, []);

  // const handleChangeBarParticipantPerTraining = (training_option_id) => {
  //   dispatch(
  //     getDashboardTrainingParticipantsMonthlyGraph({
  //       ...trainingParticipantstMonthlyGraphFilter,
  //       training_option_id,
  //     })
  //   );
  // };

  // const handleChangeBarParticipantPerType = (is_retraining) => {
  //   dispatch(
  //     getDashboardTrainingParticipantsMonthlyGraph({
  //       ...trainingParticipantstMonthlyGraphFilter,
  //       is_retraining,
  //     })
  //   );
  // };

  const getParticipantsMonthlyData = () => {
    let list = []
    trainingParticipantsMonthlyGraph.map((data) => {
      list.push({
        name: moment(data.month_year).format("MMMM YYYY"),
        peserta: data.total_training_participants,
      })
    })
    return list
  }
    // [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2].map(function (n) {
    //   let sum = 0;
    //   for (let i = 0; i < trainingParticipantsMonthlyGraph.length; i++) {
    //     if (
    //       trainingParticipantsMonthlyGraph[i].month_year ===
    //       moment().add(n, "months").format("MMMM YYYY")
    //     ) {
    //       sum = trainingParticipantsMonthlyGraph[i].total_training_participants;
    //     }
    //   }
    //   return {
    //     name: moment().add(n, "months").format("MMMM YYYY"),
    //     peserta: sum,
    //   };
    // });
  
  function handleChangeFilter(filter) {
    setFilter(filter)
    dispatch(
      getDashboardTrainingParticipantsMonthlyGraph(filter)
    );
  }

  return (
    <>
      <FilterBarChartMonthlyParticipants
        // valueFilterTraining={
        //   trainingParticipantstMonthlyGraphFilter.training_option_id
        // }
        // onChangeFilterTraining={handleChangeBarParticipantPerTraining}
        trainingOptions={trainingOptions}
        // valueFilterStatus={
        //   trainingParticipantstMonthlyGraphFilter.is_retraining
        // }
        // onChangeFilterStatus={handleChangeBarParticipantPerType}
        locations={locations}
        filter={filter}
        setFilter={handleChangeFilter}
      />
      <DashboardBarChart
        barKey="peserta"
        fillColor="#003366"
        data={getParticipantsMonthlyData()}
        barName="Peserta"
      />
    </>
  );
}
