import React from "react";
import PageContentWrapper from "../../components/PageContentWrapper";
import MutationsListContainer from "../../containers/adminRole/mutation/MutationsListContainer";
import MutationsListFilterContainer from "../../containers/adminRole/mutation/MutationsListFilterContainer";

export default function MutationPage() {
  return (
    <PageContentWrapper 
    title="Mutasi Rekening"
    >
      {/* <MutationSummaryContainer /> */}
      <MutationsListFilterContainer />
      <MutationsListContainer />
    </PageContentWrapper>
  );
}
