import {
  SET_TRAINING_CATEGORIES,
  SET_TRAINING_CATEGORIES_LOADING,
  DELETE_TRAINING_CATEGORY,
  SET_TRAINING_CATEGORY,
  SET_TRAINING_CATEGORY_LOADING,
  UPDATE_TRAINING_CATEGORY,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  trainingCategories: [],
  trainingCategoriesLoading: false,
  trainingCategory: null,
  trainingCategoryLoading: false,
};

const TrainingCategoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TRAINING_CATEGORIES: {
      return {
        ...state,
        trainingCategories: action.payload,
        trainingCategoriesLoading: false,
      };
    }
    case SET_TRAINING_CATEGORY: {
      return {
        ...state,
        trainingCategory: action.payload,
        trainingCategoryLoading: false,
      };
    }
    case SET_TRAINING_CATEGORIES_LOADING: {
      return {
        ...state,
        trainingCategoriesLoading: action.payload,
      };
    }
    case SET_TRAINING_CATEGORY_LOADING: {
      return {
        ...state,
        trainingCategoryLoading: action.payload,
      };
    }
    case DELETE_TRAINING_CATEGORY: {
      return {
        ...state,
        trainingCategories: state.trainingCategories.filter(
          (p) => p.id !== action.payload
        ),
      };
    }
    case UPDATE_TRAINING_CATEGORY: {
      return {
        ...state,
        trainingCategories: state.trainingCategories.map((t) =>
          t.id === action.payload.id ? action.payload : t
        ),
        trainingCategory:
          state.trainingCategory &&
          (state.trainingCategory.id === action.payload.id
            ? action.payload
            : state.trainingCategory),
      };
    }
    default:
      return state;
  }
};

export default TrainingCategoryReducer;
