// Import Package
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Import File
import {
  createTrainingCategory,
  updateTrainingCategory,
} from "../../../../appRedux/actions";
import TraningCategoryForm from "../../../../components/adminRole/trainingCategory/TrainingCategoryForm";

// Component
export default function TrainingCategoriesFormContainer({ trainingCategory }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (data) => {
    if (!trainingCategory) {
      dispatch(
        dispatch(createTrainingCategory(data)).then(() =>
          history.push("/training-category")
        )
      );
    } else {
      dispatch(
        updateTrainingCategory({ ...data, id: trainingCategory.id })
      ).then(() => history.push("/training-category"));
    }
  };

  return (
    <TraningCategoryForm
      onSubmit={handleSubmit}
      trainingCategory={trainingCategory}
    />
  );
}
