import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export default function AddTrainingParticipantButton({ onClick }) {
  return (
    <center>
      <Button type="primary" icon={<PlusOutlined />} onClick={onClick}>
        Tambah
      </Button>
    </center>
  );
}
