// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import EditAds from "../../components/adminRole/EditAds";
import { createAd, deleteAd, getAdImage, getAds } from "../../appRedux/actions";
import axios from "axios";
import CircularProgress from "../../components/CircularProgress";

export default function EditAdsPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = (data) => {
    data.image_path.map((response) =>
      dispatch(createAd({ ...data, image_path: response })).then(() => {
        history.push("/ads");
      })
    );
  };

  const { ads, adsLoadings } = useSelector(({ adSlot }) => adSlot);

  useEffect(() => {
    dispatch(getAds());
  }, [dispatch]);

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const config = {
      headers: {
        "Content-Type": file.type,
        "x-ms-blob-type": "BlockBlob",
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    try {
      const { upload_url, blob_name, expired_at } = await dispatch(
        getAdImage()
      );
      await axios.put(upload_url, file, config);
      onSuccess(blob_name);
      dispatch(createAd({ image_path: blob_name }))
    } catch (e) {
      onError({ e });
    }
  };

  const handleRemove = (v) => {
    dispatch(deleteAd(v.uid))
  }
  return adsLoadings ? (<CircularProgress/>) : ads ? (
    <PageContentWrapper title="Ganti iklan" backLink="/ads">
      <EditAds
        onSubmit={handleSubmit}
        uploadImage={uploadImage}
        adsList={ads}
        onRemove={handleRemove}
      />
    </PageContentWrapper>
  ) : (
    <CircularProgress/>
  )
}
