import { Table, Tag, Space } from "antd";

const columns = () => {
  let result = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => index + 1,
      align: "center",
      responsive: ["sm"],
      width: 10,
    },
    {
      title: "Point",
      dataIndex: "point_used",
      key: "point_used",
      render: (pointUsed) => pointUsed,
    },
    {
      title: "Training",
      dataIndex: "training_registration",
      key: "training_registration",
      render: (trainingRegistration) =>
        trainingRegistration.training.training_option.name,
    },
    {
      title: "Tipe Training",
      dataIndex: "training_registration",
      render: (trainingRegistration) => (
        <>
          <Space>
            {trainingRegistration.training.is_online ? (
              <Tag color="green" style={{ margin: 0 }}>
                ONLINE
              </Tag>
            ) : (
              <Tag color="blue" style={{ margin: 0 }}>
                OFFLINE
              </Tag>
            )}
          </Space>
        </>
      ),
    },
    {
      title: "Jadwal",
      dataIndex: "training_registration",
      key: "training_registration",
      render: (trainingRegistration) =>
        trainingRegistration.training.dates_summary,
    },
  ];
  return result;
};

export default function AffiliatePointHistoryList({
  affiliatePointsHistory = [],
  loading = false,
  totalTrainings = 0,
  onChangePagination,
  pageSize = 10,
}) {
  return (
    <Table
      loading={loading}
      size="small"
      columns={columns()}
      dataSource={affiliatePointsHistory.map((aph) => ({
        ...aph,
        key: aph.id,
      }))}
      pagination={false}
    />
  );
}
