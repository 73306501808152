// Import package
import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import DiscountListContainer from "../../containers/adminRole/Discount/DiscountListContainer";
import KuponListContainer from "../../containers/participantRole/kupon/KuponListContainer";

const AdminRolePage = () => {
  return (
    <PageContentWrapper
      title="Diskon"
      extra={
        <Link to="/discount/add">
          <Button icon={<PlusOutlined />} type="primary" style={{ margin: 0 }}>
            Tambah
          </Button>
        </Link>
      }
    >
      <DiscountListContainer />
    </PageContentWrapper>
  );
};

const ParticipantRolePage = () => {
  return (
    <PageContentWrapper title="Kupon Diskon">
      <KuponListContainer />
    </PageContentWrapper>
  );
};

// Component
export default function DiscountPage() {
  const { authUser } = useSelector(({ auth }) => auth);
  return authUser.role === "admin" ? (
    <AdminRolePage />
  ) : (
    <ParticipantRolePage />
  );
}
