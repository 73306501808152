// Import package
import React from "react";
import { Modal, Button, Form, Input } from "antd";

// Import file
import { generate_random_string } from "../../../libs";

export default function AdminChangePassForm({ visible, onCancel, onSave }) {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Ubah Password"
      style={{ top: 10 }}
      destroyOnClose={true}
      footer={null}
    >
      <Form
        layout="vertical"
        fields={[{ name: "password", value: generate_random_string(10) }]}
        onFinish={(values) => {
          onSave(values.password);
        }}
      >
        <Form.Item
          label="Kata Sandi Baru"
          name="password"
          rules={[{
            required: true,
            message: "Minimal 8 karakter, terdiri dari huruf kapital, huruf kecil, dan angka!",
            pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button onClick={onCancel}>Batal</Button>
          <Button type="primary" htmlType="submit">
            Simpan
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
