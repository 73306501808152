import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Button, Card, Col, Row, Select, Space } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import { getTrainings, setTrainingsPagination } from "../../appRedux/actions";
import SummaryTrainingThisMonthList from "../../components/trainerRole/dashboard/SummaryTrainingThisMonthList";
import SummaryTrainingNextMonthList from "../../components/trainerRole/dashboard/SummaryTrainingNextMonthList";
import CardTotal from "../../components/trainerRole/dashboard/CardTotal";
import DashboardBarChart from "../../components/adminRole/dashboard/DashboardBarChart";
import { getGoogleCalendarAuthUrl, isGoogleCalendarAuthenticated } from "../../appRedux/actions/GoogleCalendar";

export default function TrainerRolePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [barData, setBarData] = useState([])
  const [ltrainings, setLtrainings] = useState([])
  const [notGcalAuth, setNotGcalAuth] = useState(false)
  const [authUrl, setAuthUrl] = useState("")
  let winLogin = useRef(null)

  const initialOptions = { value: "", label: <span>Semua Training</span> }
  const [options, setOptions] = useState([initialOptions])

  const { trainings, trainingsLoading, getTrainingsFilter, totalTrainings } = useSelector(
    ({ training }) => training
  );
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const activeTab = query.get("tab") || "this-month";
  let filter = queryString.parse(useLocation().search);

  useEffect(() => {
    dispatch(
      getTrainings()
    );
    getGoogleCalendarAuthenticated()
  }, []);

  useEffect(() => {
    let ltrainings = trainings.filter((training) => training.total_participants > 0)

    let arr = []
    let arrData = []
    arrData.push(initialOptions)
    ltrainings.map((v) => {
      if (!arr.includes(v.training_option.name)) {
        arr.push(v.training_option.name)
        arrData.push({ value: v.training_option.name, label: <span>{v.training_option.name}</span> })
      }
    })
    arrData.sort((a, b) => {
      const nameA = a.value.toUpperCase();
      const nameB = b.value.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setOptions(arrData)
    setBarData(getParticipantsTaught())
    setLtrainings(ltrainings)
  }, [trainings]);

  const tabList = [
    {
      key: "this-month",
      tab: "Training bulan ini",
    },
    {
      key: "next-month",
      tab: "Training bulan depan",
    },
    {
      key: "all",
      tab: "Semua Training",
    },
  ];

  const handleTabsChange = (key) => {
    if (key === "this-month") {
      history.push("/dashboard?tab=" + key);
    } else {
      history.push("/dashboard?tab=" + key);
    }
  };

  const getParticipantsTaught = (trainingOptionName = "") => {
    let obj = {}
    trainings.map(function (t) {
      if (trainingOptionName == "" || trainingOptionName == t.training_option.name) {
        const index = moment(t.dates[0].date).format("YYYYMM")
        if (!obj[index])
          obj[index] = 0
        obj[index] += t.participants.length
      }
    })

    let arr = []
    for (const index in obj) {
      arr.push({
        name: moment(index + "01").format("MMMM YYYY"),
        peserta: obj[index],
      })
    }
    return arr
  }

  const handleSelectChange = (value) => {
    setBarData(getParticipantsTaught(value))
  }

  const handleChangeTrainingsPagination = (currentPage, pageSize) =>
    dispatch(setTrainingsPagination({ currentPage, pageSize }));

  const getGoogleCalendarAuthenticated = () => {
    isGoogleCalendarAuthenticated().then(() => {
      setNotGcalAuth(false)
    }).catch((error) => {
      setNotGcalAuth(true)
      getGoogleCalendarAuthUrl().then(data => {
        const url = data.authorization_url
        setAuthUrl(url)
      })
    })
  }

  const openWinLogin = () => {
    winLogin = window.open(authUrl)
    let checkConnect = setInterval(function () {
      if (!winLogin || !winLogin.closed)
        return;
      clearInterval(checkConnect);
      getGoogleCalendarAuthenticated();
    }, 1000);
  }

  return (
    <PageContentWrapper title="Dashboard">
      {notGcalAuth &&
        <Alert
          message="Google Calendar"
          description="Jadwal Training belum tersinkron dengan Google Calendar. Perlu mendapatkan otorisasi pengguna."
          type="info"
          showIcon
          action={
            <a target="_blank" onClick={openWinLogin}>
              <Button size="small" type="primary">
                Dapatkan Otorisasi
              </Button>
            </a>
          }
        />
      }

      <CardTotal trainings={trainings} />

      <Row>
        <Row wrap gutter={[16, 16]} justify="space-between">
          <Col></Col>
          <Col><Space wrap>
            <Select
              defaultValue={""}
              dropdownMatchSelectWidth={true}
              onChange={(value) => { handleSelectChange(value) }}
              style={{ borderRadius: 0, width: 200 }}
              options={options}
              showSearch
              filterOption={(input, option) =>
                (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Space></Col>
        </Row>
        <DashboardBarChart
          barKey="peserta"
          fillColor="#003366"
          data={barData}
          barName="Jumlah Peserta Di-ajar"
        />
      </Row>

      <Card
        // extra={<a href="/my-training">Lihat semua</a>}
        style={{
          boxShadow: "0 0 5px 5px rgba(0,0,0,0.03)",
          paddingRight: "20px",
          paddingLeft: "20px",
          paddingTop: "10px",
          paddingBottom: "10px",
          width: "100%",
          borderRadius: "10px",
          marginTop: "1em",
        }}
        tabList={tabList}
        activeTabKey={activeTab}
        onTabChange={handleTabsChange}
      >
        {activeTab === "this-month" && (
          <SummaryTrainingThisMonthList
            trainings={ltrainings.filter(
              (t) =>
                moment(
                  t.dates[0].date
                ).format("MMMM YYYY") ===
                moment().add(0, "months").startOf("month").format("MMMM YYYY")
            )}
            // totalTrainings={totalTrainings}
            trainingsLoading={trainingsLoading}
          // onChangePagination={handleChangeTrainingsPagination}
          // pageSize={getTrainingsFilter.page_size}
          // currentPage={getTrainingsFilter.current_page}
          />
        )}

        {activeTab === "next-month" && (
          <SummaryTrainingNextMonthList
            trainings={ltrainings.filter(
              (t) =>
                moment(
                  t.dates[0].date
                ).format("MMMM YYYY") ===
                moment().add(1, "months").startOf("month").format("MMMM YYYY")
            )}
            // totalTrainings={totalTrainings}
            trainingsLoading={trainingsLoading}
          // onChangePagination={handleChangeTrainingsPagination}
          // pageSize={getTrainingsFilter.page_size}
          // currentPage={getTrainingsFilter.current_page}
          />
        )}

        {activeTab === "all" && (
          <SummaryTrainingNextMonthList
            trainings={ltrainings}
            // totalTrainings={totalTrainings}
            trainingsLoading={trainingsLoading}
          // onChangePagination={handleChangeTrainingsPagination}
          // pageSize={getTrainingsFilter.page_size}
          // currentPage={getTrainingsFilter.current_page}
          />
        )}
      </Card>
    </PageContentWrapper>
  );
}
