// Import package
import React from "react";
import { Modal } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

// Import file
import {
  updateTrainingRegistration,
  deleteTrainingRegistration,
  sendDpPaymentInformtionEmailAndWa,
  sendPaidOffPaymentInformtionEmailAndWa
} from "../../../../appRedux/actions";
import TrainingParticipantActions from "../../../../components/adminRole/trainingParticipant/TrainingParticipantActions";

export default function TrainingParticipantActionsContainer({
  trainingParticipantId,
  trainingRegistration,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleDelete = () => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin menghapus registrasi ini ?",
      onOk: () =>
        dispatch(deleteTrainingRegistration(trainingRegistration.id)).then(() =>
          history.push("/training-registration")
        ),
    });
  };
  const handleCancel = () => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin membatalkan registrasi ini ?",
      onOk: () =>
        dispatch(
          updateTrainingRegistration({
            ...trainingRegistration,
            is_canceled: true,
          })
        ),
    });
  };
  const handleSetPaidOff = () => {
    dispatch(
      updateTrainingRegistration({
        ...trainingRegistration,
        already_paid: trainingRegistration.total_price,
      })
    ).then((data) => dispatch(sendPaidOffPaymentInformtionEmailAndWa({id: data.id})))
  };
  const handleSetPaidDp = () => {
    dispatch(
      updateTrainingRegistration({
        ...trainingRegistration,
        already_paid: 500000,
      })
    ).then((data) => dispatch(sendDpPaymentInformtionEmailAndWa({id: data.id})));
  };
  const handleEdit = () => {
    history.push(
      "/training-registration/" +
        trainingParticipantId +
        "/edit?back_link=" +
        location.pathname
    );
  };
  return (
    <TrainingParticipantActions
      onDelete={handleDelete}
      onCancel={handleCancel}
      onSetPaidOff={handleSetPaidOff}
      onEdit={handleEdit}
      onSetPaidDp={handleSetPaidDp}
      showSetPaidDp={trainingRegistration.status === "waiting_for_down_payment"}
      showSetPaidOff={
        trainingRegistration.status === "waiting_for_paid_off" ||
        trainingRegistration.status === "waiting_for_down_payment"
      }
      showCancel={trainingRegistration.status !== "canceled"}
    />
  );
}
