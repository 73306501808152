// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Import file
import { getTrainingOptions } from "../../../../appRedux/actions";
import TrainingOptionsSelect from "../../../../components/adminRole/trainingOption/TrainingOptionsSelect";

export default function TrainingOptionsSelectContainer({
  selectedTrainingOption,
  onSelect,
}) {
  const { getTrainingOptionsLoading, trainingOptions } = useSelector(
    ({ trainingOption }) => trainingOption
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTrainingOptions({ page_size: 100 }));
  }, [dispatch]);

  return (
    <TrainingOptionsSelect
      loading={getTrainingOptionsLoading}
      trainingOptions={trainingOptions}
      selectedTrainingOption={selectedTrainingOption}
      onSelect={onSelect}
    />
  );
}
