import React from "react";
import { Descriptions, Tag, Typography, Badge, Table } from "antd";
import PhoneNumber from "awesome-phonenumber";
import {
  WalletOutlined,
  UserOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { toRupiah } from "../../../../libs";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";

export default function TrainingParticipantDescription({ data }) {
  const location = useLocation();
  const {
    participant,
    status,
    training,
    is_retraining,
    total_price,
    already_paid,
    transfer_code,
    dp_deadline,
    created_at,
    payments,
    affiliate_code,
    affiliate_point,
    discount_code,
    discount_value,
    is_discount_percent,
    discount_id,
    note,
  } = data;
  console.log(data);
  return (
    <>
      <Descriptions bordered size="small" column={{ xs: 1, md: 2 }}>
        <Descriptions.Item label="Kode" span={2}>
          #{data.id}
        </Descriptions.Item>
        <Descriptions.Item label="Sudah Dibayar">
          {toRupiah(already_paid)}
        </Descriptions.Item>
        <Descriptions.Item label="Total Harga">
          {training.price > total_price && (
            <Typography.Text delete>{toRupiah(training.price)}</Typography.Text>
          )}{" "}
          {toRupiah(total_price)}
        </Descriptions.Item>
        <Descriptions.Item label="Jenis">
          {data.is_retraining ? "Mengulang" : "Reguler"}
        </Descriptions.Item>
        {data.is_retraining &&
          <>
            <Descriptions.Item label="Makan">
              {data.retraining_with_meal ? "Dengan Makan" : "Tanpa Makan"}
            </Descriptions.Item>
            <Descriptions.Item label="Training Kit">
              {data.retraining_received_kit ? "Sudah Dapat" : "Belum Dapat"}
            </Descriptions.Item>
          </>
        }
        <Descriptions.Item label="Kode Transfer">
          {transfer_code ? transfer_code : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Batas waktu pelunasan uang muka">
          {dp_deadline ? moment(dp_deadline).format("LLL") : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {!is_retraining ? (
            <Badge
              style={{ margin: 0 }}
              status={
                status === "paid_off"
                  ? "success"
                  : status === "canceled"
                    ? "error"
                    : "processing"
              }
              text={
                status === "paid_off"
                  ? "Lunas"
                  : status === "canceled"
                    ? "Batal"
                    : status === "waiting_for_paid_off"
                      ? "Belum lunas"
                      : "Belum bayar"
              }
            />
          ) : (
            "-"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Waktu Pendaftaran">
          {moment(created_at).format("LLL")}
        </Descriptions.Item>
        <Descriptions.Item label="Kode diskon">
          {is_discount_percent !== null ? (
            <>
              <Link to={"/discount/" + discount_id}>{discount_code}</Link> (
              {is_discount_percent ? discount_value + "%" : toRupiah(discount_value)}
              )
            </>
          ) : (
            "-"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Penggunaan point">
          {affiliate_point ? affiliate_point : 0}
        </Descriptions.Item>
        <Descriptions.Item label="Kode affiliasi">
          {affiliate_code ? (
            <>
              <Link to={"/participant/?current_page=1&page_size=7&search=" + affiliate_code}>{affiliate_code}</Link> ({5}%)
            </>
          ) : (
            "-"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Catatan">
          {data.note}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <h2 className="title">
        <UserOutlined /> Peserta
      </h2>
      <Descriptions bordered size="small" column={{ xs: 1, md: 2 }}>
        <Descriptions.Item label="Kode" span={2}>
          <Link to={"/participant/" + data.participant_id}>
            #{data.participant_id}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Nama">{participant.name}</Descriptions.Item>

        <Descriptions.Item label="Email">
          <a href={"mailto:" + participant.email}>{participant.email}</a>
        </Descriptions.Item>
        <Descriptions.Item label="Nomor Whatsapp">
          {participant.whatsapp_number ? (
            <a href={"https://wa.me/" + participant.whatsapp_number}>
              {new PhoneNumber(participant.whatsapp_number).getNumber(
                "national"
              )}
            </a>
          ) : (
            "-"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Perusahaan">
          {participant.company ? participant.company : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Posisi / Jabatan">
          {participant.job_position ? participant.job_position : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Ukuran Kaos">
          {participant.shirt_size ? participant.shirt_size : "-"}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <h2 className="title">
        <CalendarOutlined /> Training
      </h2>
      {/* <Divider orientation="" */}
      <Descriptions bordered size="small" column={{ xs: 1, md: 2 }}>
        <Descriptions.Item label="Kode" span={2}>
          <Link
            to={
              "/training/" +
              data.training_id +
              "?back_link=" +
              location.pathname
            }
          >
            #{data.training_id}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Nama">
          {training.training_option.name}
        </Descriptions.Item>
        <Descriptions.Item label="Jadwal">
          {training.dates_summary}
        </Descriptions.Item>
        <Descriptions.Item label="Tipe">
          <Tag
            style={{ margin: 0 }}
            color={data.is_online ? "magenta" : "blue"}
          >
            {data.is_online ? "online" : "offline"}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Lokasi">
          {training.location}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Badge
            style={{ margin: 0 }}
            status={training.is_running ? "success" : "error"}
            text={training.is_running ? "Berjalan" : "Batal"}
          />
        </Descriptions.Item>
      </Descriptions>
      <br />
      <h2 className="title">
        <WalletOutlined /> Pembayaran
      </h2>
      <Table
        size="small"
        dataSource={payments.map((p) => ({ ...p, key: p.id }))}
        pagination={false}
        columns={[
          {
            title: "Jumlah",
            dataIndex: "amount",
            render: (amount) => toRupiah(amount),
          },
          {
            title: "Rekening",
            dataIndex: "bank_account",
            align: "center",
            render: (bankAccount) => bankAccount.name,
          },
          {
            title: "Waktu",
            dataIndex: "time",
            render: (time) => moment(time).format("LLL"),
          },
        ]}
      />
    </>
  );
}
