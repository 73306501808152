// Import Package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Import file
import {
  getDiscounts, getTrainings,
} from "../../../appRedux/actions";
import KuponList from "../../../components/participantRole/kupon/KuponList";

// Component
export default function KuponListContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { discounts } = useSelector(({ discount }) => discount);
  const { trainings } = useSelector(({ training }) => training);
  
  useEffect(() => {
    dispatch(getDiscounts());
    dispatch(getTrainings())
  }, [dispatch]);

  return (
    <KuponList
      discounts={discounts}
      trainings={trainings}
    />
  ) 
}
