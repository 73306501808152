// Import package
import React from "react";

// Import file
import ParticipantFormContainer from "../../containers/adminRole/participant/ParticipantFormContainer";
import PageContentWrapper from "../../components/PageContentWrapper";

// Component
export default function NewParticipantPage() {
  return (
    <PageContentWrapper title="Tambah Peserta" backLink={"/participant"}>
      <ParticipantFormContainer />
    </PageContentWrapper>
  );
}
