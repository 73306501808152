import {
  SET_DISCOUNT,
  SET_DISCOUNTS,
  SET_GET_DISCOUNTS_LOADING,
  SET_DISCOUNT_LOADING,
  SET_TOTAL_DISCOUNTS,
  DELETE_DISCOUNT,
  UPDATE_DISCOUNT,
  SET_DISCOUNT_CODE_SUCCESS,
  SET_LOADING_DISCOUNT_CODE_SUCCESS,
  SET_DISCOUNTS_DATES,
  SET_DISCOUNTS_SEARCH,
  RESET_DISCOUNTS_FILTER,
  SET_DISCOUNTS_PARTICIPANTS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  discounts: [],
  participants: [],
  discountsLoading: false,
  totalDiscounts: 0,
  discount: null,
  discountLoading: false,
  discountCode: null,
  discountCodeLoading: false,
  discountsFilter: {
    search: "",
    start_date: null,
    end_date: null,
  },
};

const DiscountReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DISCOUNT_CODE_SUCCESS: {
      return {
        ...state,
        discountCode: action.payload,
        discountCodeLoading: false,
      };
    }
    case SET_LOADING_DISCOUNT_CODE_SUCCESS: {
      return {
        ...state,
        discountCodeLoading: action.payload,
      };
    }
    case SET_DISCOUNTS: {
      return {
        ...state,
        discounts: action.payload,
        discountsLoading: false,
      };
    }
    case SET_DISCOUNT: {
      return {
        ...state,
        discount: action.payload,
        discountLoading: false,
      };
    }
    case SET_GET_DISCOUNTS_LOADING: {
      return {
        ...state,
        discountsLoading: action.payload,
      };
    }
    case SET_DISCOUNT_LOADING: {
      return {
        ...state,
        discountLoading: action.payload,
      };
    }
    case SET_TOTAL_DISCOUNTS: {
      return {
        ...state,
        totalDiscounts: action.payload,
      };
    }
    case DELETE_DISCOUNT: {
      return {
        ...state,
        totalDiscounts: state.totalDiscounts - 1,
        discounts: state.discounts.filter((p) => p.id !== action.payload),
      };
    }
    case UPDATE_DISCOUNT: {
      return {
        ...state,
        discounts: state.discounts.map((t) =>
          t.id === action.payload.id ? action.payload : t
        ),
        discount:
          state.discount &&
          (state.discount.id === action.payload.id
            ? action.payload
            : state.discount),
      };
    }
    case SET_DISCOUNTS_DATES: {
      return {
        ...state,
        discountsFilter: {
          ...state.discountsFilter,
          start_date: action.payload[0],
          end_date: action.payload[1],
        },
      };
    }
    case SET_DISCOUNTS_SEARCH: {
      return {
        ...state,
        discountsFilter: {
          ...state.discountsFilter,
          search: action.payload,
        },
      };
    }
    case RESET_DISCOUNTS_FILTER: {
      return {
        ...state,
        discountsFilter: {
          ...state.discountsFilter,
          start_date: null,
          search: "",
          end_date: null,
        },
      };
    }
    case SET_DISCOUNTS_PARTICIPANTS: {
      return {
        ...state,
        participants: action.payload,
      };
    }
    default:
      return state;
  }
};

export default DiscountReducer;
