import React from "react";
import ReactDOM from "react-dom";

import NextApp from "./NextApp";
import * as serviceWorker from "./registerServiceWorker";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import moment from "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";
Moment.globalLocale = "id";
moment.locale("id");

ReactDOM.render(<NextApp />, document.getElementById("root"));
serviceWorker.unregister();
