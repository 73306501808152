// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Import file
import { getTrainers } from "../../../../appRedux/actions";
import TrainersSelect from "../../../../components/adminRole/trainer/TrainersSelect";

// Component
export default function TrainersSelectContainer({ onSelect, selectedTrainer }) {
  const { trainers, trainersLoading } = useSelector(({ trainer }) => trainer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTrainers());
  }, [dispatch]);
  return (
    <TrainersSelect
      trainers={trainers}
      loading={trainersLoading}
      onSelect={onSelect}
      selectedTrainer={selectedTrainer}
    />
  );
}
