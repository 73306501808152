import React from "react";
import { Descriptions, Tag, Typography, Badge } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { toRupiah } from "../../../libs";
import moment from "moment";

export default function TrainingRegistrationDetail({ data }) {
  const {
    status,
    training,
    is_retraining,
    total_price,
    already_paid,
    transfer_code,
    dp_deadline,
    created_at,
  } = data;
  return (
    <>
      <Descriptions bordered size="small" column={{ xs: 1, md: 2 }}>
        <Descriptions.Item label="Kode" span={2}>
          #{data.id}
        </Descriptions.Item>
        <Descriptions.Item label="Sudah Dibayar">
          {toRupiah(already_paid)}
        </Descriptions.Item>
        <Descriptions.Item label="Total Harga">
          {training.price > total_price && (
            <Typography.Text delete>{toRupiah(training.price)}</Typography.Text>
          )}{" "}
          {toRupiah(total_price)}
        </Descriptions.Item>
        <Descriptions.Item label="Jenis">
          {data.is_retraining ? "Mengulang" : "Reguler"}
        </Descriptions.Item>
        {data.is_retraining &&
          <>
            <Descriptions.Item label="Makan">
              {data.retraining_with_meal ? "Dengan Makan" : "Tanpa Makan"}
            </Descriptions.Item>
            <Descriptions.Item label="Training Kit">
              {data.retraining_received_kit ? "Sudah Dapat" : "Belum Dapat"}
            </Descriptions.Item>
          </>
        }
        <Descriptions.Item label="Kode Transfer">
          {transfer_code ? transfer_code : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Batas waktu pelunasan uang muka">
          {dp_deadline ? moment(dp_deadline).format("LLL") : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {!is_retraining ? (
            <Badge
              style={{ margin: 0 }}
              status={
                status === "paid_off"
                  ? "success"
                  : status === "canceled"
                  ? "error"
                  : "processing"
              }
              text={
                status === "paid_off"
                  ? "Lunas"
                  : status === "canceled"
                  ? "Batal"
                  : status === "waiting_for_paid_off"
                  ? "Belum lunas"
                  : "Belum bayar"
              }
            />
          ) : (
            "-"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Waktu Pendaftaran">
          {moment(created_at).format("LLL")}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <h2 className="title">
        <CalendarOutlined /> Training
      </h2>
      <Descriptions bordered size="small" column={{ xs: 1, md: 2 }}>
        <Descriptions.Item label="Kode" span={2}>
          #{data.training_id}
        </Descriptions.Item>
        <Descriptions.Item label="Nama">
          {training.training_option.name}
        </Descriptions.Item>
        <Descriptions.Item label="Jadwal">
          {training.dates_summary}
        </Descriptions.Item>
        <Descriptions.Item label="Tipe">
          <Tag
            style={{ margin: 0 }}
            color={data.is_online ? "magenta" : "blue"}
          >
            {data.is_online ? "online" : "offline"}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Lokasi">
          {!data.is_online
            ? training.location
            : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Badge
            style={{ margin: 0 }}
            status={training.is_running ? "success" : "error"}
            text={training.is_running ? "Berjalan" : "Batal"}
          />
        </Descriptions.Item>
      </Descriptions>
      <br />
    </>
  );
}
