import { UPDATE_USERS } from "../../constants/ActionTypes";

import { baseRequest } from "../../util/Api";
import {
  setErrorMessage,
  setSuccessMessage,
  setLoadingMessage,
} from "./Common";
import { v4 } from "uuid";
import { message } from "antd";

export const createUser = (data) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    baseRequest("post", "/users/", null, data)
      .then((newData) => {
        dispatch(setSuccessMessage("Berhasil membuat user", message));
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const updateUser = (user) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    baseRequest("put", "/users/" + user.id, null, user)
      .then((newData) => {
        dispatch({
          type: UPDATE_USERS,
          payload: newData,
        });
        dispatch(setSuccessMessage("Berhasil memperbarui user", messageKey));
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage("Gagal memperbarui user, " + e, messageKey));
      });
  });

export const updateUserPass =
  ({ old_password, password, confirm_new_password }) =>
  (dispatch) =>
    new Promise((resolve) => {
      const messageKey = v4();
      dispatch(setLoadingMessage("Memperbarui password ...", messageKey));
      baseRequest("post", "/users/edit-password", null, {
        old_password,
        password,
        confirm_new_password,
      })
        .then((newData) => {
          dispatch(setSuccessMessage("Password berhasil diubah", messageKey));
          dispatch({
            type: UPDATE_USERS,
            payload: newData,
          });
          resolve(newData);
        })
        .catch((e) => {
          dispatch(setErrorMessage(e, messageKey));
        });
    });
