import {
  SET_TRAINING_PARTICIPANTS,
  SET_TRAINING_PARTICIPANTS_LOADING,
  DELETE_TRAINING_PARTICIPANT,
  CREATE_TRAINING_PARTICIPANT,
  SET_PAID_TRAINING_PARTICIPANT,
  SET_TRAINING_PARTICIPANT,
  UPDATE_TRAINING_PARTICIPANT,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  trainingParticipants: [],
  trainingParticipantsLoading: false,
  trainingParticipant: null,
};

const TrainingParticipantReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TRAINING_PARTICIPANTS: {
      return {
        ...state,
        trainingParticipants: action.payload,
        trainingParticipantsLoading: false,
      };
    }
    case SET_TRAINING_PARTICIPANT: {
      return {
        ...state,
        trainingParticipant: action.payload,
      };
    }
    case SET_TRAINING_PARTICIPANTS_LOADING: {
      return {
        ...state,
        trainingParticipantsLoading: action.payload,
      };
    }
    case DELETE_TRAINING_PARTICIPANT: {
      return {
        ...state,
        trainingParticipants: state.trainingParticipants.filter(
          (p) => p.id !== action.payload
        ),
      };
    }
    case CREATE_TRAINING_PARTICIPANT: {
      return {
        ...state,
        trainingParticipants: state.trainingParticipants.concat(action.payload),
      };
    }
    case SET_PAID_TRAINING_PARTICIPANT: {
      return {
        ...state,
        trainingParticipants: state.trainingParticipants.map((p) =>
          p.id === action.payload ? { ...p, lack_of_money: 0 } : p
        ),
      };
    }
    case UPDATE_TRAINING_PARTICIPANT: {
      return {
        ...state,
        trainingParticipants: state.trainingParticipants.map((p) =>
          p.id === action.payload.id ? action.payload : p
        ),
      };
    }
    default:
      return state;
  }
};

export default TrainingParticipantReducer;
