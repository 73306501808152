// Import Package
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Import File
import {
  createAdmin,
  updateAdmin,
  createUser,
  updateUser,
} from "../../../appRedux/actions";
import AdminForm from "../../../components/adminRole/admin/AdminForm";

// Component
export default function AdminFormContainer({ admin }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = (data) => {
    if (!admin) {
      dispatch(createAdmin(data))
        .then(() => history.push("/admin"))
    } else {
      dispatch(updateAdmin({ ...data, id: admin.id }))
        .then(() => history.push("/admin"));
    }
  };
  return <AdminForm onSubmit={handleSubmit} admin={admin} />;
}
