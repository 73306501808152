import {
  SET_TRAINING_OPTIONS,
  SET_GET_TRAINING_OPTIONS_LOADING,
  SET_TOTAL_TRAINING_OPTIONS,
  DELETE_TRAINING_OPTION,
  SET_TRAINING_OPTION,
  SET_TRAINING_OPTION_LOADING,
  UPDATE_TRAINING_OPTION,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  trainingOptions: [],
  getTrainingOptionsLoading: false,
  totalTrainingOptions: 0,
  trainingOption: null,
  trainingOptionLoading: false,
};

const TrainingOptionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TRAINING_OPTIONS: {
      return {
        ...state,
        trainingOptions: action.payload,
        getTrainingOptionsLoading: false,
      };
    }
    case SET_GET_TRAINING_OPTIONS_LOADING: {
      return {
        ...state,
        getTrainingOptionsLoading: action.payload,
      };
    }
    case SET_TOTAL_TRAINING_OPTIONS: {
      return {
        ...state,
        totalTrainingOptions: action.payload,
      };
    }
    case DELETE_TRAINING_OPTION: {
      return {
        ...state,
        totalTrainingOptions: state.totalTrainingOptions - 1,
        trainingOptions: state.trainingOptions.filter(
          (t) => t.id !== action.payload
        ),
        trainingOption:
          state.trainingOption &&
          (state.trainingOption.id === action.payload
            ? null
            : state.trainingOption),
      };
    }
    case SET_TRAINING_OPTION: {
      return {
        ...state,
        trainingOption: action.payload,
        trainingOptionLoading: false,
      };
    }
    case SET_TRAINING_OPTION_LOADING: {
      return {
        ...state,
        trainingOptionLoading: action.payload,
      };
    }
    case UPDATE_TRAINING_OPTION: {
      return {
        ...state,
        trainingOptions: state.trainingOptions.map((t) =>
          t.id === action.payload.id ? action.payload : t
        ),
        trainingOption:
          state.trainingOption &&
          (state.trainingOption.id === action.payload.id
            ? action.payload
            : state.trainingOption),
      };
    }
    default:
      return state;
  }
};

export default TrainingOptionReducer;
