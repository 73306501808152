import { baseRequest } from "../../util/Api";
import {
  SET_BANK_ACCOUNTS_LOADING,
  SET_BANK_ACCOUNTS,
} from "../../constants/ActionTypes";
import { setErrorMessage } from "./Common";

export const getBankAccounts = () => (dispatch) => {
  dispatch({
    type: SET_BANK_ACCOUNTS_LOADING,
    payload: true,
  });
  baseRequest("get", "/bank-accounts/")
    .then((data) => {
      dispatch({
        type: SET_BANK_ACCOUNTS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_BANK_ACCOUNTS_LOADING,
        payload: false,
      });
    });
};
