import {
  SET_PARTICIPANT,
  SET_PARTICIPANTS,
  SET_PARTICIPANTS_LOADING,
  SET_PARTICIPANT_LOADING,
  SET_TOTAL_PARTICIPANTS,
  DELETE_PARTICIPANT,
  UPDATE_PARTICIPANT,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  participants: [],
  participantsLoading: false,
  totalParticipants: 0,
  participant: null,
  participantLoading: false,
};

const ParticipantReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PARTICIPANTS: {
      return {
        ...state,
        participants: action.payload,
        participantsLoading: false,
      };
    }
    case SET_PARTICIPANT: {
      return {
        ...state,
        participant: action.payload,
        participantLoading: false,
      };
    }
    case SET_PARTICIPANTS_LOADING: {
      return {
        ...state,
        participantsLoading: action.payload,
      };
    }
    case SET_PARTICIPANT_LOADING: {
      return {
        ...state,
        participantLoading: action.payload,
      };
    }
    case SET_TOTAL_PARTICIPANTS: {
      return {
        ...state,
        totalParticipants: action.payload,
      };
    }
    case DELETE_PARTICIPANT: {
      return {
        ...state,
        totalParticipants: state.totalParticipants - 1,
        participants: state.participants.filter((p) => p.id !== action.payload),
      };
    }
    case UPDATE_PARTICIPANT: {
      return {
        ...state,
        participants: state.participants.map((t) =>
          t.id === action.payload.id ? action.payload : t
        ),
        participant:
          state.participant &&
          (state.participant.id === action.payload.id
            ? action.payload
            : state.participant),
      };
    }
    default:
      return state;
  }
};

export default ParticipantReducer;
