// Import package
import { v4 } from "uuid";

// Import file
import {
  SET_DISCOUNT_PARTICIPANTS,
  SET_GET_DISCOUNT_PARTICIPANTS_LOADING,
  DELETE_DISCOUNT_PARTICIPANT,
} from "../../constants/ActionTypes";
import { baseRequest } from "../../util/Api";
import {
  setLoadingMessage,
  setSuccessMessage,
  setErrorMessage,
} from "./Common";

// Method-method
export const createDiscountParticipant = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    dispatch(setLoadingMessage("Menambah diskon peserta ...", key));
    baseRequest("post", "/discount-participants/", null, data)
      .then((newData) => {
        dispatch(setSuccessMessage("Menambah diskon peserta berhasil", key));
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
        reject(e);
      });
  });

export const getDiscountParticipants = (filter) => (dispatch) => {
  dispatch({
    type: SET_GET_DISCOUNT_PARTICIPANTS_LOADING,
    payload: true,
  });
  baseRequest("get", "/discount-participants/", filter)
    .then((data) => {
      dispatch({
        type: SET_DISCOUNT_PARTICIPANTS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_GET_DISCOUNT_PARTICIPANTS_LOADING,
        payload: false,
      });
    });
};

export const deleteDiscountParticipant = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    baseRequest("delete", "/discount-participants/" + id)
      .then(() => {
        dispatch({
          type: DELETE_DISCOUNT_PARTICIPANT,
          payload: id,
        });
        resolve();
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
        reject(e);
      });
  });
