// Import package
import React, { useEffect, useState, useCallback } from "react";
import { Button, Card, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
  getAffiliatePointsHistory,
  getParticipant,
  getTrainings,
  setTrainingsLocation,
  setTrainingsPagination,
} from "../../appRedux/actions";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import AffiliateTabs from "../../components/participantRole/affiliate/AffiliateTabs";
import AffiliatePointHistoryList from "../../components/participantRole/affiliate/AffiliatePointHistoryList";
import AffiliateParticipantHistoryList from "../../components/participantRole/affiliate/AffiliateParticipantHistoryList";
import { getAffiliateParticipantsHistory } from "../../appRedux/actions/AffiliateParticipantHistory";
import CircularProgress from "../../components/CircularProgress";

// Component
export default function AffiliatePage() {
  const { authUser } = useSelector(({ auth }) => auth);
  const [dataCode, setDataCode] = useState("");
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const activeTab = query.get("tab") || "history";
  const { affiliateParticipantsHistory, affiliateParticipantsHistoryLoading } =
    useSelector(
      ({ affiliateParticipantsHistory }) => affiliateParticipantsHistory
    );
  const { affiliatePointsHistory, affiliatePointsHistoryLoading } =
    useSelector(
      ({ affiliatePointsHistory }) => affiliatePointsHistory
    );
  const { participant, participantLoading } = useSelector(
    ({ participant }) => participant
  );
  const dispatch = useDispatch();

  const fetchMyAPI = useCallback(async () => {
    let result = await dispatch(getParticipant(authUser.participant.id))
    dispatch(
      getAffiliateParticipantsHistory({
        affiliate_code: result.affiliate_code,
      })
    );
    dispatch(
      getAffiliatePointsHistory()
    )
  }, [dispatch])

  useEffect(() => {
    fetchMyAPI()
  }, [fetchMyAPI]);

  const handleTabsChange = (key) => {
    dispatch(
      getAffiliateParticipantsHistory({
        affiliate_code: participant.affiliate_code,
      })
    );
    dispatch(
      getAffiliatePointsHistory()
    )
    history.push("/affiliate?tab=" + key);
  };

  return participantLoading && affiliateParticipantsHistoryLoading && affiliatePointsHistoryLoading ? (
    <CircularProgress />
  ) : participant && affiliateParticipantsHistory && affiliatePointsHistory ? (
    <PageContentWrapper title="Affiliate">
      <p className="gx-mb-2">
        <b>
          Kode Affiliate Anda: <danger>{participant.affiliate_code}</danger>
        </b>
      </p>
      <p className="gx-mb-2">
        <b>Point Anda: {participant.affiliate_point}</b>
      </p>
      <p className="gx-mb-2">
        <b>Note:</b>
      </p>
      <p className="gx-mb-2">
        **Kode afiliasi hanya bisa digunakan jika pemilik kode afiliasi sudah pernah mengikuti training di IDN.ID
      </p>
      <br />
      <p className="gx-mb-2">
        Anda akan mendapatkan 200 point setiap Ada orang yang mendaftar Training
        Apapun di IDN menggunakan kode affiliate milik Anda
      </p>
      <br />
      <p className="gx-mb-2">
        Affiliate Point bisa dimanfaatkan untuk mengikuti Training IDN.ID
      </p>
      <br />
      <p className="gx-mb-2">
        1 point bernilai 1.000 rupiah. Anda bisa juga memanfaatkan point untuk
        membayar sebagian biaya Training. Sebagai contoh biaya Training
        2.500.000, Kemudian Anda memiliki 500 Point, maka Anda bisa menggunakan
        point tersebut, sehingga Anda hanya perlu membayar 2.000.000 rupiah.
      </p>
      <br />
      <p className="gx-mb-2">
        Bagi siapapun yang mendaftar menggunakan kode affiliate Anda, maka
        mereka akan mendapatkan tambahan diskon 5% Sebagai contoh misal mereka
        daftar tanpa menggunakan diskon kode, maka mereka mendapatkan diskon 5%
        Jika mereka mendaftar menggunakan diskon kode sebesar 25% maka mereka
        akan mendapat diskon 25+5=30%
      </p>
      <br />
      <Space direction="vertical" style={{ width: "100%" }}>
        <AffiliateTabs
          activeKey={activeTab}
          onChange={handleTabsChange}
        />
        {activeTab === "history" && (
          <AffiliatePointHistoryList
            affiliatePointsHistory={affiliatePointsHistory}
            loading={affiliatePointsHistoryLoading}
          //   trainings={trainings}
          //   loading={getTrainingsLoading}
          //   totalTrainings={totalTrainings}
          //   onChangePagination={handleChangeTrainingsPagination}
          />
        )}
        {activeTab === "participant" && (
          <AffiliateParticipantHistoryList
            affiliateParticipantsHistory={affiliateParticipantsHistory}
            loading={affiliateParticipantsHistoryLoading}
            //   totalTrainings={totalTrainings}
            //   onChangePagination={handleChangeTrainingsPagination}
            //   pageSize={getTrainingsFilter.page_size}
            // currentPage={getTrainingsFilter.current_page}
          />
        )}
      </Space>
    </PageContentWrapper>
  ) : (
    <CircularProgress />
  );
}
