// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import CircularProgress from "../../components/CircularProgress";
import { getDiscount, getDiscountParticipants, getDiscountTrainings, getDiscountTrainingOptions } from "../../appRedux/actions";
import DiscountFormContainer from "../../containers/adminRole/Discount/DiscountFormContainer";

// Component
export default function EditDiscountPage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { discount, discountLoading } = useSelector(({ discount }) => discount);

  const { discountParticipants, discountParticipantsLoading } = useSelector(
    ({ discountParticipant }) => discountParticipant
  );

  const { discountTrainings, discountTrainingsLoading } = useSelector(
    ({ discountTraining }) => discountTraining
  );

  const { discountTrainingOptions, discountTrainingOptionsLoading } = useSelector(
    ({ discountTrainingOption }) => discountTrainingOption
  );

  useEffect(() => {
    dispatch(getDiscount(id));
    dispatch(getDiscountParticipants({ discount_id: id }));
    dispatch(getDiscountTrainings({ discount_id: id }));
    dispatch(getDiscountTrainingOptions({ discount_id: id }));
  }, [dispatch]);

  return discount &&
    discountParticipants &&
    discountTrainings &&
    discountTrainingOptions &&
    !discountTrainingsLoading &&
    !discountTrainingOptionsLoading &&
    !discountParticipantsLoading &&
    !discountLoading ? (
    <PageContentWrapper title="Edit Diskon" backLink={"/discount"}>
      <DiscountFormContainer
        discount={discount}
        discountParticipants={discountParticipants}
        discountTrainings={discountTrainings}
        discountTrainingOptions={discountTrainingOptions}
      />
    </PageContentWrapper>
  ) : discountLoading && discountParticipantsLoading && discountTrainingsLoading ? (
    <CircularProgress />
  ) : (
    "Tidak di temukan"
  );
}
