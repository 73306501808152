// Import package
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import _ from "lodash";

// Import file
import { getTrainingCategories } from "../../../../appRedux/actions/TrainingCategory";
import { getTrainingOptions } from "../../../../appRedux/actions/TrainingOption";
import TrainingOptionsListFilter from "../../../../components/adminRole/trainingOption/TrainingOptionsListFilter";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function TrainingOptionsListFilterContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { trainingCategories, trainingCategoriesLoading } = useSelector(
    ({ trainingCategory }) => trainingCategory
  );
  useEffect(() => {
    dispatch(getTrainingCategories());
  }, []);
  let filter = queryString.parse(useLocation().search);
  const handleSearch = (v) => {
    if (v == "") {
      delete filter["search"];
    } else {
      filter["search"] = v;
    }
    filter["current_page"] = 1;
    history.push("/training-option?" + queryString.stringify(filter));
  };

  const handleSelectTrainingCategory = (v) => {
    if (v == "all") {
      delete filter["training_category_id"];
    } else {
      filter["training_category_id"] = v;
    }
    filter["current_page"] = 1;

    history.push("/training-option?" + queryString.stringify(filter));
  };
  useEffect(() => {
    dispatch(getTrainingOptions(filter));
  }, [location.search]);
  return (
    <TrainingOptionsListFilter
      onSearch={handleSearch}
      onSelectTrainingCategory={handleSelectTrainingCategory}
      selectedTrainingCategory={
        filter.training_category_id
          ? parseInt(filter.training_category_id)
          : "all"
      }
      searchValue={filter.search}
      trainingCategories={trainingCategories}
      trainingCategoriesLoading={trainingCategoriesLoading}
    />
  );
}
