import React from "react";
import { ResponsiveContainer, Tooltip, Area, AreaChart } from "recharts";
import { Row, Col } from "antd";
import ChartCard from "../../../components/dashboard/Listing/ChartCard";

export default function ChartCards({
  totalTrainingAttended = 0,
  totalTrainingThisMonth = 0,
  totalTrainingWaitingPaidOff = 0,
  totalBillNotPaid = 0,
}) {
  return (
    <Row>
      <Col xl={6} lg={12} md={12} sm={12} xs={24}>
        <ChartCard
          chartProperties={{
            title: "TRAINING YANG DI IKUTI",
            prize: totalTrainingAttended,
            icon: "stats",
            bgColor: "primary",
            styleName: "up",
            desc: "Total",
          }}
          children={
            <ResponsiveContainer width="100%" height={75}>
              <AreaChart
                //   data={propertiesData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <Tooltip />
                <Area
                  dataKey="properties"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#092453"
                  fill="#092453"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          }
        />
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={24}>
        <ChartCard
          chartProperties={{
            title: "TRAINING BELUM LUNAS ",
            prize: totalTrainingWaitingPaidOff,
            icon: "stats",
            bgColor: "teal",
            styleName: "down",
            desc: "Total",
            percent: "",
          }}
          children={
            <ResponsiveContainer width="100%" height={75}>
              <AreaChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <Tooltip />
                <Area
                  dataKey="visit"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#158765"
                  fill="#158765"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          }
        />
      </Col>
      <Col xl={12} lg={12} md={12} sm={12} xs={24}>
        <ChartCard
          chartProperties={{
            title: "TAGIHAN BELUM LUNAS",
            prize: totalBillNotPaid,
            icon: "stats",
            bgColor: "blue",
            styleName: "down",
            desc: "Total seluruh training",
          }}
          children={
            <ResponsiveContainer width="100%" height={75}>
              <AreaChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <Tooltip />
                <Area
                  dataKey="queries"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#BB1258"
                  fill="#07599d"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          }
        />
      </Col>
    </Row>
  );
}
