import React from "react";
import { Dropdown, Menu, Button } from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

export default function MyTrainingListActions({
  onDelete,
  onAcceptPayment,
  // showSetPaidOffAndSetWaitingPaidOff,
  // showSetWaitingPaidOff,
  isCancel,
  isPaidOffAndDP,
  // onCancel,
  showSetPaidDownPayment,
  isRetraining
}) {
  return isCancel || isPaidOffAndDP || isRetraining ? (<Button type="primary"><a href={"https://wa.me/6281908191001"} target="_blank" rel="noreferrer">Hub Admin</a></Button>) :  (
    <Dropdown.Button
      overlay={
        <Menu>
          {showSetPaidDownPayment && (
            <Menu.Item
              key="2"
              icon={<DeleteOutlined />}
              danger
              onClick={onDelete}
            >
              Hapus
            </Menu.Item>
          )}
          {/* {showSetPaidOffAndSetWaitingPaidOff && (
            <Menu.Item key="3" icon={<PhoneOutlined />}>
              <a href={"https://wa.me/6281908191001"} target="_blank" rel="noreferrer">Hubungi Admin</a>
            </Menu.Item>
          )} */}
          {/* {showSetWaitingPaidOff && (
            <Menu.Item
              key="3"
              icon={<CloseOutlined />}
              danger
              onClick={onCancel}
            >
              Batal
            </Menu.Item>
          )} */}
        </Menu>
      }
      placement="bottomRight"
      trigger={["click"]}
      onClick={isCancel ? null : onAcceptPayment}
      type="primary"
      disabled={isCancel}
    >
        Bayar
    </Dropdown.Button>
  );
}
