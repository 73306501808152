// Import package
import React from "react";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import TrainingOptionFormContainer from "../../containers/adminRole/trainingOption/TrainingOptionFormContainer";

export default function NewTrainingOptionPage() {
  return (
    <PageContentWrapper
      backLink="/training-option"
      title="Tambah Pilihan Training"
    >
      <TrainingOptionFormContainer />
    </PageContentWrapper>
  );
}
