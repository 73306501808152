import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import { ResponsiveContainer, Tooltip } from "recharts";
import { Area, AreaChart } from "recharts";

// Import file
import ChartCard from "../../../components/dashboard/Listing/ChartCard";
import { getTrainings } from "../../../appRedux/actions";
import moment from "moment";
import { citiesData, propertiesData, queriesData, visitsData } from "../../../routes/DashboardPage/AdminRolePage";


export default function CardTotal({ trainings }) {
  const trainingIsOver = trainings.filter(
    (t) => moment(t.dates.at(-1).date).toISOString() < moment(Date.now()).toISOString()
  ).filter(t => t.total_participants > 0).length;

  function trainees() {
    var sum = 0;
    for (let index = 0; index < trainings.length; index++) {
      sum += trainings[index].total_participants;
    }
    return sum;
  }

  function trainingsThisMonth() {
    const thisMonth = trainings.filter(
      (t) =>
        moment(
          t.dates[0].date
        ).format("MMMM YYYY") ===
        moment().add(0, "months").startOf("month").format("MMMM YYYY")
    )
    let participants = 0
    thisMonth.map((value) => {
      participants += value.participants.length
    })
    return {
      sumOfClasses: thisMonth.length,
      sumOfParticipants: participants,
    }
  }

  function trainingsLastMonth() {
    const thisMonth = trainings.filter(
      (t) =>
        moment(
          t.dates[0].date
        ).format("MMMM YYYY") ===
        moment().subtract(1, "months").startOf("month").format("MMMM YYYY")
    )
    let participants = 0
    thisMonth.map((value) => {
      participants += value.participants.length
    })
    return {
      sumOfClasses: thisMonth.length,
      sumOfParticipants: participants,
    }
  }

  function trainingsThisYear() {
    const thisMonth = trainings.filter(
      (t) =>
        moment(
          t.dates[0].date
        ).format("YYYY") ===
        moment().format("YYYY")
    )
    let participants = 0
    thisMonth.map((value) => {
      participants += value.participants.length
    })
    return {
      sumOfClasses: thisMonth.length,
      sumOfParticipants: participants,
    }
  }

  function trainingsLastYear() {
    const thisMonth = trainings.filter(
      (t) =>
        moment(
          t.dates[0].date
        ).format("YYYY") ===
        moment().subtract(1, "years").startOf("month").format("YYYY")
    )
    let participants = 0
    thisMonth.map((value) => {
      participants += value.participants.length
    })
    return {
      sumOfClasses: thisMonth.length,
      sumOfParticipants: participants,
    }
  }

  return (
    <Row>
      <Col xl={6} lg={12} md={12} sm={12} xs={24}>
        <ChartCard
          chartProperties={{
            title: "BULAN INI",
            prize: trainingsThisMonth().sumOfClasses,
            desc: "Kelas",
            prize2: trainingsThisMonth().sumOfParticipants,
            desc2: "Peserta",
            icon: "stats",
            bgColor: "primary",
            styleName: "up",
          }}
          children={
            <ResponsiveContainer width="100%" height={75}>
              <AreaChart
                data={propertiesData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <Tooltip />
                <Area
                  dataKey="properties"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#092453"
                  fill="#092453"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          }
        />
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={24}>
        <ChartCard
          chartProperties={{
            title: "BULAN LALU",
            prize: trainingsLastMonth().sumOfClasses,
            desc: "Kelas",
            prize2: trainingsLastMonth().sumOfParticipants,
            desc2: "Peserta",
            icon: "stats",
            bgColor: "orange",
            styleName: "up",
          }}
          children={
            <ResponsiveContainer width="100%" height={75}>
              <AreaChart
                data={citiesData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <Tooltip />
                <Area
                  dataKey="cities"
                  type="monotone"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#C87000"
                  fill="#C87000"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          }
        />
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={24}>
        <ChartCard
          chartProperties={{
            title: "TAHUN INI",
            prize: trainingsThisYear().sumOfClasses,
            desc: "Kelas",
            prize2: trainingsThisYear().sumOfParticipants,
            desc2: "Peserta",
            icon: "stats",
            bgColor: "teal",
            styleName: "down",
          }}
          children={
            <ResponsiveContainer width="100%" height={75}>
              <AreaChart
                data={visitsData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <Tooltip />
                <Area
                  dataKey="visit"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#158765"
                  fill="#158765"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          }
        />
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={24}>
        <ChartCard
          chartProperties={{
            title: "TAHUN LALU",
            prize: trainingsLastYear().sumOfClasses,
            desc: "Kelas",
            prize2: trainingsLastYear().sumOfParticipants,
            desc2: "Peserta",
            icon: "stats",
            bgColor: "blue",
            styleName: "down",
          }}
          children={
            <ResponsiveContainer width="100%" height={75}>
              <AreaChart
                data={queriesData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <Tooltip />
                <Area
                  dataKey="queries"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#BB1258"
                  fill="#07599d"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          }
        />
      </Col>
      {/* <Col xl={6} lg={12} md={12} sm={12} xs={24}>
        <ChartCard
          chartProperties={{
            title: "TRAINING SUDAH SELESAI",
            prize: trainingIsOver,
            icon: "stats",
            bgColor: "primary",
            styleName: "up",
            desc: "Total",
          }}
          children={
            <ResponsiveContainer width="100%" height={75}>
              <AreaChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <Tooltip />
                <Area
                  dataKey="properties"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#092453"
                  fill="#092453"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          }
        />
      </Col>
      <Col xl={6} lg={12} md={12} sm={12} xs={24}>
        <ChartCard
          chartProperties={{
            title: "PESERTA YANG DILATIH",
            prize: trainees(),
            icon: "stats",
            bgColor: "blue",
            styleName: "up",
            desc: "Total",
            percent: "",
          }}
          children={
            <ResponsiveContainer width="100%" height={75}>
              <AreaChart
                //   data={citiesData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <Tooltip />
                <Area
                  dataKey="cities"
                  type="monotone"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#C87000"
                  fill="#C87000"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          }
        />
      </Col> */}
    </Row>
  );
}
