import {
  SET_DISCOUNT_TRAINING_OPTIONS,
  SET_DISCOUNT_TRAINING_OPTIONS_LOADING,
  DELETE_DISCOUNT_TRAINING_OPTION,
  UPDATE_DISCOUNT_TRAINING_OPTION,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  discountTrainingOptions: [],
  discountTrainingOptionsLoading: false,
};

const DiscountTrainingOptionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DISCOUNT_TRAINING_OPTIONS: {
      return {
        ...state,
        discountTrainingOptions: action.payload,
        discountTrainingOptionsLoading: false,
      };
    }
    case SET_DISCOUNT_TRAINING_OPTIONS_LOADING: {
      return {
        ...state,
        discountTrainingOptionsLoading: action.payload,
      };
    }
    case DELETE_DISCOUNT_TRAINING_OPTION: {
      return {
        ...state,
        discountTrainingOptions: state.discountTrainingOptions.filter(
          (p) => p.id !== action.payload
        ),
      };
    }
    case UPDATE_DISCOUNT_TRAINING_OPTION: {
      return {
        ...state,
        discountTrainingOptions: state.discountTrainingOptions.map((t) =>
          t.id === action.payload.id ? action.payload : t
        ),
      };
    }
    default:
      return state;
  }
};

export default DiscountTrainingOptionReducer;
