// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import { getTrainer, getParticipant, getAdmin } from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import EditPasswordFormContainer from "../../containers/EditPasswordFormContainer";

// Component
export default function EditPasswordPage() {
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <>
      <PageContentWrapper title="Edit Password">
          <EditPasswordFormContainer/>
      </PageContentWrapper>
    </>
  );
}
