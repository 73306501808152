import {
  SET_TRAINING_REGISTRATIONS,
  SET_TRAINING_REGISTRATIONS_LOADING,
  SET_TOTAL_TRAINING_REGISTRATIONS,
  DELETE_TRAINING_REGISTRATION,
  UPDATE_TRAINING_REGISTRATION,
  SET_TRAINING_REGISTRATION,
  SET_TRAINING_REGISTRATION_LOADING,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  trainingRegistrations: [],
  trainingRegistrationsLoading: false,
  totalTrainingRegistrations: 0,
  trainingRegistration: null,
  trainingRegistrationLoading: false,
};

const TrainingRegistrationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TRAINING_REGISTRATIONS: {
      return {
        ...state,
        trainingRegistrations: action.payload,
        trainingRegistrationsLoading: false,
      };
    }
    case SET_TRAINING_REGISTRATIONS_LOADING: {
      return {
        ...state,
        trainingRegistrationsLoading: action.payload,
      };
    }
    case SET_TOTAL_TRAINING_REGISTRATIONS: {
      return {
        ...state,
        totalTrainingRegistrations: action.payload,
      };
    }
    case DELETE_TRAINING_REGISTRATION: {
      return {
        ...state,
        totalTrainingRegistrations: state.totalTrainingRegistrations - 1,
        trainingRegistrations: state.trainingRegistrations.filter(
          (t) => t.id !== action.payload
        ),
      };
    }
    case UPDATE_TRAINING_REGISTRATION: {
      return {
        ...state,
        trainingRegistrations: state.trainingRegistrations.map((t) =>
          t.id === action.payload.id ? action.payload : t
        ),
        trainingRegistration:
          state.trainingRegistration &&
          (state.trainingRegistration.id === action.payload.id
            ? action.payload
            : state.trainingRegistration),
      };
    }
    case SET_TRAINING_REGISTRATION: {
      return {
        ...state,
        trainingRegistration: action.payload,
        trainingRegistrationLoading: false,
      };
    }
    case SET_TRAINING_REGISTRATION_LOADING: {
      return {
        ...state,
        trainingRegistrationLoading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default TrainingRegistrationReducer;
