// Import package
import React from "react";
import { Table, Menu, Dropdown, Switch } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined, UnorderedListOutlined } from "@ant-design/icons";

// Menu
const menu = ({ item, onEdit, onDelete, onParticipants }) => (
  <Dropdown
    overlay={
      <Menu>
        <Menu.Item
          key="1"
          icon={<UnorderedListOutlined />}
          onClick={() => onParticipants(item.id, item.name)}
        >
          Partisipan
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<EditOutlined />}
          onClick={() => onEdit(item.id)}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="0"
          icon={<DeleteOutlined />}
          danger
          onClick={() => onDelete(item.id)}
        >
          Hapus
        </Menu.Item>
      </Menu>
    }
    placement="bottomRight"
    trigger={["click"]}
  >
    <i className="gx-icon-btn icon icon-ellipse-v" />
  </Dropdown>
);

// Columns
const columns = ({ onClickItem, onEdit, onDelete, discounts, onEnabled, onParticipants }) => {
  return [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      responsive: ["sm"],
      width: 1,
    },
    {
      title: "Nama",
      key: "name",
      responsive: ["sm"],
      dataIndex: "name",
      render: (name, field) => <Link to={"/discount/" + field.id}>{name}</Link>,
    },
    {
      title: "Kode",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "Berlaku",
      key: "enabled",
      dataIndex: "enabled",
      render: (enabled, field) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={enabled}
          onChange={(checked) => onEnabled({ ...field, enabled: checked })}
        />
      ),
    },
    {
      title: "Berlaku hingga",
      key: "expired_at",
      dataIndex: "expired_at",
      render: (expiredAt) => moment(expiredAt).format("D MMMM YYYY"),
    },
    {
      width: 10,
      render: (item) => menu({ item, onEdit, onDelete, onParticipants }),
    },
  ];
};

// Component
export default function DiscountList({
  discounts = [],
  loading = false,
  onClickItem,
  onEdit,
  onDelete,
  onEnabled,
  onParticipants,
}) {
  return (
    <Table
      pagination={false}
      loading={loading}
      dataSource={discounts.map((d) => ({ ...d, key: d.id }))}
      columns={columns({ onClickItem, onEdit, onDelete, discounts, onEnabled, onParticipants })}
      tableLayout="auto"
    />
  );
}
