import {
  SET_BANK_TRANSACTIONS,
  SET_BANK_TRANSACTIONS_LOADING,
  SET_TOTAL_BANK_TRANSACTIONS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  bankTransactions: [],
  bankTransactionsLoading: false,
  totalBankTransactions: 0,
};

const BankTransactionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_BANK_TRANSACTIONS: {
      return {
        ...state,
        bankTransactions: action.payload,
        bankTransactionsLoading: false,
      };
    }
    case SET_BANK_TRANSACTIONS_LOADING: {
      return {
        ...state,
        bankTransactionsLoading: action.payload,
      };
    }
    case SET_TOTAL_BANK_TRANSACTIONS: {
      return {
        ...state,
        totalBankTransactions: action.payload,
      };
    }
    default:
      return state;
  }
};

export default BankTransactionReducer;
