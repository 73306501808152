// Import Package
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Space, DatePicker, Input, Table, Tag } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import * as XLSX from 'xlsx/xlsx.mjs';

// Import file
import {
  deleteDiscount,
  deleteDiscountParticipant,
  getDiscountParticipants,
  getDiscounts,
  setDiscountsDates,
  setDiscountsSearch,
  resetDiscountsFilter,
  setDiscountEnabled,
  getParticipantsUsingDiscount,
} from "../../../../appRedux/actions";
import DiscountList from "../../../../components/adminRole/discount/DiscountList";
// import CircularProgress from "../../../components/CircularProgress";
import { baseRequest } from "../../../../util/Api";

// Component
export default function DiscountListContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [exportEnabled, setExportEnabled] = useState(false);
  const { discounts, discountsFilter, participants } = useSelector(({ discount }) => discount);
  const { discountParticipants, discountParticipantsLoading } = useSelector(
    ({ discountParticipant }) => discountParticipant
  );
  useEffect(() => {
    dispatch(getDiscounts(discountsFilter));
  }, [discountsFilter]);

  useEffect(() => {
    const timeOutId = setTimeout(() => dispatch(setDiscountsSearch(query)), 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const handleDelete = (id) => {
    dispatch(getDiscountParticipants({ discount_id: id }));
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin menghapus diskon ini ?",
      onOk: () => {
        // discountParticipants.map((dp) => dp.id !== undefined ? dispatch(deleteDiscountParticipant(dp.id)) : null)
        setTimeout(() => {
          dispatch(deleteDiscount(id)).then(() => dispatch(getDiscounts()))
        }, 200)
      }

    })
  };

  const handleEdit = (id) => {
    history.push("/discount/" + id + "/edit");
  };

  const handleReset = () => dispatch(resetDiscountsFilter());

  const handleEnabled = (discount) => dispatch(setDiscountEnabled(discount));

  const handleExportDiscountParticipants = (data) => {
    let rows = [[
      "Nama",
      "Email",
      "Nomor Whatsapp",
      "Nama Training",
      "Tipe Training",
      "Tanggal Training"
    ]];
    data.map((row) => {
      rows.push([
        row.participant.name,
        row.participant.email,
        row.participant.whatsapp_number,
        row.training.name,
        (row.training.is_online ? "ONLINE" : row.training.location),
        row.training.dates_summary,
      ])
    });
    const ws_name = "partisipan"
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(rows)
    XLSX.utils.book_append_sheet(wb, ws, ws_name)
    XLSX.writeFile(wb, "diskon-partisipan.xlsx")
    return false
  }

  const handleParticipants = async (id, name) => {
    const data = await baseRequest("get", "/discounts/participants/" + id)
    if (!data)
      return
    let key = 0;
    Modal.success({
      title: <>
        <span>Data Partisipan Diskon {name}</span>
        <div style={{ float: 'right' }}>
          <Button
            type="primary"
            style={{ margin: 0 }}
            icon={<FileExcelOutlined />}
            onClick={() => { handleExportDiscountParticipants(data) }}
          >
            Ekspor
          </Button>
        </div>
      </>,
      width: 1024,
      maskClosable: true,
      content: (
        <>
          <Table
            pagination={false}
            loading={false}
            dataSource={data.map((d) => ({ ...d, key: ++key }))}
            columns={[
              {
                title: 'No.',
                dataIndex: 'key',
                rowScope: 'row',
              },
              {
                title: "Nama",
                key: "name",
                responsive: ["sm"],
                dataIndex: "participant",
                render: (participant) => <div>{participant.name}</div>,
              },
              {
                title: "Email",
                key: "email",
                responsive: ["sm"],
                dataIndex: "participant",
                render: (participant) => <div>{participant.email}</div>,
              },
              {
                title: "Nomor Whatsapp",
                key: "whatsapp_number",
                responsive: ["sm"],
                dataIndex: "participant",
                render: (participant) => <div>{participant.whatsapp_number}</div>,
              },
              {
                title: "Nama Training",
                key: "training_name",
                responsive: ["sm"],
                dataIndex: "training",
                render: (training) => <div>{training.name}</div>,
              },
              {
                title: "Tipe Training",
                key: "training_type",
                responsive: ["sm"],
                dataIndex: "training",
                render: (training) => (
                  <>
                    {training.is_online &&
                      <Tag color="green" style={{ margin: 1 }}>ONLINE</Tag>
                    }
                    {((!training.is_online && training.location) || training.is_offline) &&
                      <Tag color="blue" style={{ margin: 1 }}>{training.location}</Tag>
                    }
                  </>
                ),
              },
              {
                title: "Tanggal Training",
                key: "training_dates_summary",
                responsive: ["sm"],
                dataIndex: "training",
                render: (training) => <div>{training.dates_summary}</div>,
              },

            ]}
            tableLayout="auto"
          />
        </>
      ),
    });
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Space wrap>
        <Input
          style={{ margin: 0 }}
          placeholder="ketik pencarian"
          allowClear
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
        <DatePicker.RangePicker
          format="D MMMM YYYY"
          onChange={(v) =>
            v
              ? dispatch(setDiscountsDates(v.map((date) => date.toISOString())))
              : dispatch(setDiscountsDates([null, null]))
          }
          value={[discountsFilter.start_date, discountsFilter.end_date].map((date) => (date ? moment(date) : null))}
        />
        <Button
          type="primary"
          style={{ margin: 0 }}
          disabled={
            discountsFilter.start_date === null &&
            discountsFilter.end_date === null &&
            discountsFilter.search === ""
          }
          onClick={handleReset}
        >
          Reset
        </Button>
      </Space>
      <DiscountList
        discounts={discounts}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onEnabled={handleEnabled}
        onParticipants={handleParticipants}
      />
    </Space>
  )
}
