import {
  SET_TRAINING_PARTICIPANTS_LOADING,
  SET_TRAINING_PARTICIPANTS,
  DELETE_TRAINING_PARTICIPANT,
  CREATE_TRAINING_PARTICIPANT,
  SET_PAID_TRAINING_PARTICIPANT,
  SET_TRAINING_PARTICIPANT,
  UPDATE_TRAINING_PARTICIPANT,
} from "../../constants/ActionTypes";
import { baseRequest } from "../../util/Api";
import {
  setErrorMessage,
  setLoadingMessage,
  setSuccessMessage,
} from "./Common";
import { v4 } from "uuid";
import { createTask } from "./Task";

export const getTrainingParticipants = (filter) => (dispatch) => {
  dispatch({
    type: SET_TRAINING_PARTICIPANTS_LOADING,
    payload: true,
  });
  baseRequest("get", "/training-participants/", filter)
    .then((datas) => {
      dispatch({
        type: SET_TRAINING_PARTICIPANTS,
        payload: datas,
      });
      dispatch({
        type: SET_TRAINING_PARTICIPANTS_LOADING,
        payload: false,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_TRAINING_PARTICIPANTS_LOADING,
        payload: false,
      });
    });
};

export const exportParticipantsCsv = (trainingOptionId, isForAllParticipant) => (dispatch) =>
  new Promise((resolve) => {
    const key = v4();
    dispatch(setLoadingMessage("Mengekspor peserta ...", key));
    baseRequest("get", "/participants/csv", {
      training_option_id: trainingOptionId,
      is_for_all_participant: isForAllParticipant
    })
      .then((data) => {
        dispatch(setSuccessMessage("Eksport berhasil", key));
        resolve(data.download_url);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
      });
  });

export const deleteTrainingParticipant = (id) => (dispatch) =>
  new Promise((resolve) => {
    const key = v4();
    dispatch(setLoadingMessage("Menghapus peserta ...", key));
    baseRequest("delete", "/training-participants/" + id)
      .then(() => {
        dispatch({
          type: DELETE_TRAINING_PARTICIPANT,
          payload: id,
        });
        dispatch(setSuccessMessage("Berhasil menghapus peserta", key));
        resolve();
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
      });
  });

export const createTrainingParticipant = (data) => (dispatch) =>
  new Promise((resolve) => {
    const key = v4();
    baseRequest("post", "/training-participants/", null, data)
      .then((newData) => {
        dispatch({
          type: CREATE_TRAINING_PARTICIPANT,
          payload: newData,
        });
        resolve();
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
      });
  });

export const setPaidTrainingParticipant = (trainingParticipant) => (dispatch) =>
  new Promise((resolve) => {
    const key = v4();
    dispatch(setLoadingMessage("Melunasi peserta ...", key));
    baseRequest(
      "put",
      "/training-participants/" + trainingParticipant.id,
      null,
      {
        ...trainingParticipant,
        lack_of_money: 0,
      }
    )
      .then((newData) => {
        dispatch({
          type: SET_PAID_TRAINING_PARTICIPANT,
          payload: newData.id,
        });
        dispatch(setSuccessMessage("Berhasil melunasi peserta", key));
        resolve();
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
      });
  });

export const setTrainingParticipant = (trainingParticipant) => (dispatch) =>
  dispatch({
    type: SET_TRAINING_PARTICIPANT,
    payload: trainingParticipant,
  });

export const updateTrainingParticipant = (trainingParticipant) => (dispatch) =>
  new Promise((resolve) => {
    const key = v4();
    dispatch(setLoadingMessage("Memperbarui peserta ...", key));
    baseRequest(
      "put",
      "/training-participants/" + trainingParticipant.id,
      null,
      trainingParticipant
    )
      .then((newData) => {
        dispatch({
          type: UPDATE_TRAINING_PARTICIPANT,
          payload: newData,
        });
        dispatch(setSuccessMessage("Berhasil memperbarui peserta", key));
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
      });
  });

export const getTrainingParticipantCertificate = (id) => (dispatch, getState) =>
  new Promise((resolve) => {
    const key = v4();
    dispatch(setLoadingMessage("Membuat sertifikat ...", key));
    dispatch(
      createTask({
        task: "generate_training_participant_certificate",
        args: { training_participant_id: id },
      })
    )
      .then((result) => {
        dispatch(setSuccessMessage("Berhasil membuat sertifikat", key));
        resolve(result.url);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
      });
  });
