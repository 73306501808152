// Import package
import React from "react";
import { Form, Input, Button } from "antd";
import "react-phone-number-input/style.css";

// Component
export default function EditPasswordForm({ onSubmit }) {
  return (
    <Form
      layout="vertical"
      style={{ padding: 10 }}
      onFinish={(values) => {
        onSubmit({ ...values });
      }}
    >
      <Form.Item
        name="old_password"
        label="Old Password"
        rules={[
          {
            required: true,
            message: "Mohon masukkan password lama!",
          },
        ]}
      >
        <Input.Password/>
      </Form.Item>
      <Form.Item
        name="new_password"
        label="New Password"
        rules={[
          {
            required: true,
            message: "Mohon masukkan password baru!",
          },
        ]}
      >
        <Input.Password/>
      </Form.Item>
      <Form.Item
        name="confirm_new_password"
        label="Confirm New Password"
        rules={[
          {
            required: true,
            message: "Mohon masukkan konfirmasi password baru!",
          },
        ]}
      >
        <Input.Password/>
      </Form.Item>
      <Form.Item>
        <center>
          <Button size="large" type="primary" htmlType="submit">
            Simpan
          </Button>
        </center>
      </Form.Item>
    </Form>
  );
}
