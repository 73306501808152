// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Import file
import {
  getTrainingOption,
  getTrainingCategories,
} from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import TrainingOptionFormContainer from "../../containers/adminRole/trainingOption/TrainingOptionFormContainer";
import PageContentWrapper from "../../components/PageContentWrapper";

export default function EditTrainingOptionPage() {
  const dispatch = useDispatch();
  const { trainingOptionId } = useParams();
  const { trainingOption, trainingOptionLoading } = useSelector(
    ({ trainingOption }) => trainingOption
  );
  useEffect(() => {
    dispatch(getTrainingOption(trainingOptionId));
  }, [dispatch]);
  return trainingOption && !trainingOptionLoading ? (
    <PageContentWrapper
      title={"Edit " + trainingOption.name}
      backLink={"/training-option/" + trainingOption.id}
    >
      <TrainingOptionFormContainer trainingOption={trainingOption} />
    </PageContentWrapper>
  ) : trainingOptionLoading ? (
    <CircularProgress />
  ) : (
    "tidak ditemukan"
  );
}
