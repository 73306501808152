import React, {useEffect, useState} from "react";
import {Button, Alert, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {reset, userSignIn} from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";


const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const authUser = useSelector(({auth}) => auth.authUser);
  const [showAlert, setShowAlert] = useState(false);

  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    dispatch(reset(values)).then(() => setShowAlert(true));
  };

  useEffect(() => {
    if (authUser !== null) {
      props.history.push('/');
    }
  }, [authUser, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
      {showAlert && (
        <Alert
          message={"Kami sedang mengirim pesan ke email anda, harap tunggu beberapa saat, lalu cek kotak masuk email anda"}
          type="info"
          showIcon
          closable={showAlert}
          afterClose={() => setShowAlert(false)}
        />
      )}
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
            <img alt="example" src="bg.jpg"/>
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="Lupa Password"/></h1>
              {/* <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p> */}
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo-idn.png" width="150"/>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <Form.Item
                label="Email"
                rules={[{ message: 'The input is not valid E-mail!' }]} name="email">
                <Input placeholder="Masukkan Email"/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="Send Reset Link"/>
                </Button>
                <span>
                  <IntlMessages id="atau" />
                </span>{" "}
                <Link to="/signin">
                  <IntlMessages id="Login" />
                </Link>
              </Form.Item>
            </Form>
          </div>
          <InfoView/>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
