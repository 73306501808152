// Import package
import React from "react";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import TrainerFormContainer from "../../containers/adminRole/trainer/TrainerFormContainer";

// Component
export default function NewTrainerPage() {
    return (
      <PageContentWrapper title="Tambah Trainer" backLink={"/trainer"}>
        <TrainerFormContainer/>
      </PageContentWrapper>
    );
  }
  