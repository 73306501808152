// Import package
import React from "react";
import { Input } from "antd";

// Component
export default function ParticipantSearch({ onSearch, searchValue = "asd" }) {
  return (
    <Input.Search
      style={{ margin: 0 }}
      allowClear
      placeholder="Cari"
      onSearch={onSearch}
      enterButton
    />
  );
}
