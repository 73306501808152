import {
  SET_GET_TRAININGS_LOADING,
  SET_TRAININGS,
  SET_TOTAL_TRAININGS,
  SET_GET_TRAININGS_FILTER,
  SET_TRAININGS_ONLINE,
  SET_TRAININGS_LOCATION,
  SET_TRAININGS_DATES,
  SET_TRAININGS_SEARCH,
  SET_TRAININGS_TRAINING_OPTION,
  SET_TRAININGS_TRAINER,
  SET_TRAININGS_PAGINATION,
  RESET_TRAININGS_FILTER,
  DELETE_TRAINING,
  CANCEL_TRAINING,
  ENABLE_TRAINING,
  SET_CREATE_TRAINING_LOADING,
  CREATE_TRAINING,
  SET_TRAINING,
  SET_TRAINING_LOADING,
  SET_TRAINING_CALENDAR,
  SET_TRAINING_CALENDAR_FILTER,
} from "../../constants/ActionTypes";
import { baseRequest } from "../../util/Api";
import {
  setErrorMessage,
  setLoadingMessage,
  setSuccessMessage,
} from "./Common";
import { v4 } from "uuid";
import { createTask } from "./Task";

export const getTrainings = (filter) => {
  return (dispatch) => {
    dispatch({
      type: SET_GET_TRAININGS_LOADING,
      payload: true,
    });
    (async () => {
      try {
        const { data, meta } = await baseRequest("get", "/trainings/", filter);
        dispatch({
          type: SET_TRAININGS,
          payload: data,
        });
        dispatch({
          type: SET_TOTAL_TRAININGS,
          payload: meta.total,
        });
      } catch (e) {
        dispatch(setErrorMessage(e));
        dispatch({
          type: SET_GET_TRAININGS_LOADING,
          payload: false,
        });
      }
    })();
  };
};

export const setTrainingsFilter = (filter) => {
  return (dispatch) =>
    dispatch({
      type: SET_GET_TRAININGS_FILTER,
      payload: filter,
    });
};

export const setTrainingsOnline = (isOnline) => (dispatch) =>
  dispatch({
    type: SET_TRAININGS_ONLINE,
    payload: isOnline,
  });
export const setTrainingsLocation = (location) => (dispatch) =>
  dispatch({
    type: SET_TRAININGS_LOCATION,
    payload: location,
  });

export const setTrainingsDates = (dates) => (dispatch) =>
  dispatch({
    type: SET_TRAININGS_DATES,
    payload: dates,
  });

export const setTrainingsSearch = (search) => (dispatch) =>
  dispatch({
    type: SET_TRAININGS_SEARCH,
    payload: search,
  });

export const setTrainingsTrainingOption = (trainingOptionId) => (dispatch) =>
  dispatch({
    type: SET_TRAININGS_TRAINING_OPTION,
    payload: trainingOptionId,
  });

export const setTrainingsTrainer = (trainerId) => (dispatch) =>
  dispatch({
    type: SET_TRAININGS_TRAINER,
    payload: trainerId,
  });

export const setTrainingsPagination = (pagination) => (dispatch) =>
  dispatch({
    type: SET_TRAININGS_PAGINATION,
    payload: pagination,
  });

export const resetTrainingsFilter = () => (dispatch) =>
  dispatch({
    type: RESET_TRAININGS_FILTER,
  });

export const deleteTraining = (trainingId) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menghapus training ...", messageKey));
    (async () => {
      try {
        await baseRequest("delete", "/trainings/" + trainingId);
        dispatch({
          type: DELETE_TRAINING,
          payload: trainingId,
        });
        dispatch(setSuccessMessage("Menghapus training berhasil", messageKey));
        resolve();
      } catch (e) {
        dispatch(setErrorMessage(e, messageKey));
      }
    })();
  });

export const getTrainingAttendance = (trainingId) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Membuat absensi training ...", messageKey));
    dispatch(
      createTask({
        task: "generate_training_attendance",
        args: { training_id: trainingId },
      })
    )
      .then((result) => {
        dispatch(
          setSuccessMessage("Membuat absensi training berhasil", messageKey)
        );
        resolve(result.url);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });
export const getTrainingCertificates = (trainingId) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Membuat sertifikat...", messageKey));
    dispatch(
      createTask({
        task: "generate_training_certificates",
        args: { training_id: trainingId },
      })
    )
      .then((result) => {
        dispatch(setSuccessMessage("Membuat sertifikat berhasil", messageKey));
        resolve(result.url);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });
export const cancelTraining = (trainingId, withNotification) => (dispatch) => {
  const messageKey = v4();
  dispatch(setLoadingMessage("Membatalkan training ...", messageKey));
  dispatch(
    createTask({
      task: "cancel_training",
      args: { training_id: trainingId, with_notification: withNotification },
    })
  )
    .then(() => {
      dispatch(setSuccessMessage("Membatalkan training berhasil", messageKey));
      dispatch({
        type: CANCEL_TRAINING,
        payload: trainingId,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage("Gagal membatalkan training, " + e, messageKey));
    });
};
export const rescheduleTraining = (data) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Mengubah tanggal training ...", messageKey));
    dispatch(
      createTask({
        task: "reschedule_training",
        args: data,
      })
    )
      .then(() => {
        dispatch(
          setSuccessMessage("Mengubah tanggal training berhasil", messageKey)
        );
        resolve();
      })
      .catch((e) => {
        dispatch(
          setErrorMessage("Gagal mengubah tanggal training, " + e, messageKey)
        );
      });
  });

export const enableTraining = (trainingId) => (dispatch) => {
  const messageKey = v4();
  dispatch(setLoadingMessage("Mengaktifkan training ...", messageKey));
  dispatch(
    createTask({ task: "enable_training", args: { training_id: trainingId } })
  )
    .then(() => {
      dispatch(setSuccessMessage("Berhasil mengaktifkan training", messageKey));
      dispatch({
        type: ENABLE_TRAINING,
        payload: trainingId,
      });
    })
    .catch((e) => {
      dispatch(
        setErrorMessage("Gagal mengaktifkan training, " + e, messageKey)
      );
    });
};

export const createTraining = (data) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menambah training ...", messageKey));
    dispatch({
      type: SET_CREATE_TRAINING_LOADING,
      payload: true,
    });
    baseRequest("post", "/trainings/", null, data)
      .then((newData) => {
        dispatch(setSuccessMessage("Berhasil menambah training", messageKey));
        dispatch({
          type: SET_CREATE_TRAINING_LOADING,
          payload: false,
        });
        dispatch({
          type: CREATE_TRAINING,
          payload: newData,
        });
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
        dispatch({
          type: SET_CREATE_TRAINING_LOADING,
          payload: false,
        });
      });
  });
export const getTraining = (trainingId) => (dispatch) => {
  dispatch({
    type: SET_TRAINING_LOADING,
    payload: true,
  });
  baseRequest("get", "/trainings/" + trainingId)
    .then((training) => {
      dispatch({
        type: SET_TRAINING,
        payload: training,
      });
      dispatch({
        type: SET_TRAINING_LOADING,
        payload: false,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_TRAINING_LOADING,
        payload: false,
      });
    });
};

export const updateTraining = (data) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Memperbarui training ...", messageKey));
    baseRequest("put", "/trainings/" + data.id, null, data)
      .then((newData) => {
        dispatch(
          setSuccessMessage("Berhasil memperbarui training", messageKey)
        );
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const getTrainingCalendar = (filter) => (dispatch) => {
  baseRequest("get", "/trainings/calendar", filter)
    .then((data) => {
      dispatch({
        type: SET_TRAINING_CALENDAR,
        payload: data,
      });
      dispatch({
        type: SET_TRAINING_CALENDAR_FILTER,
        payload: filter.trainer_id,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
    });
};
