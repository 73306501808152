import React, { useEffect } from "react";
import MutationsListFilter from "../../../../components/adminRole/mutation/MutationsListFilter";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import { getBankTransactions } from "../../../../appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getBankAccounts } from "../../../../appRedux/actions/BankAccount";

export default function MutationsListFilterContainer() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  let filter = queryString.parse(location.search);
  filter["search"] = filter.search ? filter.search : "";
  filter["is_debit"] = filter.is_debit ? filter.is_debit === "true" : null;
  filter["bank_account_id"] = filter.bank_account_id
    ? parseInt(filter.bank_account_id)
    : null;
  filter["start_date"] = filter.start_date ? filter.start_date : null;
  filter["end_date"] = filter.end_date ? filter.end_date : null;
  const { bankAccounts, bankAccountsLoading } = useSelector(
    ({ bankAccount }) => bankAccount
  );
  const handleSearch = (v) => {
    filter["search"] = v;
    filter["current_page"] = 1;
    history.push("/mutation?" + queryString.stringify(filter));
  };
  const handleSelectIsDebit = (isDebit) => {
    filter["is_debit"] = isDebit;
    filter["current_page"] = 1;
    history.push("/mutation?" + queryString.stringify(filter));
  };
  const handleChangeDateRange = (dates) => {
    filter["start_date"] = dates[0];
    filter["end_date"] = dates[1];
    filter["current_page"] = 1;
    history.push("/mutation?" + queryString.stringify(filter));
  };
  const handleChangeBankAccount = (v) => {
    filter["bank_account_id"] = v;
    filter["current_page"] = 1;
    history.push("/mutation?" + queryString.stringify(filter));
  };
  useEffect(() => {
    dispatch(getBankTransactions(filter));
  }, [location.search]);
  useEffect(() => {
    dispatch(getBankAccounts());
  }, [dispatch]);
  const handleReset = () => {
    history.push("/mutation");
  };
  return (
    <MutationsListFilter
      onSearch={handleSearch}
      search={filter.search}
      onSelectIsDebit={handleSelectIsDebit}
      isDebit={filter.is_debit}
      onChangeDateRange={handleChangeDateRange}
      dateRange={
        filter.start_date && filter.end_date
          ? [filter.start_date, filter.end_date]
          : null
      }
      bankAccounts={bankAccounts}
      bankAccountsLoading={bankAccountsLoading}
      bankAccount={filter.bank_account_id}
      onChangeBankAccount={handleChangeBankAccount}
      onReset={handleReset}
      enableReset={
        filter.search !== "" ||
        filter.is_debit !== null ||
        filter.start_date !== null ||
        filter.end_date !== null ||
        filter.bank_account_id !== null
      }
    />
  );
}
