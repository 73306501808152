// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import { getParticipant } from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import ParticipantFormContainer from "../../containers/adminRole/participant/ParticipantFormContainer";

// Component
export default function EditParticipantPage() {
  const dispatch = useDispatch();
  const { participantId } = useParams();
  const { participant, participantLoading } = useSelector(
    ({ participant }) => participant
  );

  useEffect(() => {
    dispatch(getParticipant(participantId));
  }, [dispatch]);
  return participant && !participantLoading ? (
    <PageContentWrapper
      title={"Edit " + participant.name}
      backLink={"/participant/"}
    >
      <ParticipantFormContainer participant={participant} />
    </PageContentWrapper>
  ) : participantLoading ? (
    <CircularProgress />
  ) : (
    "Tidak di temukan"
  );
}
