// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import { getTrainer, getParticipant, getAdmin } from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import EditProfileContainer from "../../containers/trainerRole/EditProfileContainer";
import EditProfileParticipantContainer from "../../containers/participantRole/EditProfileParticipantContainer";
import EditProfileAdminContainer from "../../containers/adminRole/EditProfileAdminContainer";

const ParticipantRolePage = () => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { participant, participantLoading } = useSelector(
    ({ participant }) => participant
  );

  useEffect(() => {
    dispatch(getParticipant(authUser.participant.id));
  }, [dispatch]);
  return participant && !participantLoading ? (
    <EditProfileParticipantContainer userParticipant={participant} />
  ) : participantLoading ? (
    <CircularProgress />
  ) : (
    "Tidak ditemukan"
  );
};

const TrainerRolePage = () => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { trainer, trainerLoading } = useSelector(({ trainer }) => trainer);

  useEffect(() => {
    dispatch(getTrainer(authUser.trainer.id));
  }, [dispatch]);
  return trainer && !trainerLoading ? (
    <EditProfileContainer user={trainer} />
  ) : trainerLoading ? (
    <CircularProgress />
  ) : (
    "Tidak ditemukan"
  );
};

const AdminRolePage = () => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { admin, adminLoading } = useSelector(({ admin }) => admin);

  useEffect(() => {
    dispatch(getAdmin(authUser.admin.id));
  }, [dispatch]);
  return admin && !adminLoading ? (
    <EditProfileAdminContainer userAdmin={admin} />
  ) : adminLoading ? (
    <CircularProgress />
  ) : (
    "Tidak ditemukan"
  );
};

// Component
export default function EditProfilePage() {
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <>
      <PageContentWrapper title="Edit Profile">
        {authUser.role === "trainer" && <TrainerRolePage />}
        {authUser.role === "participant" && <ParticipantRolePage />}
        {authUser.role === "admin" && <AdminRolePage />}
      </PageContentWrapper>
    </>
  );
}
