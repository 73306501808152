// Import package
import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  DatePicker,
  Divider,
  Radio,
  Select,
  Button,
  Tag,
} from "antd";
import moment from "moment";

// Import file
import "react-phone-number-input/style.css";
import { currencyFormatter, currencyParser, generate_random_string } from "../../../libs";

// Component
export default function DiscountForm({
  onSubmit,
  discount,
  discountParticipants = [],
  discountTrainings = [],
  discountTrainingOptions = [],
  onChangeForSpecifics,
  trainingOptions = [],
  trainingOptionsLoading = false,
  trainings = [],
  trainingsLoading = false,
  searchTraining,
  participants = [],
  participantsLoading = false,
  searchParticipant
}) {
  const [form] = Form.useForm();
  const [isPercentValue, setValueIsPercent] = useState(!discount ? true : discount.percent !== null);
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={
        !discount
          ? {
            percent: 10,
            code: generate_random_string(8),
            for_specific_participants: false,
            for_specific_trainings: false,
            for_specific_training_options: false,
            is_percent: true,
          }
          : {
            ...discount,
            for_specific_training_options: discount.for_specific_training_options ? discount.for_specific_training_options : false,
            is_percent: isPercentValue,
            specific_participants: discountParticipants.map(
              (dp) =>
                dp.participant_id
            ),
            specific_trainings: discountTrainings.map(
              (dt) =>
                dt.training_id
            ),
            specific_training_options: discountTrainingOptions.map(
              (dto) =>
                dto.training_option_id
            ),
            expired_at: moment(discount.expired_at),
          }
      }
      style={{ padding: 10 }}
      onFinish={(values) => {
        onSubmit({ ...values });
        // console.log(values);

      }}
      onValuesChange={(changedValues, allValues) => {
        if (
          "for_specific_trainings" in changedValues ||
          "for_specific_participants" in changedValues ||
          "for_specific_training_categories" in changedValues ||
          "for_specific_training_options" in changedValues ||
          "for_specific_training_types" in changedValues
        ) {
          onChangeForSpecifics({
            for_specific_participants: allValues.for_specific_participants,
            for_specific_trainings: allValues.for_specific_trainings,
            for_specific_training_categories:
              allValues.for_specific_training_categories,
            for_specific_training_options:
              allValues.for_specific_training_options,
            for_specific_training_types: allValues.for_specific_training_types,
          });
        }
      }}
    >
      <Form.Item
        label="Nama Diskon"
        name="name"
        rules={[{ required: true, message: "Mohon isikan nama diskon" }]}
      >
        <Input placeholder="DISKON-HARIRAYA" />
      </Form.Item>
      <Form.Item
        label="Kode"
        name="code"
        rules={[{ required: true, message: "Mohon isikan kode diskon" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Potongan" name="is_percent">
        <Radio.Group
          onChange={({ target: { value } }) => {
            setValueIsPercent(value)
          }}
          optionType="button" buttonStyle="solid">
          <Radio.Button value={true}>Persen (%)</Radio.Button>
          <Radio.Button value={false}>Harga (Rp)</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {form.getFieldValue('is_percent') ? (
        <Form.Item
          name="percent"
          rules={[
            {
              required: true,
              type: "number",
              min: 0,
              message: "Mohon isi potongan persen dengan benar",
            },
          ]}
        >
          <InputNumber
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace("%", "")}
          />
        </Form.Item>
      ) : (
        <Form.Item
          name="price"
          rules={[
            {
              required: true,
              type: "number",
              min: 0,
              message: "Mohon isi potongan harga dengan benar",
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            formatter={currencyFormatter}
            parser={currencyParser}
            // max={form.getFieldValue("total_price")}
            step={100000}
          />
        </Form.Item>
      )}
      {!discount && (
        <Form.Item
          label="Berlaku hingga"
          name="expired_at"
          rules={[
            { required: true, message: "Mohon isikan masa aktif diskon" },
          ]}
        >
          <DatePicker disabledDate={(d) => d < moment()} format="D MMMM YYYY" />
        </Form.Item>
      )}
      {discount && (
        <Form.Item
          label="Berlaku hingga"
          name="expired_at"
          rules={[
            { required: true, message: "Mohon isikan masa aktif diskon" },
          ]}
        >
          <DatePicker disabledDate={(d) => d < moment()} format="D MMMM YYYY" />
        </Form.Item>
      )}
      <Divider dashed>Target</Divider>
      <Form.Item name="for_specific_participants" label="Peserta / pelanggan">
        <Radio.Group>
          <Radio.Button value={false}>Semua</Radio.Button>
          <Radio.Button value={true}>Tertentu</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() =>
          form.getFieldValue("for_specific_participants") && (
            <Form.Item
              name="specific_participants"
              rules={[
                {
                  required: true,
                  message: "Mohon isikan nama peserta",
                },
              ]}
            >
              <Select
                showSearch
                defaultActiveFirstOption={false}
                mode="multiple"
                allowClear
                loading={participantsLoading}
                optionFilterProp="label"
                placeholder="Cari nama peserta / pelanggan"
                onSearch={(v) => searchParticipant(v)}
                filterOption={false}
              >
                {participants.map((o) => (
                  <Select.Option key={o.id} value={o.id}>
                    {o.name} {o.email && " ( " + o.email + " )"}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item name="for_specific_trainings" label="Jadwal training">
        <Radio.Group>
          <Radio.Button value={false}>Semua</Radio.Button>
          <Radio.Button value={true}>Tertentu</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() =>
          form.getFieldValue("for_specific_trainings") && (
            <Form.Item
              name="specific_trainings"
              rules={[
                {
                  required: true,
                  message: "Mohon isikan jadwal training",
                },
              ]}>
              <Select
                showSearch
                defaultActiveFirstOption={false}
                mode="multiple"
                allowClear
                loading={trainingsLoading}
                optionFilterProp="label"
                placeholder="Cari jadwal training"
                onSearch={(v) => searchTraining(v)}
                filterOption={false}
              >
                {trainings.map((o) => (
                  <Select.Option key={o.id} value={o.id}>
                    {o.training_option.name}{" "}
                    {o.is_online && (
                      <Tag color="green" style={{ margin: 0 }}>
                        ONLINE
                      </Tag>
                    )}
                    {o.is_offline && (
                      <Tag color="blue" style={{ margin: 0 }}>
                        OFFLINE
                      </Tag>
                    )}
                    {" "}
                    {o.dates.length > 0 &&
                      " (" +
                      moment(o.dates[0].date).format("D MMMM YYYY") +
                      " - " +
                      moment(o.dates[o.dates.length - 1].date).format(
                        "D MMMM YYYY"
                      ) +
                      ")"}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item name="for_specific_training_options" label="Pilihan training">
        <Radio.Group>
          <Radio.Button value={false}>Semua</Radio.Button>
          <Radio.Button value={true}>Tertentu</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() =>
          form.getFieldValue("for_specific_training_options") && (
            <Form.Item
              name="specific_training_options"
              rules={[
                {
                  required: true,
                  message: "Mohon isikan pilihan training",
                },
              ]}>
              <Select
                mode="multiple"
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={filterOption}
                options={
                  trainingOptions.map((o) => {
                    return {
                      value: o.id,
                      label: o.name,
                    }
                  }
                )}
              />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item>
        <center>
          <Button type="primary" htmlType="submit">
            Simpan
          </Button>
        </center>
      </Form.Item>
    </Form>
  );
}
