// Import package
import React from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import Calendar from "react-awesome-calendar";

// Component
export default function TrainingCalendar({trainingCalendar,
  onChange,}) {
  const history = useHistory();

  const getEvents = () => {
    const events = [];
    trainingCalendar.forEach((tc) => {
      events.push({
        id: tc.training_id,
        color: "#fd3153",
        title: tc.training_name,
        from: moment(tc.date).utc(moment.utc().format()).local().format(), // 15 jam 09
        to: moment(tc.date).utc(moment.utc().format()).local().format(), // 15 jam 17
      });
    });
    return events;
  };

  return (
    <Calendar
    events={getEvents()}
    onClickEvent={(id) => history.push("/training/" + id)}
    onChange={onChange}
    />
  );
}
