// Import package
import { v4 } from "uuid";
import { message } from "antd";

// Import file
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  USER_TOKEN_SET,
  UPDATE_USER,
  SET_LOADING_AUTH_USER,
  FAILED_LOGIN,
} from "../../constants/ActionTypes";
import {
  setErrorMessage,
  setSuccessMessage,
  setLoadingMessage,
} from "./Common";
import { baseRequest, client } from "../../util/Api";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const registrationVerify = ({ registrationToken }) => {
  return (dispatch) => {
    (async () => {
      const key = v4();
      dispatch(setLoadingMessage("Memverifikasi ...", key));
      try {
        const result = await baseRequest(
          "get",
          "/registration-verification/" + registrationToken
        );
        dispatch(setSuccessMessage("Berhasil melakukan verifikasi", key));
        localStorage.setItem("token", result.token);
        client.defaults.headers.common["Authorization"] =
          "Bearer " + result.token;
        dispatch({ type: USER_TOKEN_SET, payload: result.token });

        dispatch(getUser(result.token));
      } catch (e) {
        dispatch(setErrorMessage(e, key));
      }
    })();
  };
};

export const reset =
  ({ email }) =>
  (dispatch) =>
    new Promise((resolve) => {
      const key = v4();
      dispatch(setLoadingMessage("Mengirim email ...", key));
      baseRequest("post", "auth/reset", null, {
        email,
      })
        .then((data) => {
          localStorage.setItem("token", data.token);
          client.defaults.headers.common["Authorization"] =
            "Bearer " + data.token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch(setSuccessMessage("Sukses", key));
          dispatch({ type: USER_TOKEN_SET, payload: data.token });
          resolve(data);
        })
        .catch((e) => dispatch(setErrorMessage(e, key)));
    });

export const userSignUp =
  ({ name, email, password, recaptcha  }) =>
  (dispatch) =>
    new Promise((resolve) => {
      dispatch({ type: FETCH_START });
      const messageKey = v4();
      baseRequest("post", "auth/register", null, {
        name,
        email,
        password,
        recaptcha,
      })
        .then((data) => {
          localStorage.setItem("token", data.token);
          client.defaults.headers.common["Authorization"] =
            "Bearer " + data.token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch(setSuccessMessage("Registrasi berhasil", messageKey));
          dispatch({ type: USER_TOKEN_SET, payload: data.token });
          resolve(data);
        })
        .catch((e) => {
          dispatch({ type: FETCH_ERROR, payload: e });
        });
    });

export const userSignIn = ({ email, password, recaptcha }) => {
  return (dispatch) => {
    (async () => {
      dispatch({ type: FETCH_START });
      try {
        const data = await baseRequest("post", "auth/login", null, {
          email,
          password,
          recaptcha,
        });
        localStorage.setItem("token", data.token);
        client.defaults.headers.common["Authorization"] =
          "Bearer " + data.token;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.token });
        dispatch(getUser(data.token));
      } catch (e) {
        dispatch({ type: FAILED_LOGIN });
        dispatch({ type: FETCH_ERROR, payload: e });
      }
    })();
  };
};

export const getUser = (token) => {
  return (dispatch) => {
    if (!token) {
      token = localStorage.getItem("token");
    }
    if (token === null) {
      return;
    }
    client.defaults.headers.common["Authorization"] = "Bearer " + token;
    (async () => {
      const key = v4();
      dispatch({
        type: SET_LOADING_AUTH_USER,
        payload: true,
      });
      try {
        const data = await baseRequest("get", "auth/me");
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: UPDATE_USER,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_AUTH_USER,
          payload: false,
        });
      } catch (e) {
        dispatch(setErrorMessage(e, key));
        dispatch({
          type: SET_LOADING_AUTH_USER,
          payload: false,
        });
        localStorage.removeItem("token");
        dispatch({
          type: UPDATE_USER,
          payload: null,
        });
      }
    })();
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    localStorage.removeItem("token");
    dispatch({
      type: UPDATE_USER,
      payload: null,
    });
  };
};


export const resendVerification = (values) => {
  return new Promise((resolve) => {
    const key = v4();
    message.open({ key, type: 'loading', content: "Mengirim kode verifikasi ..." })
    baseRequest("post", "auth/resend-verification", null, values)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        message.error({ key, type: 'error', content: e })
      });
  });
}