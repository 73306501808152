import React from "react";
import { Descriptions, Typography, Button, Space } from "antd";
import { toRupiah } from "../../../../libs";
import { CloudDownloadOutlined } from "@ant-design/icons";
export default function TrainingOptionDescription({
  trainingOption,
  onDownloadDummyCertificate,
}) {
  const {
    name,
    default_price,
    training_category,
    detail_link,
    certificate_template_blob_path,
    default_day_duration,
    default_max_participant,
    default_max_participant_offline,
    description,
    total_hours,
  } = trainingOption;

  return (
    <Descriptions bordered size="middle" column={{ xs: 1, sm: 1, md: 2 }}>
      <Descriptions.Item label="Nama">{name}</Descriptions.Item>
      <Descriptions.Item label="Harga">
        {toRupiah(default_price)}
      </Descriptions.Item>
      <Descriptions.Item label="Kategori">
        {training_category ? training_category.name : "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Alamat URL detail">
        <Typography.Link href={detail_link}>{detail_link}</Typography.Link>
      </Descriptions.Item>
      <Descriptions.Item label="Durasi (default)">
        {default_day_duration} Hari
      </Descriptions.Item>
      <Descriptions.Item label="Maksimal peserta Online (default)">
        {default_max_participant} Peserta
      </Descriptions.Item>
      <Descriptions.Item label="Maksimal peserta Offline (default)">
        {default_max_participant_offline} Peserta
      </Descriptions.Item>
      <Descriptions.Item label="Deskripsi" span={2}>
        {description ? description : "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Jumlah jam">
        {total_hours} Jam
      </Descriptions.Item>
      <Descriptions.Item label="Sertifikat">
        {certificate_template_blob_path ? (
          <Space>
            <Button
              style={{ margin: 0 }}
              type="primary"
              icon={<CloudDownloadOutlined />}
              onClick={onDownloadDummyCertificate}
            >
              Unduh contoh
            </Button>
          </Space>
        ) : (
          "-"
        )}
      </Descriptions.Item>
    </Descriptions>
  );
}
