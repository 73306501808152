import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getParticipant } from "../../appRedux/actions";
import PageContentWrapper from "../../components/PageContentWrapper";
import MyTrainingListContainer from "../../containers/participantRole/MyTrainingListContainer";
import { Button, Alert } from "antd";
import { Link } from "react-router-dom";
import MyTrainingFilterContainer from "../../containers/participantRole/MyTrainingFilterContainer";
import CircularProgress from "../../components/CircularProgress";
// import PageContentWrapper from "../../constants/components/PageContentWrapper";

export default function MyTrainingPage() {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);
  const { participant, participantLoading } = useSelector(
    ({ participant }) => participant
  );

  useEffect(() => {
    dispatch(getParticipant(authUser.participant.id));
  }, [dispatch]);
  return participant && !participantLoading ? (
    <PageContentWrapper
      title="History Training"
      extra={
        participant.whatsapp_number == null ||
        participant.company == null ||
        participant.job_position == null ||
        participant.address == null ||
        participant.shirt_size == null ? (
          <Button
            type="primary"
            onClick={() => setShowAlert(true)}
            style={{ margin: 0 }}
          >
            Daftar Training
          </Button>
        ) : (
          <Link to="/my-training/training-registration">
            <Button type="primary" style={{ margin: 0 }}>
              Daftar Training
            </Button>
          </Link>
        )
      }
    >
      {showAlert && (
        <Alert
          message={<p>Anda harus melengkapi profile Anda sebelum mendaftar, <Link to="/edit-profile">klik disini</Link></p>}
          type="error"
          closable={showAlert}
          afterClose={() => setShowAlert(false)}
        />
      )}
      <MyTrainingFilterContainer />
      <MyTrainingListContainer />
    </PageContentWrapper>
  ) : participantLoading ? (
    <CircularProgress />
  ) : (
    " "
  );
}
