import React, {useState} from "react";
import Widget from "components/Widget/index";
import { Space, Select, Row, Col } from "antd";


const ChartCard = (props) => {
  const [isHide, setHide] = useState(false);
  const handleToggle = () => {
    setHide(!isHide);
  };

  const {prize, prize2, title, styleName, desc, desc2, bgColor, percent} = props.chartProperties;
  return (
    <Widget styleName={`gx-card-full`}>
      <div
        className={isHide === true ? `gx-fillchart gx-bg-${bgColor} gx-fillchart-nocontent` : `gx-fillchart gx-bg-${bgColor} gx-overlay-fillchart`}>

        <div className="ant-card-head-title">{title}</div>
        {props.children}
        <div className="gx-fillchart-content">
          <div className="ant-card-head-title gx-mb-3">{title}</div>
          <Row wrap gutter={[16, 16]} justify="space-between">
            <Col>
              <h2 className="gx-mb-2 gx-fs-xxxl gx-font-weight-medium">{prize}</h2>
              <p className="gx-mb-0 gx-fs-sm">{desc}</p>
            </Col>
            <Col>
              <h2 className="gx-mb-2 gx-fs-xxxl gx-font-weight-medium">{prize2}</h2>
              <p className="gx-mb-0 gx-fs-sm" >{desc2}</p>
            </Col>
              
          </Row>
          {percent > 0}
          
        </div>
        <div className="gx-fillchart-btn-close" onClick={handleToggle}><i
          className="icon icon-close"/></div>
        <div className="gx-fillchart-btn" onClick={handleToggle}><i
          className={`icon icon-stats gx-fs-xxxl`}/>
        </div>
      </div>
    </Widget>
  );
};

export default ChartCard;
