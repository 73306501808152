// Import package
import React from "react";
import { Table } from "antd";
import moment from "moment";

// Columns
const columns = () => {
  return [
    {
      title: "Nama Diskon",
      key: "name",
      align: "center",
      dataIndex: "name",
      responsive: ["sm"],
      render: (name, field) => (
        <a>{name}</a>
      ),
    },
    {
      title: "Kode Diskon",
      key: "code",
      align: "center",
      dataIndex: "code",
    },
    {
      title: "Diskon persen",
      key: "percent",
      dataIndex: "percent",
      align: "center",
      render: (percent) => percent + "%",
    },
    {
      title: "Berlaku hingga",
      key: "expired_at",
      align: "center",
      dataIndex: "expired_at",
      render: (expiredAt) => moment(expiredAt).format("D MMMM YYYY"),
    },
  ];
};

// Component
export default function KuponList({
  discounts = [],
  trainings,
  loading = false,
}) {
  return (
    <Table
      pagination={false}
      loading={loading}
      dataSource={discounts.map((d) => ({ ...d, key: d.id }))}
      columns={columns({ trainings})}
      tableLayout="auto"
    />
  );
}
