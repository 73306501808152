import React, { useEffect } from "react";
import { message } from "antd";

import { useDispatch, useSelector } from "react-redux";
// import { hideMessage, deleteMessage } from "../../../redux/actions/Common";
import CircularProgress from "components/CircularProgress";
import { hideMessage } from "appRedux/actions/Common";
import { deleteMessage } from "../../appRedux/actions/Common";

const InfoView = () => {
  const dispatch = useDispatch();
  const common = useSelector(({ common }) => common);
  const { error, loading } = common;
  const displayMessage = common.message;
  useEffect(() => {
    setTimeout(() => {
      dispatch(hideMessage());
    }, 3000);
  }, [error, displayMessage]);
  useEffect(() => {
    if (error) {
      message.error(error);
    }
    if (displayMessage) {
      message.info(displayMessage);
    }
  }, [error, displayMessage]);
  useEffect(() => {
    const messages = common.messages;
    if (messages.length > 0) {
      messages.forEach((m) => {
        if (m.type == "loading") {
          message
            .loading({
              content: m.content,
              key: m.key,
              duration: 0,
            })
            .then(() => dispatch(deleteMessage(m.key)));
        } else if (m.type == "success") {
          message
            .success({
              content: m.content,
              key: m.key,
            })
            .then(() => dispatch(deleteMessage(m.key)));
        } else if (m.type == "error") {
          message
            .error({
              content: m.content,
              key: m.key,
            })
            .then(() => dispatch(deleteMessage(m.key)));
        }
      });
    }
  }, [common.messages]);

  return (
    <>
      {loading && (
        <div className="gx-loader-view gx-loader-position">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
export default InfoView;
