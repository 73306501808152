import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  ADD_MESSAGE,
  DELETE_MESSAGE,
} from "../../constants/ActionTypes";
import { v4 } from "uuid";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS,
  };
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error,
  };
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const setLoadingMessage = (content, key = v4()) => {
  return (dispatch) => {
    dispatch({
      type: ADD_MESSAGE,
      payload: {
        type: "loading",
        key,
        content,
        duration: 0,
      },
    });
  };
};

export const setSuccessMessage = (content, key = v4()) => {
  return (dispatch) => {
    dispatch({
      type: ADD_MESSAGE,
      payload: {
        type: "success",
        key,
        content,
      },
    });
  };
};

export const setErrorMessage = (content, key = v4()) => {
  return (dispatch) => {
    dispatch({
      type: ADD_MESSAGE,
      payload: {
        type: "error",
        key,
        content,
      },
    });
  };
};

export const deleteMessage = (key) => {
  return (dispatch) =>
    dispatch({
      type: DELETE_MESSAGE,
      payload: key,
    });
};
