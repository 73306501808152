import { PageHeader, Divider, Row, Col, Card } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import CircularProgress from '../../components/CircularProgress'
import { getCounter } from '../../appRedux/actions/Counter'

export const CounterPage = () => {
  const [data, setData] = useState(null)
  const prevData = useRef(null)
  const [audio] = useState(new Audio("/counter-up.mp3"))

  function getData() {
    getCounter()
      .then(responseData => {
        prevData.current = data
        setData(responseData)
      }
      )
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (prevData.current) {
      if (
        data.last_day != prevData.current.last_day
        || data.last_week != prevData.current.last_week
        || data.last_month != prevData.current.last_month
        || data.this_day != prevData.current.this_day
        || data.this_week != prevData.current.this_week
        || data.this_month != prevData.current.this_month
      ) {
        audio.play()
      }
    }
    prevData.current = data
    const timeout = setTimeout(() => { getData() }, 10000)
    return () => clearTimeout(timeout)
  }, [data])

  return <>
    {data == null ?
      <CircularProgress />
      :
      <>
        <div style={{ borderColor: "#000", height: "100vh" }}>
          <Row gutter={[1, 1]} style={{
            height: "16em",
            backgroundColor: "#9A031E",
            justifyContent: "center",
            color: "#F9FBE7",
            height: "50vh"
          }}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1
            }}>
              <div style={{ textAlign: "center" }}>
                <div>
                  Pendaftar Hari Kemarin
                </div>
                <div style={{ fontSize: "8em" }}>{data.last_day}</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1
            }}>
              <div style={{ textAlign: "center" }}>
                <div>
                  Pendaftar Minggu Lalu
                </div>
                <div style={{ fontSize: "8em" }}>{data.last_week}</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1
            }}>
              <div style={{ textAlign: "center" }}>
                <div>
                  Pendaftar Bulan Lalu
                </div>
                <div style={{ fontSize: "8em" }}>{data.last_month}</div>
              </div>
            </Col>
          </Row>
          <Row gutter={[1, 1]} style={{
            height: "16em",
            justifyContent: "center",
            color: "#9A031E",
            backgroundColor: "#F9FBE7"
          }}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1
            }}>
              <div style={{ textAlign: "center" }}>
                <div>
                  Pendaftar Hari Ini
                </div>
                <div style={{ fontSize: "8em" }}>{data.this_day}</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1
            }}>
              <div style={{ textAlign: "center" }}>
                <div>
                  Pendaftar Minggu Ini
                </div>
                <div style={{ fontSize: "8em" }}>{data.this_week}</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1
            }}>
              <div style={{ textAlign: "center" }}>
                <div>
                  Pendaftar Bulan Ini
                </div>
                <div style={{ fontSize: "8em" }}>{data.this_month}</div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    }
  </>
}
