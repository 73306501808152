// Import package
import React from "react";
import { Carousel } from "antd";

export default function Carousels({ adsList = [] }) {
  const contentStyle = {
    height: "300px",
    color: "#fff",
    lineHeight: "300px",
    textAlign: "center",
    background: "#364d79",
  };
  const getAdData = (adsList) =>
    adsList.map(function (ad) {
      return (
        <div style={contentStyle}>
          <img src={ad.image_path} alt="gambar" />
        </div>
      );
    });
  return (
    <Carousel autoplay>
      {adsList && getAdData(adsList)}
      {adsList.length === 0 && (
        <div>
          <h3 style={contentStyle}>Tidak ada iklan</h3>
        </div>
      )}
    </Carousel>
  );
}
