// Import package
import React from "react";
import { Form, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export default function EditAds({
  onSubmit,
  uploadImage,
  adsList = [],
  adsLoading,
  onRemove,
}) {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getAdData = (adsList) =>
    adsList.map(function (ad) {
      return {
        uid: ad.id,
        name: "Image",
        status: "done",
        url: "https://clientarea.blob.core.windows.net/berkas/" + ad.image_path,
      };
    });
  return (
    <Form
      layout="vertical"
      style={{ padding: 10 }}
      onFinish={(values) => {
        if (!values.image_path) {
          values.image_path = [];
        }
        onSubmit({
          ...values,
          image_path: values.image_path.map((image) => image.response),
        });
      }}
    >
      <Form.Item
        name="image_path"
        label="Iklan"
        rules={[
          {
            required: true,
            message: "Mohon iklan",
          },
        ]}
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload
          method="put"
          listType="picture-card"
          customRequest={uploadImage}
          accept="image/*"
          valuePropName="fileList"
          defaultFileList={getAdData(adsList)}
          onRemove={onRemove}
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Form.Item>
    </Form>
  );
}
