// Import package
import React from "react";
import { Form, Input, InputNumber, Select, Button } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

// Component
export default function ParticipantForm({ onSubmit, participant }) {
  return (
    <Form
      layout="vertical"
      initialValues={
        !participant
          ? {
              shirt_size: "L",
              affiliate_point: 0,
            }
          : {
              ...participant,
            }
      }
      style={{ padding: 10 }}
      onFinish={(values) => {
        onSubmit({ ...values });
      }}
    >
      <Form.Item
        name="name"
        label="Nama lengkap"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nama lengkap peserta!",
          },
        ]}
      >
        <Input placeholder="Dimas Hermawan" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Alamat email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Mohon masukkan alamat email dengan benar",
          },
        ]}
      >
        <Input placeholder="email@gmail.com" />
      </Form.Item>
      {!participant && (
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Masukkan password",
            },
          ]}
        >
          <Input placeholder="Masukkan password" />
        </Form.Item>
      )}
      <Form.Item 
        name="whatsapp_number"
        label="Nomor whatsapp"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nomor whatsapp peserta!"
          }
        ]}>
        <PhoneInput
          defaultCountry="ID"
          style={{ width: "100%" }}
          placeholder="081362778829"
        />
      </Form.Item>
      <Form.Item name="company" label="Perusahaan">
        <Input placeholder="ID-Networkers (IDN.ID)" />
      </Form.Item>
      <Form.Item name="job_position" label="Jabatan / posisi di perusahaan">
        <Input placeholder="Network Engineer" />
      </Form.Item>
      <Form.Item name="address" label="Alamat tempat tinggal">
        <Input.TextArea placeholder="Jl. Anggrek Rosliana no 12A, RT 3/RW 9, Kemanggisan Slipi Palmerah, Jakarta Barat 11480" />
      </Form.Item>
      <Form.Item
        name="affiliate_code"
        label="Kode afiliasi"
        tooltip="Kosongkan jika ingin digenerate otomatis"
      >
        <Input placeholder="abhsjw" />
      </Form.Item>
      <Form.Item
        name="affiliate_point"
        label="Poin afiliasi"
        rules={[{ type: "number", min: 0 }]}
      >
        <InputNumber placeholder="100" style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="shirt_size" label="Ukuran kaos">
        <Select>
          <Select.Option value={null}>Tanpa kaos</Select.Option>
          <Select.Option value="L">
            L (lebar 50 cm, panjang 69 cm)
          </Select.Option>
          <Select.Option value="XL">
            XL (lebar 52 cm, panjang 71 cm)
          </Select.Option>
          <Select.Option value="XXL">
            XXL (lebar 56 cm, panjang 76 cm)
          </Select.Option>
          <Select.Option value="XXXL">
            XXXL (lebar 67 cm, panjang 77 cm)
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="know_idn_from_where"
        label="Tahu IDN darimana?"
      >
        <Select placeholder="Pilih">  
          <Select.Option value="Instagram">Instagram</Select.Option>
          <Select.Option value="Facebook">Facebook</Select.Option>
          <Select.Option value="Website">Website</Select.Option>
        </Select> 
      </Form.Item>
      <Form.Item>
        <center>
          <Button size="large" type="primary" htmlType="submit">
            Simpan
          </Button>
        </center>
      </Form.Item>
    </Form>
  );
}
