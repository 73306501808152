// Import package
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import CircularProgress from "../../components/CircularProgress";
import {
  getTrainingRegistrations,
  getTrainingRegistration,
} from "../../appRedux/actions/TrainingRegistration";
import TrainingRegistrationFormContainer from "../../containers/adminRole/trainingRegistration/TrainingRegistrationFormContainer";

export default function EditTrainingRegistrationPage() {
  const dispatch = useDispatch();
  const { trainingRegistrationId } = useParams();
  const { trainingRegistration, trainingRegistrationLoading } = useSelector(
    ({ trainingRegistration }) => trainingRegistration
  );
  useEffect(() => {
    dispatch(getTrainingRegistration(trainingRegistrationId));
  }, [dispatch]);
  return trainingRegistration && !trainingRegistrationLoading ? (
    <PageContentWrapper
      title="Edit Registrasi Training"
      backLink={"/training-registration"}
    >
      <TrainingRegistrationFormContainer
        trainingRegistration={trainingRegistration}
      />
    </PageContentWrapper>
  ) : trainingRegistrationLoading ? (
    <CircularProgress />
  ) : (
    "registrasi tidak ditemukan"
  );
}
