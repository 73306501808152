import React, { useEffect } from "react";
import MyTrainingFilter from "../../components/participantRole/MyTrainingFilter";
import { useDispatch, useSelector } from "react-redux";
import { getTrainingOptions } from "../../appRedux/actions";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
export default function MyTrainingFilterContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { trainingOptions, getTrainingOptionsLoading } = useSelector(
    ({ trainingOption }) => trainingOption
  );
  let filter = queryString.parse(useLocation().search);
  filter["page_size"] = filter.page_size ? parseInt(filter.page_size) : 6;
  filter["current_page"] = filter.current_page
    ? parseInt(filter.current_page)
    : 1;
  filter["search"] = filter.search ? filter.search : "";
  filter["status"] = filter.status ? filter.status : null;
  // filter["training_option_id"] = filter.training_option_id
  //   ? parseInt(filter.training_option_id)
  //   : null;
  filter["is_retraining"] = filter.is_retraining ? filter.is_retraining : null;
  filter["month"] = filter.month ? filter.month : null;
  // useEffect(() => {
  //   dispatch(getTrainingOptions({ page_size: 100 }));
  // }, [dispatch]);
  const handleSearch = (v) => {
    filter["search"] = v;
    filter["current_page"] = 1;
    history.push("/my-training?" + queryString.stringify(filter));
  };
  const handleChangeIsRetraining = (isRetraining) => {
    filter["is_retraining"] = isRetraining;
    filter["current_page"] = 1;
    history.push("/my-training?" + queryString.stringify(filter));
  };
  const handleChangeStatus = (status) => {
    filter["status"] = status;
    filter["current_page"] = 1;
    history.push("/my-training?" + queryString.stringify(filter));
  };
  // const handleChangeTrainingOption = (training_option_id) => {
  //   filter["training_option_id"] = training_option_id;
  //   filter["current_page"] = 1;
  //   history.push("/my-training?" + queryString.stringify(filter));
  // };
  const handleChangeMonth = (v) => {
    filter["month"] = v ? v.toISOString() : null;
    filter["current_page"] = 1;
    history.push("/my-training?" + queryString.stringify(filter));
  };
  const handleReset = () => history.push("/my-training");
  return (
    <MyTrainingFilter
      // trainingOptions={trainingOptions}
      // trainingOptionsLoading={getTrainingOptionsLoading}
      onSearch={handleSearch}
      onChangeIsRetraining={handleChangeIsRetraining}
      onChangeMonth={handleChangeMonth}
      onChangeStatus={handleChangeStatus}
      // onChangeTrainingOption={handleChangeTrainingOption}
      status={filter.status ? filter.status : null}
      // trainingOption={
      //   filter.training_option_id ? parseInt(filter.training_option_id) : null
      // }
      // isRetraining={
      //   filter.is_retraining
      //     ? filter.is_retraining === "true"
      //       ? true
      //       : false
      //     : null
      // }
      search={filter.search ? filter.search : ""}
      month={filter.month ? moment(filter.month) : null}
      onReset={handleReset}
      enableReset={
        filter.page_size !== 6 ||
        filter.current_page !== 1 ||
        filter.search !== "" ||
        filter.status !== null ||
        // filter.training_option_id !== null ||
        filter.month !== null ||
        filter.is_retraining !== null
      }
    />
  );
}
