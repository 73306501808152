import React from "react";
import { Table, Tag, Space } from "antd";
import { toRupiah } from "libs";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import MyTrainingListActionsContainer from "../../../containers/participantRole/MyTrainingListActionsContainer";
const getColumns = () => [
  {
    title: '#',
    key: 'index',
    align: "center",
    width: 10,
  },
  {
    title: "Training",
    dataIndex: "training",
    render: (training, item) => (
      <>
        <Space>
          <Link to={"/training-registration/" + item.id}>{training.training_option.name}</Link>
        </Space>
      </>
    ),
  },
  {
    title: "Tipe Training",
    dataIndex: "training",
    responsive: ["sm"],
    render: (training) => (
      <>
        <Space>
          {training.is_online ? (
            <Tag color="green" style={{ margin: 0 }}>
              ONLINE
            </Tag>
          ) : (
            <Tag color="blue" style={{ margin: 0 }}>
              OFFLINE
            </Tag>
          )}
        </Space>
      </>
    ),
  },
  {
    title: "Jadwal",
    dataIndex: "training",
    responsive: ["md"],
    render: (training) => (
      <>
        <Space>
          {training.dates_summary}
          {!training.is_online ? (
            <Tag color="magenta" style={{ margin: 0 }}>
              {training.location}
            </Tag>
          ) : (
            <Tag color="blue" style={{ margin: 0 }}>
              online
            </Tag>
          )}
        </Space>
      </>
    ),
  },
  {
    title: "Total Harga",
    dataIndex: "total_price",
    responsive: ["md"],
    render: (totalPrice, item) => (
      <>
        <Space>{toRupiah(totalPrice)}</Space>
      </>
    ),
  },
  {
    title: "Belum dibayar",
    dataIndex: "already_paid",
    responsive: ["md"],
    render: (alreadyPaid, item) => (
      <>
        <Space>{toRupiah(item.total_price - alreadyPaid)}</Space>
      </>
    ),
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    width: 10,
    responsive: ["md"],
    render: (status, field) =>
      !field.is_retraining ? (
        <Tag
          icon={
            status === "paid_off" ? (
              <CheckCircleOutlined />
            ) : status === "canceled" ? (
              <CloseCircleOutlined />
            ) : (
              <ClockCircleOutlined />
            )
          }
          color={
            status === "paid_off"
              ? "success"
              : status === "canceled"
                ? "error"
                : "default"
          }
          style={{ margin: 0 }}
        >
          {status === "paid_off"
            ? "lunas"
            : status === "canceled"
              ? "batal"
              : status === "waiting_for_paid_off"
                ? "belum lunas"
                : "belum bayar"}
        </Tag>
      ) : (
        "-"
      ),
  },
  {
    width: 10,
    title: "Action",
    render: (item) =>
    (
      <MyTrainingListActionsContainer trainingRegistration={item} />
    ),
  },
];
export default function TrainingRunningList({
  trainingRegistrations = [],
  loading = false,
  totalTrainingRegistrations = 0,
  pageSize = 6,
  currentPage = 1,
  onChangeCurrentPage,
  onChangePageSize,
}) {
  return (
    <Table
      size="small"
      dataSource={trainingRegistrations.filter((tr) => tr.status === "paid_off" || tr.is_retraining === true)
        .map((t) => ({ ...t, key: t.id }))}
      loading={loading}
      columns={getColumns()}
      pagination={{
        total: totalTrainingRegistrations,
        pageSize: pageSize,
        current: currentPage,
        showSizeChanger: true,
        responsive: true,
        pageSizeOptions: [7, 10, 20, 50, 100],
        onChange: (page, size) => {
          onChangeCurrentPage(page);
          onChangePageSize(size);
        },
        position: ["bottomCenter"],
      }}
    />
  );
}
