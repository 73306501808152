import { v4 } from "uuid";
import {
  setLoadingMessage,
  setSuccessMessage,
  setErrorMessage,
} from "./Common";
import { baseRequest } from "../../util/Api";
import {
  SET_TRAINING_REGISTRATIONS,
  SET_TOTAL_TRAINING_REGISTRATIONS,
  SET_TRAINING_REGISTRATIONS_LOADING,
  DELETE_TRAINING_REGISTRATION,
  UPDATE_TRAINING_REGISTRATION,
  SET_TRAINING_REGISTRATION,
  SET_TRAINING_REGISTRATION_LOADING,
} from "../../constants/ActionTypes";

export const createTrainingRegistration = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    dispatch(setLoadingMessage("Menambah registrasi training ...", key));
    baseRequest("post", "/training-registrations/", null, data)
      .then((newData) => {
        dispatch(setSuccessMessage("Registrasi training berhasil", key));

        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
        reject(e);
      });
  });

export const getTrainingRegistrations = (filter) => (dispatch) => {
  dispatch({
    type: SET_TRAINING_REGISTRATIONS_LOADING,
    payload: true,
  });
  baseRequest("get", "/training-registrations/", filter)
    .then(({ data, meta }) => {
      dispatch({
        type: SET_TRAINING_REGISTRATIONS,
        payload: data,
      });
      dispatch({
        type: SET_TOTAL_TRAINING_REGISTRATIONS,
        payload: meta.total,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_TRAINING_REGISTRATIONS_LOADING,
        payload: false,
      });
    });
};

export const deleteTrainingRegistration = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    dispatch(setLoadingMessage("Menghapus training registrasi ...", key));
    baseRequest("delete", "/training-registrations/" + id)
      .then(() => {
        dispatch(setSuccessMessage("Berhasil menghapus training", key));
        dispatch({
          type: DELETE_TRAINING_REGISTRATION,
          payload: id,
        });
        resolve();
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
        reject(e);
      });
  });

export const updateTrainingRegistration = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    dispatch(setLoadingMessage("Memperbarui registrasi ...", key));
    baseRequest("put", "/training-registrations/" + data.id, null, data)
      .then((newData) => {
        dispatch(setSuccessMessage("Berhasil memperbarui registrasi", key));
        dispatch({
          type: UPDATE_TRAINING_REGISTRATION,
          payload: newData,
        });
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
        reject(e);
      });
  });

export const cancelTrainingRegistration = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    dispatch(setLoadingMessage("Membatalkan training ...", key));
    baseRequest(
      "put",
      "/training-registrations/status-cancel/" + data.id,
      null,
      data
    )
      .then((newData) => {
        dispatch(setSuccessMessage("Berhasil membatalkan training", key));
        dispatch({
          type: UPDATE_TRAINING_REGISTRATION,
          payload: newData,
        });
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
        reject(e);
      });
  });

export const getTrainingRegistration = (id) => (dispatch) => {
  dispatch({
    type: SET_TRAINING_REGISTRATION_LOADING,
    payload: true,
  });
  baseRequest("get", "/training-registrations/" + id)
    .then((data) => {
      dispatch({
        type: SET_TRAINING_REGISTRATION,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_TRAINING_REGISTRATION_LOADING,
        payload: false,
      });
    });
};

export const sendPaymentInformtionEmailAndWa = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    dispatch(
      setLoadingMessage("Mengirim informasi pembayaran ke peserta ...", key)
    );
    baseRequest(
      "post",
      "/training-registrations/send-payment-information",
      null,
      data
    )
      .then((newData) => {
        dispatch(
          setSuccessMessage(
            "Berhasil mengirimkan informasi pembayaran ke peserta",
            key
          )
        );

        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
        reject(e);
      });
  });

export const sendDpPaymentInformtionEmailAndWa = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    dispatch(
      setLoadingMessage("Mengirim informasi pembayaran ke peserta ...", key)
    );
    baseRequest("post", "/training-registrations/send-dp-payment", null, data)
      .then((newData) => {
        dispatch(
          setSuccessMessage(
            "Berhasil mengirimkan informasi pembayaran ke peserta",
            key
          )
        );

        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
        reject(e);
      });
  });

  export const sendRepeatParticipantInformtionEmailAndWa = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    dispatch(
      setLoadingMessage("Mengirim informasi ke peserta ...", key)
    );
    baseRequest("post", "/training-registrations/send-repeat-participant", null, data)
      .then((newData) => {
        dispatch(
          setSuccessMessage(
            "Berhasil mengirimkan informasi ke peserta",
            key
          )
        );

        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
        reject(e);
      });
  });

export const sendPaidOffPaymentInformtionEmailAndWa = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    dispatch(
      setLoadingMessage("Mengirim informasi pembayaran ke peserta ...", key)
    );
    baseRequest(
      "post",
      "/training-registrations/send-paid-off-payment",
      null,
      data
    )
      .then((newData) => {
        dispatch(
          setSuccessMessage(
            "Berhasil mengirimkan informasi pembayaran ke peserta",
            key
          )
        );

        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
        reject(e);
      });
  });

  export const exportTrainingRegistCsv = (isForAllTrainingRegist) => (dispatch) =>
  new Promise((resolve) => {
    const key = v4();
    dispatch(setLoadingMessage("Mengekspor registrasi training ...", key));
    baseRequest("get", "/participants/csv-training-reg", {
      is_for_all_training_registration: isForAllTrainingRegist
    })
      .then((data) => {
        dispatch(setSuccessMessage("Eksport berhasil", key));
        resolve(data.download_url);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
      });
  });
