// Import package
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setTrainingsDates,
  setTrainingsTrainingOption
} from "../../../../appRedux/actions";

// Import file
import { getTrainingParticipants } from "../../../../appRedux/actions";
import ParticipantTraining from "../../../../components/adminRole/participant/ParticipantTraining";

// Component
export default function ParticipantTrainingListContainer({
  participantTrainingId,
}) {
  const dispatch = useDispatch();
  const { trainingParticipants } = useSelector(
    ({ trainingParticipant }) => trainingParticipant
  );
  useEffect(() => {
    dispatch(
      getTrainingParticipants({ participant_id: participantTrainingId })
    );
  }, [dispatch]);

  const handleOnClick = (value) => {
    dispatch(setTrainingsDates(value.dates))
    dispatch(setTrainingsTrainingOption(value.trainingOptionId))
  }
  return <ParticipantTraining participantTrainings={trainingParticipants} handleOnClickLink={handleOnClick} />;
}
