import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const UserProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() =>
          history.push(
            `/edit-profile`
          )
        }
      >
        Edit Profile
      </li>
      <li onClick={() => history.push("/edit-password")}>Ubah Password</li>
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        style={{ width: 200 }}
      >
        <Avatar
          src="https://via.placeholder.com/150x150"
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name">
          {authUser.name.split(" ")[0]}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
