// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import { getAdmin } from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import AdminFormContainer from "../../containers/adminRole/admin/AdminFormContainer";

// Component
export default function EditAdminPage() {
  const dispatch = useDispatch();
  const { adminId } = useParams();
  const { admin, adminLoading } = useSelector(({ admin }) => admin);

  useEffect(() => {
    dispatch(getAdmin(adminId));
  }, [dispatch]);
  return admin && !adminLoading ? (
    <PageContentWrapper title={"Edit " + admin.name} backLink={"/admin"}>
      <AdminFormContainer admin={admin} />
    </PageContentWrapper>
  ) : adminLoading ? (
    <CircularProgress />
  ) : (
    "Tidak di temukan"
  );
}
