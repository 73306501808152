// Admin const
export const SET_ADMIN = "SET_ADMIN";
export const SET_ADMINS = "SET_ADMINS";
export const SET_ADMIN_LOADING = "SET_ADMIN_LOADING";
export const SET_ADMINS_LOADING = "SET_ADMINS_LOADING";
export const SET_TOTAL_ADMINS = "SET_TOTAL_ADMINS";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const UPDATE_ADMIN = "UPDATE_ADMIN";

// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";
export const UPDATE_LOAD_USER = "UPDATE_LOAD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SET_LOADING_AUTH_USER = "SET_LOADING_AUTH_USER";
export const FAILED_LOGIN = "FAILED_LOGIN";

// Users const
export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const SET_USER_LOADING = "SET_USER_LOADING";
export const SET_USERS_LOADING = "SET_USERS_LOADING";
export const SET_TOTAL_USERS = "SET_TOTAL_USERS";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USERS = "UPDATE_USERS";

// Training const
export const SET_TRAININGS = "SET_TRAININGS";
export const SET_GET_TRAININGS_LOADING = "SET_GET_TRAININGS_LOADING";
export const SET_TOTAL_TRAININGS = "SET_TOTAL_TRAININGS";
export const SET_GET_TRAININGS_FILTER = "SET_GET_TRAININGS_FILTER";
export const SET_TRAININGS_LOCATION = "SET_TRAININGS_LOCATION";
export const SET_TRAININGS_SEARCH = "SET_TRAININGS_SEARCH";
export const SET_TRAININGS_DATES = "SET_TRAININGS_DATE";
export const SET_TRAININGS_TRAINER = "SET_TRAININGS_TRAINER";
export const SET_TRAININGS_TRAINING_OPTION = "SET_TRAININGS_TRAINING_OPTION";
export const SET_TRAININGS_ONLINE = "SET_TRAININGS_ONLINE";
export const SET_TRAININGS_PAGINATION = "SET_TRAININGS_PAGINATION";
export const RESET_TRAININGS_FILTER = "RESET_TRAININGS_FILTER";
export const DELETE_TRAINING = "DELETE_TRAINING";
export const CANCEL_TRAINING = "CANCEL_TRAINING";
export const ENABLE_TRAINING = "ENABLE_TRAINING";
export const CREATE_TRAINING = "CREATE_TRAINING";
export const SET_CREATE_TRAINING_LOADING = "SET_CREATE_TRAINING_LOADING";
export const SET_TRAINING = "SET_TRAINING";
export const SET_TRAINING_LOADING = "SET_TRAINING_LOADING";

// Training options const
export const SET_TRAINING_OPTIONS = "SET_TRAINING_OPTIONS";
export const SET_GET_TRAINING_OPTIONS_LOADING =
  "SET_GET_TRAINING_OPTIONS_LOADING";
export const SET_TOTAL_TRAINING_OPTIONS = "SET_TOTAL_TRAINING_OPTIONS";
export const DELETE_TRAINING_OPTION = "DELETE_TRAINING_OPTION";
export const SET_TRAINING_OPTION = "SET_TRAINING_OPTION";
export const SET_TRAINING_OPTION_LOADING = "SET_TRAINING_OPTION_LOADING";
export const UPDATE_TRAINING_OPTION = "UPDATE_TRAINING_OPTION";

// Trainers const
export const SET_TRAINERS = "SET_TRAINERS";
export const SET_TRAINERS_LOADING = "SET_TRAINERS_LOADING";
export const SET_TRAINER = "SET_TRAINER";
export const SET_TRAINER_LOADING = "SET_TRAINER_LOADING";
export const DELETE_TRAINER = "DELETE_TRAINER";
export const UPDATE_TRAINER = "UPDATE_TRAINER";
export const SET_TOTAL_TRAINERS = "SET_TOTAL_TRAINERS";

// Training participants const
export const SET_TRAINING_PARTICIPANTS = "SET_TRAINING_PARTICIPANTS";
export const SET_TRAINING_PARTICIPANTS_LOADING =
  "SET_TRAINING_PARTICIPANTS_LOADING";
export const DELETE_TRAINING_PARTICIPANT = "DELETE_TRAINING_PARTICIPANT";
export const CREATE_TRAINING_PARTICIPANT = "CREATE_TRAINING_PARTICIPANT";
export const SET_PAID_TRAINING_PARTICIPANT = "SET_PAID_TRAINING_PARTICIPANT";
export const SET_TRAINING_PARTICIPANT = "SET_TRAINING_PARTICIPANT";
export const UPDATE_TRAINING_PARTICIPANT = "UPDATE_TRAINING_PARTICIPANT";

// Participants const
export const SET_PARTICIPANTS = "SET_PARTICIPANTS";
export const SET_PARTICIPANT = "SET_PARTICIPANT";
export const SET_PARTICIPANTS_LOADING = "SET_PARTICIPANTS_LOADING";
export const SET_PARTICIPANT_LOADING = "SET_PARTICIPANT_LOADING";
export const SET_TOTAL_PARTICIPANTS = "SET_TOTAL_PARTICIPANTS";
export const DELETE_PARTICIPANT = "DELETE_PARTICIPANT";
export const UPDATE_PARTICIPANT = "UPDATE_PARTICIPANT";

// Training category const
export const SET_TRAINING_CATEGORIES = "SET_TRAINING_CATEGORIES";
export const SET_TRAINING_CATEGORIES_LOADING =
  "SET_TRAINING_CATEGORIES_LOADING";
export const SET_TRAINING_CATEGORY = "SET_TRAINING_CATEGORY";
export const SET_TRAINING_CATEGORY_LOADING = "SET_TRAINING_CATEGORY_LOADING";
export const DELETE_TRAINING_CATEGORY = "DELETE_TRAINING_CATEGORY";
export const UPDATE_TRAINING_CATEGORY = "UPDATE_TRAINING_CATEGORY";

// Training regist
export const SET_TRAINING_REGISTRATIONS = "SET_TRAINING_REGISTRATIONS";
export const SET_TRAINING_REGISTRATIONS_LOADING =
  "SET_TRAINING_REGISTRATIONS_LOADING";
export const SET_TOTAL_TRAINING_REGISTRATIONS =
  "SET_TOTAL_TRAINING_REGISTRATIONS";
export const DELETE_TRAINING_REGISTRATION = "DELETE_TRAINING_REGISTRATION";
export const UPDATE_TRAINING_REGISTRATION = "UPDATE_TRAINING_REGISTRATION";
export const SET_TRAINING_REGISTRATION = "SET_TRAINING_REGISTRATION";
export const SET_TRAINING_REGISTRATION_LOADING =
  "SET_TRAINING_REGISTRATION_LOADING";

// Discount const
export const SET_DISCOUNTS = "SET_DISCOUNTS";
export const SET_GET_DISCOUNTS_LOADING =
  "SET_GET_DISCOUNTS_LOADING";
export const SET_TOTAL_DISCOUNTS = "SET_TOTAL_DISCOUNTS";
export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const SET_DISCOUNT = "SET_DISCOUNT";
export const SET_DISCOUNT_LOADING = "SET_DISCOUNT_LOADING";
export const UPDATE_DISCOUNT = "UPDATE_TRAINING_OPTION";
export const SET_DISCOUNT_CODE = "SET_DISCOUNT_CODE";
export const SET_DISCOUNT_CODE_SUCCESS = "SET_DISCOUNT_CODE_SUCCESS";
export const SET_LOADING_DISCOUNT_CODE_SUCCESS = "SET_LOADING_DISCOUNT_CODE_SUCCESS";
export const SET_DISCOUNTS_DATES = "SET_DISCOUNTS_DATES";
export const SET_DISCOUNTS_SEARCH = "SET_DISCOUNTS_SEARCH";
export const RESET_DISCOUNTS_FILTER = "RESET_DISCOUNTS_FILTER";
export const SET_DISCOUNTS_PARTICIPANTS = "SET_DISCOUNTS_PARTICIPANTS";

// Discount Participant
export const SET_DISCOUNT_PARTICIPANTS = "SET_DISCOUNT_PARTICIPANTS";
export const SET_GET_DISCOUNT_PARTICIPANTS_LOADING =
  "SET_GET_DISCOUNT_PARTICIPANTS_LOADING";
export const DELETE_DISCOUNT_PARTICIPANT = "DELETE_DISCOUNT_PARTICIPANT";
export const UPDATE_DISCOUNT_PARTICIPANT = "UPDATE_DISCOUNT_PARTICIPANT";

// Discount Trainings
export const SET_DISCOUNT_TRAININGS = "SET_DISCOUNT_TRAININGS";
export const SET_DISCOUNT_TRAININGS_LOADING =
  "SET_DISCOUNT_TRAININGS_LOADING";
export const DELETE_DISCOUNT_TRAINING = "DELETE_DISCOUNT_TRAINING";
export const UPDATE_DISCOUNT_TRAINING = "UPDATE_DISCOUNT_TRAINING";

// Discount Training Options
export const SET_DISCOUNT_TRAINING_OPTIONS = "SET_DISCOUNT_TRAINING_OPTIONS";
export const SET_DISCOUNT_TRAINING_OPTIONS_LOADING =
  "SET_DISCOUNT_TRAINING_OPTIONS_LOADING";
export const DELETE_DISCOUNT_TRAINING_OPTION = "DELETE_DISCOUNT_TRAINING_OPTION";
export const UPDATE_DISCOUNT_TRAINING_OPTION = "UPDATE_DISCOUNT_TRAINING_OPTION";

// Bank transaction const
export const SET_BANK_TRANSACTIONS = "SET_BANK_TRANSACTIONS";
export const SET_BANK_TRANSACTIONS_LOADING = "SET_BANK_TRANSACTIONS_LOADING";
export const SET_TOTAL_BANK_TRANSACTIONS = "SET_TOTAL_BANK_TRANSACTIONS";

export const SET_BANK_ACCOUNTS = "SET_BANK_ACCOUNTS";
export const SET_BANK_ACCOUNTS_LOADING = "SET_BANK_ACCOUNTS_LOADING";

// Affiliate Participant History
export const SET_AFFILIATE_PARTICIPANTS_HISTORY = "SET_AFFILIATE_PARTICIPANTS_HISTORY"
export const SET_AFFILIATE_PARTICIPANTS_HISTORY_LOADING = "SET_AFFILIATE_PARTICIPANTS_HISTORY_LOADING"
export const CREATE_AFFILIATE_PARTICIPANTS_HISTORY = "SET_AFFILIATE_PARTICIPANTS_HISTORY_LOADING"
export const SET_TOTAL_AFFILIATE_PARTICIPANTS_HISTORY = "SET_TOTAL_AFFILIATE_PARTICIPANTS_HISTORY"

// Affiliate Point History
export const SET_AFFILIATE_POINTS_HISTORY = "SET_AFFILIATE_POINTS_HISTORY"
export const SET_AFFILIATE_POINTS_HISTORY_LOADING = "SET_AFFILIATE_POINTS_HISTORY_LOADING"

// Ads
export const SET_ADS = "SET_ADS"
export const SET_ADS_LOADING = "SET_ADS_LOADING"
export const DELETE_AD = "DELETE_AD" 

// DASHBOARD
export const SET_DASHBOARD_SUMMARY = "SET_DASHBOARD_SUMMARY"
export const SET_DASHBOARD_SUMMARY_LOADING = "SET_DASHBOARD_SUMMARY_LOADING"

// DASHBOARD (Bar Chart TRAINING_REGIST_MONTHLY)
export const SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH = "SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH"
export const SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_LOADING = "SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_LOADING"
export const SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_FILTER = "SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_FILTER"

// DASHBOARD (Bar Chart TRAINING_PARTICIPANTS_MONTHLY)
export const SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH = "SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH"
export const SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_LOADING = "SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_LOADING"
export const SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_FILTER = "SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_FILTER"

// DASHBOARD (Bar Chart TRAINING_PARTICIPANT_REGULAR)
export const SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH = "SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH"
export const SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_LOADING = "SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_LOADING"
export const SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_FILTER = "SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_FILTER"

// DASHBOARD (Bar Chart TRAINING_PARTICIPANT_RETRAINING)
export const SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH = "SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH"
export const SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_LOADING = "SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_LOADING"
export const SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_FILTER = "SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_FILTER"

// DASHBOARD (Bar Chart TRAINING_REGIST_PAID)
export const SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH = "SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH"
export const SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_LOADING = "SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_LOADING"
export const SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_FILTER = "SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_FILTER"

// DASHBOARD (Bar Chart TRAINING_REGIST_CANCEL)
export const SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH = "SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH"
export const SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_LOADING = "SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_LOADING"
export const SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_FILTER = "SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_FILTER"

// DASHBOARD (Bar Chart TRAINING_STATUS_RUNNING)
export const SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH = "SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH"
export const SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_LOADING = "SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_LOADING"
export const SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_FILTER = "SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_FILTER"

// DASHBOARD (Bar Chart TRAINING_STATUS_CANCEL)
export const SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH = "SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH"
export const SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_LOADING = "SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_LOADING"
export const SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_FILTER = "SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_FILTER"

// CALENDAR 
export const SET_TRAINING_CALENDAR = "SET_TRAINING_CALENDAR"
export const SET_TRAINING_CALENDAR_FILTER = "SET_TRAINING_CALENDAR_FILTER"

// ADMIN LOGS
export const SET_LOGS = "SET_LOGS";
export const SET_GET_LOGS_LOADING = "SET_GET_LOGS_LOADING";
export const SET_GET_LOGS_FILTER = "SET_GET_LOGS_FILTER";
export const SET_TOTAL_LOGS = "SET_TOTAL_LOGS";
export const SET_LOGS_PAGINATION = "SET_LOGS_PAGINATION";
export const RESET_LOGS_FILTER = "RESET_LOGS_FILTER";
export const SET_LOGS_DATES = "SET_LOGS_DATES";
