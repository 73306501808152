// Import package
import React from "react";
import { List, Typography, Avatar, Dropdown, Menu } from "antd";
import {
  KeyOutlined,
  DeleteOutlined,
  EditOutlined,
  UserOutlined,
} from "@ant-design/icons";

// Menu
const menu = ({ id, onChangePass, onEdit, onDelete }) => (
  <Menu>
    <Menu.Item
      key="ubah password"
      onClick={() => onChangePass(id)}
      icon={<KeyOutlined />}
    >
      Ubah password
    </Menu.Item>
    <Menu.Item key="edit" onClick={() => onEdit(id)} icon={<EditOutlined />}>
      Edit
    </Menu.Item>
    <Menu.Item
      onClick={() => onDelete(id)}
      key="0"
      icon={<DeleteOutlined />}
      danger
    >
      Hapus
    </Menu.Item>
  </Menu>
);

// Render
const renderItem = ({ item, onChangePass, onEdit, onDelete }) => {
  const { name, email, id } = item;
  return (
    <List.Item
      actions={[
        <Dropdown
          overlay={menu({ id, onChangePass, onEdit, onDelete })}
          placement="bottomRight"
          trigger={["click"]}
        >
          <i className="gx-icon-btn icon icon-ellipse-v" />
        </Dropdown>,
      ]}
    >
      <List.Item.Meta
        avatar={<Avatar className="gx-bg-green" icon={<UserOutlined />} />}
        title={<Typography.Link strong>{name}</Typography.Link>}
        description={email ? email : "-"}
      />
    </List.Item>
  );
};

// Component
export default function AdminList({
  admins,
  loading,
  onDelete,
  onEdit,
  onChangePass,
}) {
  return (
    <List
      pagination={false}
      size="small"
      dataSource={admins}
      renderItem={(item) =>
        renderItem({ item, onChangePass, onEdit, onDelete })
      }
      loading={loading}
    />
  );
}
