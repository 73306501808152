import React from "react";
import {
  Form,
  Button,
  DatePicker,
  Checkbox,
  Modal,
  List,
  Typography,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment-timezone";
export default function RescheduleTrainingForm({
  currentDates = [],
  showWithNotificationField = true,
  onSubmit,
}) {
  return (
    <Form
      initialValues={{
        dates: currentDates.map((d) => moment(d)),
        with_notification: true,
      }}
      layout="vertical"
      onFinish={(v) => {
        v.dates = v.dates.map((date) => date.endOf("day").toISOString());
        v.dates = _.uniq(v.dates).sort();

        Modal.confirm({
          title: "Konfirmasi",
          content: (
            <List>
              {v.dates.sort().map((date, index) => {
                return (
                  <List.Item key={index}>
                    Hari ke-{index + 1} :{" "}
                    <Typography.Text strong>
                      {" "}
                      {moment(date).format("dddd, D MMMM yyyy")}
                    </Typography.Text>
                  </List.Item>
                );
              })}
            </List>
          ),
          onOk: () =>
            onSubmit({
              ...v,
              dates: v.dates,
              with_notification: showWithNotificationField
                ? v.with_notification
                : false,
            }),
        });
      }}
    >
      <Form.List
        name="dates"
        rules={[
          {
            validator: async (_, dates) => {
              if (!dates || dates.length < 1) {
                return Promise.reject(
                  new Error("Mohon tambahkan tanggal pelaksanaan training")
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  key={field.key}
                  label={index === 0 ? "Tanggal pelaksanaan" : ""}
                >
                  <Form.Item
                    {...field}
                    noStyle
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        message: "Mohon masukkan tanggal",
                      },
                    ]}
                  >
                    <DatePicker multiple format="dddd, D MMMM yyyy" />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      style={{ marginLeft: 10, cursor: "pointer" }}
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                >
                  Tambah tanggal
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      {showWithNotificationField && (
        <Form.Item name="with_notification" valuePropName="checked">
          <Checkbox>Kirim pemberitahuan ke seluruh peserta</Checkbox>
        </Form.Item>
      )}
      <Form.Item>
        <center>
          <Button size="large" type="primary" htmlType="submit">
            Simpan
          </Button>
        </center>
      </Form.Item>
    </Form>
  );
}
