import React, { useEffect } from "react";
import { PageHeader, Card } from "antd";
import { Router, useHistory } from "react-router-dom";
import NewTrainingFormImport from "../../components/adminRole/training/NewTrainingFormImport";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrainers,
  getTrainingOptions,
  createTraining,
} from "../../appRedux/actions";
import { createTrainingDate } from "../../appRedux/actions/TrainingDates";

export default function NewTrainingImport(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { trainers, getTrainersLoading } = useSelector(
    ({ trainer }) => trainer
  );
  // const { createTrainingLoading } = useSelector(({ training }) => training);
  const { trainingOptions, getTrainingOptionsLoading } = useSelector(
    ({ trainingOption }) => trainingOption
  );

  useEffect(() => {
    dispatch(getTrainers());
    dispatch(getTrainingOptions({ page_size: 100 }));
  }, [dispatch]);
  
  return (
    <>
      <PageHeader
        ghost={false}
        title="Impor Jadwal Training"
        onBack={() => history.push("/training")}
      />
      <Card bordered={false} style={{ borderRadius: 0 }}>
        <NewTrainingFormImport
          trainers={trainers}
          trainingOptions={trainingOptions}
        />
      </Card>
    </>
  );
}
