import {
  SET_AFFILIATE_PARTICIPANTS_HISTORY,
  SET_AFFILIATE_PARTICIPANTS_HISTORY_LOADING,
} from "../../constants/ActionTypes";
import { baseRequest } from "../../util/Api";
import {
  setErrorMessage,
} from "./Common";

export const getAffiliateParticipantsHistory = (filter) => (dispatch) => {
  dispatch({
    type: SET_AFFILIATE_PARTICIPANTS_HISTORY_LOADING,
    payload: true,
  });
  baseRequest("get", "/affiliate-participants-history/", filter)
    .then((data) => {
      dispatch({
        type: SET_AFFILIATE_PARTICIPANTS_HISTORY,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_AFFILIATE_PARTICIPANTS_HISTORY_LOADING,
        payload: false,
      });
    });
};
