// Import package
import React, { useState, useEffect } from "react";
import { Row, Modal, Col, Form, Space, Button, Select } from "antd";
import {
  useQueryParams,
  StringParam,
  NumberParam,
  withDefault,
} from "use-query-params";
import { useDispatch, useSelector } from "react-redux";

// Import file
import ParticipantSearch from "../../../../components/adminRole/participant/ParticipantSearch";
import {
  exportParticipantsCsv,
  getTrainingOptions,
} from "../../../../appRedux/actions";
import ModalExportAll from "../../../../components/adminRole/participant/ModalExportAll";

// Component
export default function ParticipantSearchContainer() {
  const dispatch = useDispatch();
  const [showExportFormPerTraining, setShowExportFormPerTraining] =
    useState(false);
  const [showExportAll, setShowExportAll] = useState(false);
  const [query, setQuery] = useQueryParams({
    current_page: withDefault(NumberParam, 1),
    page_size: withDefault(NumberParam, 7),
    search: StringParam,
  });
  const { trainingOptions, trainingOptionsLoading } = useSelector(
    ({ trainingOption }) => trainingOption
  );

  useEffect(() => {
    dispatch(
      getTrainingOptions({
        page_size: 100,
      })
    );
  }, []);

  const handleSearch = (v) => {
    setQuery({ ...query, search: v, current_page: 1 });
  };
  return (
    <>
      <ModalExportAll
        visible={showExportAll}
        onCancel={() => setShowExportAll(false)}
        onFinish={(value) => {
          if (value.type === "all-participant") {
            dispatch(exportParticipantsCsv(null, true)).then((url) =>
              window.open(url, "_blank")
            );
          } else if (value.type === "only-registered-training-participant") {
            dispatch(exportParticipantsCsv()).then((url) =>
              window.open(url, "_blank")
            );
          }
        }}
      />
      <Modal
        visible={showExportFormPerTraining}
        onCancel={() => setShowExportFormPerTraining(false)}
        title="Ekspor peserta per training"
        style={{ top: 10 }}
        destroyOnClose={true}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={(values) => {
            dispatch(exportParticipantsCsv(values.training_option_id)).then(
              (url) => window.open(url, "_blank")
            );
          }}
        >
          <Form.Item
            label="Pilih training"
            name="training_option_id"
            rules={[{ required: true, message: "Mohon pilih training" }]}
          >
            <Select
              loading={trainingOptionsLoading}
              showSearch
              placeholder="pilih training yang ingin di ekspor"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {trainingOptions.map((t) => (
                <Select.Option key={t.id} value={t.id} label={t.name}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button onClick={() => setShowExportFormPerTraining(false)}>
              Batal
            </Button>
            <Button type="primary" htmlType="submit">
              Ekspor
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row
        justify="space-between"
        gutter={16}
        align="middle"
        style={{ marginBottom: 10 }}
      >
        <Col>
          <Space wrap>
            <Button
              type="primary"
              onClick={
                () => setShowExportAll(true)
                // dispatch(exportParticipantsCsv()).then((url) =>
                //   window.open(url, "_blank")
                // )
              }
            >
              Export Semua
            </Button>
            <Button
              type="primary"
              onClick={() => setShowExportFormPerTraining(true)}
            >
              Export Per Training
            </Button>
          </Space>
        </Col>
        <Col>
          <ParticipantSearch onSearch={handleSearch} />
        </Col>
      </Row>
    </>
  );
}
