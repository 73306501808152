// Import package
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

// Import file
import { getTrainings } from "../../appRedux/actions";
import TrainingList from "../../components/trainerRole/TrainingList";

export default function TrainingListContainer() {
  const dispatch = useDispatch();
  const { trainings } = useSelector(({ training }) => training);

  useEffect(() => {
    dispatch(
      getTrainings({
        start_date: moment().startOf("month").toISOString(),
        end_date: moment().endOf("month").toISOString(),
      })
    );
  }, [dispatch]);

  return (
    <>
      {/* <Typography.Text>Total {totalTrainings} training</Typography.Text> */}
      <TrainingList trainings={trainings} />
    </>
  );
}
