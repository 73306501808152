// Import package
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";

// Import file
import {
  getTrainings,
  getTrainingOptions,
  getParticipants,
} from "../../../../appRedux/actions";
import TrainingRegistrationForm from "../../../../components/adminRole/trainingRegistration/TrainingRegistrationForm";
import {
  createTrainingRegistration,
  updateTrainingRegistration,
  sendDpPaymentInformtionEmailAndWa,
  sendPaidOffPaymentInformtionEmailAndWa,
  sendPaymentInformtionEmailAndWa,
  sendRepeatParticipantInformtionEmailAndWa
} from "../../../../appRedux/actions/TrainingRegistration";

export default function TrainingRegistrationFormContainer({
  trainingRegistration,
}) {
  const history = useHistory();
  const location = useLocation();
  const { back_link } = queryString.parse(location.search);

  const dispatch = useDispatch();
  const { trainings, getTrainingsLoading } = useSelector(
    ({ training }) => training
  );
  const { participants, participantsLoading } = useSelector(
    ({ participant }) => participant
  );
  const { trainingOptions, getTrainingOptionsLoading } = useSelector(
    ({ trainingOption }) => trainingOption
  );
  const [searchParticipants, setSearchParticipants] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(getParticipants({ page_size: 10, search: searchParticipants }));
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [searchParticipants]);
  useEffect(() => {
    dispatch(getTrainingOptions({ page_size: 100 }));
    if (trainingRegistration) {
      dispatch(
        getTrainings({
          training_option_id: trainingRegistration.training.training_option_id,
          is_online: trainingRegistration.training.is_online,
          location: trainingRegistration.training.location,
          page_size: 100,
          start_date: moment(trainingRegistration.training.dates[0].date)
            .startOf("month")
            .toISOString(),
          end_date: moment(
            trainingRegistration.training.dates[
              trainingRegistration.training.dates.length - 1
            ].date
          )
            .endOf("month")
            .toISOString(),
        })
      );
    }
  }, [dispatch]);
  const handleChangeTrainingsFilter = (filter) => {
    dispatch(getTrainings({ ...filter, page_size: 100 }));
  }
  const handleSearchParticipant = (v) => {
    setSearchParticipants(v);
  };
  const handleSubmit = (values) => {
    if (!trainingRegistration) {
      if (values.send_message) {
        if (values.is_corporate_participant) {
          dispatch(createTrainingRegistration(values)).then((data => {
            dispatch(sendRepeatParticipantInformtionEmailAndWa({ id: data.id }))
          })).then(() => history.push("/training-registration"))
        } else {
          dispatch(createTrainingRegistration(values)).then((data) => {
            if (data.status === "waiting_for_paid_off") {
              dispatch(sendDpPaymentInformtionEmailAndWa({ id: data.id }))
            } else if (data.status === "paid_off") {
              dispatch(sendPaidOffPaymentInformtionEmailAndWa({ id: data.id }))
            } else if (data.status === null){
              dispatch(sendRepeatParticipantInformtionEmailAndWa({ id: data.id })) // mengirim notifikasi peserta mengulang
            }
          }).then(() => history.push("/training-registration"))
        }
      } else {
        dispatch(createTrainingRegistration(values)).then(() => history.push("/training-registration"))
      }
    } else if (values.send_message) {
      if (values.is_corporate_participant) {
        dispatch(
          updateTrainingRegistration({
            id: trainingRegistration.id,
            is_canceled: trainingRegistration.is_canceled,
            ...values,
          })
        ).then((data) => {
          dispatch(sendRepeatParticipantInformtionEmailAndWa({ id: data.id }))
        }).then((newData) =>
        history.push(
          !back_link ? "/training-registration/" + newData.id : back_link
        )
      )
      } else {
        dispatch(
          updateTrainingRegistration({
            id: trainingRegistration.id,
            is_canceled: trainingRegistration.is_canceled,
            ...values,
          })
        ).then((data) => {
          if (data.status === "waiting_for_paid_off") {
            dispatch(sendDpPaymentInformtionEmailAndWa({ id: data.id }))
          } else if (data.status === "paid_off") {
            dispatch(sendPaidOffPaymentInformtionEmailAndWa({ id: data.id }))
          } else if (data.status === "waiting_for_down_payment") {
            dispatch(sendPaymentInformtionEmailAndWa({ id: data.id }))
          } else {
            dispatch(sendRepeatParticipantInformtionEmailAndWa({ id: data.id }))
          }
        }).then((newData) =>
          history.push(
            !back_link ? "/training-registration/" + newData.id : back_link
          )
        )
      }
    } else {
      dispatch(
        updateTrainingRegistration({
          id: trainingRegistration.id,
          is_canceled: trainingRegistration.is_canceled,
          ...values,
        })
      )
      // .then((data) => dispatch(sendPaymentInformtionEmailAndWa({ id: data.id })))
      .then((newData) =>
        history.push(
          !back_link ? "/training-registration/" + newData.id : back_link
        )
      )
    }
  }
  // const handleSubmit = (values) =>
  //   !trainingRegistration
  //     ? values.send_message
  //       ? dispatch(createTrainingRegistration(values))
  //           .then((data) => 
  //             data.status === "waiting_for_paid_off"
  //               ? dispatch(sendDpPaymentInformtionEmailAndWa({ id: data.id }))
  //               : data.status === "paid_off"
  //               ? dispatch(
  //                   sendPaidOffPaymentInformtionEmailAndWa({ id: data.id })
  //                 )
  //               : data.status === "waiting_for_down_payment" 
  //               ? console.log("Status", data.status) // mengirim notifikasi ke peserta yg blm bayar
  //               : dispatch(sendRepeatParticipantInformtionEmailAndWa({ id: data.id })) // mengirim notifikasi peserta mengulang
  //           )
  //           .then(() => history.push("/training-registration"))
  //       : dispatch(createTrainingRegistration(values)).then(() =>
  //           history.push("/training-registration")
  //         )
  //     : values.send_message
  //     ? dispatch(
  //         updateTrainingRegistration({
  //           id: trainingRegistration.id,
  //           is_canceled: trainingRegistration.is_canceled,
  //           ...values,
  //         })
  //       )
  //         .then((data) =>
  //           data.status === "waiting_for_paid_off"
  //             ? dispatch(sendDpPaymentInformtionEmailAndWa({ id: data.id }))
  //             : data.status === "paid_off"
  //             ? dispatch(
  //                 sendPaidOffPaymentInformtionEmailAndWa({ id: data.id })
  //               )
  //             : data.status === "waiting_for_down_payment"
  //             ? dispatch(sendPaymentInformtionEmailAndWa({ id: data.id }))
  //             : null
  //         )
  //         .then((newData) =>
  //           history.push(
  //             !back_link ? "/training-registration/" + newData.id : back_link
  //           )
  //         )
  //     : dispatch(
  //         updateTrainingRegistration({
  //           id: trainingRegistration.id,
  //           is_canceled: trainingRegistration.is_canceled,
  //           ...values,
  //         })
  //       ).then((newData) =>
  //         history.push(
  //           !back_link ? "/training-registration/" + newData.id : back_link
  //         )
  //       );

  return (
    <TrainingRegistrationForm
      trainingsLoading={getTrainingsLoading}
      // trainings={trainings}
      trainings={trainings.filter(t => t.status == "available")}
      trainingOptions={trainingOptions}
      trainingOptionsLoading={getTrainingOptionsLoading}
      onChangeTrainingsFilter={handleChangeTrainingsFilter}
      onSearchParticipant={handleSearchParticipant}
      participants={participants}
      participantsLoading={participantsLoading}
      onSubmit={handleSubmit}
      trainingRegistration={trainingRegistration}
    />
  );
}
