import {
  SET_ADMIN,
  SET_ADMINS,
  SET_ADMINS_LOADING,
  SET_ADMIN_LOADING,
  SET_TOTAL_ADMINS,
  DELETE_ADMIN,
  UPDATE_ADMIN,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  admins: [],
  adminsLoading: false,
  totalAdmins: 0,
  admin: null,
  adminLoading: false,
};

const AdminReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ADMINS: {
      return {
        ...state,
        admins: action.payload,
        adminsLoading: false,
      };
    }
    case SET_ADMIN: {
      return {
        ...state,
        admin: action.payload,
        adminLoading: false,
      };
    }
    case SET_ADMINS_LOADING: {
      return {
        ...state,
        adminsLoading: action.payload,
      };
    }
    case SET_ADMIN_LOADING: {
      return {
        ...state,
        adminLoading: action.payload,
      };
    }
    case SET_TOTAL_ADMINS: {
      return {
        ...state,
        totalAdmins: action.payload,
      };
    }
    case DELETE_ADMIN: {
      return {
        ...state,
        totalAdmins: state.totalAdmins - 1,
        admins: state.admins.filter((p) => p.id !== action.payload),
      };
    }
    case UPDATE_ADMIN: {
      return {
        ...state,
        admins: state.admins.map((t) =>
          t.id === action.payload.id ? action.payload : t
        ),
        admin:
          state.admin &&
          (state.admin.id === action.payload.id ? action.payload : state.admin),
      };
    }
    default:
      return state;
  }
};

export default AdminReducer;
