import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrainings,
  getTrainingOptions,
  getDiscounts,
} from "../../../appRedux/actions";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { createTrainingRegistration } from "../../../appRedux/actions/TrainingRegistration";
import TrainingRegistrationForm from "../../../components/participantRole/trainingRegistration/TrainingRegistrationForm";
export default function TrainingRegistrationFormContainer({
  trainingRegistration,
}) {
  const history = useHistory();

  const dispatch = useDispatch();
  const { trainings, getTrainingsLoading } = useSelector(
    ({ training }) => training
  );
  const { discountCode } = useSelector(({ discount }) => discount);
  const { trainingOptions, getTrainingOptionsLoading } = useSelector(
    ({ trainingOption }) => trainingOption
  );
  useEffect(() => {
    dispatch(getDiscounts());
    dispatch(getTrainingOptions({ page_size: 100 }));
    if (trainingRegistration) {
      dispatch(
        getTrainings({
          training_option_id: trainingRegistration.training.training_option_id,
          is_online: trainingRegistration.training.is_online,
          location: trainingRegistration.training.location,
          page_size: 100,
          start_date: moment(trainingRegistration.training.dates[0].date)
            .startOf("month")
            .toISOString(),
          end_date: moment(
            trainingRegistration.training.dates[
              trainingRegistration.training.dates.length - 1
            ].date
          )
            .endOf("month")
            .toISOString(),
          // status: "available",
        })
      );
    }
  }, [dispatch]);

  const handleChangeTrainingsFilter = (filter) => 
    dispatch(getTrainings({ ...filter, page_size: 100 }));
  const handleSubmit = (values) => {
    dispatch(createTrainingRegistration(values)).then(() =>
      history.push("/my-training")
    );
  };

  return (
    <TrainingRegistrationForm
      trainingsLoading={getTrainingsLoading}
      trainings={trainings.filter(t => t.publicly_visible !== false && t.status == "available")}
      trainingOptions={trainingOptions}
      trainingOptionsLoading={getTrainingOptionsLoading}
      onChangeTrainingsFilter={handleChangeTrainingsFilter}
      onSubmit={handleSubmit}
      trainingRegistration={trainingRegistration}
    />
  );
}
