import { message } from "antd";
import { baseRequest } from "../../util/Api";
import { v4 } from "uuid";

export const getAccounts = () => {
  return new Promise((resolve, reject) => {
    baseRequest("get", "/bank-accounts/")
      .then((data) => {
        resolve(data)
      }).catch((e) => {
        reject(e)
      })
  })
}

export const getAccount = (id) => {
  return new Promise((resolve, reject) => {
    baseRequest("get", "/bank-accounts/" + id)
      .then((data) => {
        resolve(data)
      }).catch((e) => {
        reject(e)
      })
  })
}

export const getAccountTrainingCategories = () => {
  return new Promise((resolve, reject) => {
    baseRequest("get", "/training-categories/")
      .then((data) => {
        resolve(data)
      }).catch((e) => {
        reject(e)
      })
  })
}


export const setActive = (id, active) => {
  return new Promise((resolve) => {
    const key = v4();
    let txt = "Menonaktifkan"
    if (active)
      txt = "Mengaktifkan"
    message.open({ key, type: 'loading', content: txt + " rekening ..." })
    baseRequest("put", "/bank-accounts/active/" + id, null, { active: active })
      .then((newData) => {
        message.success({ key, type: 'success', content: txt + " rekening berhasil" })
        resolve(newData);
      })
      .catch((e) => {
        message.error({ key, type: 'error', content: e })
      });
  });
}

export const saveAccount = (id, data) => {
  return new Promise((resolve) => {
    const key = v4();
    message.open({ key, type: 'loading', content: "Memperbarui data rekening ..." })
    baseRequest("put", "/bank-accounts/" + id, null, data)
      .then((newData) => {
        message.success({ key, type: 'success', content: "Memperbarui data rekening berhasil" })
        resolve(newData);
      })
      .catch((e) => {
        message.error({ key, type: 'error', content: e })
      });
  });
}

export const saveNewAccount = (data) => {
  return new Promise((resolve) => {
    const key = v4();
    message.open({ key, type: 'loading', content: "Menambah data rekening ..." })
    baseRequest("post", "/bank-accounts/", null, data)
      .then((newData) => {
        message.success({ key, type: 'success', content: "Menambah data rekening berhasil" })
        resolve(newData);
      })
      .catch((e) => {
        message.error({ key, type: 'error', content: e })
      });
  });
}

export const setDefault = (id, defa) => {
  return new Promise((resolve) => {
    const key = v4();
    let txt = "Mengubah default "
    message.open({ key, type: 'loading', content: txt + " rekening ..." })
    baseRequest("put", "/bank-accounts/default/" + id, null, { default: defa })
      .then((newData) => {
        message.success({ key, type: 'success', content: txt + " rekening berhasil" })
        resolve(newData);
      })
      .catch((e) => {
        message.error({ key, type: 'error', content: e })
      });
  });
}