import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";

import { resetPassword, getUser } from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { reset } from "../appRedux/actions/Password";

const ResetPasswordConfirm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { token } = useParams();

  const onFinish = (values) => {
    dispatch(
      reset({
        token_reset: token,
        password: values.password,
        confirm_password: values.confirm_password,
      })
    ).then((result) => {
      history.push("/signin");
      dispatch(getUser(result.token));
    });
  };

  useEffect(() => {
    dispatch(resetPassword({ resetToken: token }));
  }, []);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img alt="example" src="bg.jpg" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="Buat Password" />
              </h1>
              {/* <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p> */}
            </div>
            <div className="gx-app-logo">
              <img
                alt="example"
                src="/assets/images/logo-idn.png"
                width="150"
              />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                label="Password"
                rules={[
                  {
                    required: true, message: "Minimal 8 karakter, terdiri dari huruf kapital, huruf kecil, dan angka!",
                    pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
                  },
                ]}
                name="password"
              >
                <Input type="password" placeholder="Masukkan Password" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Harap masukkan confirm password!",
                  },
                ]}
                name="confirm_password"
              >
                <Input type="password" placeholder="Masukkan Password" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="Simpan Password" />
                </Button>
              </Form.Item>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordConfirm;
