// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

// Import file
import TrainingRegistrationsList from "../../../../components/adminRole/trainingRegistration/TrainingRegistrationsList";
import { getTrainingRegistrations } from "../../../../appRedux/actions/TrainingRegistration";

export default function TrainingRegistrationsListContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    trainingRegistrations,
    trainingRegistrationsLoading,
    totalTrainingRegistrations,
  } = useSelector(({ trainingRegistration }) => trainingRegistration);
  let filter = queryString.parse(useLocation().search);
  const handleChangeCurrentPage = (currentPage) => {
    filter["current_page"] = currentPage;
    history.push("/training-registration?" + queryString.stringify(filter));
  };
  const handleChangePageSize = (pageSize) => {
    filter["page_size"] = pageSize;
    history.push("/training-registration?" + queryString.stringify(filter));
  };
  useEffect(() => {
    dispatch(getTrainingRegistrations(filter));
  }, [location.search]);
  return (
    <TrainingRegistrationsList
      trainingRegistrations={trainingRegistrations}
      loading={trainingRegistrationsLoading}
      totalTrainingRegistrations={totalTrainingRegistrations}
      onChangeCurrentPage={handleChangeCurrentPage}
      onChangePageSize={handleChangePageSize}
      pageSize={filter.page_size ? parseInt(filter.page_size) : 6}
      currentPage={filter.current_page ? parseInt(filter.current_page) : 1}
    />
  );
}
