// Import package
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Import file
import CircularProgress from "../../components/CircularProgress";
import { getAccount, getAccountTrainingCategories, saveAccount } from "../../appRedux/actions/Accounts";
import { Button, Card, Form, Input, PageHeader, Select } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toRupiah } from "../../libs";
import {
  ArrowRightOutlined,
} from "@ant-design/icons";

export default function EditAccountsPage() {
  const { bankAccountId } = useParams()

  const [account, setAccount] = useState(null)
  const [trainingCategoriesOptions, setTrainingCategoriesOptions] = useState(null)
  const [trainingCategoriesValues, setTrainingCategoriesValues] = useState([])
  const [form] = Form.useForm()
  const history = useHistory()

  useEffect(() => {
    getDataAccount()
  }, [])

  function getDataAccount() {
    getAccount(bankAccountId).then((data) => {
      if (!data.training_categories) {
        setTrainingCategoriesValues([])
        return
      }
      const values = []
      data.training_categories.map((value) => {
        values.push(value.id)
      })
      setTrainingCategoriesValues(values)
      setAccount(data)
    })
    getAccountTrainingCategories().then(data => {
      const options = []
      data.map((option) => {
        options.push({
          label: option.name,
          value: option.id
        })
      })
      setTrainingCategoriesOptions(options)
    })
  }

  async function onSubmit(values) {
    const response = await saveAccount(bankAccountId, values)
    if (response)
      history.push("/accounts/")
  }

  return account ?
    <>
      <PageHeader
        ghost={false}
        title={"Edit " + account.name + " " + account.number}
        onBack={() => history.push("/accounts/")}
      />
      <Card bordered={false} style={{ borderRadius: 0 }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            id: account.id,
            balance: toRupiah(account.balance),
            code: account.code,
            owner_name: account.owner_name,
            training_categories_values: trainingCategoriesValues,
            name: account.name,
          }}
          preserve
          onFinish={(values) => { onSubmit(values) }}
          onValuesChange={(v) => { }}
        >
          <Form.Item
            name="code"
            label={"Kode"}
          >
            <Input type="text" readOnly={true} disabled={true} />
          </Form.Item>
          <Form.Item
            name="balance"
            label={"Saldo"}
          >
            <Input type="text" readOnly={true} disabled={true} />
          </Form.Item>
          <Form.Item
            name="name"
            label={"Nama Bank"}
            rules={[
              {
                required: true,
                message: "Mohon masukkan nama bank",
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="owner_name"
            label={"Nama Pemilik"}
            rules={[
              {
                required: true,
                message: "Mohon masukkan nama pemilik",
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label={"Kategori Training"}
            name="training_categories_values"
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Pilih kategori training"
              options={trainingCategoriesOptions}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            <center>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                icon={<ArrowRightOutlined />}
              >
                Simpan
              </Button>
            </center>
          </Form.Item>
        </Form>
      </Card>
    </>
    :
    <CircularProgress />
}
