// Import package
import React from "react";
import { useSelector } from "react-redux";

// Import file
import TrainerRolePage from "./TrainerRolePage";
import AdminRolePage from "./AdminRolePage";
import ParticipantRolePage from "./ParticipantRolePage";

// Component
export default function DashboardPage() {
  const { authUser } = useSelector(({ auth }) => auth);

  return authUser.role === "admin" ? (
    <AdminRolePage />
  ) : authUser.role === "trainer" ? (
    <TrainerRolePage />
  ) : (
    <ParticipantRolePage/>
  )
}
