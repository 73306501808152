// Import Package
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Import File
import {
  createTrainer,
  createUser,
  updateUser,
  updateTrainer,
} from "../../../../appRedux/actions";
import TrainerForm from "../../../../components/adminRole/trainer/TrainerForm";

// Component
export default function TrainerFormContainer({ trainer }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = (data) => {
    if (!trainer) {
      dispatch(
        createTrainer(data)
      ).then(() => history.push("/trainer"))
    } else {
      dispatch(updateTrainer({ ...data, id: trainer.id }))
        .then(() => history.push("/trainer"))
    }
  };

  return <TrainerForm onSubmit={handleSubmit} trainer={trainer} />;
}
