import moment from "moment";
import { Table, Typography, Badge, Tag, Tooltip } from "antd";
import { toRupiah } from "libs";
import { Link } from "react-router-dom";
import TrainingsListItemActionsContainer from "../../../../containers/adminRole/training/TrainingsListItemActionsContainer";

const columns = () => {
  let result = [
    {
      title: "Waktu",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return {
          props: {
            style: {}
          },
          children: (
            <div>
              {moment(created_at).format("D MMMM YYYY, HH:mm:ss")}
            </div>
          )
        }
      },
    },
    {
      title: "Nama Pengguna",
      dataIndex: "user_name",
      key: "user_name",
      render: (user_name) => {
        return {
          props: {
            style: {}
          },
          children: (
            <div>{user_name}</div>
          )
        }
      },
    },
    {
      title: "Email",
      dataIndex: "user_email",
      key: "user_email",
      render: (user_email) => {
        return {
          props: {
            style: {}
          },
          children: (
            <div>{user_email}</div>
          )
        }
      },
    },
    {
      title: "Catatan",
      dataIndex: "note",
      key: "note",
      render: (note) => {
        return {
          props: {
            style: {}
          },
          children: (
            <div>{note}</div>
          )
        }
      },
    },
  ];
  return result;
};

export default function LogsList({
  logs,
  loading,
  totalLogs,
  onChangePagination,
  pageSize,
  currentPage,
}) {
  return (
    <Table
      loading={loading}
      size="small"
      columns={columns()}
      dataSource={logs.map((log) => ({
        ...log,
        key: log.id,
      }))}
      pagination={{
        total: totalLogs,
        position: ["bottomCenter"],
        responsive: true,
        onChange: onChangePagination,
        pageSize,
        current: currentPage,
      }}
    />
  );
}
