// Import package
import React from "react";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import TrainingRegistrationFormContainer from "../../containers/participantRole/trainingRegistration/TrainingRegistrationFormContainer";

// Component
export default function ParticipantTrainingRegistPage() {
  return (
    <PageContentWrapper title="Daftar Training" backLink={"/my-training"}>
        <TrainingRegistrationFormContainer/>
    </PageContentWrapper>
  );
}
