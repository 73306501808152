import React from "react";
import { List, Avatar, Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";

export default function TrainingOptionsList({
  trainingOptions = [],
  loading = false,
  searchValue,
  totalTrainingOptions,
  currentPage = 1,
  pageSize = 5,
  onChangePagination,
  onDelete,
}) {
  return (
    <List
      size="small"
      header={searchValue && "Hasil pencarian : " + searchValue}
      dataSource={trainingOptions}
      loading={loading}
      pagination={{
        total: totalTrainingOptions,
        pageSize: pageSize,
        current: currentPage,
        onChange: (page, pageSize) => onChangePagination(page, pageSize),
      }}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => onDelete(item.id)}
                    key="0"
                    icon={<DeleteOutlined />}
                    danger
                  >
                    Hapus
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              trigger={["click"]}
            >
              <i className="gx-icon-btn icon icon-ellipse-v" />
            </Dropdown>,
          ]}
        >
          <List.Item.Meta
            avatar={
              <Link to={"/training-option/" + item.id}>
                <Avatar style={{ backgroundColor: "#87d068" }} shape="square">
                  {item.name.charAt(0)}
                </Avatar>
              </Link>
            }
            title={<Link to={"/training-option/" + item.id}>{item.name}</Link>}
            description={item.training_category && item.training_category.name}
          />
        </List.Item>
      )}
    />
  );
}
