// Import package
import React from "react";
import { Descriptions } from "antd";

// Import file
import "react-phone-number-input/style.css";

// Component
export default function TrainerDetail({ trainer }) {
  const {
    name,
    email,
    whatsapp_number,
    certifications,
    linkedin_url,
    telegram_username,
  } = trainer;
  return (
    <Descriptions bordered size="middle" column={{ xs: 1, sm: 1 }}>
      <Descriptions.Item label="Nama">{name}</Descriptions.Item>
      <Descriptions.Item label="Alamat Email">{email}</Descriptions.Item>
      <Descriptions.Item label="Nomor Whatsapp">
        {whatsapp_number}
      </Descriptions.Item>
      <Descriptions.Item label="Username Telegram">
        {telegram_username}
      </Descriptions.Item>
      <Descriptions.Item label="Alamat URL Linkedid">
        {linkedin_url}
      </Descriptions.Item>
      <Descriptions.Item label="Sertifikasi">
        {certifications}
      </Descriptions.Item>
    </Descriptions>
  );
}
