import { React, useEffect, useState } from "react";
import {
  Form,
  Select,
  Input,
  InputNumber,
  DatePicker,
  Button,
  Checkbox,
  Modal,
  Descriptions,
  Tag,
  Radio,
} from "antd";
import moment from "moment";
import {
  SaveOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { currencyFormatter, currencyParser, toRupiah } from "../../../../libs";
import { baseRequest } from "../../../../util/Api";

export default function TrainingRegistrationForm({
  trainings = [],
  trainingsLoading = false,
  trainingOptions = [],
  trainingOptionsLoading = false,
  onChangeTrainingsFilter,
  onSearchParticipant,
  participants = [],
  participantsLoading = false,
  onSubmit,
  trainingRegistration,
}) {
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);

  useEffect(async () => {
    const data = await baseRequest("get", "/trainings/locations", null);
    let options = [];
    data.map((value) => {
      options.push({
        value: value,
        label: value,
      });
    });
    setLocations(options);
  }, []);

  useEffect(() => {
    if (trainings.length < 1) {
      form.setFieldsValue({ training_id: null });
    } else {
      form.setFieldsValue({ training_id: trainings[0].id });
      if (trainingRegistration) {
        if (trainingRegistration.total_price) {
          form.setFieldsValue({
            total_price: trainingRegistration.total_price,
          });
        } else {
          form.setFieldsValue({ total_price: trainings[0].price });
        }
      } else {
        form.setFieldsValue({ total_price: trainings[0].price });
      }
      form.setFieldsValue({ total_price: trainings[0].price });
    }
  }, [trainings]);
  return (
    <Form
      scrollToFirstError={true}
      form={form}
      layout="vertical"
      initialValues={
        !trainingRegistration
          ? {
            training_type: "offline",
            training_location: null,
            training_dates: moment(),
            already_paid: 500000,
            paid_off: true,
            total_price: 2500000,
            dp_deadline: moment().add(6, "hours"),
            is_retraining: false,
            send_message: true,
            is_corporate_participant: false,
            note: "",
            retraining_with_meal: false,
            retraining_received_kit: true,
          }
          : {
            ...trainingRegistration,
            training_option_id:
              trainingRegistration.training.training_option_id,
            training_type: trainingRegistration.is_online
              ? "online"
              : "offline",
            training_location:
              trainingRegistration.training.location,
            paid_off:
              trainingRegistration.already_paid >=
              trainingRegistration.total_price,
            training_dates: moment(
              trainingRegistration.training.dates[0].date
            ),
            total_price: trainingRegistration.total_price,
            already_paid: trainingRegistration.already_paid
              ? trainingRegistration.already_paid
              : 500000,
            send_message: true,
            dp_deadline: trainingRegistration.dp_deadline
              ? moment(trainingRegistration.dp_deadline)
              : moment().add(6, "hours"),
            is_corporate_participant: trainingRegistration.is_corporate_participant !== null ? trainingRegistration.is_corporate_participant : false
          }
      }
      onValuesChange={(changedValues, allValues) => {
        // console.log("changedValues", changedValues);
        // console.log("allValues", allValues);
        if (
          "training_option_id" in changedValues ||
          "training_type" in changedValues ||
          "training_location" in changedValues ||
          "training_dates" in changedValues
        ) {
          if (allValues.training_option_id) {
            onChangeTrainingsFilter({
              training_option_id: allValues.training_option_id,
              is_online: allValues.training_type === "online",
              location:
                allValues.training_type === "offline"
                  ? (allValues.training_location ? allValues.training_location : "XXXXXXXXXX")
                  : null,
              start_date: allValues.training_dates
                .startOf("month")
                .toISOString(),
              end_date: allValues.training_dates.endOf("month").toISOString(),
              // status: "available",
            });
          }
        }
        if (allValues.paid_off) {
          form.setFieldsValue({
            already_paid: allValues.total_price
          })
        }
      }}
      onFinish={(v) => {
        const result = {
          already_paid: !v.is_retraining
            ? v.paid_off
              ? v.total_price
              : v.already_paid
            : null,
          participant_id: !trainingRegistration
            ? v.participant_id
            : trainingRegistration.participant_id,
          total_price: !trainingRegistration ? v.total_price : v.total_price,
          training_id: !trainingRegistration
            ? v.training_id
            : trainingRegistration.training_id,
          dp_deadline: !v.is_retraining
            ? v.dp_deadline
              ? v.dp_deadline.toISOString()
              : null
            : null,
          is_retraining: v.is_retraining,
          send_message: !trainingRegistration
            ? participants.find((p) => p.id === v.participant_id).whatsapp_number === null
              ? false
              : v.already_paid < 500000
                ? true
                : v.send_message
            : v.is_canceled ? false : trainingRegistration.participant.whatsapp_number === null
              ? false : v.already_paid < 500000 ? true : v.send_message,
          is_canceled: !trainingRegistration ? false : v.is_canceled,
          is_corporate_participant: v.is_corporate_participant,
          note: v.note,
          retraining_with_meal: v.retraining_with_meal,
          retraining_received_kit: v.retraining_received_kit,
          is_online: v.training_type == "online",
        };
        const training = !trainingRegistration
          ? trainings.find((t) => t.id === result.training_id)
          : trainingRegistration.training;
        const participant = !trainingRegistration
          ? participants.find((p) => p.id === result.participant_id)
          : trainingRegistration.participant;
        const trainingOption = training.training_option;
        const trainingLocation = !result.is_online
          ? "Offline di " + training.location
          : "Online";
        console.log(trainingLocation)
        !trainingRegistration
          ? Modal.confirm({
            title: "Konfirmasi",
            centered: true,
            width: 600,
            icon: null,
            content: (
              <Descriptions size="small" bordered column={1}>
                <Descriptions.Item label="Training">
                  {trainingOption.name + " - "}
                  {trainingLocation + " - "}
                  {training.dates_summary}
                </Descriptions.Item>
                <Descriptions.Item label="Peserta">
                  {participant.name}
                </Descriptions.Item>
                <Descriptions.Item label="Jenis peserta">
                  {result.is_retraining ? "Mengulang" : "Reguler"}
                </Descriptions.Item>
                <Descriptions.Item label="Kirim pesan email dan WA ke peserta">
                  {result.send_message
                    ? "Ya"
                    : "Tidak"
                  }
                </Descriptions.Item>
                {!result.is_retraining && (
                  <>
                    <Descriptions.Item label="Total harga">
                      {toRupiah(result.total_price)}
                    </Descriptions.Item>
                    {result.already_paid < result.total_price && (
                      <Descriptions.Item label="Jumlah uang yang baru dibayar">
                        {toRupiah(result.already_paid)}
                      </Descriptions.Item>
                    )}
                    {v.paid_off && (
                      <Descriptions.Item label="Peserta Corporate / Perusahaan">
                        {result.is_corporate_participant
                          ? "Ya"
                          : "Tidak"}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="Status">
                      <Tag
                        icon={
                          result.is_canceled ? (
                            <CloseCircleOutlined />
                          ) : result.already_paid === result.total_price ? (
                            <CheckCircleOutlined />
                          ) : (
                            <ClockCircleOutlined />
                          )
                        }
                        color={
                          result.is_canceled
                            ? "error"
                            : result.already_paid === result.total_price
                              ? "success"
                              : "default"
                        }
                        style={{ margin: 0 }}
                      >
                        {result.is_canceled
                          ? "batal"
                          : result.already_paid === result.total_price
                            ? "lunas total"
                            : result.already_paid >= 500000
                              ? "belum lunas"
                              : "belum bayar"}
                      </Tag>
                    </Descriptions.Item>
                    {result.dp_deadline && (
                      <Descriptions.Item label="Batas waktu pelunasan uang muka">
                        {moment(result.dp_deadline).format("LLLL")}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="Catatan">
                      {v.note}
                    </Descriptions.Item>
                  </>
                )}
              </Descriptions>
            ),
            onOk: () => onSubmit(result),
          })
          : onSubmit(result);
      }}
    >
      {!trainingRegistration && (
        <>
          <Form.Item
            label="Pilihan training"
            name="training_option_id"
            rules={[
              { required: true, message: "Mohon pilih pilihan training" },
            ]}
          >
            <Select
              loading={trainingOptionsLoading}
              showSearch
              placeholder="pilih pilihan training"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {trainingOptions.map((t) => (
                <Select.Option key={t.id} value={t.id} label={t.name}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Tipe training" name="training_type" required>
            <Select>
              <Select.Option value="offline">Offline</Select.Option>
              <Select.Option value="online">Online</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {
              () => (
                <Form.Item
                  label="Lokasi training"
                  name="training_location"
                  required
                  style={{
                    display:
                      form.getFieldValue("training_type") === "offline"
                        ? "block"
                        : "none",
                  }}
                >
                  <Select options={locations}>
                  </Select>
                </Form.Item>
              )
              // )
            }
          </Form.Item>
          <Form.Item label="Bulan training" name="training_dates" required>
            <DatePicker picker="month" format="MMMM YYYY" allowClear={false} />
          </Form.Item>
        </>
      )}
      {!trainingRegistration ? (
        <Form.Item
          label="Jadwal training"
          rules={[{ required: true, message: "mohon pilih jadwal training" }]}
          name="training_id"
        >
          <Select
            placeholder="pilih jadwal training"
            loading={trainingsLoading}
            disabled={
              form.getFieldValue("training_option_id") === null ||
              form.getFieldValue("training_option_id") === undefined
            }
          >
            {trainings.map((t) => (
              <Select.Option key={t.id} value={t.id} disabled={!t.is_running}>
                {t.dates_summary}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item label="Jadwal training">
          <Input
            disabled
            value={
              trainingRegistration.training.training_option.name +
              " - " +
              (!trainingRegistration.is_online
                ? trainingRegistration.training.location
                : "Online") +
              " - " +
              trainingRegistration.training.dates_summary
            }
          />
        </Form.Item>
      )}
      {!trainingRegistration ? (
        <Form.Item
          label="Peserta"
          name="participant_id"
          rules={[{ required: true, message: "Mohon pilih peserta" }]}
        >
          <Select
            showSearch
            onSearch={onSearchParticipant}
            filterOption={false}
            notFoundContent={null}
            loading={participantsLoading}
          >
            {participants.map((p) => (
              <Select.Option key={p.id} value={p.id}>
                {p.name} {`(${p.email})`} {p.whatsapp_number === null ? "- (Peserta tidak memiliki nomor whatsapp)" : ""}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item label="Peserta">
          <Input disabled value={trainingRegistration.participant.name} />
        </Form.Item>
      )}
      {trainingRegistration && (
        <Form.Item name="is_canceled" valuePropName="checked">
          <Checkbox>Batal</Checkbox>
        </Form.Item>
      )}
      <Form.Item name="is_retraining" label="Jenis peserta">
        <Radio.Group>
          <Radio.Button value={false}>Reguler</Radio.Button>
          <Radio.Button value={true}>Mengulang</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {!trainingRegistration
        ? (
          <Form.Item shouldUpdate noStyle>
            {() => form.getFieldValue("already_paid") >= 500000 && participants.find((p) => p.id === form.getFieldValue("participant_id")) !== undefined && participants.find((p) => p.id === form.getFieldValue("participant_id")).whatsapp_number !== null && (
              <Form.Item
                name="send_message"
                label={"Kirim pesan email dan WA ke peserta"}
              >
                <Radio.Group
                >
                  <Radio.Button
                    value={true}>Ya</Radio.Button>
                  <Radio.Button
                    value={false}>Tidak</Radio.Button>
                </Radio.Group>
              </Form.Item>
            )}
          </Form.Item>
        ) : (
          <Form.Item shouldUpdate noStyle>
            {() => form.getFieldValue("is_canceled") === false && form.getFieldValue("already_paid") >= 500000 && trainingRegistration.participant.whatsapp_number !== null && (
              <Form.Item
                name="send_message"
                label={"Kirim pesan email dan WA ke peserta"}
              >
                <Radio.Group
                >
                  <Radio.Button
                    value={true}>Ya</Radio.Button>
                  <Radio.Button
                    value={false}>Tidak</Radio.Button>
                </Radio.Group>
              </Form.Item>
            )}
          </Form.Item>
        )
      }
      <Form.Item noStyle shouldUpdate>
        {() =>
          !form.getFieldValue("is_retraining") ? (
            <>
              <Form.Item
                label="Total harga"
                name="total_price"
                rules={[{ required: true, message: "Mohon isi harga total" }]}
              >
                <InputNumber
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  style={{ width: "100%" }}
                  min={0}
                  step={100000}
                />
              </Form.Item>
              <Form.Item name="paid_off" valuePropName="checked">
                <Checkbox>Lunas total</Checkbox>
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {() =>
                  form.getFieldValue("paid_off") && (
                    <Form.Item
                      name="is_corporate_participant"
                      label="Peserta Corporate / Perusahaan"
                    >
                      <Radio.Group>
                        <Radio.Button value={true}>Ya</Radio.Button>
                        <Radio.Button value={false}>Tidak</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {() =>
                  !form.getFieldValue("paid_off") && (
                    <Form.Item
                      label="Jumlah uang yang sudah dibayar"
                      name="already_paid"
                      rules={[
                        {
                          required: true,
                          message: "Mohon isi uang yang sudah dibayar",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        max={form.getFieldValue("total_price")}
                        step={100000}
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {() =>
                  form.getFieldValue("already_paid") < 500000 &&
                  !form.getFieldValue("paid_off") && (
                    <Form.Item
                      label="Batas waktu pelunasan uang muka (Rp 500.000)"
                      name="dp_deadline"
                      rules={[{ required: true, message: "Mohon diisi" }]}
                    >
                      <DatePicker
                        showTime
                        format="dddd, DD MMMM YYYY [pukul] HH:mm"
                        allowClear={false}
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item name="retraining_with_meal" label="Makan">
                <Radio.Group>
                  <Radio.Button value={true}>Dengan Makan</Radio.Button>
                  <Radio.Button value={false}>Tanpa Makan</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="retraining_received_kit" label="Training Kit">
                <Radio.Group>
                  <Radio.Button value={true}>Sudah Dapat</Radio.Button>
                  <Radio.Button value={false}>Belum Dapat</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </>
          )
        }
      </Form.Item>
      <Form.Item name="note" label="Catatan">
        <Input.TextArea />
      </Form.Item>
      <center>
        <Button
          htmlType="submit"
          type="primary"
          icon={<SaveOutlined />}
          size="large"
        >
          Simpan
        </Button>
      </center>
    </Form>
  );
}
