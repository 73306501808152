// Import package
import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";

// Import file
import TrainerList from "../../../../components/adminRole/trainer/TrainerList";
import {
    getTrainers,
    getTrainer,
    deleteTrainer,
    updateUser
  } from "../../../../appRedux/actions";
import TrainerChangePassForm from "../../../../components/adminRole/trainer/TrainerChangePassForm";

// Component
export default function TrainerListContainer() {
    const [showChangePassModal, setShowChangePassModal] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { trainers, trainersLoading, trainer } = useSelector(
        ({ trainer }) => trainer
    )

    useEffect(() => {
        dispatch(getTrainers());
      }, [dispatch]);
    
    const handleDelete = (id) => {
        Modal.confirm({
            title: "Konfirmasi",
            content: "Apakah anda yakin ingin peserta ini ?",
            onOk: () =>
              dispatch(deleteTrainer(id)).then(() => {
                dispatch(getTrainers());
              }),
          });
    }

    const handleEdit = (id) => {
        history.push("/trainer/" + id + "/edit")
    }

    const handleChangePass = (id) => {
        dispatch(getTrainer(id))
        setShowChangePassModal(true)
    }

    const handleSavePass = (newPassword) => {
        dispatch(updateUser({
            id: trainer.user_id,
            email: trainer.user.email,
            password: newPassword,
            role: "trainer"
        })).then(() => {
            setShowChangePassModal(false)
        })
    }
    
    return (
        <>
            <TrainerChangePassForm
            visible={showChangePassModal}
            onCancel={() => setShowChangePassModal(false)}
            onSave={handleSavePass}
            />
            <TrainerList
                onSelectItem
                trainerList={trainers}
                loading={trainersLoading}
                onDelete={handleDelete}
                onEdit={handleEdit}
                onChangePass={handleChangePass}/>
        </>
    )
}
