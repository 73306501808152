import {
  SET_DASHBOARD_SUMMARY,
  SET_DASHBOARD_SUMMARY_LOADING,

  SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH,
  SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH,
  SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH,
  SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH,
  SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH,
  SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH,
  SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH,
  SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH,
  SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_FILTER,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  summary: {},
  summaryLoading: false,

  trainingRegisMonthlyGraph: [],
  trainingRegisMonthlyGraphLoading: false,
  trainingRegistMonthlyGraphFilter: {
    training_option_id: null,
    status: null
  },

  trainingParticipantsMonthlyGraph: [],
  trainingParticipantsMonthlyGraphLoading: false,
  trainingParticipantstMonthlyGraphFilter: {
    training_option_id: null,
    is_retraining: null
  },

  trainingParticipantRegularGraph: [],
  trainingParticipantRegularGraphLoading: false,
  trainingParticipantRegularGraphFilter: "1",

  trainingParticipantRetrainingGraph: [],
  trainingParticipantRetrainingGraphLoading: false,
  trainingParticipantRetrainingGraphFilter: "1",

  trainingRegistPaidGraph: [],
  trainingRegistPaidGraphLoading: false,
  trainingRegistPaidGraphFilter: "1",

  trainingRegistCancelGraph: [],
  trainingRegistCancelGraphLoading: false,
  trainingRegistCancelGraphFilter: "1",

  trainingStatusRunningGraph: [],
  trainingStatusRunningGraphLoading: false,
  trainingStatusRunningGraphFilter: "1",

  trainingStatusCancelGraph: [],
  trainingStatusCancelGraphLoading: false,
  trainingStatusCancelGraphFilter: "1",
};

const DashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DASHBOARD_SUMMARY: {
      return {
        ...state,
        summary: action.payload,
        summaryLoading: false,
      };
    }
    case SET_DASHBOARD_SUMMARY_LOADING: {
      return {
        ...state,
        summaryLoading: action.payload,
      };
    }
    
    // TRAINING REGIST MONTHLY GRAPH
    case SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH: {
      return {
        ...state,
        trainingRegisMonthlyGraph: action.payload,
        trainingRegisMonthlyGraphLoading: false,
      };
    }
    case SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_LOADING: {
      return {
        ...state,
        trainingRegisMonthlyGraphLoading: action.payload,
      };
    }
    case SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_FILTER: {
      return {
        ...state,
        trainingRegistMonthlyGraphFilter: action.payload,
      };
    }
    //---------------------------------------

    // TRAINING PARTICIPANTS MONTHLY GRAPH
    case SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH: {
      return {
        ...state,
        trainingParticipantsMonthlyGraph: action.payload,
        trainingParticipantsMonthlyGraphLoading: false,
      };
    }
    case SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_LOADING: {
      return {
        ...state,
        trainingParticipantsMonthlyGraphLoading: action.payload,
      };
    }
    case SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_FILTER: {
      return {
        ...state,
        trainingParticipantstMonthlyGraphFilter: action.payload,
      };
    }
    //---------------------------------------

    // TRAINING PARTICIPANT REGULAR GRAPH
    case SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH: {
      return {
        ...state,
        trainingParticipantRegularGraph: action.payload,
        trainingParticipantRegularGraphLoading: false,
      };
    }
    case SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_LOADING: {
      return {
        ...state,
        trainingParticipantRegularGraphLoading: action.payload,
      };
    }
    case SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_FILTER: {
      return {
        ...state,
        trainingParticipantRegularGraphFilter: action.payload,
      };
    }
    //---------------------------------------

    // TRAINING PARTICIPANT RETRAINING GRAPH
    case SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH: {
      return {
        ...state,
        trainingParticipantRetrainingGraph: action.payload,
        trainingParticipantRetrainingGraphLoading: false,
      };
    }
    case SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_LOADING: {
      return {
        ...state,
        trainingParticipantRetrainingGraphLoading: action.payload,
      };
    }
    case SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_FILTER: {
      return {
        ...state,
        trainingParticipantRetrainingGraphFilter: action.payload,
      };
    }
    //---------------------------------------

    // TRAINING REGIST PAID GRAPH
    case SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH: {
      return {
        ...state,
        trainingRegistPaidGraph: action.payload,
        trainingRegistPaidGraphLoading: false,
      };
    }
    case SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_LOADING: {
      return {
        ...state,
        trainingRegistPaidGraphLoading: action.payload,
      };
    }
    case SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_FILTER: {
      return {
        ...state,
        trainingRegistPaidGraphFilter: action.payload,
      };
    }
    //---------------------------------------

    // TRAINING REGIST CANCEL GRAPH
    case SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH: {
      return {
        ...state,
        trainingRegistCancelGraph: action.payload,
        trainingRegistCancelGraphLoading: false,
      };
    }
    case SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_LOADING: {
      return {
        ...state,
        trainingRegistCancelGraphLoading: action.payload,
      };
    }
    case SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_FILTER: {
      return {
        ...state,
        trainingRegistCancelGraphFilter: action.payload,
      };
    }
    //---------------------------------------


    // TRAINING STATUS RUNNING GRAPH
    case SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH: {
      return {
        ...state,
        trainingStatusRunningGraph: action.payload,
        trainingStatusRunningGraphLoading: false,
      };
    }
    case SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_LOADING: {
      return {
        ...state,
        trainingStatusRunningGraphLoading: action.payload,
      };
    }
    case SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_FILTER: {
      return {
        ...state,
        trainingStatusRunningGraphFilter: action.payload,
      };
    }
    //---------------------------------------

    // TRAINING STATUS CANCEL GRAPH
    case SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH: {
      return {
        ...state,
        trainingStatusCancelGraph: action.payload,
        trainingStatusCancelGraphLoading: false,
      };
    }
    case SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_LOADING: {
      return {
        ...state,
        trainingStatusCancelGraphLoading: action.payload,
      };
    }
    case SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_FILTER: {
      return {
        ...state,
        trainingStatusCancelGraphFilter: action.payload,
      };
    }
    //---------------------------------------
    default:
      return state;
  }
};

export default DashboardReducer;
