// Import Package
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Import File
import {
  updateUserPass,
} from "../../appRedux/actions";
import EditPasswordForm from "../../components/EditPasswordForm";

// Component
export default function EditPasswordFormContainer() {
  const dispatch = useDispatch();
  const handleSubmit = (data) => {
    dispatch(updateUserPass({
        old_password: data.old_password,
        password: data.new_password,
        confirm_new_password: data.confirm_new_password
    })).then(() => {
      setTimeout(() => {
        window.location.reload(true)
      }, 500)
    })
    // .then(() => document.location.reload(true));
  };
  return <EditPasswordForm onSubmit={handleSubmit}/>;
}
