// Import package
import React from "react";
import { Form, Input, Button } from "antd";
import "react-phone-number-input/style.css";

export default function EditProfile({ onSubmit, userAdmin }) {
  return (
    <Form
      layout="vertical"
      initialValues={{
        ...userAdmin,
      }}
      style={{ padding: 10 }}
      onFinish={(values) => {
        onSubmit({ ...values });
      }}
    >
      <Form.Item
        name="name"
        label="Nama lengkap"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nama lengkap peserta!",
          },
        ]}
      >
        <Input placeholder="Dimas Hermawan" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Alamat email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Mohon masukkan alamat email dengan benar",
          },
        ]}
      >
        <Input placeholder="email@gmail.com" />
      </Form.Item>
      <Form.Item>
        <center>
          <Button size="large" type="primary" htmlType="submit">
            Simpan
          </Button>
        </center>
      </Form.Item>
    </Form>
  );
}
