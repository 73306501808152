import React from "react";
import { Form, Input, Button, InputNumber, Select, Upload } from "antd";
import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import slugify from "slugify";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
export default function TrainingOptionForm({
  trainingCategories = [],
  trainingCategoriesLoading = false,
  onSubmit,
  uploadImage,
  trainingOption,
}) {
  return (
    <Form
      layout="vertical"
      initialValues={
        !trainingOption
          ? {
              default_day_duration: 4,
              default_max_participant: 10,
              default_max_participant_offline: 10,
              default_price: 2500000,
              training_category_id: null,
              detail_link: "https://www.idn.id/",
              total_hours: 36,
            }
          : {
              ...trainingOption,
              certificate_template_blob_path:
                trainingOption.certificate_template_blob_path && [
                  {
                    name:
                      "template-sertifikat-" +
                      slugify(trainingOption.name, { lower: true }) +
                      ".png",
                    status: "done",
                  },
                ],
            }
      }
      onFinish={(v) => {
        if (!v.certificate_template_blob_path) {
          v.certificate_template_blob_path = [];
        }
        onSubmit({
          ...v,
          certificate_template_blob_path:
            v.certificate_template_blob_path.length > 0
              ? v.certificate_template_blob_path[0].response
                ? v.certificate_template_blob_path[0].response
                : trainingOption &&
                  trainingOption.certificate_template_blob_path
              : null,
        });
      }}
    >
      <Form.Item
        name="name"
        rules={[{ required: true, message: "Mohon masukkan nama" }]}
        label="Nama"
      >
        <Input placeholder="CCNA Enterprise" />
      </Form.Item>
      <Form.Item
        name="default_price"
        label="Harga (Default)"
        rules={[
          {
            required: true,
            message: "Mohon masukkan harga",
          },
        ]}
      >
        <Input type="number" addonBefore="Rp" />
      </Form.Item>
      <Form.Item
        name="training_category_id"
        label="Kategori"
        rules={[
          {
            required: true,
            message: "Mohon pilih kategori",
          },
        ]}
      >
        {trainingOption && trainingCategoriesLoading ? (
          <Input disabled value={trainingOption.name} />
        ) : (
          <Select
            loading={trainingCategoriesLoading}
            placeholder="pilih kategori"
          >
            {trainingCategories.map((category) => (
              <Select.Option key={category.id} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item
        name="detail_link"
        rules={[{ required: true, message: "Mohon masukkan alamat" }]}
        label="Alamat URL detail"
      >
        <Input placeholder="https://www.idn.id/" />
      </Form.Item>
      <Form.Item
        name="certificate_template_blob_path"
        label="Template sertifikat"
        valuePropName="fileList"
        tooltip="ekstensi .png dengan resolusi 7016px x 4960px"
        getValueFromEvent={normFile}
      >
        <Upload
          method="put"
          customRequest={uploadImage}
          maxCount={1}
          listType="picture"
          accept="image/png"
          multiple={false}
        >
          <Button icon={<UploadOutlined />}>Klik untuk mengunggah</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name="default_day_duration"
        label="Durasi (Default)"
        rules={[
          {
            required: true,
            message: "Mohon masukkan durasi",
          },
        ]}
      >
        <Input type="number" addonAfter="Hari" />
      </Form.Item>
      <Form.Item
        name="default_max_participant"
        label="Jumlah Maksimal Peserta Online (Default)"
        rules={[
          {
            required: true,
            message: "Mohon masukkan jumlah maksimal peserta utama",
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        name="default_max_participant_offline"
        label="Jumlah Maksimal Peserta Offline (Default)"
        rules={[
          {
            required: true,
            message: "Mohon masukkan jumlah maksimal peserta utama",
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="description" label="Deskripsi">
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="total_hours"
        label="Total jam"
        rules={[
          {
            required: true,
            message: "Mohon masukkan total jam",
          },
        ]}
      >
        <Input type="number" min={1} addonAfter="Jam" />
      </Form.Item>
      <center>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          icon={<SaveOutlined />}
        >
          Simpan
        </Button>
      </center>
    </Form>
  );
}
