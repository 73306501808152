import PageContentWrapper from "../../components/PageContentWrapper";
import { Form, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import {
  setErrorMessage,
  setLoadingMessage,
  setSuccessMessage
} from "../../appRedux/actions/Common";
import { baseRequest } from "../../util/Api";

export default function CertVerificationPage(props) {
  const dispatch = useDispatch();
  const recaptchaRef = React.createRef();
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [data, setData] = useState([]);

  const messageKey = "CertVerificationPage";

  const columns = [
    {
      title: "Nomor Sertifikat",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Nama Lengkap",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Nama Training",
      dataIndex: "training_name",
      key: "training_name",
    },
    {
      title: "Tanggal Training",
      dataIndex: "training_dates_summary",
      key: "training_dates_summary",
    },
  ]

  const onSearch = async (value) => {
    value = value.trim()
    if (value == "")
      return
    dispatch(setLoadingMessage("Mencari sertifikat ...", messageKey));
    setSearchEnabled(false);
    setData([])
    const recaptchaComp = recaptchaRef.current;
    try {
      const recaptcha = recaptchaComp.getValue();
      const data = await baseRequest("post", "/cert-verification/", null, { value, recaptcha });
      if (data.length == 0)
        return dispatch(setErrorMessage("Sertifikat tidak ditemukan", messageKey));
      setData(data)
      dispatch(setSuccessMessage("Sertifikat ditemukan", messageKey));
    } catch (error) {
      dispatch(setErrorMessage(error, messageKey));
    }
    recaptchaComp.reset();
  }

  return (
    <PageContentWrapper
      extra={<></>}
      title="Verifikasi Sertifikat Training Reguler ID-Networkers"
    >
      <Form
        layout="vertical"
        style={{ padding: 10 }}
        onFinish={(values) => {

        }}
      >
        <Form.Item 
          label="Klik 'I'm not a robot' dulu"
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={() => { setSearchEnabled(true) }}
            onErrored={() => { setSearchEnabled(false) }}
            onExpired={() => { setSearchEnabled(false) }}
          />
        </Form.Item>
        <Form.Item
          name="value"
          label="Silahkan untuk memasukkan nama lengkap atau nomor sertifikat"
          rules={[
            {
              required: true,
              message: "Mohon masukkan  nama lengkap atau nomor sertifikat!",
            },
          ]}
        >
          <Input.Search
            placeholder="nama lengkap atau nomor sertifikat"
            enterButton="Cari"
            onSearch={onSearch}
            disabled={!searchEnabled}
          />
        </Form.Item>
        <Form.Item>
          <Table columns={columns} dataSource={data} bordered />
        </Form.Item>
      </Form>
    </PageContentWrapper>
  );
}