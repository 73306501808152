import {
  SET_AFFILIATE_POINTS_HISTORY,
  SET_AFFILIATE_POINTS_HISTORY_LOADING,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  affiliatePointsHistory: [],
  affiliatePointsHistoryLoading: false,
  totalAffiliatePointsHistory: 0,
};

const AffiliatePointsHistoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AFFILIATE_POINTS_HISTORY: {
      return {
        ...state,
        affiliatePointsHistory: action.payload,
        affiliatePointsHistoryLoading: false,
      };
    }
    case SET_AFFILIATE_POINTS_HISTORY_LOADING: {
      return {
        ...state,
        affiliatePointsHistoryLoading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default AffiliatePointsHistoryReducer;
