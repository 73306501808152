import React, { useState } from "react";
import { Space, Select, DatePicker, Input, Button } from "antd";
export default function TrainingRegistrationFilter({
  onSearch,
  onChangeStatus,
  onChangeTrainingOption,
  onChangeMonth,
  onChangeIsRetraining,
  onChangeValueChangeTrainingType,
  search = "",
  status = null,
  trainingOption = null,
  month = null,
  isRetraining = null,
  trainingOptions = [],
  trainingOptionsLoading = false,
  onReset,
  enableReset = false,
  valueChangeTrainingType = null
}) {
  const [searchValue, setSearchValue] = useState(search);
  return (
    <Space wrap style={{ marginBottom: 10 }}>
      <Input.Search
        style={{ margin: 0 }}
        allowClear
        placeholder="Cari"
        onSearch={onSearch}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <Select
        placeholder="Status"
        defaultActiveFirstOption
        dropdownMatchSelectWidth={false}
        value={status}
        onChange={onChangeStatus}
        se
      >
        <Select.Option value={null}>Semua status</Select.Option>
        <Select.Option value="canceled">Batal</Select.Option>
        <Select.Option value="paid_off">Lunas</Select.Option>
        <Select.Option value="waiting_for_down_payment">
          Belum bayar
        </Select.Option>
        <Select.Option value="waiting_for_paid_off">Belum lunas</Select.Option>
      </Select>
      <Select
        placeholder="Pilihan training"
        defaultActiveFirstOption
        dropdownMatchSelectWidth={false}
        value={trainingOption}
        onChange={onChangeTrainingOption}
        loading={trainingOptionsLoading}
        showSearch
        optionFilterProp="children"
        style={{ width: 200 }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Select.Option value={null}>Semua pilihan training</Select.Option>
        {trainingOptions.map((t) => (
          <Select.Option value={t.id} key={t.id}>
            {t.name}
          </Select.Option>
        ))}
      </Select>
      <DatePicker
        placeholder="Pilih bulan"
        picker="month"
        format="MMMM YYYY"
        onChange={onChangeMonth}
        value={month}
      />
      <Select
        value={isRetraining}
        placeholder="Jenis peserta"
        dropdownMatchSelectWidth={false}
        onChange={onChangeIsRetraining}
      >
        <Select.Option value={null}>Semua jenis peserta</Select.Option>
        <Select.Option value={false}>Reguler</Select.Option>
        <Select.Option value={true}>Mengulang</Select.Option>
      </Select>
      <Select
            defaultValue={valueChangeTrainingType}
            dropdownMatchSelectWidth={true}
            onChange={onChangeValueChangeTrainingType}
            style={{ borderRadius: 0, width: 200 }}
          >
            <Select.Option value="1">Semua peserta</Select.Option>
            <Select.Option value="2">Peserta online</Select.Option>
            <Select.Option value="3">Peserta offline</Select.Option>
          </Select>
      {enableReset && (
        <Button
          type="primary"
          style={{ margin: 0 }}
          onClick={() => {
            onReset();
            setSearchValue("");
          }}
        >
          Reset
        </Button>
      )}
    </Space>
  );
}
