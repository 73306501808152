import { React, useState, useEffect } from "react";
import { Space, DatePicker, Button, Select } from "antd";
import moment from "moment-timezone";
import TrainingOptionsSelectContainer from "../../../../containers/adminRole/trainingOption/TrainingOptionsSelectContainer";
import TrainersSelectContainer from "../../../../containers/adminRole/trainer/TrainersSelectContainer";
import { baseRequest } from "../../../../util/Api";

export default function TrainingFilter({
  onChangeSearch,
  search,
  onChangeDates,
  dates,
  trainingOption,
  onChangeTrainingOption,
  onChangeTrainer,
  trainer,
  onReset,
  onChangeType,
  onChangeLocation,
}) {
  const [locations, setLocations] = useState([]);

  useEffect(async () => {
    let options = [{
      value: null,
      label: "Semua Lokasi",
    }];
    const data = await baseRequest("get", "/trainings/locations", null);
    data.map((value) => {
      options.push({
        value: value,
        label: value,
      });
    });
    setLocations(options);
  }, []);

  return (
    <Space wrap>
      <DatePicker.RangePicker
        format="D MMMM YYYY"
        onChange={(v) =>
          v
            ? onChangeDates(v.map((date) => date.toISOString()))
            : onChangeDates([null, null])
        }
        value={dates.map((date) => (date ? moment(date) : null))}
      />
      <Select
        defaultValue={null}
        showSearch
        style={{ width: 120 }}
        optionFilterProp="label"
        onChange={onChangeType}
        // value={null}
        loading={false}
      >
        <Select.Option value={null} label="semua">
          Semua Tipe
        </Select.Option>
        <Select.Option value={true} label="online">
          Online
        </Select.Option>
        <Select.Option value={false} label="offline">
          Offline
        </Select.Option>
      </Select>
      <Select
        defaultValue={null}
        style={{ width: 100 }}
        options={locations}
        onChange={onChangeLocation} />
      <TrainingOptionsSelectContainer
        selectedTrainingOption={trainingOption}
        onSelect={onChangeTrainingOption}
      />
      <TrainersSelectContainer
        selectedTrainer={trainer}
        onSelect={onChangeTrainer}
      />
      <Button
        type="primary"
        style={{ margin: 0 }}
        disabled={
          dates[0] === null &&
          dates[1] === null &&
          trainer === "all" &&
          trainingOption === "all"
        }
        onClick={onReset}
      >
        Reset
      </Button>
    </Space>
  );
}
