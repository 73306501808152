import { baseRequest } from "../../util/Api";
import {
  SET_BANK_TRANSACTIONS,
  SET_TOTAL_BANK_TRANSACTIONS,
  SET_BANK_TRANSACTIONS_LOADING,
} from "../../constants/ActionTypes";
import { setErrorMessage } from "./Common";

export const getBankTransactions = (filter) => (dispatch) => {
  dispatch({
    type: SET_BANK_TRANSACTIONS_LOADING,
    payload: true,
  });
  baseRequest("get", "/bank-transactions/", filter)
    .then(({ data, meta }) => {
      dispatch({
        type: SET_BANK_TRANSACTIONS,
        payload: data,
      });
      dispatch({
        type: SET_TOTAL_BANK_TRANSACTIONS,
        payload: meta.total,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_BANK_TRANSACTIONS_LOADING,
        payload: false,
      });
    });
};
