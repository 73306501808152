import { message } from "antd";
import { baseRequest } from "../../util/Api";

export const getCounter = () => {
  return new Promise((resolve, reject) => {
    baseRequest("get", "/counters/")
      .then((data) => {
        resolve(data)
      }).catch((e) => {
        message.error(e)
      })
  })
}