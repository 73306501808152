import {
  SET_DISCOUNT_TRAININGS,
  SET_DISCOUNT_TRAININGS_LOADING,
  DELETE_DISCOUNT_TRAINING,
  UPDATE_DISCOUNT_TRAINING,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  discountTrainings: [],
  discountTrainingsLoading: false,
};

const DiscountTrainingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DISCOUNT_TRAININGS: {
      return {
        ...state,
        discountTrainings: action.payload,
        discountTrainingsLoading: false,
      };
    }
    case SET_DISCOUNT_TRAININGS_LOADING: {
      return {
        ...state,
        discountTrainingsLoading: action.payload,
      };
    }
    case DELETE_DISCOUNT_TRAINING: {
      return {
        ...state,
        discountTrainings: state.discountTrainings.filter(
          (p) => p.id !== action.payload
        ),
      };
    }
    case UPDATE_DISCOUNT_TRAINING: {
      return {
        ...state,
        discountTrainings: state.discountTrainings.map((t) =>
          t.id === action.payload.id ? action.payload : t
        ),
      };
    }
    default:
      return state;
  }
};

export default DiscountTrainingReducer;
