// Import Package
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";

// Import file
import AdminList from "../../../components/adminRole/admin/AdminList";
import {
  deleteAdmin,
  getAdmin,
  getAdmins,
  updateUser,
} from "../../../appRedux/actions";
import AdminChangePassForm from "../../../components/adminRole/admin/AdminChangePassForm";

// Component
export default function AdminListContainer() {
  const [showChangePassModal, setShowChangePassModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { admins, admin } = useSelector(
    ({ admin }) => admin
  );
  useEffect(() => {
    dispatch(getAdmins());
  }, []);

  const handleEdit = (id) => {
    history.push("/admin/" + id + "/edit");
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin menghapus admin ini ?",
      onOk: () =>
        dispatch(deleteAdmin(id)).then(() => {
          dispatch(getAdmins());
        }),
    });
  };

  const handleChangePass = (id) => {
    dispatch(getAdmin(id));
    setShowChangePassModal(true);
  };

  const handleSavePass = (newPassword) => {
    dispatch(
      updateUser({
        id: admin.user_id,
        email: admin.user.email,
        password: newPassword,
        role: "admin",
      })
    ).then(() => {
      setShowChangePassModal(false);
    });
  };
  return (
    <>
      <AdminChangePassForm
        visible={showChangePassModal}
        onCancel={() => setShowChangePassModal(false)}
        onSave={handleSavePass}
      />
      <AdminList
        admins={admins}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onChangePass={handleChangePass}
      />
    </>
  );
}
