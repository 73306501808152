import React from "react";
import { PageHeader, Card } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import queryString from "query-string";

export default function PageContentWrapper({
  children,
  backLink,
  title,
  extra,
  footer,
  subTitle,
}) {
  const history = useHistory();
  const location = useLocation();
  const { back_link } = queryString.parse(location.search);
  return (
    <>
      <PageHeader
        ghost={false}
        title={title}
        backIcon={backLink || back_link ? <ArrowLeftOutlined /> : false}
        onBack={() => history.push(back_link ? back_link : backLink)}
        extra={extra}
        footer={footer}
        subTitle={subTitle}
      />
      <Card bordered={false} style={{ borderRadius: 0 }}>
        {children}
      </Card>
    </>
  );
}
