import React, { useEffect } from "react";
import { Button, Menu, notification } from "antd";
import { Link, useHistory } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { getParticipant } from "../../appRedux/actions";

const menus = [
  {
    name: "Dasbor",
    roles: ["admin", "trainer", "participant"],
    path: "/dashboard",
    key: "dashboard",
    icon: "dasbhoard",
  },
  {
    name: "Slot Iklan",
    roles: ["admin"],
    path: "/ads",
    key: "ads",
    icon: "files",
  },
  {
    name: "Daftar Training",
    roles: ["participant"],
    path: "/my-training/training-registration",
    key: "training-registration",
    icon: "edit",
  },
  {
    name: "Training Saya",
    roles: ["participant"],
    path: "/my-training",
    key: "my-training",
    icon: "files",
  },
  // {
  //   name: "Kupon",
  //   roles: ["participant"],
  //   path: "/discount",
  //   key: "discount",
  //   icon: "ticket-new",
  // },
  {
    name: "Jadwal Training",
    roles: ["participant"],
    path: "https://jadwal.idn.id/",
    key: "lihatjadwal",
    icon: "table",
  },
  {
    name: "Program Affiliasi",
    roles: ["participant"],
    path: "/affiliate",
    key: "affiliate",
    icon: "table-data",
  },
  {
    name: "Hubungi Kami",
    roles: ["participant"],
    path: "https://www.idn.id/contact/",
    key: "call",
    icon: "phone",
  },
  {
    name: "Training",
    roles: ["admin", "trainer"],
    path: "/training",
    key: "training",
    icon: "table",
  },
  {
    name: "Kalender Training",
    roles: ["admin", "trainer"],
    path: "/calendar",
    key: "calendar",
    icon: "calendar",
  },
  {
    name: "Registrasi Training",
    roles: ["admin"],
    path: "/training-registration",
    key: "training-registration",
    icon: "files",
  },
  {
    name: "Diskon",
    roles: ["admin"],
    path: "/discount",
    key: "discount",
    icon: "ticket-new",
  },
  {
    name: "Pilihan Training",
    roles: ["admin"],
    path: "/training-option",
    key: "training-option",
    icon: "select",
  },
  {
    name: "Kategori Training",
    roles: ["admin"],
    path: "/training-category",
    key: "training-category",
    icon: "crm",
  },
  {
    name: "Peserta",
    roles: ["admin"],
    path: "/participant",
    key: "participant",
    icon: "user",
  },
  {
    name: "Trainer",
    roles: ["admin"],
    path: "/trainer",
    key: "trainer",
    icon: "user",
  },
  {
    name: "Admin",
    roles: ["admin"],
    path: "/admin",
    key: "admin",
    icon: "user",
  },
  {
    name: "Mutasi Rekening",
    roles: ["admin"],
    path: "/mutation",
    key: "mutation",
    icon: "transfer",
  },
  {
    name: "Multi Rekening",
    roles: ["admin"],
    path: "/accounts",
    key: "accounts",
    icon: "pricing-table",
  },
  {
    name: "Validasi Sertifikat",
    roles: ["admin"],
    path: "/cert-verification",
    key: "cert-verification",
    icon: "check-square-o",
  },
  {
    name: "Log Admin",
    roles: ["admin"],
    path: "/logs",
    key: "logs",
    icon: "listing-dbrd",
  },
  {
    name: "Edit info Jadwal",
    roles: ["admin"],
    path: "/jadwal",
    key: "jadwal",
    icon: "schedule",
  },
  {
    name: "Counter",
    roles: ["admin"],
    path: "/counter",
    key: "counter",
    icon: "data-display",
  },
];

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const { participant, participantLoading } = useSelector(
    ({ participant }) => participant
  );

  useEffect(() => {
    if (authUser.role === "participant") {
      dispatch(getParticipant(authUser.participant.id));
    }
  }, [dispatch]);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  const history = useHistory();

  const openNotification = () => {
    const key = `open${Date.now()}`;

    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key);
          history.push("/edit-profile");
        }}
      >
        Klik Disini
      </Button>
    );
    notification.warning({
      message: "Harap Lengkapi Profile",
      description: "Anda harus melengkapi profile Anda sebelum mendaftar",
      className: "custom-class",
      style: {
        width: 500,
      },
      btn,
      key,
    });
  };

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <div>
            <br></br>
          </div>
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            {menus.map(
              (menu) =>
                menu.roles.includes(authUser.role) && (
                  <Menu.Item key={menu.key}>
                    {menu.path === "https://www.idn.id/contact/" ||
                    menu.path === "https://jadwal.idn.id/" ? (
                      <a href={menu.path} target="_blank">
                        <i className={"icon icon-" + menu.icon} />
                        <span>{menu.name}</span>
                      </a>
                    ) : menu.name === "Daftar Training" ? (
                      participant && !participantLoading ? (
                        participant.whatsapp_number == null ||
                        participant.company == null ||
                        participant.job_position == null ||
                        participant.address == null ||
                        participant.shirt_size == null ? (
                          <Link onClick={openNotification}>
                            <i className={"icon icon-" + menu.icon} />
                            <span>{menu.name}</span>
                          </Link>
                        ) : (
                          <Link to={menu.path}>
                            <i className={"icon icon-" + menu.icon} />
                            <span>{menu.name}</span>
                          </Link>
                        )
                      ) : (
                        <Link>
                          <i className={"icon icon-" + menu.icon} />
                          <span>{menu.name}</span>
                        </Link>
                      )
                    ) : (
                      <Link to={menu.path}>
                        <i className={"icon icon-" + menu.icon} />
                        <span>{menu.name}</span>
                      </Link>
                    )}
                  </Menu.Item>
                )
            )}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
