import {
  SET_DISCOUNT_PARTICIPANTS,
  SET_GET_DISCOUNT_PARTICIPANTS_LOADING,
  DELETE_DISCOUNT_PARTICIPANT,
  UPDATE_DISCOUNT_PARTICIPANT,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  discountParticipants: [],
  discountParticipantsLoading: false,
};

const DiscountParticipantReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DISCOUNT_PARTICIPANTS: {
      return {
        ...state,
        discountParticipants: action.payload,
        discountParticipantsLoading: false,
      };
    }
    case SET_GET_DISCOUNT_PARTICIPANTS_LOADING: {
      return {
        ...state,
        discountParticipantsLoading: action.payload,
      };
    }
    case DELETE_DISCOUNT_PARTICIPANT: {
      return {
        ...state,
        discountParticipants: state.discountParticipants.filter(
          (p) => p.id !== action.payload
        ),
      };
    }
    case UPDATE_DISCOUNT_PARTICIPANT: {
      return {
        ...state,
        discountParticipants: state.discountParticipants.map((t) =>
          t.id === action.payload.id ? action.payload : t
        ),
        discountParticipant:
          state.discountParticipant &&
          (state.discountParticipant.id === action.payload.id
            ? action.payload
            : state.discountParticipant),
      };
    }
    default:
      return state;
  }
};

export default DiscountParticipantReducer;
