import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import { useHistory } from "react-router-dom";

const UserInfo = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() =>
          history.push(
            `/edit-profile`
          )
        }
      >
        Edit Profile
      </li>
      <li onClick={() => history.push("/edit-password")}>Ubah Password</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
      <Avatar src={"https://via.placeholder.com/150"} className="gx-avatar gx-pointer" alt=""/>
    </Popover>
  );
};

export default UserInfo;
