// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import { getTrainer } from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import TrainerFormContainer from "../../containers/adminRole/trainer/TrainerFormContainer";

// Component
export default function EditTrainerPage() {
  const dispatch = useDispatch();
  const { trainerId } = useParams();
  const { trainer, trainerLoading } = useSelector(
      ({ trainer }) => trainer
  )

  useEffect(() => {
      dispatch(getTrainer(trainerId))
  }, [dispatch])

  return trainer && !trainerLoading ? (
    <PageContentWrapper
      title="Edit"
      backLink={"/trainer/"}
    >
        <TrainerFormContainer trainer={trainer}/>
    </PageContentWrapper>
  ) : trainerLoading ? (
      <CircularProgress/>
  ) : (
      "Tidak ditemukan"
  )
}
