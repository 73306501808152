import React from "react";
import { Space, Button, Dropdown, Menu } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

export default function TrainingParticipantActions({
  onEdit,
  onSetPaidDp,
  onSetPaidOff,
  onCancel,
  onDelete,
  showSetPaidDp,
  showSetPaidOff,
  showCancel,
}) {
  return (
    <Space>
      <Button type="primary" style={{ margin: 0 }} onClick={onEdit}>
        Edit
      </Button>
      <Dropdown
        overlay={
          <Menu>
            {showSetPaidDp && (
              <Menu.Item key="1" icon={<CheckOutlined />} onClick={onSetPaidDp}>
                Tandai lunas dp
              </Menu.Item>
            )}
            {showSetPaidOff && (
              <Menu.Item
                key="2"
                icon={<CheckOutlined />}
                onClick={onSetPaidOff}
              >
                Tandai lunas total
              </Menu.Item>
            )}
            {showCancel && (
              <Menu.Item
                onClick={onCancel}
                key="3"
                icon={<CloseOutlined />}
                danger
              >
                Batalkan
              </Menu.Item>
            )}
            <Menu.Item
              key="0"
              icon={<DeleteOutlined />}
              danger
              onClick={onDelete}
            >
              Hapus
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
        trigger={["click"]}
      >
        <i className="gx-icon-btn icon icon-ellipse-v" />
      </Dropdown>
    </Space>
  );
}
