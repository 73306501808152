// Import package
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Tabs } from "antd";

// Import file
import {
  getParticipant,
  getAffiliateParticipantsHistory,
  getAffiliatePointsHistory,
} from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import PageContentWrapper from "../../components/PageContentWrapper";
import ParticipantDetail from "../../components/adminRole/participant/ParticipantDetail";
import ParticipantAffiliate from "../../components/adminRole/participant/ParticipantAffiliate";
import ParticipantTrainingListContainer from "../../containers/adminRole/participant/ParticipantTrainingListContainer";

// Component
export default function DetailParticipantPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { participant, participantLoading } = useSelector(
    ({ participant }) => participant
  );
  const { affiliateParticipantsHistory } = useSelector(
    ({ affiliateParticipantsHistory }) => affiliateParticipantsHistory
  );
  const { affiliatePointsHistory } = useSelector(
    ({ affiliatePointsHistory }) => affiliatePointsHistory
  );
  const { participantId } = useParams();
  const fetchMyAPI = useCallback(async () => {
    let result = await dispatch(getParticipant(participantId));
    dispatch(
      getAffiliateParticipantsHistory({
        affiliate_code: result.affiliate_code,
      })
    );
    dispatch(
      getAffiliatePointsHistory({
        participant_id: participantId,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  useEffect(() => {
    dispatch(getParticipant(participantId));
  }, [dispatch]);

  const handleTabChange = (v) =>
    history.push("/participant/" + participant.id + "/" + v);
  const subpath = location.pathname.split("/")[3] || "profile";

  return participant && !participantLoading ? (
    <PageContentWrapper
      title="Detail"
      backLink="/participant/"
      footer={
        <Tabs activeKey={subpath} onChange={handleTabChange}>
          <Tabs.TabPane key="profile" tab="Profile" />
          <Tabs.TabPane key="training" tab="Training" />
          <Tabs.TabPane key="affiliate" tab="Affiliate" />
        </Tabs>
      }
    >
      {subpath === "profile" && <ParticipantDetail participant={participant} />}

      {subpath === "training" && (
        <ParticipantTrainingListContainer
          participantTrainingId={participant.id}
        />
      )}

      {subpath === "affiliate" && (
        <ParticipantAffiliate
          totalPoint={participant.affiliate_point}
          listParticipantUseCode={affiliateParticipantsHistory}
          totalTrainingUsedPoint={affiliatePointsHistory.length}
        />
      )}
    </PageContentWrapper>
  ) : participantLoading ? (
    <CircularProgress />
  ) : (
    "Masi Gagal bang"
  );
}
