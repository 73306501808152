// Import package
import React from "react";
import { Form, Input, Button } from "antd";
import "react-phone-number-input/style.css";

// Component
export default function TraningCategoryForm({ onSubmit, trainingCategory }) {
  return (
    <Form
      layout="vertical"
      initialValues={
        trainingCategory
          ? {
              ...trainingCategory,
            }
          : {}
      }
      style={{ padding: 10 }}
      onFinish={(values) => {
        onSubmit({ ...values });
      }}
    >
      <Form.Item
        name="name"
        label="Nama"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nama kategori",
          },
        ]}
      >
        <Input placeholder="Android, Mikrotik" />
      </Form.Item>
      <Form.Item>
        <Button size="large" type="primary" htmlType="submit">
          Simpan
        </Button>
      </Form.Item>
    </Form>
  );
}
