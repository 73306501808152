// Import package
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// Import file
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Training from "./Training";
import TrainingOption from "./TrainingOption";
import TrainerReducer from "./Trainer";
import TrainingParticipantReducer from "./TrainingParticipant";
import ParticipantReducer from "./Participant";
import TrainingCategoryReducer from "./TrainingCategory";
import TrainingRegistrationReducer from "./TrainingRegistration";
import BankTransactionReducer from "./BankTransaction";
import BankAccountReducer from "./BankAccount";
import UserReducer from "./Users";
import AdminReducer from "./Admin";
import DiscountReducer from "./Discount";
import DiscountParticipantReducer from "./DiscountParticipant";
import DiscountTrainingReducer from "./DiscountTraining";
import AffiliateParticipantsHistoryReducer from "./AffiliateParticipantHistory";
import AffiliatePointsHistoryReducer from "./AffiliatePointHistory";
import AdSlotReducer from "./AdSlot";
import DashboardReducer from "./Dashboard";
import DiscountTrainingOptionReducer from "./DiscountTrainingOption";
import LogsReducer from "./Logs";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    training: Training,
    trainingOption: TrainingOption,
    trainer: TrainerReducer,
    trainingParticipant: TrainingParticipantReducer,
    participant: ParticipantReducer,
    trainingCategory: TrainingCategoryReducer,
    trainingRegistration: TrainingRegistrationReducer,
    bankTransaction: BankTransactionReducer,
    bankAccount: BankAccountReducer,
    users: UserReducer,
    admin: AdminReducer,
    discount: DiscountReducer,
    discountParticipant: DiscountParticipantReducer,
    discountTraining: DiscountTrainingReducer,
    discountTrainingOption: DiscountTrainingOptionReducer,
    affiliateParticipantsHistory: AffiliateParticipantsHistoryReducer,
    affiliatePointsHistory: AffiliatePointsHistoryReducer,
    adSlot: AdSlotReducer,
    dashboard: DashboardReducer,
    logs: LogsReducer,
  });

export default createRootReducer;
