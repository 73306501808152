// Import package
import React, { useEffect, useState } from "react";
import { PageHeader, Card, Col, Space, Select, Button } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { CalendarOutlined, PlusOutlined, FileExcelOutlined, FileAddOutlined } from "@ant-design/icons";
import * as XLSX from 'xlsx/xlsx.mjs';

// Import file
import TrainingsList from "../../components/adminRole/training/TrainingsList";
import {
  getTrainings,
  setTrainingsOnline,
  setTrainingsLocation,
  setTrainingsDates,
  setTrainingsSearch,
  setTrainingsTrainingOption,
  setTrainingsTrainer,
  setTrainingsPagination,
  resetTrainingsFilter,
} from "../../appRedux/actions";
import TrainingFilter from "../../components/adminRole/training/TrainingFilter";
import TrainingLocationTabs from "../../components/adminRole/training/TrainingLocationTabs";
import TrainingTypeTabs from "../../components/adminRole/training/TrainingTypeTabs";
import TrainingListContainer from "../../containers/trainerRole/TrainingListContainer";

const AdminRolePage = () => {
  const { trainings, getTrainingsLoading, getTrainingsFilter, totalTrainings } =
    useSelector(({ training }) => training);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTrainings(getTrainingsFilter));
  }, [getTrainingsFilter]);

  const handleChangeTrainingType = (isOnline) =>
    dispatch(setTrainingsOnline(isOnline));

  const handleChangeTrainingLocation = (newLocation) =>
    dispatch(setTrainingsLocation(newLocation));

  const handleChangeDates = (newDates) => dispatch(setTrainingsDates(newDates));

  const handleChangeSearch = (newSearch) =>
    dispatch(setTrainingsSearch(newSearch));

  const handleChangeTrainingOption = (newTrainingOption) =>
    dispatch(setTrainingsTrainingOption(newTrainingOption));

  const handleChangeTrainer = (newTrainer) =>
    dispatch(setTrainingsTrainer(newTrainer));
  const handleChangeTrainingsPagination = (currentPage, pageSize) =>
    dispatch(setTrainingsPagination({ currentPage, pageSize }));
  const handleReset = () => dispatch(resetTrainingsFilter());

  const handleChangeLocation = (location) =>
    dispatch(setTrainingsLocation(location));
  
  const handleEkspor = () => {
    let data = [[
      "Pilihan training",
      "Online",
      "Trainer",
      "Lokasi",
      "Ruang",
      "Harga",
      "Jumlah Maksimal Peserta",
      "Waktu mulai",
      "Waktu selesai",
      "Tampilkan di halaman jadwal untuk publik (ya/tidak)",
      "Mengirim notifikasi jika ada pendaftar pertama",
      "Mengirim notifikasi jika kelas penuh",
      "Hari sebelum training ditutup",
      "Catatan",
      "Tanggal pelaksanaan 1",
      "Tanggal pelaksanaan 2",
      "Tanggal pelaksanaan 3",
      "Tanggal pelaksanaan 4",
      "Tanggal pelaksanaan 5",
      "Tanggal pelaksanaan 6",
      "Tanggal pelaksanaan 7",
      "Tanggal pelaksanaan 8",
      "Tanggal pelaksanaan 9",
      "Tanggal pelaksanaan 10",
    ]]
    trainings.map(training => {
      let row = [
        training.name,
        training.is_online ? "Ya" : "Tidak",
        training.trainer.name,
        training.location,
        training.room,
        training.price,
        training.max_participants,
        training.time_start,
        training.time_end,
        training.publicly_visible ? "Ya" : "Tidak",
        training.notification_first ? "Ya" : "Tidak",
        training.notification_full ? "Ya" : "Tidak",
        training.days_before_closing,
        training.note,
      ]
      training.dates.map(value => {
        const d = new Date(value.date) 
        row.push(d.toISOString().split('T')[0])
      })
      data.push(row)
    })
    const ws_name = "data"
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(data)
    XLSX.utils.book_append_sheet(wb, ws, ws_name)
    XLSX.writeFile(wb, "training.xlsx")
    return false
  }

  return (
    <>
      <PageHeader
        ghost={false}
        title="Jadwal Training"
        // footer={
        //   <TrainingTypeTabs
        //     onChange={handleChangeTrainingType}
        //     isOnline={getTrainingsFilter.is_online}
        //   />
        // }
        extra={
          <>
            <Link to="/training/add">
              <Button
                style={{ margin: 0 }}
                type="primary"
                icon={<PlusOutlined />}
              >
                Tambah
              </Button>
            </Link>
            <Link to="/training/import">
              <Button
                style={{ margin: 0 }}
                type="primary"
                icon={<FileAddOutlined />}
              >
                Impor
              </Button>
            </Link>
            <Link onClick={handleEkspor}>
            <Button
              style={{ margin: 0 }}
              type="primary"
              icon={<FileExcelOutlined />}
            >
              Ekspor
            </Button>
            </Link>
          </>
        }
      />
      <Card bordered={false} style={{ borderRadius: 0 }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <TrainingFilter
            onChangeDates={handleChangeDates}
            dates={[getTrainingsFilter.start_date, getTrainingsFilter.end_date]}
            onChangeSearch={handleChangeSearch}
            search={getTrainingsFilter.search}
            onChangeTrainingOption={handleChangeTrainingOption}
            trainingOption={
              getTrainingsFilter.training_option_id
                ? getTrainingsFilter.training_option_id
                : "all"
            }
            trainer={
              getTrainingsFilter.trainer_id
                ? getTrainingsFilter.trainer_id
                : "all"
            }
            onChangeTrainer={handleChangeTrainer}
            onReset={handleReset}
            onChangeType={handleChangeTrainingType}
            onChangeLocation={handleChangeLocation}
          />
          {/* {!getTrainingsFilter.is_online && (
            <TrainingLocationTabs
              location={getTrainingsFilter.location}
              onChange={handleChangeTrainingLocation}
            />
          )} */}
          <TrainingsList
            trainings={trainings}
            loading={getTrainingsLoading}
            totalTrainings={totalTrainings}
            onChangePagination={handleChangeTrainingsPagination}
            pageSize={getTrainingsFilter.page_size}
            currentPage={getTrainingsFilter.current_page}
          />
        </Space>
      </Card>
    </>
  );
};

const TrainerRolePage = () => {
  const dispatch = useDispatch();
  const [month, setMonth] = useState(moment().startOf("month").toISOString());
  const handleChangeMonth = (v) => {
    setMonth(v);
    dispatch(
      getTrainings({
        start_date: v,
        end_date: moment(v).endOf("month").toISOString(),
      })
    );
  };
  return (
    <>
      <PageHeader
        ghost={false}
        title="Jadwal Training"
        extra={
          <Col>
            <Space wrap>
              <Select
                value={month}
                dropdownMatchSelectWidth={true}
                onChange={handleChangeMonth}
                style={{ borderRadius: 0 }}
                suffixIcon={<CalendarOutlined />}
              >
                {[0, 1, 2, 3, 4].map((n) => (
                  <Select.Option
                    key={n}
                    value={moment()
                      .add(n, "months")
                      .startOf("month")
                      .toISOString()}
                  >
                    {moment().add(n, "months").format("MMMM YYYY")}
                  </Select.Option>
                ))}
              </Select>
            </Space>
          </Col>
        }
      />
      <Card bordered={false} style={{ borderRadius: 0 }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <TrainingListContainer />
        </Space>
      </Card>
    </>
  );
};

// Component
export default function TrainingPage() {
  const { authUser } = useSelector(({ auth }) => auth);

  return authUser.role === "admin" ? <AdminRolePage /> : <TrainerRolePage />;
}
