import React, { useEffect } from "react";
import DashboardBarChart from "../../../components/adminRole/dashboard/DashboardBarChart";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardTrainingParticipantRetrainingGraph } from "../../../appRedux/actions";
import FilterBarChartParticipant from "../../../components/adminRole/dashboard/FilterBarChartParticipant";

export default function BarChartRetrainingParticipantsThisMonth({ locations }) {
  const dispatch = useDispatch();
  const {
    trainingParticipantRetrainingGraph,
    trainingParticipantRetrainingGraphFilter,
  } = useSelector(({ dashboard }) => dashboard);

  useEffect(() => {
    dispatch(
      getDashboardTrainingParticipantRetrainingGraph(
        trainingParticipantRetrainingGraphFilter,
      )
    );
  }, []);

  const handleChangeType = (filter, dates) => {
    dispatch(getDashboardTrainingParticipantRetrainingGraph(filter, dates));
  };

  const getParticipantRetrainingData = () =>
    trainingParticipantRetrainingGraph.map(function (tpg) {
      return {
        name: tpg.training_option_name,
        peserta_mengulang: tpg.total_participants,
      };
    });

  return (
    <>
      <FilterBarChartParticipant
        value={trainingParticipantRetrainingGraphFilter}
        onChange={handleChangeType}
        locations={locations}
      />
      <DashboardBarChart
        barKey="peserta_mengulang"
        fillColor="#6db7ff"
        data={getParticipantRetrainingData()}
        barName="Peserta Mengulang"
      />
    </>
  );
}
