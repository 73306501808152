// Import package
import { v4 } from "uuid";

// Import file
import {
  SET_DISCOUNT_TRAININGS,
  SET_DISCOUNT_TRAININGS_LOADING,
  DELETE_DISCOUNT_TRAINING,
} from "../../constants/ActionTypes";
import { baseRequest } from "../../util/Api";
import {
  setLoadingMessage,
  setSuccessMessage,
  setErrorMessage,
} from "./Common";

// Method-method
export const createDiscountTraining = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    dispatch(setLoadingMessage("Menambah diskon training ...", key));
    baseRequest("post", "/discount-trainings/", null, data)
      .then((newData) => {
        dispatch(setSuccessMessage("Menambah diskon training berhasil", key));
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
        reject(e);
      });
  });

export const getDiscountTrainings = (filter) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SET_DISCOUNT_TRAININGS_LOADING,
      payload: true,
    });
    baseRequest("get", "/discount-trainings/", filter)
      .then((data) => {
        dispatch({
          type: SET_DISCOUNT_TRAININGS,
          payload: data,
        });
        resolve(data);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e));
        dispatch({
          type: SET_DISCOUNT_TRAININGS_LOADING,
          payload: false,
        });
      });
  });

export const deleteDiscountTraining = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    baseRequest("delete", "/discount-trainings/" + id)
      .then(() => {
        dispatch({
          type: DELETE_DISCOUNT_TRAINING,
          payload: id,
        });
        resolve();
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, key));
        reject(e);
      });
  });
