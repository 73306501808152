import React from "react";
import { Space, Input, Select, Row, Col } from "antd";

export default function TrainingOptionsListFilter({
  onSearch,
  onSelectTrainingCategory,
  selectedTrainingCategory,
  searchValue = "",
  onReset,
  trainingCategories,
  trainingCategoriesLoading = false,
}) {
  return (
    <Row wrap gutter={[16, 16]} justify="space-between">
      <Col>
        <Space wrap>
          <Select
            value={selectedTrainingCategory}
            onChange={onSelectTrainingCategory}
            loading={trainingCategoriesLoading}
            dropdownMatchSelectWidth={false}
          >
            <Select.Option value="all">Semua kategori</Select.Option>
            {trainingCategories.map((t) => (
              <Select.Option value={t.id} key={t.id}>
                {t.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
      </Col>
      <Col>
        <Input.Search
          style={{ margin: 0 }}
          placeholder="ketik nama"
          enterButton
          onSearch={onSearch}
          defaultValue={searchValue}
          allowClear
        />
      </Col>
    </Row>
  );
}
