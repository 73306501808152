// Import package
import React, { useEffect, useState } from "react";
import moment from "moment";
import { getDashboardTrainingRegistDailyGraph } from "../../../appRedux/actions";
import FilterBarChartMonthlyRegister from "../../../components/adminRole/dashboard/FilterBarChartMonthlyRegister";
import DashboardBarChart from "../../../components/adminRole/dashboard/DashboardBarChart";
// Component
export default function BarChartDailyRegister({ trainingOptions, locations }) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({
    training_option_id: null,
    status: null,
    is_retraining: null,
    start_date: moment().startOf('year').toISOString(),
    end_date: moment().endOf('year').toISOString(),
    location: null,
    category_id: null,
  })

  useEffect(() => {
    getDashboardTrainingRegistDailyGraph(filter).then((rows) => {
      setData(rows.map((n) => {
        return {
          name: moment(n.date, 'YYYY-MM-DD').format("D MMMM"),
          pendaftar: n.total_training_registrations,
        }
      }))
    })
  }, [filter])

  const changeFilterTraining = (training_option_id) => {
    setFilter({ ...filter, training_option_id })
  }

  const changeFilterStatus = (status) => {
    setFilter({ ...filter, status })
  }

  function handleChangeFilter(filter) {
    setFilter(filter)
    getDashboardTrainingRegistDailyGraph(filter)
  }

  return (
    <>
      <FilterBarChartMonthlyRegister
        valueFilterTraining={filter.training_option_id}
        onChangeFilterTraining={changeFilterTraining}
        trainingOptions={trainingOptions}
        valueFilterStatus={filter.status}
        onChangeFilterStatus={changeFilterStatus}
        locations={locations}
        filter={filter}
        setFilter={handleChangeFilter}
      />
      <DashboardBarChart
        barKey="pendaftar"
        fillColor="#006699"
        data={data}
        barName="Pendaftar Harian"
      />
    </>
  );
}
