import { PageHeader, Card, Space, List, Modal, Button } from "antd"
import { Link } from "react-router-dom"

export default function JadwalPage() {
  const data = [
    {
      title: "Informasi",
      description: "Tampilan text di bagian atas di page jadwal.idn.id",
      path: "/jadwal/information",
    },
  ];

  return <>
    <PageHeader
      ghost={false}
      title="Jadwal"
    />

    <Card bordered={false} style={{ borderRadius: 0 }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <List
          bordered
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              actions={[<Link key="list-loadmore-edit" to={item.path}>edit</Link>]}
            >
              <List.Item.Meta
                avatar={<></>}
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </Space>
    </Card>
  </>
}
