// Import Package
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Import File
import { updateUser, updateAdmin } from "../../appRedux/actions";
import EditProfile from "../../components/adminRole/EditProfile";

export default function EditProfileAdminContainer({ userAdmin }) {
    const dispatch = useDispatch();
    const history = useHistory();
  const handleSubmit = (data) => {
    dispatch(updateAdmin({ ...data, id: userAdmin.id })).then((newData) => {
      dispatch(
        updateUser({
          id: newData.user_id,
          email: newData.email,
          role: "admin",
        })
      );
    }).then(() => document.location.reload(true));
  };
  return <EditProfile onSubmit={handleSubmit} userAdmin={userAdmin} />;
}