// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import { getTrainingCategory } from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import TrainingCategoriesFormContainer from "../../containers/adminRole/trainingCategories/TrainingCategoriesFormContainer";

// Component
export default function EditTrainingCategoryPage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { trainingCategory, trainingCategoryLoading } = useSelector(
    ({ trainingCategory }) => trainingCategory
  );

  useEffect(() => {
    dispatch(getTrainingCategory(id));
  }, [dispatch]);

  return trainingCategory && !trainingCategoryLoading ? (
    <PageContentWrapper
      title={"Edit " + trainingCategory.name}
      backLink={"/training-category"}
    >
      <TrainingCategoriesFormContainer trainingCategory={trainingCategory} />
    </PageContentWrapper>
  ) : trainingCategoryLoading ? (
    <CircularProgress />
  ) : (
    "Tidak di temukan"
  );
}
