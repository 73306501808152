// Import package
import React from "react";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import TrainingCategoriesFormContainer from "../../containers/adminRole/trainingCategories/TrainingCategoriesFormContainer";

// Component
export default function NewTrainingCategoryPage() {
  return (
    <PageContentWrapper title="Tambah Kategori" backLink={"/training-category"}>
      <TrainingCategoriesFormContainer />
    </PageContentWrapper>
  );
}
