// Import package
import React from "react";
import { Dropdown, Menu, Table } from "antd";
import { KeyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import PhoneNumber from "awesome-phonenumber";

// Column Table
const getColumns = ({ onEdit, onDelete, onChangePass }) => [
  {
    title: "#",
    dataIndex: "id",
    align: "center",
    width: 1,
  },
  {
    title: "Nama",
    render: (field) => (
      <Link to={"/participant/" + field.id}>{field.name}</Link>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    responsive: ["sm"],
    render: (email) => (email ? email : "-"),
  },
  {
    title: "Nomor WhatsApp",
    dataIndex: "whatsapp_number",
    responsive: ["md"],
    render: (w) =>
      w ? (
        <a href={"https://wa.me/" + w} target="_blank">
          {new PhoneNumber(w).getNumber("national")}
        </a>
      ) : (
        "-"
      ),
  },
  {
    title: "Kode afiliasi",
    dataIndex: "affiliate_code",
    responsive: ["md"],
  },
  {
    align: "right",
    width: 1,
    render: (item) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key="ubah password"
              onClick={() => onChangePass(item.id)}
              icon={<KeyOutlined />}
            >
              Ubah password
            </Menu.Item>
            <Menu.Item
              key="edit"
              onClick={() => onEdit(item.id)}
              icon={<EditOutlined />}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              onClick={() => onDelete(item.id)}
              key="0"
              icon={<DeleteOutlined />}
              danger
            >
              Hapus
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
        trigger={["click"]}
      >
        <i className="gx-icon-btn icon icon-ellipse-v" />
      </Dropdown>
    ),
  },
];

// Component
export default function ParticipantsList({
  participants = [],
  loading = false,
  totalParticipants = 0,
  pageSize,
  currentPage,
  onChangePagination,
  onDelete,
  onEdit,
  onChangePass,
}) {
  return (
    <Table
      dataSource={participants.map((p) => ({ ...p, key: p.id }))}
      size="small"
      loading={loading}
      columns={getColumns({ onEdit, onDelete, onChangePass })}
      pagination={{
        total: totalParticipants,
        pageSize: pageSize,
        current: currentPage,
        onChange: (page, pageSize) => onChangePagination(page, pageSize),
        showSizeChanger: true,
        responsive: true,
        pageSizeOptions: [7, 10, 20, 50, 100],
        position: ["bottomCenter"],
      }}
    />
  );
}
