// Import
import {
  SET_TRAINING_CATEGORIES_LOADING,
  SET_TRAINING_CATEGORIES,
  DELETE_TRAINING_CATEGORY,
  UPDATE_TRAINING_CATEGORY,
  SET_TRAINING_CATEGORY_LOADING,
  SET_TRAINING_CATEGORY,
} from "../../constants/ActionTypes";
import { baseRequest } from "../../util/Api";
import {
  setErrorMessage,
  setLoadingMessage,
  setSuccessMessage,
} from "./Common";
import { v4 } from "uuid";

//  METHOD
export const getTrainingCategories = () => (dispatch) => {
  dispatch({
    type: SET_TRAINING_CATEGORIES_LOADING,
    payload: true,
  });
  baseRequest("get", "/training-categories/")
    .then((data) => {
      dispatch({
        type: SET_TRAINING_CATEGORIES,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_TRAINING_CATEGORIES_LOADING,
        payload: false,
      });
    });
};

export const getTrainingCategory = (id) => (dispatch) => {
  dispatch({
    type: SET_TRAINING_CATEGORY_LOADING,
    payload: true,
  });
  baseRequest("get", "/training-categories/" + id)
    .then((data) => {
      dispatch({
        type: SET_TRAINING_CATEGORY,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({ type: SET_TRAINING_CATEGORY_LOADING, payload: false });
    });
};

export const createTrainingCategory = (data) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menambag training category ...", messageKey));
    baseRequest("post", "/training-categories/", null, data)
      .then((newData) => {
        dispatch(
          setSuccessMessage("Berhasil menambah training category", messageKey)
        );
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const updateTrainingCategory = (trainingCategory) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Memperbarui data ...", messageKey));
    baseRequest(
      "put",
      "/training-categories/" + trainingCategory.id,
      null,
      trainingCategory
    )
      .then((newData) => {
        dispatch(setSuccessMessage("Berhasil memperbaru data", messageKey));
        dispatch({
          type: UPDATE_TRAINING_CATEGORY,
          payload: newData,
        });
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const deleteTrainingCategory = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menghapus kategori ...", messageKey));
    baseRequest("delete", "/training-categories/" + id)
      .then(() => {
        dispatch(
          setSuccessMessage("Berhasil menghapus training category", messageKey)
        );
        dispatch({
          type: DELETE_TRAINING_CATEGORY,
          payload: id,
        });
        resolve();
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
        reject();
      });
  });
