// Import package
import { v4 } from "uuid";

// Import file
import { baseRequest } from "../../util/Api";
import {
  setErrorMessage,
  setSuccessMessage,
  setLoadingMessage,
} from "./Common";

export const affiliateChecker = ({ code, training_id }) => (dispatch) =>
new Promise((resolve) => {
    const messageKey = v4()
    dispatch(setLoadingMessage("Menambah kode affiliate ...", messageKey))
    baseRequest("post", "/affiliate/affiliate-checker", null, { code, training_id })
        .then((newData) => {
            dispatch(setSuccessMessage("Berhasil mendapatkan diskon 5%" , messageKey))
            resolve(newData)
        })
        .catch((e) => {
            dispatch(setErrorMessage(e, messageKey))
        })
})