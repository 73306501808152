// Import package
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// Import file
import AddTrainingParticipantButton from "../../../../components/adminRole/trainingParticipant/AddTrainingParticipantButton";
import EditTrainingParticipantFormContainer from "../EditTrainingParticipantFormContainer";
import TrainingParticipantsListContainer from "../../training/TrainingParticipantsListContainer";
import NewTrainingParticipantFormContainer from "../NewTrainingParticipantFormContainer";

// import TrainingParticipantEmpty from "../../../components/trainingParticipant/TrainingParticipantEmpty";

export default function TrainingParticipantContainer({ trainingId }) {
  const dispatch = useDispatch();
  const [showAddForm, setShowAddForm] = useState(false);
  const { trainingParticipants, trainingParticipant } = useSelector(
    ({ trainingParticipant }) => trainingParticipant
  );
  return (
    <>
      {trainingParticipant === null &&
        (!showAddForm ? (
          <>
            <TrainingParticipantsListContainer trainingId={trainingId} />
            <AddTrainingParticipantButton
              onClick={() => setShowAddForm(true)}
            />
          </>
        ) : (
          <NewTrainingParticipantFormContainer
            onCancel={() => setShowAddForm(false)}
          />
        ))}
      {trainingParticipant && <EditTrainingParticipantFormContainer />}
    </>
  );
}
