// Import package
import React from "react";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import DiscountFormContainer from "../../containers/adminRole/Discount/DiscountFormContainer";

// Component
export default function NewDiscountPage() {
  return (
    <PageContentWrapper
      title="Tambah Diskon"
      backLink={"/discount"}
    >
        <DiscountFormContainer/>
    </PageContentWrapper>
  );
}
