// Import package
import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import TrainerListContainer from "../../containers/adminRole/trainer/TrainerListContainer";

// Component
export default function TrainerPage() {
  return (
    <PageContentWrapper
      title="Trainer"
      extra={
        <Link to="/trainer/add">
          <Button icon={<PlusOutlined />} type="primary" style={{ margin: 0 }}>
            Tambah
          </Button>
        </Link>
      }
    >
        <TrainerListContainer/>
    </PageContentWrapper>
  );
}
