// Import package
import React from "react";
import { Form, Input, Button } from "antd";
import "react-phone-number-input/style.css";

// Component
export default function AdminForm({ onSubmit, admin }) {
  return (
    <Form
      layout="vertical"
      initialValues={
        admin
          ? {
              ...admin,
            }
          : {}
      }
      style={{ padding: 10 }}
      onFinish={(values) => {
        onSubmit({ ...values });
      }}
    >
      <Form.Item
        name="name"
        label="Nama lengkap"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nama lengkap peserta!",
          },
        ]}
      >
        <Input placeholder="Dimas Hermawan" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Alamat email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Mohon masukkan alamat email dengan benar",
          },
        ]}
      >
        <Input placeholder="email@gmail.com" />
      </Form.Item>
      {!admin && (
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Masukkan password",
            },
          ]}
        >
          <Input placeholder="Masukkan password" />
        </Form.Item>
      )}
      <Form.Item>
        <center>
          <Button size="large" type="primary" htmlType="submit">
            Simpan
          </Button>
        </center>
      </Form.Item>
    </Form>
  );
}
