import {
  SET_AFFILIATE_PARTICIPANTS_HISTORY,
  SET_AFFILIATE_PARTICIPANTS_HISTORY_LOADING,
  SET_TOTAL_AFFILIATE_PARTICIPANTS_HISTORY,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  affiliateParticipantsHistory: [],
  affiliateParticipantsHistoryLoading: false,
  totalAffiliateParticipantsHistory: 0,
};

const AffiliateParticipantsHistoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AFFILIATE_PARTICIPANTS_HISTORY: {
      return {
        ...state,
        affiliateParticipantsHistory: action.payload,
        affiliateParticipantsHistoryLoading: false,
      };
    }
    case SET_AFFILIATE_PARTICIPANTS_HISTORY_LOADING: {
      return {
        ...state,
        affiliateParticipantsHistoryLoading: action.payload,
      };
    }
    case SET_TOTAL_AFFILIATE_PARTICIPANTS_HISTORY: {
      return {
        ...state,
        totalAffiliateParticipantsHistory: action.payload,
      };
    }
    default:
      return state;
  }
};

export default AffiliateParticipantsHistoryReducer;
