// Import package
import React from "react";
import { List, Dropdown, Menu } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

// Menu
const menu = ({ id, onEdit, onDelete }) => (
  <Menu>
    <Menu.Item key="edit" onClick={() => onEdit(id)} icon={<EditOutlined />}>
      Edit
    </Menu.Item>
    <Menu.Item
      key="0"
      onClick={() => onDelete(id)}
      icon={<DeleteOutlined />}
      danger
    >
      Hapus
    </Menu.Item>
  </Menu>
);

// Render Item
const renderItem = ({ item, onEdit, onDelete }) => {
  const { name, id } = item;
  return (
    <List.Item
      actions={[
        <Dropdown
          overlay={menu({ id, onEdit, onDelete })}
          placement="bottomRight"
          trigger={["click"]}
        >
          <i className="gx-icon-btn icon icon-ellipse-v" />
        </Dropdown>,
      ]}
    >
      <Link to={"/training-category"}>{name}</Link>
    </List.Item>
  );
};

// Component
export default function TrainingCategoryList({
  trainingCategories,
  loading = false,
  onDelete,
  onEdit,
}) {
  return (
    <List
      itemLayout="horizontal"
      size="small"
      dataSource={trainingCategories}
      renderItem={(item) => renderItem({ item, onEdit, onDelete })}
      loading={loading}
    />
  );
}
