// Import package
import { v4 } from "uuid";

// Import file
import {
  SET_ADMIN,
  SET_ADMINS,
  SET_ADMINS_LOADING,
  SET_ADMIN_LOADING,
  DELETE_ADMIN,
  UPDATE_ADMIN,
} from "../../constants/ActionTypes";
import { baseRequest } from "../../util/Api";
import {
  setErrorMessage,
  setSuccessMessage,
  setLoadingMessage,
} from "./Common";

// Method-method
export const getAdmins = () => (dispatch) => {
  dispatch({
    type: SET_ADMINS_LOADING,
    payload: true,
  });
  baseRequest("get", "/admins/")
    .then((data) => {
      dispatch({
        type: SET_ADMINS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_ADMINS_LOADING,
        payload: false,
      });
    });
};

export const getAdmin = (id) => (dispatch) => {
  dispatch({
    type: SET_ADMIN_LOADING,
    payload: true,
  });
  baseRequest("get", "/admins/" + id)
    .then((data) => {
      dispatch({
        type: SET_ADMIN,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({ type: SET_ADMIN_LOADING, payload: false });
    });
};

export const createAdmin = (data) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menambah admin ...", messageKey));
    baseRequest("post", "/admins/", null, data)
      .then((newData) => {
        dispatch(setSuccessMessage("Menambah admin berhasil", messageKey));
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const updateAdmin = (admin) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Memperbarui admin ...", messageKey));
    baseRequest("put", "/admins/" + admin.id, null, admin)
      .then((newData) => {
        dispatch(setSuccessMessage("Memperbarui admin berhasil", messageKey));
        dispatch({
          type: UPDATE_ADMIN,
          payload: newData,
        });
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const deleteAdmin = (adminId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menghapus admin ...", messageKey));
    baseRequest("delete", "/admins/" + adminId)
      .then(() => {
        dispatch(setSuccessMessage("Menghapus admin berhasil", messageKey));
        dispatch({
          type: DELETE_ADMIN,
          payload: adminId,
        });
        resolve();
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
        reject();
      });
  });
