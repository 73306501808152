import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Button,
  Upload,
  Table,
  Tag,
} from "antd";
import { toRupiah } from "../../../../libs";
import _ from "lodash";
import moment from "moment-timezone";
import {
  CheckCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import * as XLSX from 'xlsx/xlsx.mjs';
import { useDispatch } from "react-redux";
import {
  setErrorMessage,
  setLoadingMessage,
  setSuccessMessage
} from "../../../../appRedux/actions/Common";
import { baseRequest } from "../../../../util/Api";

export default function NewTrainingFormImport({
  trainers = [],
  trainingOptions = [],
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [dataTrue, setDataTrue] = useState(true);
  const [jsonPreview, setJsonPreview] = useState(null);

  useEffect(() => {
    if (!dataTrue) {
      dispatch(setErrorMessage("Data file excel ada yang salah!"));
    }
  }, [dispatch, dataTrue]);

  let columns = [];
  const messageKey = "NewTrainingFormImport";

  const columnNames = [
    "Pilihan training",
    "Online",
    "Trainer",
    "Lokasi",
    "Ruang",
    "Harga",
    "Jumlah Maksimal Peserta",
    "Waktu mulai",
    "Waktu selesai",
    "Tampilkan di halaman jadwal untuk publik (ya/tidak)",
    "Mengirim notifikasi jika ada pendaftar pertama",
    "Mengirim notifikasi jika kelas penuh",
    "Hari sebelum training ditutup",
    "Catatan",
    "Tanggal pelaksanaan 1",
    "Tanggal pelaksanaan 2",
    "Tanggal pelaksanaan 3",
    "Tanggal pelaksanaan 4",
    "Tanggal pelaksanaan 5",
    "Tanggal pelaksanaan 6",
    "Tanggal pelaksanaan 7",
    "Tanggal pelaksanaan 8",
    "Tanggal pelaksanaan 9",
    "Tanggal pelaksanaan 10",
  ];

  const getColumns = () => {
    let cols = [];
    columnNames.map((value) => {
      const key = value.toLowerCase().replace(/[^0-9a-z]/gi, '');
      cols.push({
        title: value,
        dataIndex: key,
        key: key,
        render: (_, v) => tableRenderData(v, key),
      });
    });
    return cols;
  }

  columns = getColumns();

  const tableRenderData = (v, key) => {
    const data = v[key];
    if (data.value == null) {
      setDataTrue(false);
      return <>
        <Tag color='volcano' key={data.value}>
          {data.label}
        </Tag>
      </>
    }
    return <>{data.label}</>
  }

  const normFile = (e) => {
    setDataTrue(true);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const beforeUpload = async (file, fileList) => {
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    reviewData(jsonData);
    return false;
  }

  const reviewData = (jsonData) => {
    let preview = [];
    jsonData.slice(1).forEach((x) => {
      let column = {};
      // Pilihan training
      column[columns[0].dataIndex] = {
        label: x[0],
        value: trainingValidation(trainingOptions, x[0]),
      }
      // Tipe Online
      column[columns[1].dataIndex] = {
        label: x[1],
        value: trainingTypeValidation(x[1]),
      }
      // Trainer
      column[columns[2].dataIndex] = {
        label: x[2],
        value: trainingValidation(trainers, x[2]),
      }
      // Lokasi
      column[columns[3].dataIndex] = {
        label: x[3] ? x[3] : "",
        value: trainingLocationValidation(!column[columns[1].dataIndex].value, x[3]),
      }
      // Ruang
      column[columns[4].dataIndex] = {
        label: x[4] ? x[4] : "",
        value: x[4] ? x[4] : "",
      }
      // Harga
      column[columns[5].dataIndex] = {
        label: x[5] ? toRupiah(x[5]) : "",
        value: trainingNumberValidation(x[5])
      }
      // Jumlah Maksimal Peserta
      column[columns[6].dataIndex] = {
        label: x[6],
        value: trainingNumberValidation(x[6])
      }
      // Waktu mulai
      column[columns[7].dataIndex] = {
        label: x[7],
        value: moment(x[7], "HH:mm", true).isValid() ? x[7] : null
      }
      // Waktu selesai
      column[columns[8].dataIndex] = {
        label: x[8],
        value: moment(x[8], "HH:mm", true).isValid() ? x[8] : null
      }
      // Tampilkan di halaman jadwal untuk publik (ya/tidak)
      column[columns[9].dataIndex] = {
        label: x[9],
        value: trainingTypeValidation(x[9]),
      }
      // "Mengirim notifikasi jika ada pendaftar pertama",
      column[columns[10].dataIndex] = {
        label: x[10],
        value: trainingTypeValidation(x[10]),
      }
      // "Mengirim notifikasi jika kelas penuh",
      column[columns[11].dataIndex] = {
        label: x[11],
        value: trainingTypeValidation(x[11]),
      }
      // "Hari sebelum training ditutup",
      column[columns[12].dataIndex] = {
        label: x[12],
        value: trainingNumberValidation(x[12])
      }
      // Catatan
      column[columns[13].dataIndex] = {
        label: x[13] ? x[13] : "",
        value: x[13] ? x[13] : "",
      }
      // Tanggal pelaksanaan 1
      column[columns[14].dataIndex] = {
        label: x[14] ? x[14] : "",
        value: x[14] && moment(x[14], "YYYY-MM-DD", true).isValid() ? x[14] : null
      }
      // Tanggal pelaksanaan 2-10
      for (let i = 15; i <= 23; i++) {
        column[columns[i].dataIndex] = {
          label: x[i] ? x[i] : "-",
          value: x[i] && moment(x[i], "YYYY-MM-DD", true).isValid() ? x[i] : ""
        }
      }
      preview.push(column);
    })
    setJsonPreview(preview);
  }

  const trainingTypeValidation = (value) => {
    value = value.trim().toLowerCase();
    if (value == "ya")
      return true;
    if (value == "tidak")
      return false;
    return null;
  }

  const trainingValidation = (options, value) => {
    let valid = null;
    for (let index = 0; index < options.length; index++) {
      if (options[index].name == value) {
        valid = options[index].id;
        break;
      }
    }
    return valid;
  }

  const trainingLocationValidation = (offline, value) => {
    if (!value)
      value = "";
    value = value.trim();
    if (offline && value == "")
      return null;
    return value;
  }

  const trainingNumberValidation = (value) => {
    const numValue = parseInt(value);
    return numValue > 0 ? numValue : null
  }

  const handleCreateTraining = async () => {
    const rows = [...jsonPreview];
    setJsonPreview(null);
    dispatch(setLoadingMessage("Mengimpor training ...", messageKey));
    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      let data = {};
      let dates = [];
      data = {
        price: row.harga.value,
        max_participants: row.jumlahmaksimalpeserta.value,
        is_online: row.online.value,
        is_offline: !row.online.value,
        location: row.lokasi.value,
        room: row.ruang.value,
        time_start: row.waktumulai.value,
        time_end: row.waktuselesai.value,
        publicly_visible: row.tampilkandihalamanjadwaluntukpublikyatidak.value,
        training_option_id: row.pilihantraining.value,
        trainer_id: row.trainer.value,
        note: row.catatan.value,
        notification_first: row.mengirimnotifikasijikaadapendaftarpertama.value,
        notification_full: row.mengirimnotifikasijikakelaspenuh.value,
        days_before_closing: row.harisebelumtrainingditutup.value,
      };
      for (let tgl = 1; tgl <= 10; tgl++) {
        const date = row["tanggalpelaksanaan" + tgl].value;
        if (date == "")
          continue;
        dates.push(moment(date).toISOString());
      }
      try {
        const newData = await baseRequest("post", "/trainings/", null, data);
        for (const date of dates) {
          await baseRequest("post", "/training-dates/", null, {
            training_id: newData.id, date: date
          });
        }
      } catch (error) {
        dispatch(setErrorMessage(error, messageKey));
        return history.push("/training/import");
      }
    }
    dispatch(setSuccessMessage("Berhasil mengimpor training", messageKey));
    history.push("/training");
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={{}}
        preserve
        onFinish={async () => {
          await handleCreateTraining();
        }}
        onValuesChange={() => { }}
      >
        <Form.Item
          name="upload"
          label="File Excel"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload name="logo"
            listType="picture"
            maxCount={1}
            accept=".xlsx, .xls"
            beforeUpload={beforeUpload}
            showUploadList={false}>
            <Button icon={<UploadOutlined />}>Pilih</Button>
          </Upload>
        </Form.Item>
        {jsonPreview &&
          <>
            <Form.Item>
              <Table columns={columns} dataSource={jsonPreview} scroll={{ x: 1300 }} bordered />
            </Form.Item>
            <Form.Item>
              <center>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  icon={<CheckCircleOutlined />}
                  disabled={!dataTrue}
                >
                  Simpan
                </Button>
              </center>
            </Form.Item>
          </>
        }
      </Form>
    </>
  );
}
