import React from "react";
import { Select } from "antd";

export default function TrainersSelect({
  loading = false,
  selectedTrainer,
  trainers = [],
  onSelect,
}) {
  return (
    <Select
      defaultValue="all"
      showSearch
      style={{ width: 200 }}
      optionFilterProp="label"
      onChange={onSelect}
      value={selectedTrainer}
      loading={loading}
    >
      <Select.Option value="all" label="semua">
        Semua trainer
      </Select.Option>
      {trainers.map((t) => (
        <Select.Option key={t.id} value={t.id} label={t.name}>
          {t.name}
        </Select.Option>
      ))}
    </Select>
  );
}
