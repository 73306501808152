import React from "react";
import { Table, Typography, Popover } from "antd";
import { toRupiah } from "../../../../libs";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";

const getColumns = () => [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    align: "center",
    width: 10,
    responsive: ["sm"],
  },
  {
    title: "Jumlah",
    dataIndex: "amount",
    key: "amount",
    render: (amount, obj) => obj.training_registration_id === null ? <Typography.Text>{toRupiah(amount)}</Typography.Text> : <Link to={"/training-registration/" + obj.training_registration_id}>{toRupiah(amount)}</Link>
  },
  {
    title: "Tipe",
    align: "center",
    width: 10,
    dataIndex: "is_debit",
    render: (isDebit) =>
      isDebit ? <ArrowUpOutlined /> : <ArrowDownOutlined />,
  },
  {
    title: "Waktu",
    dataIndex: "time",
    render: (time) => moment(time).format("LLL"),
  },
  {
    title: "Rekening",
    dataIndex: "bank_account",
    align: "center",
    key: "bank_account",
    render: (bankAccount) => (
      <Popover
        // placement="left"
        content={bankAccount.number + " ( " + bankAccount.owner_name + " )"}
      >
        {bankAccount.name}
      </Popover>
    ),
    responsive: ["sm"],
  },

  {
    title: "Berita",
    dataIndex: "description",
    key: "description",
    render: (description) => (description ? description : "-"),
    responsive: ["md"],
  },
  {
    dataIndex: "training_registration_id",
    align: "center",
    render: (id, field) =>
      !field.is_debit ? id ? <CheckOutlined /> : <CloseOutlined /> : "-",
  },
];
export default function MutationsList({
  mutations = [],
  loading = false,
  totalMutations = 0,
  pageSize = 10,
  currentPage = 1,
  onChangePagination,
}) {
  return (
    <Table
      dataSource={mutations.map((m) => ({ ...m, key: m.id }))}
      loading={loading}
      columns={getColumns()}
      pagination={{
        position: ["bottomCenter"],
        total: totalMutations,
        pageSize: pageSize,
        current: currentPage,
        onChange: (page, size) => onChangePagination(page, size),
      }}
    />
  );
}
