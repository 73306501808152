// Import package
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Import file
import { getTrainer } from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import PageContentWrapper from "../../components/PageContentWrapper";
import TrainerDetail from "../../components/adminRole/trainer/TrainerDetail";

// Component
export default function DetailTrainerPage() {
    const dispatch = useDispatch();
    const { trainer, trainerLoading } = useSelector(
        ({ trainer }) => trainer
      );
      const { trainerId } = useParams();
      useEffect(() => {
        dispatch(getTrainer(trainerId));
      }, [dispatch]);
    
      return trainer && !trainerLoading ? (
          <PageContentWrapper
            title="Detail"
            backLink="/trainer/">

                <TrainerDetail trainer={trainer}/>

            </PageContentWrapper>
      ) : trainerLoading ? (
          <CircularProgress/>
      ) : (
          "Gagal mendapatkan data"
      )
}