import React from "react";
import { Dropdown, Menu } from "antd";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  AuditOutlined,
} from "@ant-design/icons";

export default function TrainingRegistrationsListActions({
  onDelete,
  showSetPaidDownPayment = false,
  showSetPaidOff = false,
  showCancel = false,
  showInvoice = false,
  showReceipt = false,
  onCancel,
  onSetPaidDownpayment,
  onEdit,
  onSetPaidOff,
  onInvoice,
  onReceipt,
}) {
  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="4" icon={<EditOutlined />} onClick={onEdit}>
            Edit
          </Menu.Item>
          {showSetPaidDownPayment && (
            <Menu.Item
              key="1"
              icon={<CheckOutlined />}
              onClick={onSetPaidDownpayment}
            // danger
            >
              Tandai lunas dp
            </Menu.Item>
          )}
          {showSetPaidOff && (
            <Menu.Item key="2" icon={<CheckOutlined />} onClick={onSetPaidOff}>
              Tandai lunas total
            </Menu.Item>
          )}
          {showCancel && (
            <Menu.Item
              key="3"
              icon={<CloseOutlined />}
              danger
              onClick={onCancel}
            >
              Batalkan
            </Menu.Item>
          )}
          {showInvoice &&
            <Menu.Item
              key="5"
              icon={<AuditOutlined />}
              danger
              onClick={onInvoice}
            >
              Invoice
            </Menu.Item>
          }
          {showReceipt &&
            <Menu.Item
              key="5"
              icon={<AuditOutlined />}
              danger
              onClick={onReceipt}
            >
              Kuitansi
            </Menu.Item>
          }
          <Menu.Item
            key="0"
            icon={<DeleteOutlined />}
            danger
            onClick={onDelete}
          >
            Hapus
          </Menu.Item>
        </Menu>
      }
      placement="bottomRight"
      trigger={["click"]}
    >
      <i className="gx-icon-btn icon icon-ellipse-v" />
    </Dropdown>
  );
}
