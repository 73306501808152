import { v4 } from "uuid";
import { baseRequest } from "../../util/Api";
import {
  setSuccessMessage,
  setErrorMessage,
  setLoadingMessage,
} from "./Common";

export const createTrainingDate = (data) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(
      setLoadingMessage("Mengatur tanggal jadwal training ...", messageKey)
    );
    baseRequest("post", "/training-dates/", null, data)
      .then(() => {
        dispatch(
          setSuccessMessage(
            "Berhasil mengatur tanggal jadwal training",
            messageKey
          )
        );
        resolve();
      })
      .catch((e) => dispatch(setErrorMessage(e, messageKey)));
  });
