// Import package
import React, { useEffect, useState } from "react";
import moment from "moment";
import FilterBarChartMonthlyParticipants from "../../../components/adminRole/dashboard/FilterBarChartMonthlyParticipants";
import DashboardBarChart from "../../../components/adminRole/dashboard/DashboardBarChart";
import { getDashboardTrainingParticipantYearlyGraph } from "../../../appRedux/actions";
// Component
export default function BarChartYearlyParticipant({ trainingOptions, locations }) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({
    training_option_id: null,
    is_retraining: null,
    start_date: moment().startOf('year').subtract(10, 'years').toISOString(),
    end_date: moment().endOf('year').toISOString(),
    location: null,
    category_id: null,
  })

  useEffect(() => {
    getDashboardTrainingParticipantYearlyGraph(filter).then((rows) => {
      setData(rows.map((n) => {
        return {
          name: n.year,
          participant: n.total,
        }
      }))
    })
  }, [filter])

  return (
    <>
      <FilterBarChartMonthlyParticipants
        trainingOptions={trainingOptions}
        locations={locations}
        filter={filter}
        setFilter={setFilter}
        yearly={true}
      />
      <DashboardBarChart
        barKey="participant"
        fillColor="#ff7a45"
        data={data}
        barName="Peserta Tahunan"
      />
    </>
  );
}
