import React from "react";
import { Table, Tag, Space, Badge } from "antd";
import { Link } from "react-router-dom";

const getColumns = () => [
  {
    title: "#",
    key: "index",
    render: (text, record, index) => index + 1,
    align: "center",
    width: 10,
  },
  {
    title: "Training",
    dataIndex: "training_option",
    render: (training_option, item) => (
      <>
        <Space>
          <Link to={"/training/" + item.id}>
            {training_option.name}
          </Link>
        </Space>
      </>
    ),
  },
  {
    title: "Jadwal",
    dataIndex: "dates_summary",
    responsive: ["md"],
    render: (dates_summary, item) => (
      <>
        <Space>
          {dates_summary}
          {!item.is_online ? (
            <Tag color="magenta" style={{ margin: 0 }}>
              {item.location}
            </Tag>
          ) : (
            <Tag color="blue" style={{ margin: 0 }}>
              online
            </Tag>
          )}
        </Space>
      </>
    ),
  },
  {
    title: "Peserta",
    dataIndex: "total_participants",
    responsive: ["md"],
    align: "center",
    render: (total_participants) => (
      <>
        <Space>{total_participants}</Space>
      </>
    ),
  },
  {
    title: "Jam",
    dataIndex: "time_start",
    responsive: ["md"],
    render: (time_start, item) => (
      <>
        <Space>
          {time_start} - {item.time_end}
        </Space>
      </>
    ),
  },

  {
    title: "Status",
    dataIndex: "is_running",
    align: "center",
    width: 10,
    responsive: ["md"],
    render: (is_running) => (
      <Badge
        style={{ margin: 0 }}
        text={is_running ? "Running" : "Batal"}
        status={is_running ? "success" : "error"}
      />
    ),
  },
];

export default function SummaryTrainingNextMonthList({
  trainings = [],
  trainingsLoading,
  pageSize = 6,
  currentPage = 1,
  onChangePagination,
  totalTrainings,
}) {
  return (
    <Table
      size="small"
      dataSource={trainings.map((training) => ({
        ...training,
        key: training.id,
      }))}
      loading={trainingsLoading}
      columns={getColumns()}
      pagination={false}
    // pagination={{
    //   total: totalTrainings,
    //   position: ["bottomCenter"],
    //   responsive: true,
    //   onChange: onChangePagination,
    //   pageSize,
    //   current: currentPage,
    // }}
    />
  );
}
