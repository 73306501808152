import React, { useEffect, useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { userSignIn } from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { baseRequest, client } from "../util/Api";
import {
  FAILED_LOGIN,
} from "../constants/ActionTypes";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.authUser);
  const numOfFailedLogin = useSelector(({ auth }) => auth.failed);
  const recaptchaRef = React.createRef();
  const [ signinDisabled, setSigninDisabled ] = useState(true);

  const onFinishFailed = (errorInfo) => { };

  const onFinish = (values) => {
    values.recaptcha = ""
    if (captchaRequired()) {
      values.recaptcha = recaptchaRef.current.getValue();
      recaptchaRef.current.reset();
      setSigninDisabled(true);
    }
    captchaRequiredByEmail(values.email);
    dispatch(userSignIn(values));
  };

  const captchaRequired = () => {
    return numOfFailedLogin > 2;
  }

  const captchaRequiredByEmail = async (email) => {
    const data = await baseRequest("get", `auth/captcha/${email.trim()}`);
    if (data.required) {
      dispatch({ type: FAILED_LOGIN });
      dispatch({ type: FAILED_LOGIN });
      dispatch({ type: FAILED_LOGIN });
    }
  }

  useEffect(() => {
    if (authUser !== null) {
      props.history.push("/");
    }
  }, [authUser, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img alt="example" src="bg.jpg" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="Login" />
              </h1>
              <p>
                <IntlMessages id="Silahkan login agar bisa mendaftar training kami" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img
                alt="example"
                src="/assets/images/logo-idn.png"
                width="150"
              />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                label="Email"
                rules={[{ required: true, message: "Harap masukkan email!" }]}
                name="email"
              >
                <Input placeholder="Masukkan Email"
                  onBlur={evt => captchaRequiredByEmail(evt.target.value)} />
              </Form.Item>
              <Form.Item
                label="Password"
                rules={[
                  { required: true, message: "Harap masukkan password!" },
                ]}
                name="password"
              >
                <Input
                  type="password"
                  placeholder="Masukkan Password"
                // iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                />
              </Form.Item>
              { captchaRequired() && (
                <Form.Item>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={ process.env.REACT_APP_RECAPTCHA_SITE_KEY }
                    onChange={() => { setSigninDisabled(false) }}
                    onErrored={() => { setSigninDisabled(true) }}
                    onExpired={() => { setSigninDisabled(true) }}
                  />
                </Form.Item>
              )}
              <div className="gx-flex-row gx-justify-content-between">
                <span></span>
                <Link to="/password-reset">
                  <IntlMessages id="Lupa password?" />
                </Link>
              </div>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit"
                    disabled={captchaRequired() ? signinDisabled : false }>
                  <IntlMessages id="Login" />
                </Button>
                <span>
                  <IntlMessages id="atau" />
                </span>{" "}
                <Link to="/signup">
                  <IntlMessages id="Daftar" />
                </Link>
                {" / "}
                <Link to="/resend-verification">
                  <IntlMessages id="Resend Email Verifikasi" />
                </Link>
              </Form.Item>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
