import React from "react";
import PageContentWrapper from "../../components/PageContentWrapper";
import TrainingRegistrationFormContainer from "../../containers/adminRole/trainingRegistration/TrainingRegistrationFormContainer";

export default function NewTrainingRegistrationPage() {
  return (
    <PageContentWrapper
      title="Tambah Registrasi Training"
      backLink="/training-registration"
    >
      <TrainingRegistrationFormContainer />
    </PageContentWrapper>
  );
}
