// Import package
import React from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";

// Import file
import TrainingActions from "../../../../components/adminRole/training/TrainingActions";
import {
  deleteTraining,
  getTrainingAttendance,
  cancelTraining,
  enableTraining,
  getTrainingCertificates,
} from "../../../../appRedux/actions";


export default function TrainingActionsContainer() {
  const { training } = useSelector(({ training }) => training);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleDeleteTraining = () => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin menghapus training ini ?",
      onOk: () =>
        dispatch(deleteTraining(training.id)).then(() =>
          history.push("/training")
        ),
    });
  };
  const handleEditTraining = () => {
    history.push("/training/" + training.id + "/edit");
  };
  const handleDownloadAttendance = () => {
    dispatch(getTrainingAttendance(training.id)).then((url) =>
      window.open(url, "_blank")
    );
  };
  const handleDownloadCertificates = () => {
    dispatch(getTrainingCertificates(training.id)).then((url) =>
      window.open(url, "_blank")
    );
  };
  const handleCancelTraining = () => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin membatalkan training ini ?",
      onOk: () => {
        Modal.confirm({
          title: "Kirim pemberitahuan",
          content:
            "Apakah sistem perlu untuk mengirim pemberitahuan tentang pembatalan ini ke seluruh peserta terdaftar ?",
          cancelText: "Tidak",
          okText: "Iya",
          onOk: () => dispatch(cancelTraining(training.id, true)),
          onCancel: () => dispatch(cancelTraining(training.id, false)),
        });
      },
    });
  };
  const handleReschedule = () => {
    history.push("/training/" + training.id + "/reschedule");
  };
  const handleEnableTraining = () => dispatch(enableTraining(training.id));
  return (
    <TrainingActions
      onDelete={handleDeleteTraining}
      onEdit={handleEditTraining}
      onDownloadAttendance={handleDownloadAttendance}
      onCancel={handleCancelTraining}
      onEnable={handleEnableTraining}
      isRunning={training.is_running}
      onDownloadCertificates={handleDownloadCertificates}
      onReschedule={handleReschedule}
    />
  );
}
