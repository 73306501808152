// Import package
import React, { useEffect } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useParams, useHistory } from "react-router-dom";
import { registrationVerify } from "../appRedux/actions";
import { useSelector, useDispatch } from "react-redux";

const VerifyRegistration = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let { token } = useParams();
  
  useEffect(() => {
    dispatch(registrationVerify({ registrationToken: token }))
    history.push("/")
  }, []);
  return null
};

export default VerifyRegistration;
