import {
  SET_GET_TRAINING_OPTIONS_LOADING,
  SET_TRAINING_OPTIONS,
  SET_TOTAL_TRAINING_OPTIONS,
  DELETE_TRAINING_OPTION,
  SET_TRAINING_OPTION,
  SET_TRAINING_OPTION_LOADING,
  UPDATE_TRAINING_OPTION,
} from "../../constants/ActionTypes";
import { baseRequest } from "../../util/Api";
import {
  setErrorMessage,
  setLoadingMessage,
  setSuccessMessage,
} from "./Common";
import { v4 } from "uuid";
import { createTask } from "./Task";

export const getTrainingOptions = (filter) => (dispatch) => {
  dispatch(setTrainingOptionsLoading(true));
  (async () => {
    try {
      const { data, meta } = await baseRequest(
        "get",
        "/training-options/",
        filter
      );
      dispatch({
        type: SET_TRAINING_OPTIONS,
        payload: data,
      });
      dispatch({
        type: SET_TOTAL_TRAINING_OPTIONS,
        payload: meta.total,
      });
    } catch (e) {
      dispatch(setErrorMessage(e));
      dispatch(setTrainingOptionsLoading(false));
    }
  })();
};

export const setTrainingOptionsLoading = (loading) => (dispatch) =>
  dispatch({
    type: SET_GET_TRAINING_OPTIONS_LOADING,
    payload: loading,
  });

export const createTrainingOption = (data) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Membuat pilihan training ...", messageKey));
    baseRequest("post", "/training-options/", null, data)
      .then((newData) => {
        dispatch(
          setSuccessMessage("Berhasil membuat pilihan training", messageKey)
        );
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const getTrainingOptionCertificateTemplate = () => (dispatch) =>
  new Promise((resolve) => {
    baseRequest("get", "/training-options/certificate-template-upload-url")
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e));
      });
  });

export const deleteTrainingOption = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menghapus pilihan training ...", messageKey));
    baseRequest("delete", "/training-options/" + id)
      .then(() => {
        dispatch(
          setSuccessMessage("Berhasil menghapus pilihan training", messageKey)
        );
        dispatch({
          type: DELETE_TRAINING_OPTION,
          payload: id,
        });
        resolve();
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
        reject();
      });
  });

export const getTrainingOption = (id) => (dispatch) => {
  dispatch({
    type: SET_TRAINING_OPTION_LOADING,
    payload: true,
  });
  baseRequest("get", "/training-options/" + id)
    .then((data) => {
      dispatch({
        type: SET_TRAINING_OPTION,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_TRAINING_OPTION_LOADING,
        payload: false,
      });
    });
};

export const updateTrainingOption = (trainingOption) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Memperbarui pilihan training ...", messageKey));
    baseRequest(
      "put",
      "/training-options/" + trainingOption.id,
      null,
      trainingOption
    )
      .then((newData) => {
        dispatch(
          setSuccessMessage("Berhasil memperbarui pilihan training", messageKey)
        );
        dispatch({
          type: UPDATE_TRAINING_OPTION,
          payload: newData,
        });
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const generateDummyCertificateTemplate =
  (trainingOptionId) => (dispatch) =>
    new Promise((resolve) => {
      const messageKey = v4();
      dispatch(setLoadingMessage("Membuat contoh sertifikat ...", messageKey));
      dispatch(
        createTask({
          task: "generate_dummy_certificate_template",
          args: { training_option_id: trainingOptionId },
        })
      )
        .then((result) => {
          dispatch(
            setSuccessMessage("Berhasil membuat contoh sertifikat", messageKey)
          );
          resolve(result);
        })
        .catch((e) => dispatch(setErrorMessage(e, messageKey)));
    });
