// Import package
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

// Import file
import TrainingParticipantForm from "../../../../components/adminRole/trainingParticipant/TrainingParticipantForm";
import {
  getParticipants,
  createTrainingParticipant,
  getTraining,
  createTrainingRegistration,
  sendDpPaymentInformtionEmailAndWa,
  sendPaidOffPaymentInformtionEmailAndWa,
  sendRepeatParticipantInformtionEmailAndWa
} from "../../../../appRedux/actions";

export default function NewTrainingParticipantFormContainer({ onCancel }) {
  const { participants, participantsLoading } = useSelector(
    ({ participant }) => participant
  );
  const { trainingParticipants } = useSelector(
    ({ trainingParticipant }) => trainingParticipant
  );
  const { training } = useSelector(({ training }) => training);
  const [searchParticipants, setSearchParticipants] = useState("");
  const dispatch = useDispatch();
  const handleSearchParticipants = (search) => {
    setSearchParticipants(search);
  };
  const handleCreateTrainingParticipant = (data) => {
    if (data.send_message) {
      if (data.is_corporate_participant) {
        dispatch(
          createTrainingRegistration({
            participant_id: data.participant_id,
            is_retraining: data.is_retraining,
            training_id: training.id,
            total_price: data.total_price,
            already_paid: data.paid
              ? data.total_price
              : data.total_price - data.lack_of_money,
            note: data.note,
            retraining_with_meal: data.retraining_with_meal,
            retraining_received_kit: data.retraining_received_kit,
          })
        ).then(result => {
          dispatch(sendRepeatParticipantInformtionEmailAndWa({ id: result.id }))
        }).then(() => {
          onCancel();
          dispatch(getTraining(training.id));
        })
      } else {
        dispatch(
          createTrainingRegistration({
            participant_id: data.participant_id,
            is_retraining: data.is_retraining,
            training_id: training.id,
            total_price: data.total_price,
            already_paid: data.paid
              ? data.total_price
              : data.total_price - data.lack_of_money,
            note: data.note,
            retraining_with_meal: data.retraining_with_meal,
            retraining_received_kit: data.retraining_received_kit,

          })
        )
          .then((result) => {
            if (result.status === "waiting_for_paid_off") {
              dispatch(sendDpPaymentInformtionEmailAndWa({ id: result.id }))
            } else if (result.status === "paid_off") {
              dispatch(
                sendPaidOffPaymentInformtionEmailAndWa({ id: result.id })
              )
            } else if (result.status === null) {
              dispatch(sendRepeatParticipantInformtionEmailAndWa({ id: result.id }))
            }
          }).then(() => {
            onCancel();
            dispatch(getTraining(training.id));
          })
      }
    } else {
      dispatch(
        createTrainingRegistration({
          participant_id: data.participant_id,
          is_retraining: data.is_retraining,
          training_id: training.id,
          total_price: data.total_price,
          already_paid: data.paid
            ? data.total_price
            : data.total_price - data.lack_of_money,
          note: data.note,
          retraining_with_meal: data.retraining_with_meal,
          retraining_received_kit: data.retraining_received_kit,
        })
      ).then(() => {
        onCancel();
        dispatch(getTraining(training.id));
      })
    }

    // data.send_message
    //   ? dispatch(
    //       createTrainingRegistration({
    //         participant_id: data.participant_id,
    //         is_retraining: data.is_retraining,
    //         training_id: training.id,
    //         total_price: data.total_price,
    //         already_paid: data.paid
    //           ? data.total_price
    //           : data.total_price - data.lack_of_money,
    //       })
    //     )
    //       .then((result) =>
    //         result.status === "waiting_for_paid_off"
    //           ? dispatch(sendDpPaymentInformtionEmailAndWa({ id: result.id }))
    //           : result.status === "paid_off"
    //           ? dispatch(
    //               sendPaidOffPaymentInformtionEmailAndWa({ id: result.id })
    //             )
    //           : null
    //       )
    //       .then(() => {
    //         onCancel();
    //         dispatch(getTraining(training.id));
    //       })
    //   : dispatch(
    //       createTrainingRegistration({
    //         participant_id: data.participant_id,
    //         is_retraining: data.is_retraining,
    //         training_id: training.id,
    //         total_price: data.total_price,
    //         already_paid: data.paid
    //           ? data.total_price
    //           : data.total_price - data.lack_of_money,
    //       })
    //     ).then(() => {
    //       onCancel();
    //       dispatch(getTraining(training.id));
    //     });
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(getParticipants({ page_size: 5, search: searchParticipants }));
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchParticipants]);
  return (
    <TrainingParticipantForm
      training={training}
      participants={participants}
      participantsLoading={participantsLoading}
      searchParticipants={handleSearchParticipants}
      existParticipants={trainingParticipants}
      onSubmit={handleCreateTrainingParticipant}
      onCancel={onCancel}
    />
  );
}
