// Import Package
import React from "react";
import { useDispatch } from "react-redux";

// Import File
import { updateUser, updateTrainer } from "../../appRedux/actions";
import EditProfile from "../../components/trainerRole/EditProfile";

// Component
export default function EditProfileContainer({ user }) {
  const dispatch = useDispatch();
  const handleSubmit = (data) => {
    dispatch(updateTrainer({ ...data, id: user.id }))
  };
  return <EditProfile onSubmit={handleSubmit} user={user} />;
}
