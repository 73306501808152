import React, { useState, useEffect } from "react";
import MyTrainingListActions from "../../components/participantRole/MyTrainingListActions";
import { useDispatch, useSelector } from "react-redux";
import PhoneNumber from "awesome-phonenumber";
import {
  updateTrainingRegistration,
  deleteTrainingRegistration,
  getTrainingRegistrations,
  cancelTrainingRegistration
} from "../../appRedux/actions";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { Modal } from "antd";
import PaymentDetail from "../../components/participantRole/PaymentDetail";
export default function MyTrainingListActionsContainer({
  trainingRegistration,
}) {
  const [showPaymentDetailModal, setShowPaymentDetailModal] = useState(false);
  const { id, status, total_price, is_retraining } = trainingRegistration;
  const dispatch = useDispatch();
  let filter = queryString.parse(useLocation().search);

  const handleDelete = () => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin membatalkan training ini ?",
      onOk: () =>
        dispatch(deleteTrainingRegistration(id)).then(() =>
          dispatch(getTrainingRegistrations({ ...filter, current_page: 1 }))
        ),
    });
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin membatalkan training ini ?",
      onOk: () =>
        dispatch(
          cancelTrainingRegistration({
            ...trainingRegistration,
            is_canceled: true,
          })
        ),
    });
  };
  const handlePayment = () => {
    setShowPaymentDetailModal(true);
  };

  // const handleCall = () => {
  //   <a href={"https://wa.me/6281908191001"}>
  //     {new PhoneNumber(6281908191001).getNumber("national")}
  //   </a>;
  // };
  return (
    <>
      <PaymentDetail
        visible={showPaymentDetailModal}
        onCancel={() => setShowPaymentDetailModal(false)}
        trainingRegistration={trainingRegistration}
      />
      <MyTrainingListActions
        isCancel={status === "canceled"}
        isPaidOffAndDP={status === "waiting_for_paid_off" || status === "paid_off"}
        isRetraining={is_retraining}
        onDelete={handleDelete}
        onAcceptPayment={handlePayment}
        // onCancel={handleCancel}
        // showSetWaitingPaidOff={status === "waiting_for_paid_off"}
        // showSetPaidOffAndSetWaitingPaidOff={
        //   status === "waiting_for_paid_off" || status === "paid_off"
        // }
        showSetPaidDownPayment={status === "waiting_for_down_payment"}
      />
    </>
  );
}
