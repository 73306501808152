import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PageHeader, Card } from "antd";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "../../components/CircularProgress";
import {
  getTraining,
  getTrainers,
  getTrainingOptions,
  updateTraining,
} from "../../appRedux/actions";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import NewTrainingForm from "../../components/adminRole/training/NewTrainingForm";

export default function EditTrainingPage() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const { trainingId } = useParams();
  const { trainers, getTrainersLoading } = useSelector(
    ({ trainer }) => trainer
  );
  const { createTrainingLoading } = useSelector(({ training }) => training);
  const { trainingOptions, getTrainingOptionsLoading } = useSelector(
    ({ trainingOption }) => trainingOption
  );
  const { training, trainingLoading } = useSelector(({ training }) => training);
  const handleUpdateTraining = (newData) => {
    dispatch(updateTraining({ ...training, ...newData })).then(() => {
      history.push("/training/" + training.id);
    });
  };
  useEffect(() => {
    dispatch(getTraining(trainingId));
    dispatch(getTrainers());
    dispatch(getTrainingOptions({ page_size: 100 }));
  }, [dispatch]);
  return training && !trainingLoading ? (
    <>
      <PageHeader
        ghost={false}
        title={
          "Edit " +
          (!screens.xs
            ? training.training_option.name
            : training.training_option.name.split(" ")[0])
        }
        onBack={() => history.push("/training/" + training.id)}
      />
      <Card bordered={false} style={{ borderRadius: 0 }}>
        <NewTrainingForm
          trainers={trainers}
          trainersLoading={getTrainersLoading}
          trainingOptions={trainingOptions}
          trainingOptionsLoading={getTrainingOptionsLoading}
          showDatesField={false}
          update={true}
          training={training}
          onSubmit={handleUpdateTraining}
          // submitLoading={createTrainingLoading}
        />
      </Card>
    </>
  ) : trainingLoading ? (
    <CircularProgress />
  ) : (
    "training tidak ditemukan"
  );
}
