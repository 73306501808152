// Import package
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

// Import file
import TrainingParticipantForm from "../../../../components/adminRole/trainingParticipant/TrainingParticipantForm";
import {
  getParticipants,
  createTrainingParticipant,
  setTrainingParticipant,
  updateTrainingParticipant,
  getTraining,
} from "../../../../appRedux/actions";

export default function EditTrainingParticipantFormContainer() {
  const dispatch = useDispatch();
  const { trainingParticipant } = useSelector(
    ({ trainingParticipant }) => trainingParticipant
  );
  const { training } = useSelector(({ training }) => training);
  const handleUpdateTrainingParticipant = (data) => {
    dispatch(
      updateTrainingParticipant({
        id: trainingParticipant.id,
        training_id: training.id,
        ...data,
      })
    ).then(() => {
      onCancel();
      dispatch(getTraining(training.id));
    });
  };
  const onCancel = () => dispatch(setTrainingParticipant(null));
  return (
    <TrainingParticipantForm
      training={training}
      onSubmit={handleUpdateTrainingParticipant}
      onCancel={onCancel}
      update={true}
      trainingParticipant={trainingParticipant}
    />
  );
}
