// Import package
import { v4 } from "uuid";

// Import file
import {
  SET_DISCOUNT,
  SET_DISCOUNTS,
  SET_GET_DISCOUNTS_LOADING,
  SET_DISCOUNT_LOADING,
  DELETE_DISCOUNT,
  UPDATE_DISCOUNT,
  SET_DISCOUNTS_DATES,
  SET_DISCOUNTS_SEARCH,
  RESET_DISCOUNTS_FILTER,
  SET_DISCOUNTS_PARTICIPANTS,
} from "../../constants/ActionTypes";
import { toRupiah } from "../../libs";
import { baseRequest } from "../../util/Api";
import {
  setErrorMessage,
  setSuccessMessage,
  setLoadingMessage,
} from "./Common";

export const getDiscounts = (filter) => (dispatch) => {
  dispatch({
    type: SET_GET_DISCOUNTS_LOADING,
    payload: true
  })
  baseRequest("get", "/discounts/", filter).then((data) => {
    dispatch({
      type: SET_DISCOUNTS,
      payload: data,
    })
  }).catch((e) => {
    dispatch(setErrorMessage(e))
    dispatch({
      type: SET_GET_DISCOUNTS_LOADING,
      payload: false
    })
  })
}

export const discountChecker = ({ code, training_id }) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menambah kode diskon ...", messageKey));
    baseRequest("post", "/discounts/discount-checker", null, { code, training_id })
      .then((newData) => {
        let discount = newData.percent !== null ? newData.percent + "%" : toRupiah(newData.price)
        dispatch(setSuccessMessage("Berhasil mendapatkan diskon " + discount, messageKey));
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const getDiscount = (id) => (dispatch) => {
  dispatch({
    type: SET_DISCOUNT_LOADING,
    payload: true
  })
  baseRequest("get", "/discounts/" + id).then((data) => {
    dispatch({
      type: SET_DISCOUNT,
      payload: data
    })
  }).catch((e) => {
    dispatch(setErrorMessage(e))
    dispatch({
      type: SET_DISCOUNT_LOADING,
      payload: false
    })
  })
}

export const createDiscount = (data) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menambah diskon ...", messageKey));
    baseRequest("post", "/discounts/", null, data)
      .then((newData) => {
        dispatch(setSuccessMessage("Menambah diskon berhasil", messageKey));
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const updateDiscount = (discount) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Memperbarui diskon ...", messageKey));
    baseRequest("put", "/discounts/" + discount.id, null, discount)
      .then((newData) => {
        dispatch(setSuccessMessage("Memperbarui diskon berhasil", messageKey));
        dispatch({
          type: UPDATE_DISCOUNT,
          payload: newData
        })
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const deleteDiscount = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menghapus diskon ...", messageKey));
    baseRequest("delete", "/discounts/" + id)
      .then(() => {
        dispatch(setSuccessMessage("Menghapus diskon berhasil", messageKey));
        dispatch({
          type: DELETE_DISCOUNT,
          payload: id,
        });
        resolve();
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
        reject();
      });
  });

export const setDiscountEnabled = (discount) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Mengaktifkan diskon ...", messageKey));
    baseRequest("patch", "/discounts/" + discount.id, null, discount)
      .then((newData) => {
        let message = "Menonaktifkan"
        if (discount.enabled)
          message = "Mengaktifkan"
        dispatch(setSuccessMessage(`${message} diskon berhasil`, messageKey));
        dispatch({
          type: UPDATE_DISCOUNT,
          payload: newData
        })
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const getParticipantsUsingDiscount = (id) => (dispatch) => {
  dispatch({
    type: SET_DISCOUNT_LOADING,
    payload: true
  })
  baseRequest("get", "/discounts/participants/" + id).then((data) => {
    dispatch({
      type: SET_DISCOUNTS_PARTICIPANTS,
      payload: data
    })
  }).catch((e) => {
    dispatch(setErrorMessage(e))
    dispatch({
      type: SET_DISCOUNT_LOADING,
      payload: false
    })
  })
}

export const setDiscountsDates = (dates) => (dispatch) =>
  dispatch({
    type: SET_DISCOUNTS_DATES,
    payload: dates,
  });

export const setDiscountsSearch = (search) => (dispatch) =>
  dispatch({
    type: SET_DISCOUNTS_SEARCH,
    payload: search,
  });

export const resetDiscountsFilter = (search) => (dispatch) =>
  dispatch({
    type: RESET_DISCOUNTS_FILTER,
  });