// Import package
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader, Card, Col, Space, Select, Button, DatePicker } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import * as XLSX from 'xlsx/xlsx.mjs';

// Import file
import TrainingParticipantActionsContainer from "../../containers/adminRole/trainingParticipant/TrainingparticipantActionsContainer";
import { setLogsDates, getLogs, resetLogsFilter, setLogsPagination } from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import TrainingParticipantDescription from "../../components/adminRole/trainingParticipant/TrainingParticipantDescription";
import PageContentWrapper from "../../components/PageContentWrapper";
import TrainingRegistrationDetail from "../../components/participantRole/trainingRegistration/TrainingRegistrationDetail";
import LogsList from "../../components/adminRole/adminLogs/LogsList";
import { baseRequest } from "../../util/Api";

export default function AdminLogs() {
  const dispatch = useDispatch();
  const { logs, logsLoading, totalLogs, getLogsFilter } = useSelector(
    ({ logs }) => logs
  );
  const handleChangeDates = (newDates) => dispatch(setLogsDates(newDates));
  const handleReset = () => dispatch(resetLogsFilter());
  const handleChangeLogsPagination = (currentPage, pageSize) =>
    dispatch(setLogsPagination({ currentPage, pageSize }));

  const handleExport = async () => {
    const { data, meta } = await baseRequest("get", "/logs/", { ...getLogsFilter, page_size: 1000000 });
    let rows = [[
      "Waktu",
      "Nama Pengguna",
      "Email",
      "Catatan",
    ]];
    data.map((row) => {
      rows.push([
        row.created_at,
        row.user_name,
        row.user_email,
        row.note,
      ])
    });
    const ws_name = "logs"
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(rows)
    XLSX.utils.book_append_sheet(wb, ws, ws_name)
    XLSX.writeFile(wb, "logs.xlsx")
    return false
  }

  useEffect(() => {
    dispatch(getLogs(getLogsFilter));
  }, [getLogsFilter]);

  return <>
    <PageHeader
      ghost={false}
      title="Log Admin"
    />
    <Card bordered={false} style={{ borderRadius: 0 }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space wrap>
          <DatePicker.RangePicker
            format="D MMMM YYYY"
            onChange={(v) =>
              v
                ? handleChangeDates(v.map((date) => date.toISOString()))
                : handleChangeDates([null, null])
            }
            value={[getLogsFilter.start_date, getLogsFilter.end_date].map((date) => (date ? moment(date) : null))}
          />
          <Button
            type="primary"
            style={{ margin: 0 }}
            disabled={
              getLogsFilter.start_date === null &&
              getLogsFilter.end_date === null
            }
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            type="primary"
            style={{ margin: 0, float: 'right' }}
            icon={<FileExcelOutlined />}
            onClick={handleExport}
          >
            Ekspor
          </Button>
        </Space>
        <LogsList
          logs={logs}
          loading={logsLoading}
          totalLogs={totalLogs}
          onChangePagination={handleChangeLogsPagination}
          pageSize={getLogsFilter.page_size}
          currentPage={getLogsFilter.current_page}
        />
      </Space>
    </Card>
  </>
}
