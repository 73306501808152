import React from "react";
import { Tabs } from "antd";

export default function AffiliateTabs({ onChange, activeKey = "history" }) {
  return (
    <Tabs type="card" onChange={onChange} activeKey={activeKey}>
      <Tabs.TabPane tab="Histori Penggunaan Poin" key="history" />
      <Tabs.TabPane tab="List Peserta Affiliate" key="participant" />
    </Tabs>
  );
}
