import { message } from "antd";
import { v4 } from "uuid";
import { baseRequest } from "../../util/Api";

export const getConfiguration = (name) => {
  return new Promise((resolve, reject) => {
    baseRequest("get", "/configurations/" + name)
      .then((data) => {
        resolve(data)
      }).catch((e) => {
        message.error(e)
      })
  })
}

export const updateConfiguration = (configuration) => {
  return new Promise((resolve) => {
    const key = v4();
    message.open({ key, type: 'loading', content: "Memperbarui konfigurasi ..." })
    baseRequest("put", "/configurations/" + configuration.name, null, configuration)
      .then((newData) => {
        message.success({ key, type: 'success', content: "Memperbarui konfigurasi berhasil" })
        resolve(newData);
      })
      .catch((e) => {
        message.error({ key, type: 'error', content: e })
      });
  });
}