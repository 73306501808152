// Import package
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Import file
import {
  getTrainingParticipants,
  deleteTrainingParticipant,
  setPaidTrainingParticipant,
  updateTrainingRegistration,
  setTrainingParticipant,
  getTraining,
  getTrainingParticipantCertificate,
} from "../../../../appRedux/actions";
// import TrainingParticipantEmpty from "../../../components/trainingParticipant/TrainingParticipantEmpty";
import TrainingParticipantsList from "../../../../components/adminRole/training/TrainingParticipantsList";

export default function TrainingParticipantsListContainer({ trainingId }) {
  const dispatch = useDispatch();
  const { trainingParticipants, trainingParticipantsLoading } = useSelector(
    ({ trainingParticipant }) => trainingParticipant
  );
  useEffect(() => {
    dispatch(getTrainingParticipants({ training_id: trainingId }));
  }, [dispatch]);
  const handleDeleteTrainingParticipant = (id) => {
    dispatch(deleteTrainingParticipant(id)).then(() =>
      dispatch(getTraining(trainingId))
    );
  };
  const handleSetPaidTrainingParticipant = (trainingParticipant) => 
    dispatch(setPaidTrainingParticipant(trainingParticipant)).then(() =>
      dispatch(getTraining(trainingId))
    );
  const handleEditTrainingParticipant = (trainingParticipant) =>
    dispatch(setTrainingParticipant(trainingParticipant));
  const handleDownloadCertificate = (trainingParticipantId) => {
    dispatch(
      getTrainingParticipantCertificate(trainingParticipantId)
    ).then((url) => window.open(url, "_blank"));
  };
  return (
    <TrainingParticipantsList
      participants={trainingParticipants}
      loading={trainingParticipantsLoading}
      onDelete={handleDeleteTrainingParticipant}
      onSetPaid={handleSetPaidTrainingParticipant}
      onEdit={handleEditTrainingParticipant}
      onDownloadCertificate={handleDownloadCertificate}
    />
  );
}
