// Import package
import React from "react";

// Import file
import AdminFormContainer from "../../containers/adminRole/admin/AdminFormContainer";
import PageContentWrapper from "../../components/PageContentWrapper";

// Component
export default function NewAdminPage() {
  return (
    <PageContentWrapper title="Tambah Admin" backLink={"/admin"}>
      <AdminFormContainer />
    </PageContentWrapper>
  );
}
