import {
  SET_GET_LOGS_LOADING,
  SET_LOGS,
  SET_TOTAL_LOGS,
  SET_GET_LOGS_FILTER,
  SET_LOGS_PAGINATION,
  RESET_LOGS_FILTER,
  SET_LOGS_DATES
} from "../../constants/ActionTypes";
import { baseRequest } from "../../util/Api";
import {
  setErrorMessage,
} from "./Common";

export const getLogs = (filter) => {
  return (dispatch) => {
    dispatch({
      type: SET_GET_LOGS_LOADING,
      payload: true,
    });
    (async () => {
      try {
        const { data, meta } = await baseRequest("get", "/logs/", filter);
        dispatch({
          type: SET_LOGS,
          payload: data,
        });
        dispatch({
          type: SET_TOTAL_LOGS,
          payload: meta.total,
        });
      } catch (e) {
        dispatch(setErrorMessage(e));
      }
      dispatch({
        type: SET_GET_LOGS_LOADING,
        payload: false,
      });
    })();
  };
};

export const setLogsFilter = (filter) => {
  return (dispatch) =>
    dispatch({
      type: SET_GET_LOGS_FILTER,
      payload: filter,
    });
};

export const setLogsPagination = (pagination) => (dispatch) =>
  dispatch({
    type: SET_LOGS_PAGINATION,
    payload: pagination,
  });

export const resetLogsFilter = () => (dispatch) =>
  dispatch({
    type: RESET_LOGS_FILTER,
  });

export const setLogsDates = (dates) => (dispatch) =>
  dispatch({
    type: SET_LOGS_DATES,
    payload: dates,
  });