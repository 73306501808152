// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Import file
// import { getTraining } from "../../../../appRedux/actions";
import TrainingDescription from "../../../../components/adminRole/training/TrainingDescription";

export default function TrainingDescriptionContainer({ trainingId }) {
  const { training, trainingLoading } = useSelector(({ training }) => training);
  return <TrainingDescription training={training} loading={trainingLoading} />;
}
