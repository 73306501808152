import {
  SET_LOGS,
  SET_GET_LOGS_LOADING,
  SET_GET_LOGS_FILTER,
  SET_TOTAL_LOGS,
  SET_LOGS_PAGINATION,
  RESET_LOGS_FILTER,
  SET_LOGS_DATES,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  logs: [],
  logsLoading: false,
  totalLogs: 0,
  getLogsFilter: {
    search: "",
    start_date: null,
    end_date: null,
    current_page: 1,
    page_size: 10,
  },
};

const LogsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOGS: {
      return {
        ...state,
        logs: action.payload,
        getlogsLoading: false,
      };
    }
    case SET_GET_LOGS_LOADING: {
      return {
        ...state,
        logsLoading: action.payload,
      };
    }
    case SET_TOTAL_LOGS: {
      return {
        ...state,
        totalLogs: action.payload,
      };
    }
    case SET_GET_LOGS_FILTER: {
      return {
        ...state,
        getLogsFilter: action.payload,
      };
    }
    case SET_LOGS_PAGINATION: {
      return {
        ...state,
        getLogsFilter: {
          ...state.getLogsFilter,
          page_size: action.payload.pageSize,
          current_page: action.payload.currentPage,
        },
      };
    }
    case RESET_LOGS_FILTER: {
      return {
        ...state,
        getLogsFilter: {
          ...state.getLogsFilter,
          start_date: null,
          search: "",
          end_date: null,
          current_page: 1,
        },
      };
    }
    case SET_LOGS_DATES: {
      return {
        ...state,
        getLogsFilter: {
          ...state.getLogsFilter,
          start_date: action.payload[0],
          end_date: action.payload[1],
          current_page: 1,
        },
      };
    }
    default:
      return state;
  }
};

export default LogsReducer;
