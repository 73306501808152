import {
  SET_BANK_ACCOUNTS,
  SET_BANK_ACCOUNTS_LOADING,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  bankAccounts: [],
  bankAccountsLoading: false,
};

const BankAccountReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_BANK_ACCOUNTS: {
      return {
        ...state,
        bankAccounts: action.payload,
        bankAccountsLoading: false,
      };
    }
    case SET_BANK_ACCOUNTS_LOADING: {
      return {
        ...state,
        bankAccountsLoading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default BankAccountReducer;
