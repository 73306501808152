// Import package
import { v4 } from "uuid";

import { INIT_URL, USER_TOKEN_SET } from "../../constants/ActionTypes";

import {
  setErrorMessage,
  setSuccessMessage,
  setLoadingMessage,
} from "./Common";
import { baseRequest, client } from "../../util/Api";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const resetPassword = ({ resetToken }) => {
  return (dispatch) => {
    (async () => {
      const key = v4();
      try {
        const result = await baseRequest("get", "/password/" + resetToken);
        localStorage.setItem("token", result.token);
        client.defaults.headers.common["Authorization"] =
          "Bearer " + result.token;
        dispatch({ type: USER_TOKEN_SET, payload: result.token });
      } catch (e) {
        dispatch(setErrorMessage(e, key));
      }
    })();
  };
};

export const reset =
  ({ token_reset, password, confirm_password }) =>
  (dispatch) =>
    new Promise((resolve) => {
      const key = v4();
      dispatch(setLoadingMessage("Mereset password ...", key));
      baseRequest("post", "/password/reset", null, {
        token_reset,
        password,
        confirm_password,
      })
        .then((newData) => {
          dispatch(setSuccessMessage("Berhasil mereset password", key));
          resolve(newData);
        })
        .catch((e) => {
          dispatch(setErrorMessage(e, key));
        });
    });
