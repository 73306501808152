import React, { useEffect } from "react";
import { PageHeader, Card } from "antd";
import { Router, useHistory } from "react-router-dom";
import NewTrainingForm from "../../components/adminRole/training/NewTrainingForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrainers,
  getTrainingOptions,
  createTraining,
} from "../../appRedux/actions";
import { createTrainingDate } from "../../appRedux/actions/TrainingDates";

export default function NewTrainingPage(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { trainers, getTrainersLoading } = useSelector(
    ({ trainer }) => trainer
  );
  const { createTrainingLoading } = useSelector(({ training }) => training);
  const { trainingOptions, getTrainingOptionsLoading } = useSelector(
    ({ trainingOption }) => trainingOption
  );

  useEffect(() => {
    dispatch(getTrainers());
    dispatch(getTrainingOptions({ page_size: 100 }));
  }, [dispatch]);

  const dispatchCreateTraining = (data) => {
    dispatch(createTraining(data)).then(
      async (newData) => {
        for (const date of data.dates) {
          await dispatch(
            createTrainingDate({ training_id: newData.id, date: date })
          );
        }
        history.push("/training");
      }
    );
  }

  const handleCreateTraining = (data) => {
    if (data.is_online)
      dispatchCreateTraining({
        ...data,
        is_offline: false,
        price_offline: 0,
        max_participants_offline: 0,
      })
    if (data.is_offline)
      dispatchCreateTraining({
        ...data,
        is_online: false,
        price: data.is_online ? data.price_offline : data.price,
        price_offline: 0,
        max_participants: data.is_online ? data.max_participants_offline : data.max_participants,
      })
  };
  return (
    <>
      <PageHeader
        ghost={false}
        title="Tambah Jadwal Training"
        onBack={() => history.push("/training")}
      />
      <Card bordered={false} style={{ borderRadius: 0 }}>
        <NewTrainingForm
          trainers={trainers}
          trainersLoading={getTrainersLoading}
          trainingOptions={trainingOptions}
          trainingOptionsLoading={getTrainingOptionsLoading}
          onSubmit={handleCreateTraining}
          submitLoading={createTrainingLoading}
        />
      </Card>
    </>
  );
}
