// Import package
import React from "react";
import { toRupiah } from "libs";
import { Modal, Button, Typography, Descriptions, Tag,Alert } from "antd";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

// Import file
export default function PaymentDetail({
  visible,
  onCancel,
  trainingRegistration,
}) {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Detail Pembayaran"
      style={{ top: 10 }}
      destroyOnClose={true}
      footer={null}
    >
      {trainingRegistration.status === "paid_off" ||
      trainingRegistration.is_retraining ? (
        <>
          <center>
            <img
              src="/assets/images/ilustrasi-lunas.png"
              width="300px"
              alt="illustrasi"
            />
          </center>
          <br />
          <center>
            <a
              href={"https://wa.me/6281908191001"}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                onClick={() => document.location.reload(true)}
                type="primary"
              >
                Hubungi Admin
              </Button>
            </a>
          </center>
        </>
      ) : (
        <>
          <Alert message="Mohon agar transfer dengan nominal sama persis dengan nominal di bawah agar dapat diverifikasi secara otomatis oleh sistem kami." type="warning" showIcon />
          <Descriptions size="small" bordered column={1}>
            {trainingRegistration.training.price !==
              trainingRegistration.total_price && (
              <Descriptions.Item label="Harga Normal">
                <Typography.Text
                  delete
                  style={{ display: "block", color: "#f65d6d" }}
                >
                  {toRupiah(trainingRegistration.training.price)}
                </Typography.Text>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Harga Training">
              {toRupiah(trainingRegistration.total_price)}{" "}
              {trainingRegistration.training.price !==
                trainingRegistration.total_price && (
                <Tag color="blue" style={{ margin: 0 }}>
                  discount
                </Tag>
              )}
            </Descriptions.Item>
            {trainingRegistration.status === "waiting_for_paid_off" && (
              <Descriptions.Item label="Sudah Dibayar">
                {toRupiah(trainingRegistration.already_paid)}
              </Descriptions.Item>
            )}
            {trainingRegistration.status !== "paid_off" && (
              <Descriptions.Item label="Kode Unik">
                {"- " + toRupiah(trainingRegistration.transfer_code)}
              </Descriptions.Item>
            )}
            {trainingRegistration.status === "waiting_for_paid_off" && (
              <Descriptions.Item label="Total Belum DiBayar">
                <Typography.Text
                  copyable={{
                    text:
                      trainingRegistration.total_price -
                      trainingRegistration.already_paid -
                      trainingRegistration.transfer_code,
                  }}
                >
                  {toRupiah(
                    trainingRegistration.total_price -
                      trainingRegistration.already_paid -
                      trainingRegistration.transfer_code
                  )}
                </Typography.Text>
              </Descriptions.Item>
            )}
            {trainingRegistration.status !== "paid_off" &&
              trainingRegistration.status !== "waiting_for_paid_off" && (
                <Descriptions.Item label="Total Transfer Full">
                  <Typography.Text
                    copyable={{
                      text:
                        trainingRegistration.total_price -
                        trainingRegistration.transfer_code -
                        trainingRegistration.already_paid,
                    }}
                  >
                    {toRupiah(
                      trainingRegistration.total_price -
                        trainingRegistration.transfer_code -
                        trainingRegistration.already_paid
                    )}
                  </Typography.Text>
                </Descriptions.Item>
              )}
            {trainingRegistration.status !== "paid_off" &&
              trainingRegistration.status !== "waiting_for_paid_off" && (
                <Descriptions.Item>{"Atau"}</Descriptions.Item>
              )}
            {trainingRegistration.status !== "paid_off" &&
              trainingRegistration.status !== "waiting_for_paid_off" && (
                <Descriptions.Item label="Transfer DP">
                  <Typography.Text
                    copyable={{
                      text: 500000 - trainingRegistration.transfer_code,
                    }}
                  >
                    {toRupiah(500000 - trainingRegistration.transfer_code)}
                  </Typography.Text>
                </Descriptions.Item>
              )}
            {trainingRegistration.status !== "paid_off" && (
              <Descriptions.Item label="Transfer Pembayaran">
                <Typography.Text
                  copyable={{
                    text: trainingRegistration.bank_account.number,
                  }}
                >
                  {trainingRegistration.bank_account.name} {trainingRegistration.bank_account.number}
                </Typography.Text>
                {<br />}
                {"A/N "}{trainingRegistration.bank_account.owner_name}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Status">
              <Tag
                icon={
                  trainingRegistration.status === "paid_off" ? (
                    <CheckCircleOutlined />
                  ) : trainingRegistration.status === "canceled" ? (
                    <CloseCircleOutlined />
                  ) : (
                    <ClockCircleOutlined />
                  )
                }
                color={
                  trainingRegistration.status === "paid_off"
                    ? "success"
                    : trainingRegistration.status === "canceled"
                    ? "error"
                    : "default"
                }
                style={{ margin: 0 }}
              >
                {trainingRegistration.status === "paid_off"
                  ? "lunas"
                  : trainingRegistration.status === "canceled"
                  ? "batal"
                  : trainingRegistration.status === "waiting_for_paid_off"
                  ? "belum lunas"
                  : "belum bayar"}
              </Tag>
            </Descriptions.Item>
            {trainingRegistration.status === "waiting_for_down_payment" && (
              <Descriptions.Item label="Batas waktu pelunasan uang muka">
                {trainingRegistration.dp_deadline
                  ? moment(trainingRegistration.dp_deadline).format("LLL")
                  : "-"}
              </Descriptions.Item>
            )}
          </Descriptions>
          {/* <p style={{ marginRight: 10, marginLeft: 10, color: "red" }}>*Mohon agar transfer dengan nominal sama persis dengan nominal di atas agar dapat diverifikasi secara otomatis oleh sistem kami.</p> */}
          <br />
          <center>
            <Button
              onClick={() => document.location.reload(true)}
              type="primary"
            >
              Refresh
            </Button>
          </center>{" "}
        </>
      )}
    </Modal>
  );
}
