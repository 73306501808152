// Import package
import React from "react";
import { List, Tag } from "antd";
import Scrollbars from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import moment from "moment";

// Import file
import { toRupiah } from "../../../libs";

// Component
export default function ParticipantTraining({ participantTrainings = [], handleOnClickLink }) {
  let participantTrainingsSortDesc = participantTrainings.sort(function(a, b) {
    return b.id - a.id
  })
  return (
    <>
      <Scrollbars autoHeight autoHeightMax={455}>
        <List
          dataSource={participantTrainingsSortDesc}
          bordered
          header={"Total " + participantTrainingsSortDesc.length + " training"}
          renderItem={(item) => {
            let listDates = item.training.dates.sort(function(a,b) {
              return new Date(a.date) - new Date(b.date)
            })
            return <List.Item
            extra={
              <Tag
                color={
                  item.is_retraining
                    ? "blue"
                    : item.lack_of_money === 0
                    ? "green"
                    : "error"
                }
                style={{ margin: 0 }}
              >
                {item.is_retraining
                  ? "Mengulang"
                  : item.lack_of_money === 0
                  ? "Lunas"
                  : "Kurang" + toRupiah(item.lack_of_money)}
              </Tag>
            }
          >
            <List.Item.Meta
              title={
                <Link
                  to={{
                    pathname: "/training",
                    state: {
                      fromParticipantPage: true,
                      trainingId: item.training_id,
                    },
                  }}
                  onClick={() => handleOnClickLink({
                    dates: [listDates[0].date, listDates[listDates.length - 1].date],
                    trainingOptionId: item.training.training_option.id
                  })}
                >
                  {item.training.training_option.name}
                </Link>
              }
              description={
                listDates.length > 0
                  ? moment(listDates[0].date).format(
                      "D MMMM YYYY"
                    ) +
                    " - " +
                    moment(
                      listDates[listDates.length - 1].date
                    ).format("D MMMM YYYY")
                  : "-"
              }
            />
          </List.Item>
          }}
        />
      </Scrollbars>
    </>
  );
}
