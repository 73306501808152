// Import package
import React, { Fragment } from "react";
import { Statistic, List, Typography, Row, Col, Card } from "antd";
import { useHistory } from "react-router";
import {
  DollarCircleOutlined,
  UserOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";


// Component
export default function ParticipantAffiliate({ totalPoint, listParticipantUseCode, totalTrainingUsedPoint }) {
  const totalParticipantUseCode = listParticipantUseCode.length
  const history = useHistory()

  const dataParticipantHistoryUseCode = listParticipantUseCode.map(function(participantUseCode) {
    const participant = participantUseCode.participant.name
    const training = participantUseCode.training_registration.training.training_option.name
    return {
      participant: { name: participant },
      training: { name: training },
    }
  })
  return (
    <Fragment>
      <Row gutter={16}>
        <Col md={8} xs={24}>
          <Card size="small">
            <Statistic
              title="Poin Afiliasi"
              value={totalPoint}
              prefix={<DollarCircleOutlined />}
            />
          </Card>
        </Col>
        <Col md={8} xs={24}>
          <Card size="small">
            <Statistic
              title="Jumlah Peserta"
              value={totalParticipantUseCode}
              prefix={<UserOutlined />}
            />
          </Card>
        </Col>
        <Col md={8} xs={24}>
          <Card size="small">
            <Statistic
              title="Jumlah Training"
              value={totalTrainingUsedPoint}
              prefix={<ScheduleOutlined />}
            />
          </Card>
        </Col>
      </Row>
      <Typography.Title level={5}>Riwayat</Typography.Title>
      <Scrollbars autoHeight>
        <List
          bordered
          dataSource={dataParticipantHistoryUseCode}
          renderItem={(item) => (
            <List.Item
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/participant/" + item.participant.id)}
            >
              <List.Item.Meta
                title={
                  <Typography.Link>{item.participant.name}</Typography.Link>
                }
                description={item.training.name}
              />
            </List.Item>
          )}
        />
      </Scrollbars>
    </Fragment>
  );
}
