// Import package
import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";

// Import file
import TrainingRegistrationFilter from "../../../../components/adminRole/trainingRegistration/TrainingRegistrationsFilter";
import { getTrainingOptions, exportTrainingRegistCsv } from "../../../../appRedux/actions";

export default function TrainingRegistrationsFilterContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showExportTrainigRegist, setShowExportTrainigRegist] =
    useState(false);
  const { trainingOptions, getTrainingOptionsLoading } = useSelector(
    ({ trainingOption }) => trainingOption
  );
  let filter = queryString.parse(useLocation().search);
  filter["page_size"] = filter.page_size ? parseInt(filter.page_size) : 6;
  filter["current_page"] = filter.current_page
    ? parseInt(filter.current_page)
    : 1;
  filter["search"] = filter.search ? filter.search : "";
  filter["status"] = filter.status ? filter.status : null;
  filter["training_option_id"] = filter.training_option_id
    ? parseInt(filter.training_option_id)
    : null;
  filter["is_retraining"] = filter.is_retraining ? filter.is_retraining : null;
  filter["month"] = filter.month ? filter.month : null;
  filter["location"] = filter.location ? filter.location : null
  filter["is_online"] = filter.is_online ? filter.is_online : null
  useEffect(() => {
    dispatch(getTrainingOptions({ page_size: 100 }));
  }, [dispatch]);
  const handleSearch = (v) => {
    filter["search"] = v;
    filter["current_page"] = 1;
    history.push("/training-registration?" + queryString.stringify(filter));
  };
  const handleChangeIsRetraining = (isRetraining) => {
    filter["is_retraining"] = isRetraining;
    filter["current_page"] = 1;
    history.push("/training-registration?" + queryString.stringify(filter));
  };
  const handleChangeStatus = (status) => {
    filter["status"] = status;
    filter["current_page"] = 1;
    history.push("/training-registration?" + queryString.stringify(filter));
  };
  const handleChangeTrainingOption = (training_option_id) => {
    filter["training_option_id"] = training_option_id;
    filter["current_page"] = 1;
    history.push("/training-registration?" + queryString.stringify(filter));
  };
  const handleChangeMonth = (v) => {
    filter["month"] = v ? v.toISOString() : null;
    filter["current_page"] = 1;
    history.push("/training-registration?" + queryString.stringify(filter));
  };
  const handleReset = () => history.push("/training-registration");

  const handleValueChangeTrainingType = (v) => {
    console.log(v)
    if (v === "1") {
      filter["is_online"] = null;
      filter["location"] = null
    } else if (v === "2") {
      filter["is_online"] = true;
      filter["location"] = null
    } else if (v === "3") {
      filter["is_online"] = false;
    }
    history.push("/training-registration?" + queryString.stringify(filter))
  }

  let valueChangeTrainingType = "1"

  return (
    <>
    <Row>
    <TrainingRegistrationFilter
      trainingOptions={trainingOptions}
      trainingOptionsLoading={getTrainingOptionsLoading}
      onSearch={handleSearch}
      onChangeIsRetraining={handleChangeIsRetraining}
      onChangeMonth={handleChangeMonth}
      onChangeStatus={handleChangeStatus}
      onChangeTrainingOption={handleChangeTrainingOption}
      status={filter.status ? filter.status : null}
      trainingOption={
        filter.training_option_id ? parseInt(filter.training_option_id) : null
      }
      isRetraining={
        filter.is_retraining
          ? filter.is_retraining === "true"
            ? true
            : false
          : null
      }
      search={filter.search ? filter.search : ""}
      month={filter.month ? moment(filter.month) : null}
      onReset={handleReset}
      enableReset={
        filter.page_size !== 6 ||
        filter.current_page !== 1 ||
        filter.search !== "" ||
        filter.status !== null ||
        filter.training_option_id !== null ||
        filter.month !== null ||
        filter.is_retraining !== null ||
        filter.is_online !== null ||
        filter.location !== null
      }
      valueChangeTrainingType={filter.is_online === null && filter.location === null ?
         valueChangeTrainingType = "1"
      : filter.is_online && filter.location === null ?
        valueChangeTrainingType = "2"
      : filter.is_online !== true ?
        valueChangeTrainingType = "3"
      : valueChangeTrainingType}
      onChangeValueChangeTrainingType={handleValueChangeTrainingType}
    />
    <Modal
    visible={showExportTrainigRegist}
    title="Expor"
    onCancel={() => setShowExportTrainigRegist(false)}
    onOk={() => dispatch(exportTrainingRegistCsv()).then((url) => {
      window.open(url, "_blank")
      setShowExportTrainigRegist(false)
      })}>
      <p>Apakah anda yakin ingin expor registrasi training?</p>
    </Modal>
    <Button
      type="primary"
      onClick={
        () => setShowExportTrainigRegist(true)
      }
    >
      Export Registrasi Training
    </Button>
    </Row>
  </>
  );
}
