import React, { useState } from 'react'
import { Space, Select, Row, Col, DatePicker } from "antd";
import moment from 'moment';
const { RangePicker } = DatePicker;


export default function FilterBarChartParticipant({ value, onChange, locations }) {
  const [dates, setDates] = useState([moment().startOf('month'), moment().endOf('month')])
  const [filter, setFilter] = useState(value)

  let options = [
    { value: "1", label: "Semua Lokasi" },
    { value: "2", label: "Online" },
    { value: "3", label: "Offline" },
  ]
  if (locations)
    locations.map(location => {
      options.push({ value: location, label: location })
    })

  return (
    <Row wrap gutter={[16, 16]} justify="space-between">
      <Col>
        <Space wrap>
          <RangePicker
            value={dates}
            onChange={(values) => {
              setDates(values)
              onChange(filter, values)
            }}
          />
          <Select
            value={filter}
            dropdownMatchSelectWidth={true}
            onChange={(v) => {
              setFilter(v)
              onChange(v, dates)
            }}
            style={{ borderRadius: 0, width: 200 }}
            options={options}
          >
            {/* <Select.Option value="1">Semua peserta</Select.Option>
            <Select.Option value="2">Peserta online</Select.Option>
            <Select.Option value="3">Peserta offline</Select.Option> */}
          </Select>
        </Space>
      </Col>
      <Col></Col>
    </Row>
  )
}
