// Import package
import React, { useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, message, PageHeader, Card } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

// Import file
import { getTraining } from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import TrainingParticipantContainer from "../../containers/adminRole/trainingParticipant/TrainingParticipantContainer";
import TrainingActionsContainer from "../../containers/adminRole/training/TrainingActionsContainer";
import TrainingDescriptionContainer from "../../containers/adminRole/training/TrainingDescriptionContainer";
import PageContentWrapper from "../../components/PageContentWrapper";
import TrainingDetail from "../../components/trainerRole/TrainingDetail";

const AdminRolePage = () => {
  const { trainingId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const history = useHistory();
  const { training, trainingLoading } = useSelector(({ training }) => training);
  const activeTab = query.get("tab") || "description";
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  useEffect(() => {
    dispatch(getTraining(trainingId));
  }, [dispatch]);
  useEffect(() => {
    if (training && training.dates.length < 1) {
      message.warning(
        "Mohon segera atur tanggal pelaksanaan untuk training ini"
      );
    }
  }, [training]);
  const handleTabsChange = (key) =>
    history.push("/training/" + trainingId + "?tab=" + key);
  return trainingLoading ? (
    <CircularProgress />
  ) : training ? (
    <>
      <PageContentWrapper
        title={
          !screens.xs
            ? training.training_option.name
            : training.training_option.name.split(" ")[0]
        }
        subTitle={!screens.xs && training.dates_summary}
        backLink={"/training"}
        extra={<TrainingActionsContainer />}
        footer={
          <Tabs onChange={handleTabsChange} activeKey={activeTab}>
            <Tabs.TabPane tab="Deskripsi" key="description" />
            <Tabs.TabPane tab="Peserta" key="participants" />
          </Tabs>
        }
      >
        {activeTab === "description" && (
          <TrainingDescriptionContainer trainingId={trainingId} />
        )}
        {activeTab === "participants" && (
          <TrainingParticipantContainer trainingId={training.id} />
        )}
      </PageContentWrapper>
    </>
  ) : (
    "tidak ditemukan"
  );
};

const TrainerRolePage = () => {
  const dispatch = useDispatch();
  const { trainingId } = useParams();
  const { training, trainingLoading } = useSelector(({ training }) => training);
  const screens = useBreakpoint();
  useEffect(() => {
    dispatch(getTraining(trainingId));
  }, [dispatch]);
  return training && !trainingLoading ? (<>
    <Card
        bordered={false}
        size="small"
        style={{ boxShadow: "0 0 5px 5px rgba(0,0,0,0.03)", padding: "10px" }}
      >
        <PageHeader
          title={training.training_option.name}
          onBack={() => window.history.back()}
          style={{ padding: "10px" }}
        />
        <TrainingDetail training={training} />
      </Card>
         </>
  ) : trainingLoading ? (
    <CircularProgress />
  ) : (
    "Kosong"
  );
};

// Component
export default function DetailTrainingPage() {
  const { authUser } = useSelector(({ auth }) => auth);
  return authUser.role === "admin" ? <AdminRolePage /> : <TrainerRolePage />;
}
