// Import package
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Import file
import {
  createDiscount,
  updateDiscount,
  deleteDiscountParticipant,
  deleteDiscountTraining,
  createDiscountParticipant,
  createDiscountTraining,
  createDiscountTrainingOption,
  getTrainings,
  getTrainingOptions,
  getParticipants,
  deleteDiscountTrainingOption,
} from "../../../../appRedux/actions/index.js";
import DiscountForm from "../../../../components/adminRole/discount/DiscountForm";

// Component
export default function DiscountFormContainer({
  discount,
  discountParticipants,
  discountTrainings,
  discountTrainingOptions,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    for_specific_participants: false,
    for_specific_trainings: false,
    for_specific_training_options: false,
    for_specific_training_categories: false,
    for_specific_training_types: false,
  });

  const { trainings, getTrainingsLoading, totalTrainings } = useSelector(
    ({ training }) => training
  );

  const { participants, participantsLoading, totalParticipants } = useSelector(
    ({ participant }) => participant
  );
  const { trainingOptions, getTrainingOptionsLoading } = useSelector(
    ({ trainingOption }) => trainingOption
  );

  const [searchTraining, setSearchTraining] = useState("");
  const [searchParticipants, setSearchParticipants] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
    dispatch(getParticipants({ page_size: 10, search: searchParticipants }));
    dispatch(
      getParticipants({
        page_size: totalParticipants,
        search: searchParticipants,
      })
    );
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [searchParticipants, totalParticipants]);

  useEffect(() => {
    dispatch(getTrainingOptions({ page_size: 100 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getTrainings({
        page_size: 10,
        current_page: 1,
        search: searchTraining,
      })
    );
    dispatch(
      getTrainings({
        page_size: totalTrainings,
        current_page: 1,
        search: searchTraining,
      })
    );
  }, [searchTraining, totalTrainings]);

  const handleSearchParticipant = (value) => {
    setSearchParticipants(value);
  };

  const handleSearchTraining = (v) => {
    setSearchTraining(v);
  };

  const handleChangeForSpecifics = (allValues) => {
    setData({
      ...data,
      for_specific_participants: allValues.for_specific_participants,
      for_specific_trainings: allValues.for_specific_trainings,
      for_specific_training_options: allValues.for_specific_training_options
    });
  };

  const handleSubmit = (values) => {
    if (!discount) {
      dispatch(createDiscount(values))
        .then((newData) => {
          if (data.for_specific_participants) {
            values.specific_participants.map((id) =>
              dispatch(
                createDiscountParticipant({
                  ...values,
                  discount_id: newData.id,
                  participant_id: id,
                })
              )
            );
          }
          if (data.for_specific_trainings) {
            values.specific_trainings.map((id) =>
              dispatch(
                createDiscountTraining({
                  ...values,
                  discount_id: newData.id,
                  training_id: id,
                })
              )
            );
          }
          if (data.for_specific_training_options) {
            values.specific_training_options.map((id) =>
              dispatch(
                createDiscountTrainingOption({
                  ...values,
                  discount_id: newData.id,
                  training_option_id: id,
                })
              )
            );
          }
        })
        .then(() => history.push("/discount"));
    } else {
      dispatch(
        updateDiscount({
          ...values,
          id: discount.id,
          percent: values.is_percent ? values.percent : null,
          price: !values.is_percent ? values.price : null,
        })
      )
        .then(() => {
          discountParticipants.map( async (dp) =>
            dp.id !== undefined ||
            dp.id !== null ||
            values.for_specific_participants === false ||
            values.for_specific_participants === true
              ? await dispatch(deleteDiscountParticipant(dp.id))
              : null
          );
          discountTrainings.map( async (dt) =>
            dt.id !== undefined ||
            dt.id !== null ||
            values.for_specific_trainings === false ||
            values.for_specific_trainings === true
              ? await dispatch(deleteDiscountTraining(dt.id))
              : null
          );
          discountTrainingOptions.map( async (dt) =>
            dt.id !== undefined ||
            dt.id !== null ||
            values.for_specific_training_options === false ||
            values.for_specific_training_options === true
              ? await dispatch(deleteDiscountTrainingOption(dt.id))
              : null
          );
          if (values.for_specific_participants) {
            setTimeout(() =>
              values.specific_participants.map((id) =>
                dispatch(
                  createDiscountParticipant({
                    ...values,
                    discount_id: discount.id,
                    participant_id: id,
                  })
                )
              )
            );
          }
          if (values.for_specific_trainings) {
            setTimeout(() =>
              values.specific_trainings.map((id) =>
                dispatch(
                  createDiscountTraining({
                    ...values,
                    discount_id: discount.id,
                    training_id: id,
                  })
                )
              )
            );
          }
          if (values.for_specific_training_options) {
            setTimeout(() =>
              values.specific_training_options.map((id) =>
                dispatch(
                  createDiscountTrainingOption({
                    ...values,
                    discount_id: discount.id,
                    training_option_id: id,
                  })
                )
              )
            );
          }
        })
        .then(() => history.push("/discount"));
    }
  };

  return (
    <DiscountForm
      discount={discount}
      onChangeForSpecifics={handleChangeForSpecifics}
      participants={participants}
      participantsLoading={participantsLoading}
      trainingOptions={trainingOptions}
      trainingOptionsLoading={getTrainingOptionsLoading}
      trainings={trainings}
      trainingsLoading={getTrainingsLoading}
      searchParticipant={handleSearchParticipant}
      searchTraining={handleSearchTraining}
      onSubmit={handleSubmit}
      discountParticipants={discountParticipants}
      discountTrainings={discountTrainings}
      discountTrainingOptions={discountTrainingOptions}
    />
  )
}
