import React, { useEffect } from "react";
import DashboardBarChart from "../../../components/adminRole/dashboard/DashboardBarChart";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardTrainingRegistCancelGraph } from "../../../appRedux/actions";
import FilterBarChartParticipant from "../../../components/adminRole/dashboard/FilterBarChartParticipant";


export default function BarChartRegisterCancelThisMonth({ locations }) {
  const dispatch = useDispatch();
  const { trainingRegistCancelGraph, trainingRegistCancelGraphFilter } =
    useSelector(({ dashboard }) => dashboard);

  useEffect(() => {
    dispatch(
      getDashboardTrainingRegistCancelGraph(trainingRegistCancelGraphFilter)
    );
  }, []);

  const handleChangeType = (filter, dates) => {
    dispatch(getDashboardTrainingRegistCancelGraph(filter, dates));
  };

  const getRegisterCancelData = () =>
  trainingRegistCancelGraph.map(function (register) {
      return {
        name: register.training_option_name,
        pendaftar_cancel: register.total_register,
      };
    });

  return (
    <>
      <FilterBarChartParticipant
        value={trainingRegistCancelGraphFilter}
        onChange={handleChangeType}
        locations={locations}
      />
      <DashboardBarChart
        barKey="pendaftar_cancel"
        fillColor="#ed7e89"
        data={getRegisterCancelData()}
        barName="Pendaftar Cancel"
      />
    </>
  )
}
