// Import package
import React, { useState } from "react";
import { PageHeader, Button, Card, Space } from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import queryString from 'query-string';
import {useLocation} from 'react-router-dom';

// Import file
import TrainingOptionsListFilterContainer from "../../containers/adminRole/trainingOption/TrainingOptionsListFilterContainer";
import TrainingOptionsListContainer from "../../containers/adminRole/trainingOption/TrainingOptionsListContainer";

export default function TrainingOptionPage() {
  let filter = queryString.parse(useLocation().search);
  return (
    <>
      <PageHeader
        ghost={false}
        title="Pilihan Training"
        extra={
          <Link to="/training-option/add">
            <Button
              style={{ margin: 0 }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Tambah
            </Button>
          </Link>
        }
      />
      <Card bordered={false} style={{ borderRadius: 0 }}>
        <Space style={{ width: "100%" }} direction="vertical">
          <TrainingOptionsListFilterContainer />
          <TrainingOptionsListContainer searchValue={filter.search} />
        </Space>
      </Card>
    </>
  );
}
