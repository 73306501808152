import React from "react";
import { List, Avatar, Dropdown, Menu, Tag } from "antd";
import {
  UserOutlined,
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { toRupiah } from "../../../../libs";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

export default function TrainingParticipantsList({
  participants = [],
  loading = false,
  onDelete,
  onSetPaid,
  onEdit,
  onDownloadCertificate,
}) {
  const screens = useBreakpoint();
  return (
    <List
      loading={loading}
      dataSource={participants}
      renderItem={(item) => (
        <List.Item
          actions={[
            !screens.xs && (
              <Tag
                color={
                  item.is_retraining
                    ? "blue"
                    : item.lack_of_money === 0
                    ? "green"
                    : "error"
                }
                style={{ margin: 0 }}
              >
                {item.is_retraining
                  ? "Mengulang"
                  : item.lack_of_money === 0
                  ? "Lunas"
                  : "Kurang " + toRupiah(item.lack_of_money)}
              </Tag>
            ),
            <Dropdown
              style={{ margin: 0 }}
              overlay={
                <Menu>
                  <Menu.Item
                    key="download_certificate"
                    icon={<DownloadOutlined />}
                    onClick={() => onDownloadCertificate(item.id)}
                  >
                    Unduh sertifikat
                  </Menu.Item>
                  <Menu.Item
                    key="edit"
                    icon={<EditOutlined />}
                    onClick={() => onEdit(item)}
                  >
                    Edit
                  </Menu.Item>
                  {item.lack_of_money > 0 && !item.retraining && (
                    <Menu.Item
                      key="paid"
                      icon={<CheckOutlined />}
                      onClick={() => onSetPaid(item)}
                    >
                      Tandai sudah lunas
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="delete"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => onDelete(item.id)}
                  >
                    Hapus
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              trigger={["click"]}
            >
              <i className="gx-icon-btn icon icon-ellipse-v" />
            </Dropdown>,
          ]}
        >
          <List.Item.Meta
            avatar={
              <Avatar
                icon={<UserOutlined />}
                shape="square"
                style={{ margin: 0 }}
              />
            }
            title={
              <Link to={"/participant/" + item.participant_id}>
                {item.participant.name}
              </Link>
            }
            description={
              screens.xs ? (
                <Tag
                  color={
                    item.is_retraining
                      ? "blue"
                      : item.lack_of_money === 0
                      ? "green"
                      : "error"
                  }
                  style={{ margin: 0 }}
                >
                  {item.is_retraining
                    ? "Mengulang"
                    : item.lack_of_money === 0
                    ? "Lunas"
                    : "Kurang " + toRupiah(item.lack_of_money)}
                </Tag>
              ) : (
                item.participant.email
              )
            }
          />
        </List.Item>
      )}
    />
  );
}
