import { v4 } from "uuid";
import { baseRequest } from "../../util/Api";

export const createTask = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    baseRequest("post", "/tasks/", null, data)
      .then((newData) => {
        dispatch(monitorTask(newData.id))
          .then((r) => resolve(r))
          .catch((e) => reject(e));
      })
      .catch((e) => {
        reject(e);
      });
  });

export const monitorTask = (taskId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const key = v4();
    baseRequest("get", "/tasks/" + taskId)
      .then((data) => {
        if (!data.loading) {
          if (data.success) {
            resolve(data.result);
          } else {
            reject(
              data.error
                ? data.error
                : "tugas gagal ditengah jalan tanpa alasan"
            );
          }
        } else {
          setTimeout(() => {
            return dispatch(monitorTask(taskId))
              .then((r) => resolve(r))
              .catch((e) => reject(e));
          }, 1000);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
