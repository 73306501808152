// Import package
import React from "react";
import { Card, Space, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";

// Import file
import TrainingCalendarContainer from "../../containers/trainerRole/TrainingCalendarContainer";
import TrainingCalendarAdminContainer from "../../containers/adminRole/TrainingCalendarAdminContainer";

// Component
export default function TrainingCalendarPage() {
  const { authUser } = useSelector(({ auth }) => auth)
  return (
    <>
    {authUser.role === "admin" && (
        <TrainingCalendarAdminContainer/>
      )}
      {authUser.role === "trainer" && (
        <TrainingCalendarContainer />
      )}
    </>
  );
}
