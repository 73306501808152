// Import package
import React from "react";
import { Link } from "react-router-dom";
import { List, Menu, Dropdown } from "antd";
import { DeleteOutlined, KeyOutlined, EditOutlined } from "@ant-design/icons";

// Component
export default function TrainerList({
  loading = false,
  trainerList,
  onEdit,
  onDelete,
  onChangePass,
}) {
  return (
    <List
      itemLayout="horizontal"
      dataSource={trainerList}
      loading={loading}
      size="small"
      renderItem={(item) => (
        <List.Item
          actions={[
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="ubah password"
                    onClick={() => onChangePass(item.id)}
                    icon={<KeyOutlined />}
                  >
                    Ubah password
                  </Menu.Item>
                  <Menu.Item
                    key="edit"
                    onClick={() => onEdit(item.id)}
                    icon={<EditOutlined />}
                  >
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => onDelete(item.id)}
                    key="0"
                    icon={<DeleteOutlined />}
                    danger
                  >
                    Hapus
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              trigger={["click"]}
            >
              <i className="gx-icon-btn icon icon-ellipse-v" />
            </Dropdown>,
          ]}
        >
          <Link to={"/trainer/" + item.id}>{item.name}</Link>
        </List.Item>
      )}
    />
  );
}
