import React from "react";
import { Select } from "antd";

export default function TrainingOptionsSelect({
  trainingOptions = [],
  selectedTrainingOption,
  onSelect,
  loading = false,
}) {
  return (
    <Select
      defaultValue="all"
      showSearch
      optionFilterProp="label"
      loading={loading}
      onChange={onSelect}
      value={selectedTrainingOption}
      dropdownMatchSelectWidth={false}
      style={{ width: 200 }}
    >
      <Select.Option value="all" label="semua">
        Semua Jenis Training
      </Select.Option>
      {trainingOptions.map((t) => (
        <Select.Option key={t.id} value={t.id} label={t.name}>
          {t.name}
        </Select.Option>
      ))}
    </Select>
  );
}
