// Import package
import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

// Import file
import ParticipantsListContainer from "../../containers/adminRole/participant/ParticipantsListContainer";
import PageContentWrapper from "../../components/PageContentWrapper";
import ParticipantSearchContainer from "../../containers/adminRole/participant/ParticipantSearchContainer";

// Component
export default function ParticipantPage() {
  return (
    <PageContentWrapper
      title="Peserta"
      extra={
        <Link to="/participant/add">
          <Button icon={<PlusOutlined />} type="primary" style={{ margin: 0 }}>
            Tambah
          </Button>
        </Link>
      }
    >
      <ParticipantSearchContainer />
      <ParticipantsListContainer />
    </PageContentWrapper>
  );
}
