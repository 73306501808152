import React, { useEffect } from "react";
import MutationsList from "../../../../components/adminRole/mutation/MutationsList";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

export default function MutationsListContainer() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  // const mutationsLoading = false;
  let filter = queryString.parse(location.search);
  filter["page_size"] = filter.page_size ? parseInt(filter.page_size) : 8;
  filter["current_page"] = filter.current_page
    ? parseInt(filter.current_page)
    : 1;
  const {
    bankTransactions,
    bankTransactionsLoading,
    totalBankTransactions,
  } = useSelector(({ bankTransaction }) => bankTransaction);
  // useEffect(() => {
  //   dispatch(getTrainingRegistrations());
  // }, [dispatch]);
  const handleChangePagination = (current, size) => {
    filter["current_page"] = current;
    filter["page_size"] = size;
    history.push("/mutation?" + queryString.stringify(filter));
  };

  return (
    <MutationsList
      mutations={bankTransactions}
      loading={bankTransactionsLoading}
      pageSize={filter.page_size}
      currentPage={filter.current_page}
      onChangePagination={handleChangePagination}
      totalMutations={totalBankTransactions}
    />
  );
}
