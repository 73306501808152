// Import package
import React from "react";
import { Dropdown, Menu, Modal } from "antd";
import {
  PrinterOutlined,
  CalendarOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";

// Import file
import {
  deleteTraining,
  getTrainingAttendance,
  cancelTraining,
  enableTraining,
  getTrainingCertificates,
  rescheduleTraining,
} from "../../../../appRedux/actions";
import TrainingsListItemActions from "../../../../components/adminRole/training/TrainingsListItemActions";
import { useHistory } from "react-router-dom";

export default function TrainingsListItemActionsContainer({
  trainingId,
  isRunning = true,
  cancelable = true,
  downloadAttendance = true,
  downloadCertificates = true,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const onDelete = () => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin menghapus training ini ?",
      onOk: () => dispatch(deleteTraining(trainingId)),
    });
  };
  const onCancel = () => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin membatalkan training ini ?",
      onOk: () => {
        Modal.confirm({
          title: "Kirim pemberitahuan",
          content:
            "Apakah sistem perlu untuk mengirim pemberitahuan tentang pembatalan ini ke seluruh peserta terdaftar ?",
          cancelText: "Tidak",
          okText: "Iya",
          onOk: () => dispatch(cancelTraining(trainingId, true)),
          onCancel: () => dispatch(cancelTraining(trainingId, false)),
        });
      },
    });
  };
  const onEnable = () => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin mengaktifkan kembali training ini ?",
      onOk: () => dispatch(enableTraining(trainingId)),
    });
  };
  const onDownloadAttendance = () => {
    dispatch(getTrainingAttendance(trainingId)).then((url) =>
      window.open(url, "_blank")
    );
  };
  const handleDownloadCertificates = () => {
    dispatch(getTrainingCertificates(trainingId)).then((url) =>
      window.open(url, "_blank")
    );
  };
  const handleReschedule = () => {
    history.push("/training/" + trainingId + "/reschedule");
  };
  const handleEdit = () => history.push("/training/" + trainingId + "/edit");
  return (
    <TrainingsListItemActions
      onCancel={onCancel}
      onDownloadAttendance={onDownloadAttendance}
      cancelable={cancelable}
      downloadAttendance={downloadAttendance}
      downloadCertificates={downloadCertificates}
      onDelete={onDelete}
      isRunning={isRunning}
      onEnable={onEnable}
      onDownloadCertificates={handleDownloadCertificates}
      onEdit={handleEdit}
      onReschedule={handleReschedule}
    />
  );
}
