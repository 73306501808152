// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { Modal } from "antd";

// Import file
import {
  getTrainingOptions,
  deleteTrainingOption,
} from "../../../../appRedux/actions";
import TrainingOptionsList from "../../../../components/adminRole/trainingOption/TrainingOptionsList";


export default function TrainingOptionsListContainer({ searchValue }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    trainingOptions,
    getTrainingOptionsLoading,
    totalTrainingOptions,
  } = useSelector(({ trainingOption }) => trainingOption);
  // useEffect(() => {
  //   dispatch(getTrainingOptions({ page_size: 100 }));
  // }, [dispatch]);
  let filter = queryString.parse(useLocation().search);
  const handleChangePagination = (page, pageSize) => {
    filter["current_page"] = page;
    filter["page_size"] = pageSize;
    history.push("/training-option?" + queryString.stringify(filter));
  };
  const handleDelete = (trainingOptionId) =>
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin menghapus pilihan training ini ?",
      onOk: () =>
        dispatch(deleteTrainingOption(trainingOptionId)).then(() =>
          dispatch(getTrainingOptions(filter))
        ),
    });

  return (
    <TrainingOptionsList
      trainingOptions={trainingOptions}
      loading={getTrainingOptionsLoading}
      searchValue={searchValue}
      totalTrainingOptions={totalTrainingOptions}
      onChangePagination={handleChangePagination}
      currentPage={filter.current_page ? parseInt(filter.current_page) : 1}
      pageSize={filter.page_size ? parseInt(filter.page_size) : 5}
      onDelete={handleDelete}
    />
  );
}
