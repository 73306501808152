import React from "react";
import {
  Form,
  Select,
  InputNumber,
  Space,
  Button,
  Switch,
  Input,
  Alert,
  Radio
} from "antd";
import { currencyFormatter, currencyParser } from "../../../../libs";
import { Link } from "react-router-dom";

export default function TrainingParticipantForm({
  participants = [],
  training,
  participantsLoading = false,
  searchParticipants,
  existParticipants = [],
  onSubmit,
  onCancel,
  update = false,
  trainingParticipant,
}) {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={
        !update
          ? {
            is_retraining: false,
            lack_of_money: 0,
            paid: true,
            send_message: true,
            total_price: training.price,
            is_corporate_participant: false,
            note: "",
            retraining_with_meal: false,
            retraining_received_kit: true,
          }
          : {
            ...trainingParticipant,
            send_message: true,
            paid: trainingParticipant.lack_of_money === 0,
          }
      }
      onFinish={(v) => {
        if (!update) {
          onSubmit(v)
        } else {
          onSubmit({ ...v, lack_of_money: v.paid ? 0 : v.lack_of_money })
        }
      }
      }
    >
      {update && (
        <>
          <br />
          <p style={{ color: "red" }}>*Silahkan hapus data peserta lalu tambah ulang jika ingin mengirim pesan email dan WA ke peserta</p>
          <br />
          <Form.Item label="Nama peserta">
            <Input disabled value={trainingParticipant.participant.name} />
          </Form.Item>
          <Form.Item name="participant_id" noStyle>
            <Input type="hidden" />
          </Form.Item>
        </>
      )}
      {!update && (
        <Form.Item
          name="participant_id"
          label="Nama peserta"
          rules={[{ required: true, message: "Mohon pilih peserta" }]}
        >
          <Select
            showSearch
            placeholder="ketikkan nama peserta"
            defaultActiveFirstOption={false}
            filterOption={false}
            loading={participantsLoading}
            onSearch={(v) => searchParticipants(v)}
            style={{ width: "100%" }}
            autoFocus
          >
            {participants.map((p) => (
              <Select.Option
                key={p.id}
                value={p.id}
                disabled={existParticipants.find(
                  (par) => par.participant_id === p.id
                )}
              >
                {p.name + ` (${p.email})`} {p.whatsapp_number === null ? "- (Peserta tidak memiliki nomor whatsapp)" : ""}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        name="is_retraining"
        label="Peserta mengulang"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() =>
          !update && (
            <Form.Item
              name="send_message"
              label="Kirim pesan email dan WA ke peserta ( hanya untuk lunas dp dan lunas total )"
            >
              <Radio.Group>
                <Radio.Button value={true}>Ya</Radio.Button>
                <Radio.Button value={false}>Tidak</Radio.Button>
              </Radio.Group>
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() =>
          !update && !form.getFieldValue("is_retraining") && (
            <Form.Item
              label="Total harga"
              name="total_price"
              rules={[{ required: true, message: "Mohon isi harga total" }]}
            >
              <InputNumber
                formatter={currencyFormatter}
                parser={currencyParser}
                style={{ width: "100%" }}
                min={0}
                step={100000}
              />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() =>
          !form.getFieldValue("is_retraining") && (
            <>
              <Form.Item name="paid" label="Lunas" valuePropName="checked">
                <Switch />
              </Form.Item>
            </>
          )
        }
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() => form.getFieldValue("paid") && !form.getFieldValue("is_retraining") && !update && (
          <Form.Item
            name="is_corporate_participant"
            label="Peserta Corporate / Perusahaan"
          >
            <Radio.Group>
              <Radio.Button value={true}>Ya</Radio.Button>
              <Radio.Button value={false}>Tidak</Radio.Button>
            </Radio.Group>
          </Form.Item>
        )
        }
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() =>
          !form.getFieldValue("is_retraining") &&
          !form.getFieldValue("paid") && (
            <Form.Item
              name="lack_of_money"
              label="Kekurangan uang"
              rules={[{ type: "number", required: true, min: 0 }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                formatter={currencyFormatter}
                parser={currencyParser}
                max={training.price}
                step={100000}
              />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() =>
          form.getFieldValue("lack_of_money") > training.price - 500000 &&
          !form.getFieldValue("is_retraining") &&
          !form.getFieldValue("paid") && (
            <Form.Item rules={[{ required: true, message: "Mohon diisi" }]}>
              <Alert
                message={
                  <p>
                    Tidak dapat menambahkan peserta yang belum bayar DP.
                    Silahkan tambahkan lewat menu{" "}
                    <Link to="/training-registration">berikut</Link>
                  </p>
                }
                type="error"
              />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() => form.getFieldValue("is_retraining") && (<>
          <Form.Item name="retraining_with_meal" label="Makan">
            <Radio.Group>
              <Radio.Button value={true}>Dengan Makan</Radio.Button>
              <Radio.Button value={false}>Tanpa Makan</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="retraining_received_kit" label="Training Kit">
            <Radio.Group>
              <Radio.Button value={true}>Sudah Dapat</Radio.Button>
              <Radio.Button value={false}>Belum Dapat</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </>
        )}
      </Form.Item>
      <Form.Item name="note" label="Catatan">
        <Input.TextArea />
      </Form.Item>
      <Form.Item>
        <center>
          <Space>
            <Button onClick={onCancel}>Batal</Button>
            <Button type="primary" htmlType="submit">
              Simpan
            </Button>
          </Space>
        </center>
      </Form.Item>
    </Form>
  );
}
