// Import Package
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";

// Import file
import {
  getTrainingCategories,
  deleteTrainingCategory,
} from "../../../../appRedux/actions";
import TrainingCategoryList from "../../../../components/adminRole/trainingCategory/TrainingCategoryList";

// Component
export default function TrainingCategoriesListContainer() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { trainingCategories } = useSelector(
        ({ trainingCategory }) => trainingCategory
    )

    useEffect(() => {
        dispatch(getTrainingCategories())
    }, [])

    const handleDelete = (id) => {
        Modal.confirm({
            title: "Konfirmasi",
            content: "Apakah anda yakin ingin menghapus ini ?",
            onOk: () => 
              dispatch(deleteTrainingCategory(id)).then(() => {
                dispatch(getTrainingCategories());
              }),
          });
    }

    const handleEdit = (id) => {
        history.push("/training-category/" + id + "/edit")
    }
    return (
        <>
            <TrainingCategoryList
                trainingCategories={trainingCategories}
                onEdit={handleEdit}
                onDelete={handleDelete}
            />
        </>
    )
}