import { Table, Tag, Space } from "antd";
import { Link } from "react-router-dom";

const columns = () => {
  let result = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => index + 1,
      align: "center",
      width: 10,
    },
    {
      title: "Nama Peserta",
      dataIndex: "participant",
      key: "participant",
      render: (participant, field) => participant.name
    },
    {
      title: "Training",
      dataIndex: "training_registration",
      key: "training_registration",
      render: (trainingRegistration) =>
        trainingRegistration.training.training_option.name,
    },
    {
      title: "Tipe Training",
      dataIndex: "training_registration",
      render: (trainingRegistration) => (
        <>
          <Space>
            {trainingRegistration.training.is_online ? (
              <Tag color="green" style={{ margin: 0 }}>
                ONLINE
              </Tag>
            ) : (
              <Tag color="blue" style={{ margin: 0 }}>
                OFFLINE
              </Tag>
            )}
          </Space>
        </>
      ),
    },
    {
      title: "Jadwal",
      dataIndex: "training_registration",
      key: "training_registration",
      render: (trainingRegistration) =>
        trainingRegistration.training.dates_summary,
    },
  ];
  return result;
};

export default function AffiliateParticipantHistoryList({
  affiliateParticipantsHistory = [],
  loading = false,
  totalTrainings = 0,
  onChangePagination,
  pageSize = 10,
  currentPage = 1,
}) {
  return (
    <Table
      loading={loading}
      size="small"
      columns={columns()}
      dataSource={affiliateParticipantsHistory.map((aph) => ({
        ...aph,
        key: aph.id,
      }))}
      pagination={false}
    />
  );
}
