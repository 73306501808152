import React, { useEffect, useState } from "react";
import { Button, Alert, Form, Input } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resendVerification, userSignUp } from "../appRedux/actions";
import ReCAPTCHA from "react-google-recaptcha";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

import {
  FETCH_ERROR
} from "../constants/ActionTypes";

const FormItem = Form.Item;

const ResendVerification = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.authUser);
  const recaptchaRef = React.createRef();

  const onFinishFailed = (errorInfo) => { };

  const onFinish = (values) => {
    values.recaptcha = recaptchaRef.current.getValue();
    if (!values.recaptcha) {
      dispatch({ type: FETCH_ERROR, payload: "Harap masukkan Captcha!" });
      return false;
    }
    resendVerification({...values}).then(data => {
      setShowAlert(true)
    })
  };

  useEffect(() => {
    if (authUser !== null) {
      props.history.push("/");
    }
  }, [authUser, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        {showAlert && (
          <Alert
            message={"Pengiriman Berhasil"}
            description={"Kode verifikasi registrasi berhasil dikirim, silahkan check email untuk melakukan konfirmasi (check folder spam, promotions, dll)"}
            type="success"
            showIcon
            closable={showAlert}
            afterClose={() => setShowAlert(false)}
          />
        )}
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img alt="example" src="bg-rg.jpg" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="Resend Email Verifikasi" />
              </h1>
              <p>
                <IntlMessages id="Mengirim ulang kode verifikasi registrasi pengguna ke email anda" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img
                alt="example"
                src="/assets/images/logo-idn.png"
                width="150"
              />
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Harap masukkan E-mail!",
                  },
                ]}
              >
                <Input placeholder="Masukkan Email" />
              </FormItem>
              <FormItem>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={() => { }}
                />
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="Kirim" />
                </Button>
                <span>
                  <IntlMessages id="atau" />
                </span>{" "}
                <Link to="/signin">
                  <IntlMessages id="Login" />
                </Link>
              </FormItem>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

export default ResendVerification;
