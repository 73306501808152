// Import package
import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

// Import file
import TrainingRegistrationsFilterContainer from "../../containers/adminRole/trainingRegistration/TrainingRegistrationsFilterContainer";
import TrainingRegistrationsListContainer from "../../containers/adminRole/trainingRegistration/TrainingRegistrationsListContainer";
import PageContentWrapper from "../../components/PageContentWrapper";

export default function TrainingRegistrationPage() {
  return (
    <PageContentWrapper
      title="Peserta Training"
      extra={
        <Link to="/training-registration/add">
          <Button type="primary" style={{ margin: 0 }} icon={<PlusOutlined />}>
            Tambah
          </Button>
        </Link>
      }
    >
      <TrainingRegistrationsFilterContainer />
      <TrainingRegistrationsListContainer />
    </PageContentWrapper>
  );
}
