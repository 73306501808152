// Import package
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Import file
import TrainingParticipantActionsContainer from "../../containers/adminRole/trainingParticipant/TrainingparticipantActionsContainer";
import { getTrainingRegistration } from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import TrainingParticipantDescription from "../../components/adminRole/trainingParticipant/TrainingParticipantDescription";
import PageContentWrapper from "../../components/PageContentWrapper";
import TrainingRegistrationDetail from "../../components/participantRole/trainingRegistration/TrainingRegistrationDetail";

const AdminRolePage = ({
  trainingRegistration,
  trainingRegistrationLoading,
  id,
}) => {
  return trainingRegistration && !trainingRegistrationLoading ? (
    <PageContentWrapper
      backLink="/training-registration"
      title={"Registrasi #" + id}
      extra={
        <TrainingParticipantActionsContainer
          trainingParticipantId={id}
          trainingRegistration={trainingRegistration}
        />
      }
    >
      <TrainingParticipantDescription data={trainingRegistration} />
    </PageContentWrapper>
  ) : trainingRegistrationLoading ? (
    <CircularProgress />
  ) : (
    "tidak ditemukan"
  );
};

const ParticipantRolePage = ({
  trainingRegistration,
  trainingRegistrationLoading,
}) => {
  return trainingRegistration && !trainingRegistrationLoading ? (
    <PageContentWrapper backLink="/my-training" title={"Detail Training"}>
      <TrainingRegistrationDetail data={trainingRegistration} />
    </PageContentWrapper>
  ) : trainingRegistrationLoading ? (
    <CircularProgress />
  ) : (
    "tidak ditemukan"
  );
};

export default function DetailTrainingRegistration() {
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const { trainingRegistration, trainingRegistrationLoading } = useSelector(
    ({ trainingRegistration }) => trainingRegistration
  );

  const { trainingRegistrationId } = useParams();
  useEffect(() => {
    dispatch(getTrainingRegistration(trainingRegistrationId));
  }, [dispatch]);
  
  return authUser.role === "admin" ? (
    <AdminRolePage
      trainingRegistration={trainingRegistration}
      trainingRegistrationLoading={trainingRegistrationLoading}
      id={trainingRegistrationId}
    />
  ) : (
    <ParticipantRolePage
      trainingRegistration={trainingRegistration}
      trainingRegistrationLoading={trainingRegistrationLoading}
    />
  );
}
