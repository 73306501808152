import React, { useState } from "react";
import { Space, Select, DatePicker, Input, Button } from "antd";
export default function MyTrainingFilter({
  onSearch,
  onChangeStatus,
  onChangeTrainingOption,
  onChangeMonth,
  onChangeIsRetraining,
  search = "",
  status = null,
  trainingOption = null,
  month = null,
  isRetraining = null,
  trainingOptions = [],
  trainingOptionsLoading = false,
  onReset,
  enableReset = false,
}) {
  const [searchValue, setSearchValue] = useState(search);
  return (
    <Space wrap style={{ marginBottom: 10 }}>
      <Input.Search
        style={{ margin: 0 }}
        allowClear
        placeholder="Cari"
        onSearch={onSearch}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <Select
        placeholder="Status"
        defaultActiveFirstOption
        dropdownMatchSelectWidth={false}
        value={status}
        onChange={onChangeStatus}
        se
      >
        <Select.Option value={null}>Semua status</Select.Option>
        <Select.Option value="canceled">Batal</Select.Option>
        <Select.Option value="paid_off">Lunas</Select.Option>
        <Select.Option value="waiting_for_down_payment">
          Belum bayar
        </Select.Option>
        <Select.Option value="waiting_for_paid_off">Belum lunas</Select.Option>
      </Select>
      <DatePicker
        placeholder="Pilih bulan"
        picker="month"
        format="MMMM YYYY"
        onChange={onChangeMonth}
        value={month}
      />
      {enableReset && (
        <Button
          type="primary"
          style={{ margin: 0 }}
          onClick={() => {
            onReset();
            setSearchValue("");
          }}
        >
          Reset
        </Button>
      )}
    </Space>
  );
}
