import { message } from "antd";
import { baseRequest } from "../../util/Api";

export const isGoogleCalendarAuthenticated = () => {
  return new Promise((resolve, reject) => {
    baseRequest("get", "/google-calendar/verify")
      .then((data) => {
        resolve(data)
      }).catch((e) => {
        reject(e)
      })
  })
}

export const getGoogleCalendarAuthUrl = () => {
  return new Promise((resolve, reject) => {
    baseRequest("get", "/google-auth/login")
      .then((data) => {
        resolve(data)
      }).catch((e) => {
        reject(e)
      })
  })
}
