import React, { useEffect } from "react";
import DashboardBarChart from "../../../components/adminRole/dashboard/DashboardBarChart";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardTrainingRegistPaidGraph } from "../../../appRedux/actions";
import FilterBarChartParticipant from "../../../components/adminRole/dashboard/FilterBarChartParticipant";

export default function BarChartRegisterPaidThisMonth({ locations }) {
  const dispatch = useDispatch();
  const { trainingRegistPaidGraph, trainingRegistPaidGraphFilter } =
    useSelector(({ dashboard }) => dashboard);

  useEffect(() => {
    dispatch(
      getDashboardTrainingRegistPaidGraph(trainingRegistPaidGraphFilter)
    );
  }, []);

  const handleChangeType = (filter, dates) => {
    dispatch(getDashboardTrainingRegistPaidGraph(filter, dates));
  };

  const getRegisterPaidData = () =>
    trainingRegistPaidGraph.map(function (register) {
      return {
        name: register.training_option_name,
        pendaftar_bayar: register.total_register,
      };
    });

  return (
    <>
      <FilterBarChartParticipant
        value={trainingRegistPaidGraphFilter}
        onChange={handleChangeType}
        locations={locations}
      />
      <DashboardBarChart
        barKey="pendaftar_bayar"
        fillColor="#74dcbb"
        data={getRegisterPaidData()}
        barName="Pendaftar Bayar"
      />
    </>
  );
}
