import {
  SET_TRAININGS,
  SET_GET_TRAININGS_LOADING,
  SET_TOTAL_TRAININGS,
  SET_GET_TRAININGS_FILTER,
  SET_TRAININGS_ONLINE,
  SET_TRAININGS_LOCATION,
  SET_TRAININGS_DATES,
  SET_TRAININGS_SEARCH,
  SET_TRAININGS_TRAINING_OPTION,
  SET_TRAININGS_TRAINER,
  SET_TRAININGS_PAGINATION,
  RESET_TRAININGS_FILTER,
  DELETE_TRAINING,
  CANCEL_TRAINING,
  ENABLE_TRAINING,
  CREATE_TRAINING,
  SET_CREATE_TRAINING_LOADING,
  SET_TRAINING,
  SET_TRAINING_LOADING,
  SET_TRAINING_CALENDAR,
  SET_TRAINING_CALENDAR_FILTER,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  trainings: [],
  training: null,
  trainingLoading: false,
  getTrainingsLoading: false,
  createTrainingLoading: false,
  totalTrainings: 0,
  getTrainingsFilter: {
    search: "",
    start_date: null,
    end_date: null,
    is_online: null,
    location: null,
    current_page: 1,
    page_size: 10,
  },
  calendar: [],
  calendarFilter: null,
};

const TrainingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TRAININGS: {
      return {
        ...state,
        trainings: action.payload,
        getTrainingsLoading: false,
      };
    }
    case SET_TRAINING_CALENDAR: {
      return {
        ...state,
        calendar: action.payload,
      };
    }
    case SET_TRAINING_CALENDAR_FILTER: {
      return {
        ...state,
        calendarFilter: action.payload,
      };
    }
    case SET_TRAINING: {
      return {
        ...state,
        training: action.payload,
        trainingLoading: false,
      };
    }
    case SET_TRAINING_LOADING: {
      return {
        ...state,
        trainingLoading: action.payload,
      };
    }
    case CREATE_TRAINING: {
      return {
        ...state,
        trainings: state.trainings.concat(action.payload),
        totalTrainings: state.totalTrainings + 1,
        createTrainingLoading: false,
      };
    }
    case SET_CREATE_TRAINING_LOADING: {
      return {
        ...state,
        createTrainingLoading: action.payload,
      };
    }
    case SET_GET_TRAININGS_LOADING: {
      return {
        ...state,
        getTrainingsLoading: action.payload,
      };
    }
    case SET_TOTAL_TRAININGS: {
      return {
        ...state,
        totalTrainings: action.payload,
      };
    }
    case SET_GET_TRAININGS_FILTER: {
      return {
        ...state,
        getTrainingsFilter: action.payload,
      };
    }
    case SET_TRAININGS_ONLINE: {
      let newFilter = {
        is_online: action.payload,
        search: "",
        start_date: null,
        end_date: null,
        current_page: 1,
        page_size: state.getTrainingsFilter.page_size,
        location: null,
      };
      return {
        ...state,
        getTrainingsFilter: newFilter,
      };
    }
    case SET_TRAININGS_LOCATION: {
      return {
        ...state,
        getTrainingsFilter: {
          ...state.getTrainingsFilter,
          location: action.payload,
          current_page: 1,
        },
      };
    }
    case SET_TRAININGS_DATES: {
      return {
        ...state,
        getTrainingsFilter: {
          ...state.getTrainingsFilter,
          start_date: action.payload[0],
          end_date: action.payload[1],
          current_page: 1,
        },
      };
    }
    case SET_TRAININGS_SEARCH: {
      return {
        ...state,
        getTrainingsFilter: {
          ...state.getTrainingsFilter,
          search: action.payload,
          current_page: 1,
        },
      };
    }
    case SET_TRAININGS_TRAINING_OPTION: {
      let newFilter = {
        ...state.getTrainingsFilter,
        current_page: 1,
        training_option_id: action.payload,
      };
      if (action.payload === "all") {
        delete newFilter["training_option_id"];
      }
      return {
        ...state,
        getTrainingsFilter: newFilter,
      };
    }
    case SET_TRAININGS_TRAINER: {
      let newFilter = {
        ...state.getTrainingsFilter,
        current_page: 1,
        trainer_id: action.payload,
      };
      if (action.payload === "all") {
        delete newFilter["trainer_id"];
      }
      return {
        ...state,
        getTrainingsFilter: newFilter,
      };
    }
    case SET_TRAININGS_PAGINATION: {
      return {
        ...state,
        getTrainingsFilter: {
          ...state.getTrainingsFilter,
          page_size: action.payload.pageSize,
          current_page: action.payload.currentPage,
        },
      };
    }
    case RESET_TRAININGS_FILTER: {
      return {
        ...state,
        getTrainingsFilter: {
          ...state.getTrainingsFilter,
          start_date: null,
          search: "",
          end_date: null,
          trainer_id: null,
          training_option_id: null,
          current_page: 1,
        },
      };
    }
    case DELETE_TRAINING: {
      return {
        ...state,
        totalTrainings: state.totalTrainings - 1,
        trainings: state.trainings.filter(
          (training) => training.id !== action.payload
        ),
        training: null,
        getTrainingsFilter: {
          ...state.getTrainingsFilter,
          current_page: 1,
        },
      };
    }
    case CANCEL_TRAINING: {
      return {
        ...state,
        trainings: state.trainings.map((training) =>
          training.id === action.payload
            ? { ...training, is_running: false }
            : training
        ),
        training:
          state.training &&
          (state.training.id === action.payload
            ? { ...state.training, is_running: false }
            : state.training),
      };
    }
    case ENABLE_TRAINING: {
      return {
        ...state,
        trainings: state.trainings.map((training) =>
          training.id === action.payload
            ? { ...training, is_running: true }
            : training
        ),
        training:
          state.training &&
          (state.training.id === action.payload
            ? { ...state.training, is_running: true }
            : state.training),
      };
    }
    default:
      return state;
  }
};

export default TrainingReducer;
