import { setErrorMessage } from "./Common";
import { baseRequest } from "../../util/Api";
import {
  SET_DASHBOARD_SUMMARY,
  SET_DASHBOARD_SUMMARY_LOADING,

  SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH,
  SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH,
  SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH,
  SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH,
  SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH,
  SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH,
  SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH,
  SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH,
  SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_FILTER,

} from "../../constants/ActionTypes";

export const getDashboardSummary = () => (dispatch) => {
  dispatch({
    type: SET_DASHBOARD_SUMMARY_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/summary")
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_SUMMARY,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_SUMMARY_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingParticipantRegularGraph = (filter, dates) => (dispatch) => {
  let newFilter = {};
  if (filter === "1") {
    newFilter = { is_online: null, location: null, is_retraining: false };
  } else if (filter === "2") {
    newFilter = { is_online: true, location: null, is_retraining: false };
  } else if (filter === "3") {
    newFilter = { is_online: false, location: null, is_retraining: false };
  } else {
    newFilter = { is_online: false, location: filter, is_retraining: false };
  }
  if (dates) {
    newFilter.start_date = dates[0].toISOString()
    newFilter.end_date = dates[1].toISOString()
  }
  dispatch({
    type: SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/training-participant-graph", newFilter)
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH,
        payload: data,
      });
      dispatch({
        type: SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_FILTER,
        payload: filter,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingParticipantRetrainingGraph = (filter, dates) => (dispatch) => {
  let newFilter = {};
  if (filter === "1") {
    newFilter = { is_online: null, location: null, is_retraining: true };
  } else if (filter === "2") {
    newFilter = { is_online: true, location: null, is_retraining: true };
  } else if (filter === "3") {
    newFilter = { is_online: false, is_retraining: true };
  } else {
    newFilter = { is_online: false, location: filter, is_retraining: true };
  }
  if (dates) {
    newFilter.start_date = dates[0].toISOString()
    newFilter.end_date = dates[1].toISOString()
  }
  dispatch({
    type: SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/training-participant-graph", newFilter)
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH,
        payload: data,
      });
      dispatch({
        type: SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_FILTER,
        payload: filter,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingRegistPaidGraph = (filter, dates) => (dispatch) => {
  let newFilter = {};
  if (filter === "1") {
    newFilter = { is_online: null, location: null, is_cancel: false };
  } else if (filter === "2") {
    newFilter = { is_online: true, location: null, is_cancel: false };
  } else if (filter === "3") {
    newFilter = { is_online: false, is_cancel: false };
  } else {
    newFilter = { is_online: false, location: filter, is_cancel: false };
  }
  if (dates) {
    newFilter.start_date = dates[0].toISOString()
    newFilter.end_date = dates[1].toISOString()
  }
  dispatch({
    type: SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/training-register-graph", newFilter)
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH,
        payload: data,
      });
      dispatch({
        type: SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_FILTER,
        payload: filter,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingRegistCancelGraph = (filter, dates) => (dispatch) => {
  let newFilter = {};
  if (filter === "1") {
    newFilter = { is_online: null, location: null, is_cancel: true };
  } else if (filter === "2") {
    newFilter = { is_online: true, location: null, is_cancel: true };
  } else if (filter === "3") {
    newFilter = { is_online: false, is_cancel: true };
  } else {
    newFilter = { is_online: false, location: filter, is_cancel: true };
  }
  if (dates) {
    newFilter.start_date = dates[0].toISOString()
    newFilter.end_date = dates[1].toISOString()
  }
  dispatch({
    type: SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/training-register-graph", newFilter)
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH,
        payload: data,
      });
      dispatch({
        type: SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_FILTER,
        payload: filter,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingStatusRunningGraph = (filter, dates) => (dispatch) => {
  let newFilter = {};
  if (filter === "1") {
    newFilter = { is_online: null, location: null, is_running: true };
  } else if (filter === "2") {
    newFilter = { is_online: true, location: null, is_running: true };
  } else if (filter === "3") {
    newFilter = { is_online: false, is_running: true };
  } else {
    newFilter = { is_online: false, location: filter, is_running: true };
  }
  if (dates) {
    newFilter.start_date = dates[0].toISOString()
    newFilter.end_date = dates[1].toISOString()
  }
  dispatch({
    type: SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/training-status-graph", newFilter)
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH,
        payload: data,
      });
      dispatch({
        type: SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_FILTER,
        payload: filter,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingStatusCancelGraph = (filter, dates) => (dispatch) => {
  let newFilter = {};
  if (filter === "1") {
    newFilter = { is_online: null, location: null, is_running: false };
  } else if (filter === "2") {
    newFilter = { is_online: true, location: null, is_running: false };
  } else if (filter === "3") {
    newFilter = { is_online: false, is_running: false };
  } else {
    newFilter = { is_online: false, location: filter, is_running: false };
  }
  if (dates) {
    newFilter.start_date = dates[0].toISOString()
    newFilter.end_date = dates[1].toISOString()
  }
  dispatch({
    type: SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/training-status-graph", newFilter)
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH,
        payload: data,
      });
      dispatch({
        type: SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_FILTER,
        payload: filter,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingRegistMonthlyGraph =
  (filter) => (dispatch) => {
    dispatch({
      type: SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_LOADING,
      payload: true,
    });
    baseRequest("get", "/dashboard/training-registration-monthly-graph", filter)
      .then((data) => {
        dispatch({
          type: SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH,
          payload: data,
        });
        dispatch({
          type: SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_FILTER,
          payload: filter,
        });
      })
      .catch((e) => {
        dispatch(setErrorMessage(e));
        dispatch({
          type: SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_LOADING,
          payload: false,
        });
      });
  };

export const getDashboardTrainingParticipantsMonthlyGraph =
  (filter) => (dispatch) => {
    dispatch({
      type: SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_LOADING,
      payload: true,
    });
    baseRequest("get", "/dashboard/training-participants-monthly-graph", filter)
      .then((data) => {
        dispatch({
          type: SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH,
          payload: data,
        });
        dispatch({
          type: SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_FILTER,
          payload: filter,
        });
      })
      .catch((e) => {
        dispatch(setErrorMessage(e));
        dispatch({
          type: SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_LOADING,
          payload: false,
        });
      });
  };

export const getDashboardTrainingRegistDailyGraph = (filter) => {
  return new Promise((resolve, reject) => {
    baseRequest("get", "/dashboard/training-registration-daily-graph", filter)
      .then((data) => {
        resolve(data)
      }).catch((e) => {
        reject(e)
      })
  })
}

export const getLocations = () => {
  return new Promise((resolve, reject) => {
    baseRequest("get", "/public/locations")
      .then((data) => {
        resolve(data)
      }).catch((e) => {
        reject(e)
      })
  })
}

export const getDashboardTrainingParticipantYearlyGraph = (filter) => {
  return new Promise((resolve, reject) => {
    baseRequest("get", "/dashboard/training-participants-yearly-graph", filter)
      .then((data) => {
        resolve(data)
      }).catch((e) => {
        reject(e)
      })
  })
}

export const getDashboardTrainingRegisterYearlyGraph = (filter) => {
  return new Promise((resolve, reject) => {
    baseRequest("get", "/dashboard/training-registration-yearly-graph", filter)
      .then((data) => {
        resolve(data)
      }).catch((e) => {
        reject(e)
      })
  })
}