import React from 'react'
import { Space, Select, Row, Col, DatePicker } from "antd";
import moment from 'moment';
const { RangePicker } = DatePicker;

export default function FilterBarChartMonthlyRegister({
  valueFilterTraining,
  onChangeFilterTraining,
  trainingOptions,
  valueFilterStatus,
  onChangeFilterStatus,
  locations,
  filter,
  setFilter,
  yearly = false,
}) {
  let options = [
    { value: null, label: "Semua Lokasi" },
    { value: "Online", label: "Online" },
    { value: "Offline", label: "Offline" },
  ]
  if (locations)
    locations.map(location => {
      options.push({ value: location, label: location })
    })
  let trainingCategories = []
  trainingOptions.map(trainingOption => {
    trainingCategories.push({
      value: trainingOption.training_category_id,
      label: trainingOption.training_category.name,
    })
  })
  trainingCategories = trainingCategories.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.value === value.value && t.label === value.label
    ))
  )
  trainingCategories.sort((a, b) => {
    return sortOptions(a, b)
  })
  trainingCategories = [{
    value: null, label: "Semua Kategori"
  }].concat(trainingCategories)

  let trainingsOptions = []
  trainingOptions.map(trainingOption => {
    trainingsOptions.push({
      value: trainingOption.id,
      label: trainingOption.name,
      category_id: trainingOption.training_category_id
    })
  })
  trainingsOptions.sort((a, b) => {
    return sortOptions(a, b)
  })
  trainingsOptions = [{
    value: null, label: "Semua Training", category_id: null
  }].concat(trainingsOptions)

  function sortOptions(a, b) {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();
    if (nameA < nameB)
      return -1;
    if (nameA > nameB)
      return 1;
    return 0;
  }

  return (
    <Row wrap gutter={[16, 16]} justify="space-between">
      <Col>
        <Space wrap>
        <RangePicker
            picker={yearly ? "year" : "month"}
            value={[moment(filter.start_date), moment(filter.end_date)]}
            onChange={(values) => {
              // setDates(values)
              setFilter({ ...filter, start_date: moment(values[0]).toISOString(), end_date: moment(values[1]).toISOString() })
            }}
            // style={{ borderRadius: 0, width: 200 }}
          />
          <Select
            value={filter.location}
            dropdownMatchSelectWidth={true}
            onChange={(v) => {
              setFilter({ ...filter, location: v })
              // setLocation(v)
              // setFilter(v)
              // onChange(v, dates)
            }}
            style={{ borderRadius: 0, width: 100 }}
            options={options}
          ></Select>
          <Select
            value={filter.category_id}
            dropdownMatchSelectWidth={true}
            onChange={(v) => {
              setFilter({ ...filter, category_id: v, training_option_id: null })
              // setCategory(v)
              // setTraining(null)
              // setFilter(v)
              // onChange(v, dates)
            }}
            style={{ borderRadius: 0, width: 140 }}
            options={trainingCategories}
          ></Select>
          <Select
            showSearch
            value={filter.training_option_id}
            dropdownMatchSelectWidth={true}
            onChange={(v) => {
              setFilter({ ...filter, training_option_id: v })
              // setTraining(v)
              // setFilter(v)
              // onChange(v, dates)
            }}
            style={{ borderRadius: 0, width: 200 }}
            options={trainingsOptions.filter((training) => {
              if (!filter.category_id || !training.value)
                return true
              if (filter.category_id == training.category_id)
                return true
            })}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          ></Select>
          <Select
            showSearch
            value={filter.status}
            dropdownMatchSelectWidth={false}
            style={{ width: 200 }}
            onChange={(v) => {
              setFilter({ ...filter, status: v })
            }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option value={null}>Semua pendaftar</Select.Option>
            <Select.Option value="canceled">Pendaftar cancel</Select.Option>
            <Select.Option value="paid">Pendaftar bayar</Select.Option>
          </Select>
        </Space>
      </Col>
    </Row>
  )
}
