import React, { useEffect, useState } from "react";
import { Typography, Card, Button, Alert } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import {
  getTrainingRegistrations,
  getAds,
  getParticipant,
} from "../../appRedux/actions";
import { toRupiah } from "libs";
import Carousels from "../../components/participantRole/dashboard/Carousels";
import ChartCards from "../../components/participantRole/dashboard/ChartCards";
import CircularProgress from "../../components/CircularProgress";
import TrainingRunningList from "../../components/participantRole/dashboard/TrainingRunningList";
import TrainingWaitingPaidOffList from "../../components/participantRole/dashboard/TrainingWaitingPaidOffList";
import TrainingNotPaidList from "../../components/participantRole/dashboard/TrainingNotPaidList";

export default function ParticipantRolePage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const activeTab = query.get("tab") || "running";
  let filter = queryString.parse(useLocation().search);
  const [showAlert, setShowAlert] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);
  const { participant, participantLoading } = useSelector(
    ({ participant }) => participant
  );

  const { ads, adsLoadings } = useSelector(({ adSlot }) => adSlot);
  const {
    trainingRegistrations,
    trainingRegistrationsLoading,
    totalTrainingRegistrations,
  } = useSelector(({ trainingRegistration }) => trainingRegistration);
  const handleTabsChange = (key) => {
    history.push("/dashboard?tab=" + key);
  };

  useEffect(() => {
    dispatch(getAds());
    dispatch(getTrainingRegistrations({ page_size: 100 }));
    dispatch(getParticipant(authUser.participant.id));
  }, [dispatch]);

  const tabList = [
    {
      key: "running",
      tab: "Training yang diikuti",
    },
    {
      key: "waiting-paid-off",
      tab: "Training belum lunas",
    },
    {
      key: "not-paid",
      tab: "Training belum bayar",
    },
  ];

  function totalBillNotPaid() {
    let sum = 0;
    for (let i = 0; i < trainingRegistrations.length; i++) {
      if (
        trainingRegistrations[i].status === "waiting_for_paid_off" ||
        trainingRegistrations[i].status === "waiting_for_down_payment"
      ) {
        var total =
          trainingRegistrations[i].total_price -
          trainingRegistrations[i].already_paid;
        sum += total;
      }
    }
    return toRupiah(sum);
  }
  return adsLoadings && participantLoading ? (
    <CircularProgress />
  ) : ads && participant && !participantLoading ? (
    <PageContentWrapper
      title="Dashboard"
      extra={
        participant.whatsapp_number == null ||
        participant.company == null ||
        participant.job_position == null ||
        participant.address == null ||
        participant.shirt_size == null ? (
          <Button
            type="primary"
            onClick={() => setShowAlert(true)}
            style={{ margin: 0 }}
          >
            Daftar Training
          </Button>
        ) : (
          <Link to="/my-training/training-registration">
            <Button type="primary" style={{ margin: 0 }}>
              Daftar Training
            </Button>
          </Link>
        )
      }
    >
      {showAlert && (
        <Alert
          message={
            <p>
              Anda harus melengkapi profile Anda sebelum mendaftar,{" "}
              <Link to="/edit-profile">klik disini</Link>
            </p>
          }
          type="error"
          closable={showAlert}
          afterClose={() => setShowAlert(false)}
        />
      )}
      <Carousels adsList={ads} />
      <ChartCards
        totalTrainingAttended={
          trainingRegistrations.filter((tr) => tr.status === "paid_off").length
        }
        totalTrainingWaitingPaidOff={
          trainingRegistrations.filter(
            (tr) =>
              tr.status === "waiting_for_paid_off" ||
              tr.status === "waiting_for_down_payment"
          ).length
        }
        totalBillNotPaid={totalBillNotPaid()}
      />
      <Card
        title={<Typography.Title level={3}>Ringkasan</Typography.Title>}
        extra={<Link to="/my-training">Lihat semua</Link>}
        style={{
          boxShadow: "0 0 5px 5px rgba(0,0,0,0.03)",
          paddingRight: "20px",
          paddingLeft: "20px",
          paddingTop: "10px",
          paddingBottom: "10px",
          width: "100%",
          borderRadius: "10px",
        }}
        tabList={tabList}
        activeTabKey={activeTab}
        onTabChange={handleTabsChange}
      >
        {activeTab === "running" && (
          <TrainingRunningList
            trainingRegistrations={trainingRegistrations}
            loading={trainingRegistrationsLoading}
            totalTrainingRegistrations={totalTrainingRegistrations}
            // onChangeCurrentPage={handleChangeCurrentPage}
            // onChangePageSize={handleChangePageSize}
            pageSize={filter.page_size ? parseInt(filter.page_size) : 6}
            currentPage={
              filter.current_page ? parseInt(filter.current_page) : 1
            }
          />
        )}

        {activeTab === "waiting-paid-off" && (
          <TrainingWaitingPaidOffList
            trainingRegistrations={trainingRegistrations}
            loading={trainingRegistrationsLoading}
            totalTrainingRegistrations={totalTrainingRegistrations}
            // onChangeCurrentPage={handleChangeCurrentPage}
            // onChangePageSize={handleChangePageSize}
            pageSize={filter.page_size ? parseInt(filter.page_size) : 6}
            currentPage={
              filter.current_page ? parseInt(filter.current_page) : 1
            }
          />
        )}

        {activeTab === "not-paid" && (
          <TrainingNotPaidList
            trainingRegistrations={trainingRegistrations}
            loading={trainingRegistrationsLoading}
            totalTrainingRegistrations={totalTrainingRegistrations}
            // onChangeCurrentPage={handleChangeCurrentPage}
            // onChangePageSize={handleChangePageSize}
            pageSize={filter.page_size ? parseInt(filter.page_size) : 6}
            currentPage={
              filter.current_page ? parseInt(filter.current_page) : 1
            }
          />
        )}
      </Card>
    </PageContentWrapper>
  ) : (
    <CircularProgress />
  );
}
