// Import package
import React from "react";
import { List, Card, Typography, Badge, Tag } from "antd";
import { Link } from "react-router-dom";
import { UserOutlined, ClockCircleOutlined } from "@ant-design/icons";

// Component
export default function TrainingList({ trainings }) {
  return (
    <List
      grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
      dataSource={trainings}
      size="small"
      itemLayout="vertical"
      renderItem={(item) => (
        <List.Item style={{ padding: 0 }}>
          <Link to={"/training/" + item.id}>
            <Card
              title={
                <Typography.Text strong>
                  {item.training_option.name}
                </Typography.Text>
              }
              bordered={false}
              style={{
                boxShadow: "0 0 5px 5px rgba(0,0,0,0.03)",
                cursor: "pointer",
                padding: "20px",
              }}
              extra={
                <Badge
                  style={{ margin: 0 }}
                  text={item.is_running ? "Running" : "Batal"}
                  status={item.is_running ? "success" : "error"}
                />
              }
            >
              <p>
                <span className="gx-mr-3 gx-d-inline-block">
                  {item.dates_summary}
                </span>
              </p>
              <p>
                <span className="gx-mr-3 gx-d-inline-block gx-text-grey">
                  <UserOutlined /> {item.total_participants} peserta
                </span>
                <span className="gx-d-inline-block gx-text-grey gx-mr-3">
                  <ClockCircleOutlined /> {item.time_start} - {item.time_end}
                </span>
                <span className="gx-d-inline-block">
                  <Tag
                    style={{ margin: 0 }}
                    color={item.is_online ? "green" : "blue"}
                  >
                    {item.is_online ? "ONLINE" : "OFFLINE"}
                  </Tag>
                </span>
              </p>
            </Card>
          </Link>
        </List.Item>
      )}
    />
  );
}
