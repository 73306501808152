import React from "react";
import { Modal, Form, Button, Select } from "antd";

export default function ModalExportAll({ visible, onCancel, onFinish }) {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Ekspor semua peserta"
      style={{ top: 10 }}
      destroyOnClose={true}
      footer={null}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Pilih tipe"
          name="type"
          rules={[{ required: true, message: "Mohon pilih tipe ekspor" }]}
        >
          <Select
            //   loading={trainingOptionsLoading}
            showSearch
            placeholder="pilih tipe ekspor"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option
              key={"all-participant"}
              value={"all-participant"}
              label={"all-participant"}
            >
              Semua peserta
            </Select.Option>
            <Select.Option
              key={"only-registered-training-participant"}
              value={"only-registered-training-participant"}
              label={"only-registered-training-participant"}
            >
              Peserta yang sudah ikut training saja
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button onClick={onCancel}>Batal</Button>
          <Button type="primary" htmlType="submit">
            Ekspor
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
